// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { ButtonHTMLAttributes, forwardRef } from 'react';
import type { ExpandedRow } from '@tanstack/react-table';
import { classNames } from 'features/utils/classnames';
import styles from './expander.module.css';
import { AppIcon } from 'components/app-icon';

export interface ExpanderProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  readonly expander: ExpandedRow;
}

export const Expander = forwardRef<HTMLButtonElement, ExpanderProps>(
  ({ expander, className, ...props }, ref) => (
    <button
      ref={ref}
      type='button'
      aria-expanded={expander.getIsExpanded()}
      aria-hidden={!expander.getCanExpand()}
      className={classNames(styles.button, className)}
      {...props}
    >
      <AppIcon
        icon={expander.getIsExpanded() ? 'CaretDown' : 'CaretRight'}
        color='disabled'
      />
    </button>
  )
);

Expander.displayName = 'Expander';
