// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { useEffect, useState, useCallback } from 'react';
import { citecApi } from 'api/engine/citec-api';
import { useSafeUpdate } from './use-safe-update';
import { useAppDispatch } from 'store/store';
import { setOrganizations } from 'store/slices/user';

export const useValidateRegisteredUser = () => {
  const dispatch = useAppDispatch();
  const runSafeUpdate = useSafeUpdate();
  const [isUserInDb, setIsUserInDb] = useState(false);

  const validateRegisteredUserInDb = useCallback(async () => {
    try {
      const data = await citecApi.getUser();
      dispatch(setOrganizations(data.organizations ?? []));
      return !!data.user_id;
    } catch (error) {
      console.log(error);

      return false;
    }
  }, [dispatch]);

  const registerUserInDb = useCallback(async () => {
    const data = await citecApi.postUser();

    if (data.user_id) return true;

    return false;
  }, []);

  useEffect(() => {
    const runValidateRegisteredUserInDb = async () => {
      try {
        const isRegisteredUser = await validateRegisteredUserInDb();

        runSafeUpdate(() => setIsUserInDb(isRegisteredUser));

        if (!isRegisteredUser) registerUserInDb();
      } catch (error) {
        console.log(error);
      }
    };

    runValidateRegisteredUserInDb().catch((error) => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isUserInDb;
};
