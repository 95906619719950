// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { PaginationState, OnChangeFn } from '@tanstack/react-table';
import { produce } from 'immer';
import { useCallback, useState } from 'react';

export interface UsePaginationProps extends PaginationState {
  pageCount: number;
}

export type UsePaginationResult = ReturnType<typeof usePagination>;

export const usePagination = (initialState?: UsePaginationProps) => {
  const [pagination, setPagination] = useState<UsePaginationProps>(
    () =>
      initialState ?? {
        pageCount: 1,
        pageIndex: 0,
        pageSize: 50,
      }
  );

  const onPaginationChange: OnChangeFn<PaginationState> = useCallback((state) => {
    setPagination((prev) =>
      produce(prev, (draft) =>
        typeof state === 'function'
          ? Object.assign(draft, state(draft))
          : Object.assign(draft, state)
      )
    );
  }, []);

  const onPaginationCountChange = useCallback((count: number) => {
    setPagination((prev) =>
      produce(prev, (draft) => {
        draft.pageCount = count;
      })
    );
  }, []);

  return {
    ...pagination,
    onPaginationChange,
    onPaginationCountChange,
  };
};
