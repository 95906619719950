// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { useCallback } from 'react';
import { useUnmountSignal } from './use-unmount-signal';

/**
 * A React hook that provides a safe way to update state in a component.
 * This hook uses an AbortSignal to ensure that state updates are only applied if the component is still mounted.
 * This is useful to prevent the "Can't perform a React state update on an unmounted component" error.
 *
 * @returns {(fn: VoidFunction) => void} A function that can be used to safely update state.
 *
 * @example
 * function MyComponent() {
 *   const safeUpdate = useSafeUpdate();
 *
 *   useEffect(() => {
 *     fetch().then(data => {
 *       safeUpdate(() => {
 *         // Update state here
 *       });
 *     });
 *   }, []);
 *
 *   return (...)
 * }
 */
export const useSafeUpdate = (): ((fn: () => void) => void) => {
  const signal = useUnmountSignal();

  const runSafeUpdate = useCallback(
    (fn: () => void) => {
      if (!signal.aborted) {
        return fn();
      }
    },
    [signal.aborted]
  );

  return runSafeUpdate;
};
