// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

export enum Broker {
  Alpaca = 'alpaca',
  Tradier = 'tradier',
  IBKR = 'interactive_brokers',
}

interface BaseAccountValues {
  investment: number;
  unrealized_capital_gains: number;
  buying_power: number;
  cash: number;
  aum: number;
  currency: string;
}

export interface AccountInfo extends BaseAccountValues {
  account_id: string;
  currency: string;
  account_type: string;
  name: string;
  last_name: string | null;
  account_manager: string;
  local_currency_values: BaseAccountValues;
}

export interface TransactionInfo {
  type: string;
  amount: number;
  currency: string;
  description: string;
  date: string;
  symbol: string;
}

export interface BrokerAccountResp {
  buying_power: number;
  cash: number;
  currency: string;
  equity: number;
  portfolio_value: number;
}
