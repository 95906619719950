// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { fetchEngineApi } from 'api/engine-api';
import {
  SubscriptionApi,
  SubscriptionsResp,
} from 'api/interfaces/engine/subscriptions';

export const subscriptionsApi: SubscriptionApi = {
  voucher: async (params) => {
    const { data } = await fetchEngineApi<SubscriptionsResp>({
      endpoint: '/subscriptions/voucher/',
      method: 'POST',
      params,
    });
    return data;
  },

  subscription: async () => {
    const { data } = await fetchEngineApi<SubscriptionsResp>({
      endpoint: '/subscriptions/subscription/',
      method: 'GET',
    });

    return data;
  },

  trial: async () => {
    const { data } = await fetchEngineApi<SubscriptionsResp>({
      endpoint: '/subscriptions/trial/',
      method: 'POST',
    });

    return data;
  },
};
