// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAiApi } from 'api/ai-api';
import { screenerApi } from 'api/ai/screener-api';
import { SearchAssetClass } from 'api/interfaces/ai/asset-allocation';
import { OptimizationObjective } from 'api/interfaces/ai/portfolio';
import { segmentsFormatted } from 'features/utils/segments-formatted';

export const getUniverseData = async (id?: string) => {
  const universesPromise = screenerApi.stockSettingsUniverseBase();

  const sectorsPromise = await screenerApi.settingsByType({
    assetType: 'stock',
    settingType: 'sectors',
  });

  const factorsPromise = await screenerApi.settingsByType({
    assetType: 'stock',
    settingType: 'factors',
  });

  const [universesResp, sectorsResp, factorsResp] = await Promise.all([
    universesPromise,
    sectorsPromise,
    factorsPromise,
  ]);

  const sectorsFiltered = sectorsResp.filter((sector) => sector !== 'N/A');

  const factorsWithRange = factorsResp.map((factor) => ({
    factor,
    ranges: [0, 100],
  }));

  return {
    universes: universesResp,
    sectors: sectorsFiltered,
    factors_filter: factorsWithRange,
    factors: factorsResp,
    id,
  };
};

export const fetchAvailableUniverseData = createAsyncThunk(
  'setAvailableParameters/universe',
  getUniverseData
);

export const getRelabanceData = async () => {
  const benchmarkPromise = screenerApi.stockSettingsBenchmarks();

  const stockObjectivesPromise = fetchAiApi<OptimizationObjective[]>({
    endpoint: '/portfolio/stock/optimization/settings/objectives/',
    method: 'GET',
  });

  const etfObjectivesPromise = fetchAiApi<OptimizationObjective[]>({
    endpoint: '/portfolio/etf/optimization/settings/objectives/',
    method: 'GET',
  });

  const mutualFundObjectivesPromise = fetchAiApi<OptimizationObjective[]>({
    endpoint: '/portfolio/mutual_fund/optimization/settings/objectives/',
    method: 'GET',
  });

  const [benchmarkResp, stockObjResp, etfObjResp, mutualFundObjResp] =
    await Promise.all([
      benchmarkPromise,
      stockObjectivesPromise,
      etfObjectivesPromise,
      mutualFundObjectivesPromise,
    ]);

  return {
    stock_objectives: stockObjResp.data,
    etf_objectives: etfObjResp.data,
    mutual_fund_objectives: mutualFundObjResp.data,
    // crypto_objectives: cryptoObjResp.data,
    benchmarks: benchmarkResp,
  };
};

export const fetchStaticRebalanceData = createAsyncThunk(
  'setAvailableParameters/rebalance',
  getRelabanceData
);

export const getAssetClasses = async () => {
  const resp = await fetchAiApi<SearchAssetClass[]>({
    endpoint: '/asset_allocation/asset_class/search/',
    method: 'POST',
    payload: {},
  });

  const formattedData = resp.data.map((item) => {
    return {
      asset_category: item.asset_category,
      asset_class: segmentsFormatted(item.asset_class),
      signal_ticker: item.signal_ticker,
      etf: item.etf,
    };
  });

  return formattedData;
};

export const fetchAssetClasses = createAsyncThunk(
  'setAssetAllocation/assetClasses',
  getAssetClasses
);
