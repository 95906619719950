// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TradeOrder,
  TraderState,
  TradeDetails as Trades,
} from 'features/utils/interfaces/redux/trades';
import { getLatestPrices, getOrderPrices } from './trader-thunk';

const initialState: TraderState = {
  trades: [],
  openTrades: [],
  loading: false,
};

export const traderSlice = createSlice({
  name: 'trader',
  initialState,
  reducers: {
    addUpdateTrades: (state, action: PayloadAction<Trades[]>) => {
      const operations = ['BUY', 'SELL'] as const;

      const response = [] as Trades[];

      operations.forEach((operation) => {
        // if equal ticker and operation, then sum quantity
        const newTrades = action.payload.filter(
          (trade) => trade.operation === operation
        );

        const newTradesTicker = newTrades.map(
          (trade: { ticker: string }) => trade.ticker
        );

        const stateTicker = state.trades
          .filter((trade) => trade.operation === operation)
          .map((trade) => trade.ticker);

        const stateTickerSet = new Set(stateTicker);

        const newTradesTickerSet = new Set(newTradesTicker);

        const intersection = new Set(
          [...stateTickerSet].filter((x) => newTradesTickerSet.has(x))
        );

        const intersectionArray = Array.from(intersection);

        const newTradesWithoutIntersection = newTrades.filter(
          (trade) => !intersectionArray.includes(trade.ticker)
        );

        const stateWithoutIntersection = state.trades.filter(
          (trade) =>
            !intersectionArray.includes(trade.ticker) &&
            trade.operation === operation
        );

        const newTradesWithIntersection = newTrades.filter((trade) =>
          intersectionArray.includes(trade.ticker)
        );

        const stateWithIntersection = state.trades.filter(
          (trade) =>
            intersectionArray.includes(trade.ticker) && trade.operation === operation
        );

        const newTradesWithIntersectionSum = newTradesWithIntersection.map(
          (trade) => {
            const stateTrade = stateWithIntersection.find(
              (stateTrade) => stateTrade.ticker === trade.ticker
            );
            let stateVolume = 0;
            let stateExpectedVolume = 0;
            let stateContractsNumber = 0;
            let stateDeltaVolume = 0;
            let stateTradeName = '';

            if (stateTrade !== undefined) {
              stateVolume = stateTrade.volume;
              stateExpectedVolume = stateTrade.expected_volume;
              stateContractsNumber = stateTrade.contracts_number;
              stateDeltaVolume = stateTrade.delta_volume;
              if (stateTrade.columnName!.name !== undefined) {
                stateTradeName = stateTrade.columnName!.name;
              }
            }

            const nameTicker =
              stateTradeName.length > trade.columnName!.name!.length
                ? stateTrade?.columnName?.name
                : trade.columnName?.name;

            return {
              ...trade,
              volume: trade.volume + stateVolume,
              expected_volume: trade.expected_volume + stateExpectedVolume,
              contracts_number: trade.contracts_number + stateContractsNumber,
              delta_volume: trade.delta_volume + stateDeltaVolume,
              columnName: {
                ...trade.columnName,
                name: nameTicker,
              },
            };
          }
        );

        response.push(
          ...stateWithoutIntersection,
          ...newTradesWithoutIntersection,
          ...newTradesWithIntersectionSum
        );
      });

      state.trades = response;
    },

    deleteTrade: (
      state,
      action: PayloadAction<Pick<Trades, 'ticker' | 'operation'>>
    ) => {
      const { ticker, operation } = action.payload;

      state.trades = state.trades.filter(
        (item) => !(item.ticker === ticker && item.operation === operation)
      );
    },

    setTrades: (state, action: PayloadAction<Trades[]>) => {
      state.trades = action.payload;
    },

    resetTrades: (state) => {
      state.trades = [];
    },

    setOpenTrades: (state, action: PayloadAction<TradeOrder[]>) => {
      state.openTrades = action.payload;
    },

    resetOpenTrades: (state) => {
      state.openTrades = [];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getOrderPrices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrderPrices.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getOrderPrices.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getLatestPrices.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getLatestPrices.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLatestPrices.rejected, (state) => {
        state.loading = false;
      }),
});

export const {
  addUpdateTrades,
  deleteTrade,
  setTrades,
  resetTrades,
  setOpenTrades,
  resetOpenTrades,
} = traderSlice.actions;

export const traderReducer = traderSlice.reducer;
