// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { Modal } from '@mui/material';
import { CloseButton, Heading } from 'components';
import { FC, PropsWithChildren } from 'react';

interface CustomModalProps extends PropsWithChildren {
  showModal: boolean;
  handleClose: () => void;
  title: string;
}

export const CustomModal: FC<CustomModalProps> = ({
  handleClose,
  showModal,
  title,
  children,
}) => {
  return (
    <Modal
      open={showModal}
      data-testid='modal'
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') handleClose();
      }}
    >
      <div className='absolute left-1/2 top-1/2 -translate-y-2/4 -translate-x-2/4 bg-[#F4F6FA] rounded-md p-10 w-[350px]'>
        <CloseButton onClick={handleClose} />
        <Heading variant='h2' title={title} className='!flex justify-center' />

        {children}
      </div>
    </Modal>
  );
};
