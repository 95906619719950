// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { usePagination } from 'components/grouped-table';
import { useInvestmentPopup } from 'components/popups';
import { exportTableToExcel } from 'features/utils/export-table-to-excel';
import { useSafeUpdate } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { FundHoldingDetail, fetchFundHoldings } from '../utils/fetch-fund-holdings';
import { renameKeys } from 'features/utils/rename-key';

interface UseAssetProfileTopHoldingsProps {
  readonly ticker: string;
  readonly assetType: string;
}

export const useAssetProfileTopHoldings = ({
  assetType,
  ticker,
}: UseAssetProfileTopHoldingsProps) => {
  const runSafeUpdate = useSafeUpdate();
  const { openInvestmentPopup } = useInvestmentPopup();
  const [holdings, setHoldings] = useState<FundHoldingDetail[]>([]);
  const [loading, setLoading] = useState(false);
  const pagination = usePagination({
    pageCount: 1,
    pageIndex: 0,
    pageSize: 10,
  });

  const getFundHoldings = useCallback(
    async (page: number, assets_page: number) => {
      try {
        const payload = { assetType, ticker, page, assets_page };
        const data = await fetchFundHoldings(payload);
        return data;
      } catch {
        return { holdings: [], pages: 1 };
      }
    },
    [assetType, ticker]
  );

  /**
   * If the user clicks on show more button, fetch all the holdings otherwise return the current holdings
   **/
  const fetchAllHoldings = useCallback(async () => {
    if (pagination.pageSize > 10) {
      const { holdings } = await getFundHoldings(1, 9999);
      return holdings;
    }
    return holdings;
  }, [getFundHoldings, holdings, pagination.pageSize]);

  const handleDownloadHoldings = useCallback(async () => {
    try {
      const currentHoldings = await fetchAllHoldings();
      const filename = `${ticker}_holdings`;
      const data = currentHoldings.map((holding) =>
        renameKeys(holding, { percentage: 'outstanding_balance' })
      );
      exportTableToExcel(data, filename);
    } catch (error) {
      console.error('Error downloading fund holdings', error);
    }
  }, [fetchAllHoldings, ticker]);

  const handleAnalyzeHoldings = useCallback(async () => {
    try {
      const currentHoldings = await fetchAllHoldings();
      const positions = currentHoldings.map((holding) => ({
        ticker: holding.ticker,
        weight: holding.percentage / 100,
      }));

      openInvestmentPopup(positions, '/analysis/stock');
    } catch (error) {
      console.error('Error analyzing fund holdings', error);
    }
  }, [fetchAllHoldings, openInvestmentPopup]);

  useEffect(() => {
    const fetchHoldings = async () => {
      try {
        runSafeUpdate(() => setLoading(true));
        const { pageIndex, pageSize } = pagination;
        const { holdings, pages } = await getFundHoldings(pageIndex, pageSize);
        setHoldings(holdings);
        pagination.onPaginationCountChange(pages);
      } catch (error) {
        console.error('Error fetching fund holdings', error);
      } finally {
        setLoading(false);
      }
    };

    fetchHoldings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.pageIndex, pagination.pageSize, assetType, ticker]);

  return {
    pagination,
    holdings,
    loading,
    onDownloadHoldings: handleDownloadHoldings,
    onAnalyzeHoldings: handleAnalyzeHoldings,
  };
};
