// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import { PopupsName } from 'features/popups';
import { round } from 'lodash';
import { PopupButton } from './popup-button';

type MetricType =
  | 'sharpe'
  | 'valueRisk'
  | 'return'
  | 'expense'
  | 'volatility'
  | 'beta';

interface ThermometerGraphProps {
  type: MetricType;
  value: number;
}

interface Metric {
  title: string;
  min: number;
  max: number;
  color: string;
  popupName?: PopupsName;
}

const color = {
  blue: '#1072ba',
  red: '#F45869',
  green: '#0BACA9',
  grey: '#56606D',
};

const metrics: Record<MetricType, Metric> = {
  sharpe: {
    title: 'SHARPE RATIO',
    color: color.blue,
    min: 0,
    max: 1.2,
    popupName: 'kpis.sharpeRatio',
  },
  valueRisk: {
    title: 'VALUE AT RISK',
    min: 0,
    max: 15,
    color: color.red,
    popupName: 'kpis.valueAtRisk',
  },
  return: {
    title: 'ANNUALIZED RETURNS',
    min: 0,
    max: 12,
    color: color.green,
  },
  expense: {
    title: 'EXPENSE RATIO',
    min: 0,
    max: 2,
    color: color.grey,
  },
  beta: {
    title: 'BETA',
    min: 0,
    max: 2,
    color: color.grey,
    popupName: 'kpis.beta',
  },
  volatility: {
    title: 'ANNUALIZED VOLATILITY',
    min: 0,
    max: 25,
    color: color.red,
    popupName: 'kpis.annualizedVolatility',
  },
};

export const ThermometerGraph = ({ type, value }: ThermometerGraphProps) => {
  const metric = metrics[type];
  const correctedValue = value + Number.EPSILON;
  const multiplier = ['sharpe', 'beta'].includes(type) ? 1 : 100;
  const roundedValue = round(correctedValue * multiplier, 2);
  const graphValue = roundedValue < 0 ? 0 : roundedValue;
  const displayValue = multiplier === 1 ? roundedValue : `${roundedValue} %`;

  const data = [
    {
      id: metric.title,
      data: [
        {
          x: type,
          y: graphValue,
          color: metric.color,
        },
      ],
    },
  ];

  return (
    <div className='overview-charts-thermometer'>
      <div className='text-thermometer-box-style'>
        <div className='text-thermometer-box-content'>
          <strong className='text-thermometer-box-content-strong'>
            {displayValue}
          </strong>
          <div style={{ padding: '5px' }}></div>
          <div className='flex items-center gap-x-2'>
            <p className='text-thermometer-box-content-p'>{metric.title}</p>
            {metric.popupName && (
              <PopupButton
                popupName={metric.popupName}
                tooltip
                className='!p-0 pb-1'
              />
            )}
          </div>
        </div>
      </div>
      <div className='radial-bar-container'>
        <ResponsiveRadialBar
          data={data}
          valueFormat='>-.2f'
          maxValue={metric.max}
          endAngle={360}
          startAngle={-360}
          innerRadius={0.25}
          cornerRadius={8}
          colors={[metric.color]}
          padding={0.5}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          enableRadialGrid={false}
          enableCircularGrid={false}
          radialAxisStart={null}
          circularAxisOuter={null}
          tracksColor='#E5E5E5'
          animate={false}
          isInteractive={false}
        />
      </div>
    </div>
  );
};
