// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { BASE_POPUP, showModal } from 'features/ui/modal';
import { formatToMoney } from './format-to-money';
import { AppIcon } from 'components';

export const alertInsufficientCash = (debt: number) => {
  const message = (
    <>
      Are you sure you want to execute <br />
      the trades? If so, you will incur in <br />a debt of&nbsp;
      <strong>{formatToMoney(debt)}</strong>
    </>
  );

  return showModal({
    ...BASE_POPUP,
    icon: <AppIcon icon='ExclamationMark' color='main' />,
    variant: 'overflow',
    buttons: {
      cancel: {
        show: true,
      },
      confirm: {
        show: true,
        text: 'Execute',
      },
    },
    content: message,
    width: 400,
    title: 'Insufficient cash',
  });
};
