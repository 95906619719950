// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum AssetTypes {
  ETFS = 'etfs',
  MUTUAL_FUNDS = 'mutual_funds',
  // CRYPTOS = 'cryptos',
}

export const assetTypesMap = {
  etf: AssetTypes.ETFS,
  mutual_fund: AssetTypes.MUTUAL_FUNDS,
};

export interface AssetType {
  readonly type: AssetTypes;
  readonly ticker: string[];
  readonly name: string[];
  readonly description: string[];
}

const assetTypeAdapter = createEntityAdapter<AssetType>({
  selectId: ({ type }) => type,
  sortComparer: /* istanbul ignore next */ ({ type }, asset) =>
    type.localeCompare(asset.type),
});

const initialState = assetTypeAdapter.getInitialState();

const assetTypeSlice = createSlice({
  name: 'assetType',
  initialState,
  reducers: {
    setAssetType: (state, { payload }: PayloadAction<AssetType>) => {
      const assetType = {
        ...payload,
        description:
          state.entities[payload.type]?.description.concat(payload.description) ??
          payload.description,
        name:
          state.entities[payload.type]?.name.concat(payload.name) ?? payload.name,
        ticker:
          state.entities[payload.type]?.ticker.concat(payload.ticker) ??
          payload.ticker,
      };

      assetTypeAdapter.upsertOne(state, assetType);
    },
    resetAssetType: (state, { payload }: PayloadAction<AssetTypes>) => {
      const assetType: AssetType = {
        type: payload,
        description: [],
        name: [],
        ticker: [],
      };

      assetTypeAdapter.setOne(state, assetType);
    },
    deleteAssetType: (
      state,
      { payload }: PayloadAction<{ type: AssetTypes; value: string }>
    ) => {
      const assetType = {
        type: payload.type,
        description: state.entities[payload.type]?.description.filter(
          (value) => value !== payload.value
        ),
        name: state.entities[payload.type]?.name.filter(
          (value) => value !== payload.value
        ),
        ticker: state.entities[payload.type]?.ticker.filter(
          (value) => value !== payload.value
        ),
      };

      assetTypeAdapter.updateOne(state, { id: payload.type, changes: assetType });
    },
  },
});

export const assetTypeSelectors = assetTypeAdapter.getSelectors();

export const {
  actions: { deleteAssetType, resetAssetType, setAssetType },
  reducer,
} = assetTypeSlice;
