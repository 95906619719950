// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import moment from 'moment';

interface TransformedData {
  x: number;
  y: number;
}

export interface TransformedObject {
  id: string;
  data: TransformedData[];
}

export const replaceNullValues = (
  data: TransformedObject[]
): TransformedObject[] => {
  const output = data.map((item) => {
    const newData = item.data.map((obj) => {
      if (obj.y === null) {
        const firstNonNullValue = item.data.find((obj) => obj.y !== null)?.y;
        return {
          x: obj.x,
          y: firstNonNullValue || null,
        };
      }
      return obj;
    });

    newData.pop();

    return {
      ...item,
      data: newData,
    };
  });

  return output as TransformedObject[];
};

export const transformChartData = (chartData: number[][]): TransformedObject[] => {
  const convertedChartData: TransformedObject[] = [
    {
      id: 'Performance',
      data: [],
    },
  ];

  for (const dataPoint of chartData) {
    convertedChartData[0].data.push({
      x: dataPoint[0],
      y: dataPoint[1],
    });
  }

  const chartDataWithoutNulls = replaceNullValues(convertedChartData);

  return chartDataWithoutNulls;
};

export const getMinMaxValues = (data: number[][]): { min: number; max: number } => {
  return data.reduce(
    (current, values) => {
      const value = values[1];

      if (value !== undefined && value !== null) {
        return {
          min: value < current.min ? value : current.min,
          max: value > current.max ? value : current.max,
        };
      }

      return current;
    },
    { min: Number.MAX_VALUE, max: Number.MIN_VALUE }
  );
};

// Time format functions for chart

export const formatDate = (timestamp: number, period: string): string => {
  const date = moment(timestamp);
  if (period === '1D') {
    const hours = date.format('HH');
    const minutes = date.format('mm');
    return `${hours}:${minutes}`;
  } else if (period === '1W' || period === '1M') {
    const day = date.format('DD');
    const month = date.format('MMM');
    return `${day}-${month}`;
  } else {
    const month = date.format('MMM');
    const year = date.format('YYYY');
    return `${month}-${year}`;
  }
};

export const tooltipDate = (timestamp: number, period: string): string => {
  const date = moment(timestamp);
  if (period === '1D') {
    const hours = date.format('HH');
    const minutes = date.format('mm');
    return `${hours}:${minutes}`;
  } else {
    const month = date.format('MMM');
    const day = date.format('DD');
    return `${day}-${month}`;
  }
};
