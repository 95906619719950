// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { SectionContainer } from 'components';
import { FC } from 'react';

interface AssetProfileDescriptionProps {
  description?: string;
  infoProperties: {
    label: string;
    value: any;
  }[];
}

export const AssetProfileDescription: FC<AssetProfileDescriptionProps> = ({
  description,
  infoProperties,
}) => {
  return (
    <SectionContainer title='Description' testId='info' className='!p-4'>
      <div className='grid grid-cols-2 gap-8 text-justify mt-4'>
        <p>{description}</p>
        <div>
          <dl
            key='info-properties'
            className='[&_dt]:font-semibold grid grid-cols-2 gap-4 '
          >
            {infoProperties.map((property) => (
              <div key={property.label}>
                <dt>{property.label}:</dt>
                <dd className='truncate'>{property.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </SectionContainer>
  );
};
