// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { SavedPortfolio } from 'features/utils/interfaces/redux/my-research-state';
import { BaseHolding } from '../commons';
import { IdeaState } from 'features/utils/interfaces/redux/idea-state';

export interface CitecApi {
  getUniverse: (params: UniverseId) => Promise<Universe>;

  postUniverse: (payload: Universe) => Promise<Universe>;

  universeAll: () => Promise<UniverseAllResp[]>;

  getUser: () => Promise<CitecUser>;

  postUser: () => Promise<CitecUser>;

  portfolioAll: (
    params: { detail: string },
    signal?: AbortSignal
  ) => Promise<Array<SavedPortfolio | IdeaState>>;

  updatePortfolio: (
    params: PortfolioId,
    payload: SavedPortfolio
  ) => Promise<SavedPortfolio>;

  getTickerTag: (params: TickerTag) => Promise<TickerTagResp>;

  searchTickerTag: (params: SearchTickerTag) => Promise<TickerTagResp[]>;

  createTickerTag: (payload: TickerTagResp) => Promise<TickerTagResp>;

  updateTickerTag: (
    params: Omit<TickerTagResp, 'description'>,
    payload: TickerTagResp
  ) => Promise<TickerTagResp>;

  deleteTickerTag: (params: TickerTag) => Promise<void>;

  getWatchlist: () => Promise<WatchlistResp[]>;

  addToWatchlist: (payload: WatchlistResp[]) => Promise<WatchlistResp[]>;

  deleteFromWatchlist: (params: RemoveWatchlistItem) => Promise<void>;

  searchMarketplace: (payload: SearchMarketplaceFilters) => Promise<Marketplace>;
}

export interface UniverseId {
  universe_id: number;
}

export interface PortfolioId {
  portfolio_id: number;
}

export interface UniverseAllResp extends UniverseId {
  description: string;
  creation_date: string;
}

export interface Universe extends UniverseAllResp {
  universe_base: UniverseBase;
  factors_filter: FactorsFilter;
  sectors_filter: string[];
  esg_exclusions: string[];
  esg_scoring: EsgScoring;
  esg_not_informed: boolean;
  to_exclude: string[];
  to_include: string[];
}

export interface UserEmail {
  email: string;
}

export interface Organization {
  flag_admin: boolean;
  organization_id: number;
  organization: string;
}

export interface CitecUser extends UserEmail {
  user_id: number;
  organizations: Organization[];
}

interface Boundaries {
  lower: number[];
  upper: number[];
}

interface FactorsFilter extends Boundaries {
  factor: string[];
}

interface EsgScoring extends Boundaries {
  filter: string[];
}

interface UniverseBase {
  name: string;
  description: string;
}

export enum TickerTagFeature {
  PortfolioPositions = 'portfolio-positions',
  WatchList = 'watchlist',
}

export interface TickerTag {
  ticker?: string;
  tag: string;
  feature: TickerTagFeature;
}

export interface SearchTickerTag {
  ticker?: string;
  tag?: string;
  feature: TickerTagFeature;
}

export interface TickerTagResp {
  ticker: string;
  tag: string;
  description?: string;
  feature: TickerTagFeature;
}

export interface WatchlistResp {
  ticker: string;
  name: string;
  isin: string;
  asset_type: string;
  saved_at?: string;
}

export interface RemoveWatchlistItem {
  ticker: string;
}

export interface SavingHolding extends BaseHolding {
  outstanding_balance: number;
}

export interface SearchMarketplaceFilters {
  client_residency?: string;
  esg?: string;
  asset_manager?: string;
  risk_level?: string;
}

export interface PortfolioDetails {
  portfolio_id: number;
  portfolio: string;
  benchmark: string;
  creation_date: string;
  optimization_status: string;
  client_residency: string;
  esg: string;
  asset_manager: string;
  manager: string;
  risk_level: string;
  description: string;
  holdings: PortfolioHolding[];
}

export interface PortfolioHolding {
  ticker: string;
  outstanding_balance: number;
  unrealized_capital_gains: number;
}

export type Marketplace = PortfolioDetails[];
