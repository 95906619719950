// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Tooltip } from '@mui/material';
import { useShowAssetProfile } from 'components/popups';
import { windowOpenPopup } from 'features/popups/window-open-popup';
import { AssetLogo } from 'features/ui/table/components/asset-logo';
import { classNames } from 'features/utils/classnames';
import { FC, useCallback } from 'react';

const STOCK_ASSET_TYPES = ['Common Stock', 'stock', 'Stock'];
const ETF_ASSET_TYPES = ['ETF', 'etf', 'ETP'];
const MUTUAL_FUND_ASSET_TYPES = ['mutual_fund', 'Mutual Fund'];

export interface AssetBrandProps {
  readonly ticker: string;
  readonly assetType?: string;
  readonly label?: string;
  readonly url?: string;
  readonly logoUrl?: string;
  readonly showTooltip?: boolean;
  readonly className?: string;
}

export const AssetBrand: FC<AssetBrandProps> = ({
  ticker,
  assetType = '',
  label,
  url,
  logoUrl,
  className = '',
}) => {
  const showAssetProfile = useShowAssetProfile();

  const isStock = STOCK_ASSET_TYPES.includes(assetType);
  const isETF = ETF_ASSET_TYPES.includes(assetType);
  const isMutualFund = MUTUAL_FUND_ASSET_TYPES.includes(assetType);
  const canShowProfile = isETF || isMutualFund;
  const canOpenUrl = isStock && !!url && url !== 'missing';

  const handleAction = useCallback(() => {
    if (canOpenUrl) {
      windowOpenPopup(url);
    }

    if (canShowProfile) {
      const assetType = isETF ? 'etf' : 'mutual_fund';
      showAssetProfile({ ticker, assetType });
    }
  }, [canOpenUrl, canShowProfile, isETF, showAssetProfile, ticker, url]);

  return (
    <div
      className={classNames(
        {
          'flex gap-x-2 items-center truncate': true,
          'cursor-pointer hover:underline hover:underline-offset-4':
            canOpenUrl || canShowProfile,
        },
        className
      )}
      onClick={handleAction}
      data-testid={`asset-brand-${ticker}`}
    >
      <AssetLogo assetType={assetType} logoUrl={logoUrl} />
      {label ? (
        <Tooltip title={label} placement='top'>
          <span className='truncate'>{label}</span>
        </Tooltip>
      ) : (
        <span>{ticker ?? '-'}</span>
      )}
    </div>
  );
};
