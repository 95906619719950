// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { selectAccount, selectBroker } from 'store/slices/broker';
import { useAppSelector } from 'store/store';
import { FC, HTMLAttributes, useMemo } from 'react';
import { classNames } from 'features/utils/classnames';
import { selectUserData } from 'store/slices/user';

interface AccountLogoProps extends HTMLAttributes<HTMLDivElement> {
  accountUser?: boolean;
}

export const AccountLogo: FC<AccountLogoProps> = ({ className, accountUser }) => {
  const account = useAppSelector(selectAccount);
  const broker = useAppSelector(selectBroker);
  const user = useAppSelector(selectUserData);

  const name = useMemo(
    () => account.name || broker || 'USER',
    [account.name, broker]
  );

  const accountInitials = useMemo(
    () =>
      name
        .split(' ')
        .map((name) => name.charAt(0))
        .slice(0, 2)
        .join(''),
    [name]
  );

  return (
    <div
      className={classNames(
        'flex min-h-[30px] min-w-[30px] select-none place-content-center place-items-center rounded-[50%] bg-[#3D58E5]',
        className
      )}
    >
      {accountUser ? (
        <img
          src={user?.picture}
          alt="Account's picture"
          className='size-[30px] rounded-full'
        />
      ) : (
        <span className='font-poppins uppercase leading-6 text-white'>
          {accountInitials}
        </span>
      )}
    </div>
  );
};
