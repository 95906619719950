// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

export const capitalizeString = (sentence: string, space = false, mayus = true) => {
  if (!sentence) {
    return '';
  }

  if (mayus) {
    const array_words = sentence.split(' ');

    for (let i = 0; i < array_words.length; i++) {
      array_words[i] =
        array_words[i].charAt(0).toUpperCase() +
        array_words[i].slice(1).toLowerCase();
    }

    sentence = array_words.join(' ');

    return sentence;
  }

  const firstCharUpper = sentence.charAt(0).toUpperCase();

  const restOfString = sentence.slice(1);

  const spacesInserted = restOfString
    .replace(/([A-Z])/g, space ? ' $1' : '$1')
    .trim();

  return `${firstCharUpper}${spacesInserted}`;
};
