// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Holding } from 'api/interfaces/ai/holding';
import { portfolioApi } from 'store/api/ai/portfolio-api';
import { selectCurrentPortfolioWithoutCash } from 'store/slices/portfolio-positions';
import { selectWorkflowAsset, selectWorkflowType } from 'store/slices/workflow';
import { useAppSelector } from 'store/store';
import { useEffect, useMemo, useState } from 'react';

interface Props {
  benchmark: string;
}

export const useValidateTickersPortfolio = ({ benchmark }: Props) => {
  const assetType = useAppSelector(selectWorkflowAsset);
  const workflowType = useAppSelector(selectWorkflowType);
  const [invalidTickers, setInvalidTickers] = useState<string[]>([]);

  const [getValidation] = portfolioApi.useLazyPortfolioValidationQuery();

  const portfolioWithoutCash = useAppSelector(selectCurrentPortfolioWithoutCash);

  const isSomeInvalidTicker = useMemo(
    () => (workflowType !== 'rebalance' ? invalidTickers?.length > 0 : false),
    [workflowType, invalidTickers?.length]
  );

  const holdings: Holding[] = useMemo(
    () =>
      portfolioWithoutCash.map((asset) => ({
        ticker: asset.ticker,
        price: asset.outstanding_balance,
        qty: 1,
        unrealized_capital_gains: asset.unrealized_capital_gains,
      })),
    [portfolioWithoutCash]
  );

  const portfolioStrategy = useMemo(
    () => ({
      holdings: holdings,
      benchmark: { target_asset: [{ ticker: benchmark, weight: 1 }] },
    }),
    [holdings, benchmark]
  );

  const validateTickers = async () => {
    try {
      const { data: validatedTickers } = await getValidation({
        payload: portfolioStrategy,
        assetType,
      });
      /* istanbul ignore next */
      setInvalidTickers(validatedTickers!.unsupported_ticker_positions);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (workflowType !== 'rebalance' && portfolioStrategy.holdings.length > 0) {
      validateTickers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetType, portfolioStrategy.holdings]);

  return { invalidTickers, isSomeInvalidTicker };
};
