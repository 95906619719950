// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { fetchEngineApi } from 'api/engine-api';
import {
  CitecApi,
  CitecUser,
  Marketplace,
  TickerTagResp,
  Universe,
  UniverseAllResp,
  WatchlistResp,
} from 'api/interfaces/engine/citec';
import { IdeaState } from 'features/utils/interfaces/redux/idea-state';
import { SavedPortfolio } from 'features/utils/interfaces/redux/my-research-state';

export const citecApi: CitecApi = {
  getUniverse: async (params) => {
    const { data } = await fetchEngineApi<Universe>({
      endpoint: '/citec/universe',
      method: 'GET',
      params,
    });

    return data;
  },

  postUniverse: async (payload) => {
    const { data } = await fetchEngineApi<Universe>({
      endpoint: '/citec/universe',
      method: 'POST',
      payload,
    });

    return data;
  },

  universeAll: async () => {
    const { data } = await fetchEngineApi<UniverseAllResp[]>({
      endpoint: '/citec/universe/all',
      method: 'GET',
    });

    return data;
  },

  getUser: async () => {
    const { data } = await fetchEngineApi<CitecUser>({
      endpoint: '/citec/user/',
      method: 'GET',
      params: {
        email: true,
      },
    });

    return data;
  },

  postUser: async () => {
    const { data } = await fetchEngineApi<CitecUser>({
      endpoint: '/citec/user/',
      method: 'POST',
      payload: {
        email: true,
      },
    });

    return data;
  },

  portfolioAll: async (params, signal) => {
    const { data } = await fetchEngineApi<IdeaState[]>({
      endpoint: '/citec/portfolio/all/',
      method: 'GET',
      params,
      signal,
    });

    return data;
  },

  updatePortfolio: async (params, payload) => {
    const { data } = await fetchEngineApi<SavedPortfolio>({
      endpoint: '/citec/portfolio/',
      method: 'PUT',
      params,
      payload,
    });

    return data;
  },

  getTickerTag: async (params) => {
    const { data } = await fetchEngineApi<TickerTagResp>({
      endpoint: '/citec/ticker/tag/',
      method: 'GET',
      params,
    });

    return data;
  },

  searchTickerTag: async (params) => {
    const { data } = await fetchEngineApi<TickerTagResp[]>({
      endpoint: '/citec/ticker/tag/search/',
      method: 'GET',
      params,
    });

    return data;
  },

  createTickerTag: async (payload) => {
    const { data } = await fetchEngineApi<TickerTagResp>({
      endpoint: '/citec/ticker/tag/',
      method: 'POST',
      payload,
    });

    return data;
  },

  updateTickerTag: async (params, payload) => {
    const { data } = await fetchEngineApi<TickerTagResp>({
      endpoint: '/citec/ticker/tag/',
      method: 'PUT',
      params,
      payload,
    });

    return data;
  },

  deleteTickerTag: async (params) => {
    await fetchEngineApi<void>({
      endpoint: '/citec/ticker/tag/',
      method: 'DELETE',
      params,
    });

    return;
  },

  getWatchlist: async () => {
    const { data } = await fetchEngineApi<WatchlistResp[]>({
      endpoint: '/citec/watchlist/',
      method: 'GET',
    });

    return data;
  },

  addToWatchlist: async (payload) => {
    const { data } = await fetchEngineApi<WatchlistResp[]>({
      endpoint: '/citec/watchlist/',
      method: 'POST',
      payload,
    });
    return data;
  },

  deleteFromWatchlist: async (params) => {
    const { data } = await fetchEngineApi<any>({
      endpoint: '/citec/watchlist/',
      method: 'DELETE',
      params,
    });
    return data;
  },

  searchMarketplace: async (payload) => {
    const { data } = await fetchEngineApi<Marketplace>({
      endpoint: '/citec/marketplace/portfolio/search',
      method: 'POST',
      payload,
    });
    return data;
  },
};
