// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { appStore as store } from 'store/store';
import { SubRows } from 'features/utils/interfaces/my-investment';
import { setCurrentPortfolioPositions } from 'store/slices/portfolio-positions';
import { selectAllTags } from 'store/slices/tag-manager';
import { WorkflowAsset } from 'features/utils/interfaces/redux/workflow-state';
import { TickerTagFeature } from 'api/interfaces/engine/citec';

export const dispatchInvestment = (
  asset: WorkflowAsset,
  tickerTag: string,
  tickers: SubRows[]
) => {
  const dispatch = store.dispatch;

  const tagsPositions = selectAllTags(
    store.getState(),
    TickerTagFeature.PortfolioPositions
  ).flatMap(({ positions }) => positions.map(({ ticker }) => ticker));

  const filteredBrokerPositions = tickers.filter((position) =>
    tagsPositions.includes(position.ticker)
  );

  const investment: SubRows[] = tickerTag ? filteredBrokerPositions : tickers;

  const holdings = investment.map((position) => {
    return {
      ticker: position.ticker,
      price: position.price,
      qty: position.quantity,
      unrealized_capital_gains: position.total_gain_loss[0],
      harvesting: {
        percentage: 0,
        status: 0,
        replacement: null,
      },
    };
  });

  dispatch(setCurrentPortfolioPositions(holdings));
};
