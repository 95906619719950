// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AccountLogo } from 'components/account-logo';
import { AppIcon } from 'components/app-icon';
import { CopilotLinkRenderer } from './copilot-link-renderer';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { IconButton } from 'features/ui/icon-button';
import { useCallback, useRef } from 'react';
import { convert } from 'html-to-text';
import { Tooltip } from '@mui/material';
import { classNames } from 'features/utils/classnames';

type Sender = 'CITEC' | 'HUMAN';

interface CopilotChatMessageProps {
  message: string;
  sender: Sender;
  loading?: boolean;
  allowCopy?: boolean;
}

export const CopilotChatMessage = ({
  message,
  sender,
  loading,
  allowCopy,
}: CopilotChatMessageProps) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const onClick = useCallback(() => {
    const container = chatContainerRef.current;

    if (container !== null) {
      const markdown = container
        .querySelector('.markdown')
        ?.cloneNode(true) as HTMLElement;

      markdown.removeAttribute('class');

      const data = new ClipboardItem({
        'text/html': new Blob([markdown.outerHTML], {
          type: 'text/html',
        }),
        'text/plain': new Blob(
          [
            convert(markdown.outerHTML, {
              selectors: [{ selector: 'table', format: 'dataTable' }],
            }) ?? '',
          ],
          {
            type: 'text/plain',
          }
        ),
      });

      navigator.clipboard.write([data]);
    }
  }, []);

  return (
    <div className='flex my-4 px-4 animate-fade'>
      {sender === 'CITEC' && (
        <div className='mr-2 rounded-[50%] bg-[#7194CE] w-[30px] h-[30px]'>
          <AppIcon icon='CopilotChatButton' className='text-[#4563E7]' size={30} />
        </div>
      )}

      <div
        className={classNames('chat-bubble aria-busy:!max-w-16', {
          'ml-auto': sender === 'HUMAN',
        })}
        aria-busy={loading}
        data-testid='chat-message-bubble'
      >
        {sender === 'CITEC' ? (
          <div className='absolute top-0 left-[-10px]' data-testid='chat-caret-left'>
            <AppIcon icon='ChatCaretLeft' size='base' />
          </div>
        ) : (
          <div
            className='absolute top-0 right-[-10px]'
            data-testid='chat-caret-right'
          >
            <AppIcon icon='ChatCaretRight' size='base' />
          </div>
        )}
        <div
          className='chat-content group flex gap-x-2 items-start group'
          aria-busy={loading}
          ref={chatContainerRef}
          data-testid='chat-message'
        >
          <Markdown
            components={{ a: CopilotLinkRenderer }}
            className='prose text-[#547051] select-text w-full max-w-[calc(100%-30px)] group-aria-busy:hidden markdown'
            remarkPlugins={[remarkGfm]}
          >
            {message}
          </Markdown>
          <div
            className='hidden group-aria-busy:flex gap-x-1 items-center'
            data-testid='chat-loading'
          >
            {Array.from({ length: 3 }, (_, key) => (
              <span
                key={key}
                className='w-2 h-2 rounded-full animate-pulse bg-primary-medium'
              />
            ))}
          </div>
          {sender === 'CITEC' && !loading && allowCopy && (
            <Tooltip title='Copy to clipboard' placement='top'>
              <IconButton
                icon='Copy'
                variant='icon'
                hoverable
                onClick={onClick}
                data-testid='chat-copy'
                className='group-hover:!inline-flex !hidden'
              />
            </Tooltip>
          )}
        </div>
      </div>

      {sender === 'HUMAN' && (
        <div className='mr-3 w-9 h-9 ml-3'>
          <AccountLogo accountUser />
        </div>
      )}
    </div>
  );
};
