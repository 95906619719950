// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { PortfolioLoaded } from 'features/utils/interfaces/portfolio-loaded';
import { useConst } from 'hooks';
import { ChangeEventHandler, useCallback, MouseEventHandler, useMemo } from 'react';
import { useAppSelector } from 'store/store';
import { selectWorkflowType } from 'store/slices/workflow';
import type { TableRowProps } from '../components/table-row';

export const useTableRow = ({
  portfolio: { ticker },
  id: key,
  setData,
  updateMyData,
  setInitialValue,
  initialValue,
  invalidPositions = [],
}: TableRowProps) => {
  const workflowType = useAppSelector(selectWorkflowType);

  const currentDate = useConst(() => new Date().toISOString().split('T')[0]);

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const name = e.target.name as keyof PortfolioLoaded;

      const value = e.target.value;

      if (key !== -1) updateMyData(key, name, value);
      else setInitialValue((prev) => ({ ...prev, [name]: value }));
    },
    [key, updateMyData, setInitialValue]
  );

  const onValueChange = useCallback(
    (value: string | undefined, name?: string) => {
      if (typeof name !== 'undefined')
        if (key !== -1) updateMyData(key, name, value);
        else setInitialValue((prev) => ({ ...prev, [name]: value }));
    },
    [setInitialValue, updateMyData, key]
  );

  const onAddRow = useCallback(() => {
    const row = {
      ticker: initialValue.ticker,
      outstanding_balance: Number(initialValue.outstanding_balance),
      unrealized_capital_gains: Number(initialValue.unrealized_capital_gains),
      date: currentDate,
    };

    setData((prev) => [row, ...prev]);

    setInitialValue({
      ticker: '',
      outstanding_balance: 0,
      unrealized_capital_gains: 0,
      date: currentDate,
      selectedAsset: false,
    });
  }, [
    currentDate,
    initialValue.outstanding_balance,
    initialValue.ticker,
    initialValue.unrealized_capital_gains,
    setData,
    setInitialValue,
  ]);

  const onRemoveRow = useCallback(
    () => setData((prev) => prev.filter((_, index) => index !== key)),
    [setData, key]
  );

  const onBlur = useCallback(
    (name: keyof PortfolioLoaded, value: string | number) => () => {
      if (key !== -1) updateMyData(key, name, value);
      else setInitialValue((prev) => ({ ...prev, [name]: value }));
    },
    [updateMyData, key, setInitialValue]
  );

  const onInputClick: MouseEventHandler<HTMLInputElement> = useCallback((event) => {
    const target = event.target as HTMLInputElement;

    if (target.type === 'date' && 'showPicker' in target) target.showPicker();
  }, []);

  const isInvalid = useMemo(
    () => invalidPositions.includes(ticker) && key !== -1,
    [invalidPositions, ticker, key]
  );

  return {
    onChange,
    onValueChange,
    onRemoveRow,
    onAddRow,
    onBlur,
    onInputClick,
    isInvalid,
    workflowType,
  };
};
