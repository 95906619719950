// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import {
  forwardRef,
  HTMLAttributes,
  MouseEventHandler,
  ReactNode,
  useCallback,
} from 'react';
import { AppIcon } from './app-icon';
import { Typography } from '@mui/material';
import { useInfoPopup } from 'hooks/use-info-popup';
import { InfoTooltip, PopupsName } from 'features/popups';

/**
 * Props for the ChartContainer component.
 */
export interface ChartContainerProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * The header content of the chart container.
   */
  readonly header?: ReactNode;

  /**
   * popup name used for displaying alerts.
   */
  readonly popupName?: PopupsName;

  readonly headerRight?: ReactNode;

  readonly tooltip?: boolean;
}

/**
 * A container component for displaying charts.
 *
 * @component
 * @returns - The rendered ChartContainer component.
 */
const ChartContainer = forwardRef<HTMLDivElement, ChartContainerProps>(
  (
    { children, popupName, header, headerRight, className = '', tooltip, ...props },
    ref
  ) => {
    const infoPopups = useInfoPopup();

    /**
     * Event handler for the info icon click.
     *
     * @param {React.MouseEvent<SVGElement>} e - The click event.
     */
    const onInfoClick: MouseEventHandler<SVGElement> = useCallback(
      (e) => {
        e.stopPropagation();

        // Display an alert if popupName is defined
        if (typeof popupName !== 'undefined') infoPopups(popupName);
      },
      [infoPopups, popupName]
    );

    return (
      <div
        className={`grid grid-cols-1 grid-rows-[auto_auto_1fr] gap-4 bg-[#EDF0F9] py-6 px-8 rounded-lg ${className}`}
        {...props}
        ref={ref}
      >
        <header className='flex justify-between'>
          <div className='flex flex-row space-x-4 items-center'>
            <Typography variant='h2' data-testid='chart-container-title'>
              {header}
            </Typography>
            {/* Render the info icon if alertOptions is defined */}
            {typeof popupName !== 'undefined' ? (
              tooltip ? (
                <InfoTooltip title={popupName}>
                  <AppIcon
                    icon='Info'
                    size='base'
                    color='disabled'
                    data-testid={`chart-container-icon-${header}`}
                    className='cursor-pointer'
                  />
                </InfoTooltip>
              ) : (
                <AppIcon
                  icon='Info'
                  size='base'
                  color='disabled'
                  data-testid={`chart-container-icon-${header}`}
                  className='cursor-pointer'
                  onClick={onInfoClick}
                />
              )
            ) : null}
          </div>
          {/* Render aditional info at the right*/}
          {typeof headerRight !== 'undefined' ? <div>{headerRight}</div> : null}
        </header>
        <hr className='bg-[#DADADA] h-px border-none' />
        <div className='min-h-[360px] flex flex-col items-stretch justify-stretch mt-4'>
          {children}
        </div>
      </div>
    );
  }
);

// Set the display name for the ChartContainer component
ChartContainer.displayName = 'ChartContainer';

export { ChartContainer };
