// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import moment from 'moment';
import { appStore as store } from 'store/store';
import Swal from 'sweetalert2';
import { fetchEngineApi } from 'api/engine-api';
import { MODAL_TEMPLATES_OPTIONS, showModal } from 'features/ui/modal';
import { getSavedPortfolios, resetSavedPortfolios } from 'store/slices/my-research';
import { SavingHolding } from 'api/interfaces/engine/citec';

interface SavePortfolioProps {
  benchmark: string;
  savingHoldings?: SavingHolding[];
  onClose?: () => void;
}

export const savePortfolio = ({
  benchmark,
  savingHoldings,
  onClose,
}: SavePortfolioProps) => {
  showModal({
    ...MODAL_TEMPLATES_OPTIONS.SAVE_PORTFOLIO,
    preConfirm: (name) => {
      const payload = {
        portfolio: !name
          ? showModal({
              ...MODAL_TEMPLATES_OPTIONS.UNHANDLED_ERROR,
              title: `Can't save unnamed portfolio.`,
              content: 'Please enter a valid portfolio name.',
            })
          : name,
        benchmark,
        creation_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        holdings: savingHoldings,
      };
      return fetchEngineApi({
        endpoint: 'citec/portfolio',
        method: 'POST',
        payload,
      });
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((result) => {
    if (result.isConfirmed) {
      store.dispatch(resetSavedPortfolios());
      store.dispatch(getSavedPortfolios());

      showModal({
        content: `The portfolio is saved correctly`,
        buttons: { confirm: { show: true, text: 'Ok' } },
      }).then(() => onClose?.());
    }
  });
};
