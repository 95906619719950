// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store/store';

export const selectPortfolioPositions = createSelector(
  (state: AppState) => state.portfolioPositions,
  (portfolioPositions) => portfolioPositions
);

export const selectPortfolioLoaded = createSelector(
  selectPortfolioPositions,
  (portfolioPositions) => portfolioPositions.portfolioLoaded
);

export const selectCurrentPortfolio = createSelector(
  selectPortfolioPositions,
  (portfolioPositions) =>
    portfolioPositions.current.map((asset) => ({
      ...asset,
      outstanding_balance:
        asset.qty * asset.price * (asset.harvesting?.percentage || 1),
    }))
);

export const selectTargetPortfolio = createSelector(
  selectPortfolioPositions,
  (portfolioPositions) => portfolioPositions.target
);

export const selectTradesTable = createSelector(
  selectPortfolioPositions,
  (portfolioPositions) => portfolioPositions.tradesTable
);

export const selectCurrentBalance = createSelector(
  selectCurrentPortfolio,
  (portfolio) =>
    portfolio.reduce((acc, { outstanding_balance }) => acc + outstanding_balance, 0)
);

export const selectCurrentPortfolioWithoutCash = createSelector(
  selectCurrentPortfolio,
  (portfolio) => portfolio.filter((holding) => holding.ticker !== '$CASH')
);

export const selectCurrentBalanceWithoutCash = createSelector(
  selectCurrentPortfolioWithoutCash,
  (portfolio) =>
    portfolio.reduce((acc, { outstanding_balance }) => acc + outstanding_balance, 0)
);

export const selectUnrealizedCapitalGains = createSelector(
  selectCurrentPortfolio,
  /* istanbul ignore next */ (portfolio) =>
    portfolio.reduce((acc, curr) => acc + curr.unrealized_capital_gains, 0)
);

export const selectUnrealizedCapitalGainsNegative = createSelector(
  selectCurrentPortfolio,
  /* istanbul ignore next */ (portfolio) =>
    portfolio
      .filter((holding) => holding.unrealized_capital_gains < 0)
      .reduce((acc, curr) => acc + Number(curr.unrealized_capital_gains), 0)
);

export const selectTaxPositions = createSelector(
  selectCurrentPortfolio,
  (portfolio) => portfolio.filter((holding) => holding.harvesting?.status === 1)
);

export const totalNegativePositionsSelected = createSelector(
  selectTaxPositions,
  /* istanbul ignore next */ (taxPositions) =>
    taxPositions
      .filter((holding) => holding.unrealized_capital_gains < 0)
      .reduce(
        (acc, curr) =>
          acc + curr.unrealized_capital_gains * (curr.harvesting?.percentage || 1),
        0
      )
);

export const selectTaxHarvestingCash = createSelector(
  selectTaxPositions,
  (taxPositions) =>
    taxPositions.reduce(
      (acc, { outstanding_balance }) => acc + outstanding_balance,
      0
    )
);

export const selectPortfolioTrades = createSelector(
  selectPortfolioPositions,
  (portfolioPositions) => portfolioPositions.trades
);

export const selectCashPosition = createSelector(
  selectCurrentPortfolio,
  (portfolio) => portfolio.find((holding) => holding.ticker === '$CASH')
);

export const selectTargetPortfolioWeight = createSelector(
  selectTargetPortfolio,
  (targetPortfolio) => targetPortfolio.reduce((acc, asset) => acc + asset.weight, 0)
);
