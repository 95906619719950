// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { InputHTMLAttributes } from 'react';
import { AppIcon } from './app-icon';

interface SearchEtfsProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  leftIcon?: JSX.Element;
  value?: string;
  onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchBar = ({
  value,
  onChangeValue,
  leftIcon = <AppIcon icon='Search' color='disabled' size='base' />,
  placeholder,
  ...props
}: SearchEtfsProps) => {
  return (
    <div className='relative'>
      <input
        className='input-rounded pl-10 line-clamp-1'
        value={value}
        onChange={(e) => {
          onChangeValue(e);
        }}
        data-testid='ticker'
        placeholder={placeholder}
        type='text'
        autoComplete='off'
        {...props}
      />
      {leftIcon && <div className='absolute top-3 left-4'>{leftIcon}</div>}
    </div>
  );
};
