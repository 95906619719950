// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import type { AppState as RootState } from 'store/store';
import { SearchUniverses } from 'api/interfaces/ai/screener';

export const popularUsIndexes = ['SPY', 'IWB', 'QQQ', 'SNPE', 'IWM', 'IWV', 'VTI'];

export const popularInternationalIndexes = [
  'FEZ',
  'VGK',
  'DAX',
  'EWQ',
  'EWP',
  'EWJ',
  'EWZ',
];

export const popularThematics = [
  'ACES',
  'ARKK',
  'CIBR',
  'XSD',
  'AQWA',
  'METV',
  'XBI',
  'IDRV',
];

export const tempAssetBenchmarks = {
  QQQ: 'Nasdaq 100',
  IJR: 'US small cap',
  VTI: 'US total stock market',
  SPY: 'S&P 500',
  FEZ: 'Europe Large-Cap',
  EEMB: 'Emerging markets bonds',
  VNQ: 'US REITs',
  FLLA: 'Latin america',
  VT: 'World all countries',
  PFF: 'Preferred stocks',
  EEM: 'Emerging markets',
  TLT: 'Long term treasuries',
  IEF: 'Intermediate(7-10 years) term treasuries',
  IEI: 'Intermediate term(3-7 years) treasuries',
  SHY: 'Short term treasuries',
  BIL: 'US cash',
  HYG: 'High yield bonds',
  TIP: 'TIPS',
  LQD: 'Investment grade bonds',
  BND: 'US total bond market',
  BNDX: 'International bond market',
  GLD: 'Gold',
  EFA: 'EAFE stocks',
  DBC: 'Commodities',
  VPL: 'Pacific',
  DAX: 'Germany DAX',
  EWP: 'Spain',
  VGK: 'Europe',
  EWU: 'United Kingdom',
  EWQ: 'France',
  EWI: 'Italy',
  EWN: 'Netherlands',
  EWD: 'Sweden',
  EDEN: 'Denmark',
  EWH: 'Hong Kong',
  EWA: 'Australia',
  EWC: 'Canada',
  EWZ: 'Brazil',
  EWW: 'Mexico',
  ECH: 'Chile',
  IKOR: 'Korea',
  EWJ: 'Japan',
};

export const formattedBenchmarks = {
  ACES: 'Clean Energy',
  AQWA: 'Water',
  ARKK: 'Innovation',
  CIBR: 'Cybersecurity',
  DGRW: 'Dividends',
  EATZ: 'Food & Beverage',
  IDRV: 'Self-Driving',
  IFRA: 'Infrastructure',
  IWB: 'Russell 1000',
  IWM: 'Russell 2000',
  IWV: 'Russell 3000',
  LCTU: 'Carbon',
  METV: 'Metaverse',
  PBJ: 'Fast Food',
  QQQ: 'Nasdaq 100',
  SNPE: 'S&P 500 ESG',
  SNSR: 'Internet of Things',
  SPY: 'S&P 500',
  FEZ: 'Europe Large-Cap',
  VTI: 'US Total Market',
  XBI: 'Biotechnology',
  XLB: 'Materials',
  XLC: 'Communication Services',
  XLE: 'Energy',
  XLF: 'Financials',
  XLI: 'Industrials',
  XLK: 'Technology',
  XLP: 'Consumer staples',
  XLRE: 'Real estate',
  XLU: 'Utilities',
  XLV: 'Healthcare',
  XLY: 'Consumer Discretionary',
  XRT: 'Lifestyle & Luxury',
  XSD: 'Semiconductors',
  YOLO: 'Marijuana',
  HLAL: 'USA Shariah',
  DAX: 'Germany',
  EWP: 'Spain',
  VGK: 'Europe Total Market',
  EWU: 'United Kingdom',
  EWQ: 'France',
  EWI: 'Italy',
  EWN: 'Netherlands',
  EWD: 'Sweden',
  EDEN: 'Denmark',
  EWH: 'Hong Kong',
  EWA: 'Australia',
  EWC: 'Canada',
  EWZ: 'Brazil',
  EWW: 'Mexico',
  ECH: 'Chile',
  IKOR: 'Korea',
  EWJ: 'Japan',
  NDIA: 'India',
};
export type AllBenchmarks = keyof typeof formattedBenchmarks;

export const selectPopularUsIndexes = (appState: RootState) => {
  const indexes: SearchUniverses[] = [];
  const benchmarks = appState.availableParameters.rebalance.benchmarks;
  popularUsIndexes.forEach((key) => {
    const aux = benchmarks.find((benchmark) => benchmark.key === key);
    if (aux != undefined) indexes.push(aux);
  });
  return indexes;
};

export const selectPopularInternationalIndexes = (appState: RootState) => {
  const indexes: SearchUniverses[] = [];
  const benchmarks = appState.availableParameters.rebalance.benchmarks;
  popularInternationalIndexes.forEach((key) => {
    const aux = benchmarks.find((benchmark) => benchmark.key === key);
    if (aux != undefined) indexes.push(aux);
  });
  return indexes;
};

export const selectPopularThematics = (appState: RootState) =>
  appState.availableParameters.rebalance.benchmarks.filter((benchmark) =>
    popularThematics.includes(benchmark.key)
  );
