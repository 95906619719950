// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Button, Dialog } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import {
  selectOpenLinkAccountModal,
  setOpenLinkAccountModal,
} from 'store/slices/broker';
import { BrokersList } from './brokers-list';

export const ModalLinkAccount = () => {
  const dispatch = useDispatch();

  const isOpen = useAppSelector(selectOpenLinkAccountModal);

  const handleCloseModal = () => dispatch(setOpenLinkAccountModal(false));

  return (
    <Dialog open={isOpen}>
      <div className='px-8 py-6'>
        <header className='flex flex-col place-content-center place-items-center text-center py-6'>
          <h1 className='text-[#2D3745] font-semibold text-xl mb-3 font-poppins'>
            Link Broker Account
          </h1>
          <p className='text-[#56606D] font-poppins'>
            To enable trading capabilities, connect to a broker account
          </p>
        </header>
        <ul>
          <li className='account-item'>
            <BrokersList />
          </li>
        </ul>
        <div className='flex justify-end mt-3'>
          <Button
            variant='outlined'
            color='secondary'
            className='!mr-8'
            onClick={handleCloseModal}
          >
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
