// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { assetsApi as axiosAssetsApi } from 'api/ai/assets-api';
import { FundHoldingsParams } from 'api/interfaces/ai/assets';
import { FundHolding } from 'api/interfaces/ai/commons';
import { Stock } from 'api/interfaces/ai/screener';
import { assetsApi } from 'store/api/ai/assets-api';
import { appStore as store } from 'store/store';

type FundHoldingProps = FundHoldingsParams & { assetType: string };

export interface FundHoldingDetail extends FundHolding {
  name?: string;
  url_logo?: string;
  url_morningstar?: string;
}

export const fetchFundHoldings = async ({
  assetType,
  ...params
}: FundHoldingProps) => {
  const { data, pages } = await axiosAssetsApi.fundHoldings({ params, assetType });
  const tickers = data.map((holding) => holding.ticker);
  const action = assetsApi.endpoints.assetsInfo.initiate({
    payload: tickers,
    params: { detail: true },
    assetType: 'stock',
  });
  const assetsInfo = (await store.dispatch(action).unwrap()) as Stock[];

  const holdings: FundHoldingDetail[] = data.map((holding) => {
    const asset = assetsInfo.find((info) => info.ticker === holding.ticker);
    return {
      ...holding,
      name: asset?.name,
      url_logo: asset?.url_logo,
      url_morningstar: asset?.url_morningstar,
    };
  });

  return {
    holdings,
    pages,
  };
};
