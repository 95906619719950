// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { User, GenericError } from '@auth0/auth0-spa-js';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { auth0 } from 'api/auth0';

export const authorizeUser = createAsyncThunk(
  'user/authorizeUser',
  async (_, { dispatch }) => {
    const auth = await auth0;

    try {
      const token = await auth.getTokenSilently();

      dispatch(getUser());

      return token || null;
    } catch (error) {
      if ((error as GenericError).error === 'login_required') {
        await auth.loginWithRedirect();
      }

      console.log(error);

      return null;
    }
  }
);

export const loginUser = createAsyncThunk('user/loginUser', async () => {
  const auth = await auth0;

  return auth.loginWithRedirect();
});

export const logoutUser = createAsyncThunk(
  'user/logoutUser',
  async (redirect: string) => {
    const auth = await auth0;

    return auth.logout({
      returnTo: redirect,
    });
  }
);

export const getUser = createAsyncThunk('user/loginUser', async () => {
  const auth = await auth0;

  const user = await auth.getUser();

  return JSON.parse(JSON.stringify(user) ?? 'null') as InstanceType<
    typeof User
  > | null;
});
