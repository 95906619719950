// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

export const MY_RESEARCH = {
  selectedAssets: (
    <p className='text-justify'>
      Create your own Watchlist by clicking the star button next to the desired
      asset. Use our customizable tag system to organize with one or multiple tags
      per asset.
    </p>
  ),

  savedPortfolios: (
    <p className='text-justify'>
      Create, upload, and save portfolios. If you have an investment idea, but you
      are not ready to invest, you can save it here and invest when you are ready.
    </p>
  ),

  screeners: (
    <p className='text-justify'>
      Create your lists of screeners, and invest when you are ready.
    </p>
  ),
};

export type MyResearchPopupName = keyof typeof MY_RESEARCH;
