// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  resetBroker,
  selectBrokerConnected,
  setOpenLinkAccountModal,
} from 'store/slices/broker';
import { useAppSelector } from 'store/store';
import { MODAL_TEMPLATES_OPTIONS, showModal } from 'features/ui/modal';

export const ButtonLinkAccount = () => {
  const dispatch = useDispatch();
  const isBrokerConnected = useAppSelector(selectBrokerConnected);

  const logoutBroker = () => {
    showModal(MODAL_TEMPLATES_OPTIONS.UNLINK_BROKER_ACCOUNT).then((result) => {
      if (result.isConfirmed) {
        dispatch(resetBroker());
      }
    });
  };

  const openModal = () => dispatch(setOpenLinkAccountModal(true));

  return (
    <Button
      color='secondary'
      data-testid='btn-link-account'
      onClick={isBrokerConnected ? logoutBroker : openModal}
    >
      {isBrokerConnected ? 'Unlink Account' : '+ Link Account'}
    </Button>
  );
};
