// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { PopupsName } from 'features/popups';
import { classNames } from 'features/utils/classnames';
import { formatToMoney } from 'features/utils/format-to-money';
import { Heading } from './heading';

export interface KpiBoxesProps {
  dataTestid?: string;
  popupName?: PopupsName;
  numberPositions?: number;
  benchmarkScore?: number;
  cash?: number;
  text: string;
  value: number | string;
}

export const KpiBoxes = ({
  value,
  text,
  popupName,
  numberPositions,
  benchmarkScore,
  dataTestid = 'kpi-box',
  cash,
}: KpiBoxesProps) => {
  return (
    <figure className={`kpi-box bg-inherit text-[#445a7a]`} data-testid={dataTestid}>
      <figcaption className='presentation'>
        <p className='text-2xl font-semibold'>{value}</p>
        <div>
          <Heading
            variant='h5'
            title={text}
            className='uppercase !text-[#445A7A] w-full flex justify-between'
            popupName={popupName}
            tooltip
          />
        </div>

        {!!numberPositions && (
          <p className='flex flex-row justify-between'>
            <span>No. of Positions</span>
            <span>{numberPositions}</span>
          </p>
        )}
        {typeof cash !== 'undefined' && (
          <div className='inline-flex flex-row gap-x-3 !justify-start'>
            <span>Cash</span>
            <span
              className={classNames({
                'text-[#D80027]': cash < 0,
                'text-[#2DCC89]': cash > 0,
              })}
            >
              {formatToMoney(cash || 0)}
            </span>
          </div>
        )}
        {benchmarkScore && (
          <p className='flex flex-row justify-between'>
            <span>Benchmark </span>
            <span>{benchmarkScore}</span>
          </p>
        )}
      </figcaption>
    </figure>
  );
};
