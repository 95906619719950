// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { User } from '@auth0/auth0-spa-js';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionsResp } from 'api/interfaces/engine/subscriptions';
import { authorizeUser, getUser } from './user-thunk';
import { Organization } from 'api/interfaces/engine/citec';

export interface UserState {
  user: InstanceType<typeof User> | null;
  token: string | null;
  expiration_date: number | null;
  status: string | null;
  isLoading: boolean;
  organizations: Organization[];
}

const initialState: UserState = {
  user: null,
  token: null,
  expiration_date: null,
  status: null,
  isLoading: true,
  organizations: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<SubscriptionsResp>) => {
      state.status = action.payload.status;
      state.expiration_date = Number(
        +action.payload.timestamp_expiration_date! * 1000
      );
    },
    setOrganizations: (state, action: PayloadAction<Organization[]>) => {
      state.organizations = action.payload;
    },
    resetUserData: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(authorizeUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(authorizeUser.fulfilled, (state, { payload }) => {
        state.token = payload;
        state.isLoading = false;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.user = payload;
      }),
});

export const {
  actions: { setUserData, resetUserData, setOrganizations },
  reducer,
} = userSlice;
