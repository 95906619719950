// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { Button, Tooltip } from '@mui/material';
import { ExpandedState, createColumnHelper } from '@tanstack/react-table';
import { AssetBrand, PortfolioActionsMenu } from 'components';
import { Table } from 'components/grouped-table';
import { capitalizeString } from 'features/utils/capitalize-string';
import { classNames } from 'features/utils/classnames';
import { MyInvestments, SubRows } from 'features/utils/interfaces/my-investment';
import { WorkflowState } from 'features/utils/interfaces/redux/workflow-state';
import { useConst } from 'hooks';
import { TradeInputParameters } from 'hooks/use-sent-to-trader';
import { FC, useMemo } from 'react';

export interface InvestmentTableProps {
  readonly data: MyInvestments[];
  readonly initialExpanded?: ExpandedState;
  readonly onLoad: (flow: WorkflowState, data: SubRows[]) => void;
  readonly onExport: (row: any) => void;
  readonly onSendToTrader: (trade: TradeInputParameters) => void;
}

const columnHelper = createColumnHelper<MyInvestments>();

export const InvestmentTable: FC<InvestmentTableProps> = ({
  data,
  initialExpanded,
  onExport,
  onLoad,
  onSendToTrader,
}) => {
  const assetsClassMap = useConst(
    new Map(
      Object.entries({
        stock: 'Stocks',
        etf: 'ETFs',
        mutual_fund: 'Mutual Fund',
        other: 'Others',
      })
    )
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('asset_class', {
        id: 'asset_class',
        cell: ({ getValue, row }) => (
          <div className='flex gap-x-2 items-center truncate'>
            <Table.Expander expander={row} />
            {row.getCanExpand() ? (
              <span>{assetsClassMap.get(getValue())}</span>
            ) : (
              <AssetBrand
                ticker={row.original.ticker!}
                assetType={row.original.asset_class}
                logoUrl={row.original.url_logo}
                url={row.original.url_morningstar}
                label={capitalizeString(row.original.name!)}
              />
            )}
          </div>
        ),
        header: 'Name',
        maxSize: 220,
      }),
      columnHelper.accessor('quantity', {
        header: 'Quantity',
        cell: ({ getValue }) => <Table.Data value={getValue()} format='number' />,
      }),
      columnHelper.accessor('currency', {
        header: 'Currency',
        cell: ({ getValue }) => getValue() ?? 'USD',
      }),
      columnHelper.accessor((row) => row.local_currency_values?.price as number, {
        header: 'Price',
        cell: ({ getValue }) => (
          <Table.Data value={getValue()} format='currency' safeValue='N/A' />
        ),
      }),
      columnHelper.accessor('investment', {
        header: 'Investment',
        cell: ({ getValue, row }) => (
          <Table.Data
            value={
              row.getCanExpand()
                ? getValue()
                : row.original.local_currency_values?.outstanding_balance
            }
            format='currency'
          />
        ),
      }),
      columnHelper.accessor('total_gain_loss', {
        header: 'Gain / loss',
        cell: ({ getValue, row }) => (
          <span
            className={classNames({
              profit: getValue()[0] >= 0,
              loss: getValue()[0] < 0,
            })}
          >
            <Table.Data
              value={
                row.getCanExpand()
                  ? getValue()[0]
                  : row.original.local_currency_values.unrealized_capital_gains
              }
              format='currency'
            />{' '}
            (
            <Table.Data
              value={
                row.getCanExpand()
                  ? getValue()[0] / row.original.investment
                  : row.original.local_currency_values.unrealized_capital_gains /
                    row.original.local_currency_values?.outstanding_balance
              }
              format='percentage'
            />
            )
          </span>
        ),
      }),
      columnHelper.display({
        id: 'options',
        enableSorting: false,
        cell: ({ row }) => (
          <div
            className='flex w-full justify-end aria-expanded:justify-between gap-x-1'
            aria-expanded={row.getCanExpand()}
          >
            {row.original.asset_class === 'other' ? null : row.getCanExpand() ? (
              <>
                <PortfolioActionsMenu
                  analyze
                  taxManager
                  rebalance
                  download
                  onAnalyze={() =>
                    onLoad(
                      { type: 'load', asset: row.original.asset_class as any },
                      row.original.subRows
                    )
                  }
                  onTaxManager={() =>
                    onLoad(
                      {
                        type: 'tax-harvesting',
                        asset: row.original.asset_class as any,
                      },
                      row.original.subRows
                    )
                  }
                  onRebalance={() =>
                    onLoad(
                      {
                        type: 'rebalance',
                        asset: row.original.asset_class as any,
                      },
                      row.original.subRows
                    )
                  }
                  onDownload={() => onExport(row.original)}
                />
              </>
            ) : (
              <Tooltip title='Sell position'>
                <Button
                  variant='outlined'
                  color='secondary'
                  size='small'
                  data-testid='test-sell-investment'
                  onClick={() =>
                    onSendToTrader({
                      operation: 'SELL',
                      ticker: row.original.ticker!,
                      volume: row.original.investment,
                      tickerInfo: row.original,
                      sourceMyInvestments: true,
                      unrealized_capital_gains: (row.original as any)
                        .unrealized_capital_gains,
                    })
                  }
                >
                  Sell
                </Button>
              </Tooltip>
            )}
          </div>
        ),
      }),
    ],
    [assetsClassMap, onExport, onLoad, onSendToTrader]
  );

  return (
    <div className='overflow-x-auto'>
      <Table.Grouped
        data={data}
        columns={columns}
        className='max-h-[320px]'
        initialExpanded={initialExpanded}
      />
    </div>
  );
};
