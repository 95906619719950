// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { aiApi } from './ai-api';
import { getEndpoint } from 'api/ai/get-endpoint';
import {
  OptimizationPayload,
  PortfolioBacktest,
  PortfolioValidationResp,
} from 'api/interfaces/ai/portfolio';
import { OptimizationResponse } from 'api/interfaces/ai/target-strategy';
import { PortfolioAnalysis } from 'api/interfaces/ai/portfolio-analysis';
import { PortfolioStrategy } from 'api/interfaces/ai/portfolio-strategy';
import { EsgAnalysis } from 'api/interfaces/ai/esg-analysis';

export const portfolioApi = aiApi.injectEndpoints({
  endpoints: (build) => ({
    portfolioBacktest: build.query<
      PortfolioBacktest,
      { params: { hist_w: number }; payload: PortfolioStrategy; assetType?: string }
    >({
      query: ({ payload, params, assetType = 'stock' }) => ({
        url: getEndpoint(assetType, 'backtest', 'portfolio'),
        method: 'POST',
        body: payload,
        params,
      }),
      providesTags: [{ type: 'Portfolio', id: 'Backtest' }],
    }),
    portfolioAnalysis: build.query<
      PortfolioAnalysis,
      { params: { hist_w: number }; payload: PortfolioStrategy; assetType?: string }
    >({
      query: ({ payload, params, assetType = 'stock' }) => ({
        url: getEndpoint(assetType, 'analysis', 'portfolio'),
        method: 'POST',
        body: payload,
        params,
      }),
      providesTags: [{ type: 'Portfolio', id: 'Analysis' }],
    }),
    portfolioStockAnalysisEsg: build.query<
      EsgAnalysis,
      { payload: PortfolioStrategy }
    >({
      query: ({ payload }) => ({
        url: '/portfolio/stock/analysis/esg/',
        method: 'POST',
        body: payload,
      }),
      providesTags: [{ type: 'Portfolio', id: 'Esg' }],
    }),
    portfolioOptimization: build.query<
      OptimizationResponse,
      {
        params: { hist_w: number; opt_global: string };
        payload: OptimizationPayload;
        assetType?: string;
      }
    >({
      query: ({ payload, params, assetType = 'stock' }) => ({
        url: getEndpoint(assetType, 'optimization', 'portfolio'),
        method: 'POST',
        body: payload,
        params,
      }),
      providesTags: [{ type: 'Portfolio', id: 'Optimization' }],
    }),
    portfolioValidation: build.query<
      PortfolioValidationResp,
      { payload: PortfolioStrategy; assetType?: string }
    >({
      query: ({ payload, assetType = 'stock' }) => ({
        url: getEndpoint(assetType, 'validation', 'portfolio'),
        method: 'POST',
        body: payload,
      }),
      providesTags: [{ type: 'Portfolio', id: 'Validation' }],
    }),
  }),
});
