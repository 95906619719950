// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { TickerTagFeature } from 'api/interfaces/engine/citec';
import { OptionsSelector } from 'components';
import { PortfolioLoaded } from 'features/utils/interfaces/portfolio-loaded';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAppSelector } from 'store/store';
import { selectWatchListPositions } from 'store/slices/my-research';
import { selectCurrentPortfolio } from 'store/slices/portfolio-positions';
import { selectAllTags } from 'store/slices/tag-manager';
import { selectWorkflowAsset, selectWorkflowType } from 'store/slices/workflow';

export interface SelectedAssetsSelectorProps {
  readonly onSelectAssets: Dispatch<SetStateAction<PortfolioLoaded[]>>;
}

export const SelectedAssetsSelector: FC<SelectedAssetsSelectorProps> = ({
  onSelectAssets,
}) => {
  const workflow = useAppSelector(selectWorkflowType);

  const assetType = useAppSelector(selectWorkflowAsset);

  const currentPortfolio = useAppSelector(selectCurrentPortfolio);

  const selectedAssets = useAppSelector(selectWatchListPositions);

  const assetsByType = useMemo(
    () =>
      selectedAssets.filter(({ asset_type }) =>
        workflow === 'rebalance' ? true : asset_type === assetType
      ),
    [assetType, selectedAssets, workflow]
  );

  const tags = useAppSelector((state) =>
    selectAllTags(state, TickerTagFeature.WatchList)
  );

  const sourceData = useMemo(() => {
    return tags
      .flatMap(({ name, positions }) =>
        assetsByType
          .filter(({ ticker }) =>
            positions.map((position) => position.ticker).includes(ticker)
          )
          .map(({ ticker }) => ({
            value: ticker,
            label: ticker,
            group: name,
          }))
      )
      .concat(
        assetsByType
          .filter(
            ({ ticker }) =>
              !tags
                .flatMap((tag) => tag.positions.map((position) => position.ticker))
                .includes(ticker)
          )
          .map((asset) => ({
            value: asset.ticker,
            label: asset.ticker,
            group: 'Non-tagged',
          }))
      );
  }, [assetsByType, tags]);

  const [optionSelected, setOptionSelected] = useState([] as string[]);

  const onChangeValueSelected = useCallback(
    (values: string[]) => {
      setOptionSelected(values);

      const addedValues = values.filter((value) => !optionSelected.includes(value));

      const removedValues = optionSelected.filter(
        (value) => !values.includes(value)
      );

      if (addedValues.length > 0) {
        return onSelectAssets((prev) =>
          prev.concat(
            addedValues.map(
              (ticker) =>
                ({
                  ticker,
                  outstanding_balance: 0,
                  unrealized_capital_gains: 0,
                  date: new Date(Date.now()).toISOString().split('T')[0] as string,
                  selectedAsset: true,
                } as PortfolioLoaded)
            )
          )
        );
      }

      if (removedValues.length > 0) {
        return onSelectAssets((prev) =>
          prev.filter((value) => !removedValues.includes(value.ticker))
        );
      }
    },
    [onSelectAssets, optionSelected]
  );

  useEffect(() => {
    if (currentPortfolio.length === 0 && optionSelected.length > 0) {
      /* istanbul ignore next */
      setOptionSelected([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPortfolio.length]);

  return (
    <OptionsSelector
      defaultText='Add Selected Assets'
      multiple
      checkbox
      optionsAvailable={sourceData}
      onChangeValueSelected={onChangeValueSelected}
      optionSelected={optionSelected}
      selectType='gray'
      grouping
      dataTestId='selected-assets-selector'
      selectedAll={false}
    />
  );
};
