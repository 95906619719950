// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Dialog, DialogContent, IconButton, Skeleton } from '@mui/material';
import { AppIcon, Heading } from 'components';
import { formatToMoney } from 'features/utils/format-to-money';
import { FC, useCallback, useMemo } from 'react';
import { OrderStatus } from '../hooks/use-execute-trades';

export interface OrdersSummaryProps {
  open: boolean;
  handleClose: () => void;
  orders: OrderStatus[];
  loading: boolean;
}

interface OrderStatusColor {
  label: string;
  color: string;
}

const statusColor: { [key: string]: OrderStatusColor } = {
  buy: { label: 'Bought', color: 'text-[#0D9795]' },
  error: { label: 'Failed', color: 'text-[#D80027]' },
  sell: { label: 'Sold', color: 'text-[#9C2B85]' },
};

export const OrdersStatus: FC<OrdersSummaryProps> = ({
  handleClose,
  open,
  orders,
  loading,
}) => {
  const getStatus = useCallback((order: OrderStatus) => {
    const isFailed = order.status === 'error';
    const operation = isFailed ? 'error' : order.operation.toLocaleLowerCase();
    return statusColor[operation];
  }, []);

  const summary = useMemo(() => {
    const buy = orders.filter((order) => order.operation === 'buy');
    const sell = orders.filter((order) => order.operation === 'sell');
    return {
      buyOrders: buy.length,
      sellOrders: sell.length,
      successBuyOrders: buy.filter((order) => order.status !== 'error').length,
      successSellOrders: sell.filter((order) => order.status !== 'error').length,
    };
  }, [orders]);

  return (
    <Dialog
      open={open}
      maxWidth='md'
      scroll='paper'
      disableScrollLock
      classes={{ paper: '!rounded-lg' }}
    >
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <AppIcon icon='Close' />
      </IconButton>
      <DialogContent className='w-[400px] !p-[27px] max-h-[900px] rounded'>
        <div className=''>
          <div className='grid justify-center gap-6'>
            <Heading variant='h2' title='Trades Processing' />
            <div className='text-[#515F70] flex justify-between'>
              <div className='flex'>
                <span className='font-bold mr-2'>Sell: </span>
                {loading ? (
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={40} />
                ) : (
                  <span>
                    {summary.successSellOrders}/{summary.sellOrders}
                  </span>
                )}
              </div>
              <div className='flex'>
                <span className='font-bold mr-2'>Buy: </span>
                {loading ? (
                  <Skeleton variant='text' sx={{ fontSize: '1rem' }} width={40} />
                ) : (
                  <span>
                    {summary.successBuyOrders}/{summary.buyOrders}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='text-[#79828D]' data-testid='orders-list'>
            {orders.map((order) => (
              <div
                key={order.ticker + order.operation}
                className='py-3 border-b border-[#DBDFEA] flex justify-between'
              >
                <div className='flex'>
                  <img
                    className='w-[25px] h-[25px] aspect-square rounded-full mt-1'
                    src={order.logo}
                    alt={order.ticker}
                  />
                  <div className='flex flex-col ml-2'>
                    <span className='font-medium leading-6'>{order.ticker}</span>
                    <span className='text-sm'>Quantity: {order.qty}</span>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <span>{formatToMoney(order.volume)}</span>
                  {loading ? (
                    <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
                  ) : (
                    <span className={`text-sm self-end ${getStatus(order).color}`}>
                      {getStatus(order).label}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
