// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Button, Slider } from '@mui/material';
import { useDynamicDialog } from 'components/dynamic-dialog';
import { Heading } from 'components/heading';
import { PortfolioPositionsCurrent } from 'features/utils/interfaces/redux/portfolio-state';
import { numberToFixed } from 'features/utils/to-fixed-number';
import { useAppNavigate } from 'hooks';
import { useCallback, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { Paths } from 'routes';
import { useAppDispatch } from 'store';
import { setCurrentPortfolioPositions } from 'store/slices/portfolio-positions';

export interface LoadPosition {
  readonly ticker: string;
  readonly weight: number;
}

export interface InvestPopupProps {
  onSubmit: (investment: number) => void;
}

const PORTFOLIO_INVESTMENT_LIMIT = 10_000_000;
const PORTFOLIO_INVESTMENT_MIN = 50_000;

export const useInvestmentPopup = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { openDialog, closeDialog } = useDynamicDialog();

  const createPortfolioPositions = useCallback(
    (positions: LoadPosition[], investment: number): PortfolioPositionsCurrent[] => {
      const totalWeight = positions.reduce((acc, { weight }) => acc + weight, 0);
      return positions.map(({ ticker, weight }) => {
        const scaledWeight = weight / totalWeight;
        return {
          ticker,
          qty: numberToFixed(investment * scaledWeight, 2),
          price: 1,
          unrealized_capital_gains: 0,
        };
      });
    },
    []
  );

  const loadPortfolioPositions = useCallback(
    (positions: LoadPosition[], investment: number, redirectPath: Paths) => {
      const portfolio = createPortfolioPositions(positions, investment);
      dispatch(setCurrentPortfolioPositions(portfolio));
      closeDialog();
      navigate(redirectPath);
    },
    [closeDialog, createPortfolioPositions, dispatch, navigate]
  );

  const openInvestmentPopup = useCallback(
    (positions: LoadPosition[], redirectPath: Paths) => {
      openDialog(
        <InvestmentPopup
          onSubmit={(investment) =>
            loadPortfolioPositions(positions, investment, redirectPath)
          }
        />,
        { disableBackdropClick: true }
      );

      return positions;
    },
    [openDialog, loadPortfolioPositions]
  );

  return { openInvestmentPopup };
};

const InvestmentPopup = ({ onSubmit }: InvestPopupProps) => {
  const [investment, setInvestment] = useState(PORTFOLIO_INVESTMENT_MIN);

  const handleInvestmentChange = useCallback((value: number) => {
    // Ensure investment is within the limits
    const investment = Math.min(
      PORTFOLIO_INVESTMENT_LIMIT,
      Math.max(PORTFOLIO_INVESTMENT_MIN, value)
    );

    setInvestment(investment);
  }, []);

  return (
    <div className='grid gap-4'>
      <Heading variant='h2' title='How much money do you want to invest?' />
      <div className='bg-[#EDF0F9] p-8 rounded-lg grid gap-4'>
        <Heading variant='h3' title='Investment' />
        <NumericFormat
          id='investment-input'
          data-testid='investment-input'
          value={investment}
          thousandSeparator={true}
          allowNegative={false}
          onValueChange={({ floatValue }) => handleInvestmentChange(floatValue!)}
          prefix='$'
          className='input-common'
        />
        <Slider
          aria-label='Investment amount'
          data-testid='investment-slider'
          valueLabelDisplay='off'
          onChange={(_, value) => handleInvestmentChange(value as number)}
          value={investment}
          min={PORTFOLIO_INVESTMENT_MIN}
          max={PORTFOLIO_INVESTMENT_LIMIT}
          step={1_000}
        />
      </div>
      <div className='flex justify-end'>
        <Button
          variant='contained'
          color='secondary'
          onClick={() => onSubmit(investment)}
        >
          Invest
        </Button>
      </div>
    </div>
  );
};
