// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { useRef } from 'react';

// Symbol used to represent an empty reference
const EMPTY_REF = Symbol('@@empty_ref');

/**
 * React hook to create a constant value that persists across re-renders.
 *
 * @template T - The type of the constant value.
 * @param {T | (() => T)} initialValue - The initial value or a function that returns the initial value.
 * @returns {T} - The constant value.
 */
export const useConst = <T>(initialValue: T | (() => T)): T => {
  // Create a reference to store the constant value
  const ref = useRef(EMPTY_REF as T);

  // If the reference is empty, assign the initial value to it
  if (ref.current === EMPTY_REF)
    ref.current = initialValue instanceof Function ? initialValue() : initialValue;

  // Return the constant value
  return ref.current;
};
