// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store/store';

export const selectTrader = createSelector(
  (state: AppState) => state.trader,
  (trader) => trader
);

export const selectTraderTrades = createSelector(
  selectTrader,
  (trader) => trader.trades
);

export const selectOpenTrades = createSelector(
  selectTrader,
  (trader) => trader.openTrades
);

export const selectValidTrades = createSelector(selectTraderTrades, (trades) =>
  trades.filter((trade) => trade.expected_volume > 0)
);

export const selectBuyTrades = createSelector(selectTraderTrades, (trades) =>
  trades.filter((trade) => trade.operation === 'BUY')
);

export const selectBuyTradesTotalVolume = createSelector(selectBuyTrades, (trades) =>
  trades.reduce((total, trade) => total + trade.expected_volume, 0)
);

export const selectSellTrades = createSelector(selectTraderTrades, (trades) =>
  trades.filter((trade) => trade.operation === 'SELL')
);

export const selectSellTradesTotalVolume = createSelector(
  selectSellTrades,
  (trades) => trades.reduce((total, trade) => total + trade.expected_volume, 0)
);

export const selectLoadingTrades = createSelector(
  selectTrader,
  ({ loading }) => loading
);
