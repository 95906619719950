// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { Button } from '@mui/material';
import { useAppNavigate } from 'hooks';
import { ButtonHTMLAttributes, forwardRef, useCallback } from 'react';
import { Paths } from 'routes';

export interface BackButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'onClick'> {
  readonly to?: Paths | 'goBack';
}

export const BackButton = forwardRef<HTMLButtonElement, BackButtonProps>(
  ({ to = 'goBack', children, ...props }, ref) => {
    const navigate = useAppNavigate();

    const onClick = useCallback(() => navigate(to), [to, navigate]);

    return (
      <Button
        {...props}
        variant='outlined'
        color='secondary'
        ref={ref}
        onClick={onClick}
      >
        <span>{children ?? 'Back'}</span>
      </Button>
    );
  }
);

BackButton.displayName = 'BackButton';
