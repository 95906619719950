// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TickerTagFeature } from 'api/interfaces/engine/citec';
import {
  TagManagerState,
  Tag,
  TagPosition,
  TickerTagState,
} from 'features/utils/interfaces/redux/tag-manager-state';
import {
  getAllTickerTags,
  createTickerTag,
  deleteTickerTag,
} from './tag-manager-thunk';

export const tickerTagEntityAdapter = createEntityAdapter<Tag>({
  selectId: ({ name }) => name,
  sortComparer: (current, next) => current.name.localeCompare(next.name),
});

export const tagManagerEntityAdapter = createEntityAdapter<TickerTagState>({
  selectId: ({ feature }) => feature,
  sortComparer: (current, next) => current.feature.localeCompare(next.feature),
});

export const initialState = tagManagerEntityAdapter.getInitialState<TagManagerState>(
  {
    entities: {
      [TickerTagFeature.PortfolioPositions]: {
        isLoading: true,
        feature: TickerTagFeature.PortfolioPositions,
        selectedTag: '',
        tags: tickerTagEntityAdapter.getInitialState(),
      },
      [TickerTagFeature.WatchList]: {
        isLoading: true,
        feature: TickerTagFeature.WatchList,
        selectedTag: '',
        tags: tickerTagEntityAdapter.getInitialState(),
      },
    },
    ids: [TickerTagFeature.PortfolioPositions, TickerTagFeature.WatchList],
  }
);

export const tagManagerSlice = createSlice({
  initialState: () => initialState,
  name: 'tagManager',
  reducers: {
    setSelectTag: (
      state,
      {
        payload: [feature, selectedTag],
      }: PayloadAction<[feature: TickerTagFeature, tag: string]>
    ) => {
      tagManagerEntityAdapter.updateOne(state, {
        id: feature,
        changes: { selectedTag },
      });
    },
    setTag: (
      state,
      {
        payload: [feature, tag],
      }: PayloadAction<[feature: TickerTagFeature, tag: Tag]>
    ) => {
      tagManagerEntityAdapter.updateOne(state, {
        id: feature,
        changes: {
          tags: tickerTagEntityAdapter.setOne(state.entities[feature]!.tags, tag),
        },
      });
    },
    setTags: (
      state,
      {
        payload: [feature, tags],
      }: PayloadAction<[feature: TickerTagFeature, tags: Tag[]]>
    ) => {
      tagManagerEntityAdapter.updateOne(state, {
        id: feature,
        changes: {
          tags: tickerTagEntityAdapter.setAll(state.entities[feature]!.tags, tags),
        },
      });
    },
    setTagInvestment: (
      state,
      {
        payload: [feature, name, investment],
      }: PayloadAction<[feature: TickerTagFeature, name: string, investment: number]>
    ) => {
      tagManagerEntityAdapter.updateOne(state, {
        id: feature,
        changes: {
          tags: tickerTagEntityAdapter.updateOne(state.entities[feature]!.tags, {
            id: name,
            changes: { investment },
          }),
        },
      });
    },
    setTagUnrealizedCapitalGains: (
      state,
      {
        payload: [feature, name, gains],
      }: PayloadAction<[feature: TickerTagFeature, name: string, gains: number]>
    ) => {
      tagManagerEntityAdapter.updateOne(state, {
        id: feature,
        changes: {
          tags: tickerTagEntityAdapter.updateOne(state.entities[feature]!.tags, {
            id: name,
            changes: { unrealizedCapitalGains: gains },
          }),
        },
      });
    },
    setTagPositions: (
      state,
      {
        payload: [feature, name, positions],
      }: PayloadAction<
        [feature: TickerTagFeature, name: string, positions: TagPosition[]]
      >
    ) => {
      tagManagerEntityAdapter.updateOne(state, {
        id: feature,
        changes: {
          tags: tickerTagEntityAdapter.updateOne(state.entities[feature]!.tags, {
            id: name,
            changes: { positions },
          }),
        },
      });
    },
    resetTagManager: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllTickerTags.pending,
        (
          state,
          {
            meta: {
              arg: { feature },
            },
          }
        ) => {
          tagManagerEntityAdapter.updateOne(state, {
            id: feature,
            changes: {
              isLoading: true,
            },
          });
        }
      )
      .addCase(
        getAllTickerTags.fulfilled,
        (
          state,
          {
            meta: {
              arg: { feature },
            },
            payload,
          }
        ) => {
          tagManagerEntityAdapter.updateOne(state, {
            id: feature,
            changes: {
              isLoading: false,
              tags: tickerTagEntityAdapter.setAll(
                state.entities[feature]!.tags,
                payload
              ),
            },
          });
        }
      )
      .addCase(
        createTickerTag.fulfilled,
        (
          state,
          {
            meta: {
              arg: { feature },
            },
            payload,
          }
        ) => {
          tagManagerEntityAdapter.updateOne(state, {
            id: feature,
            changes: {
              tags: tickerTagEntityAdapter.upsertOne(
                state.entities[feature]!.tags,
                payload
              ),
            },
          });
        }
      )
      .addCase(
        deleteTickerTag.fulfilled,
        (
          state,
          {
            meta: {
              arg: { feature },
            },
            payload,
          }
        ) => {
          tagManagerEntityAdapter.updateOne(state, {
            id: feature,
            changes: {
              tags:
                typeof payload === 'string'
                  ? tickerTagEntityAdapter.removeOne(
                      state.entities[feature]!.tags,
                      payload
                    )
                  : tickerTagEntityAdapter.setAll(
                      state.entities[feature]!.tags,
                      payload
                    ),
            },
          });
        }
      );
  },
});

export const {
  reducer,
  actions: {
    resetTagManager,
    setSelectTag,
    setTag,
    setTagInvestment,
    setTagPositions,
    setTagUnrealizedCapitalGains,
    setTags,
  },
} = tagManagerSlice;
