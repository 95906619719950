// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TargetAsset } from 'api/interfaces/ai/target-strategy';
import { Operations } from 'api/interfaces/commons';
import {
  PortfolioPositionsCurrent,
  PortfolioPositionsState,
  PortfolioPositionsTrades,
  PositionsAnalysis,
  UpdateHarvestingPosition,
} from 'features/utils/interfaces/redux/portfolio-state';

const initialState: PortfolioPositionsState = {
  portfolioLoaded: '',
  current: [],
  target: [],
  trades: [],
  tradesTable: [],
};

export const portfolioPositionsSlice = createSlice({
  name: 'portfolioPositions',
  initialState,
  reducers: {
    setPortfolioLoaded: (state, action: PayloadAction<string>) => {
      state.portfolioLoaded = action.payload;
    },

    setCurrentPortfolioPositions: (
      state,
      action: PayloadAction<PortfolioPositionsCurrent[]>
    ) => {
      state.current = action.payload;
    },

    setTargetPortfolioPositions: (state, action: PayloadAction<TargetAsset[]>) => {
      state.target = action.payload;
    },

    setTradesPortfolioPositions: (
      state,
      action: PayloadAction<PortfolioPositionsTrades[]>
    ) => {
      state.trades = action.payload;
    },

    setTradesTablePortfolioPositions: (
      state,
      action: PayloadAction<PositionsAnalysis[]>
    ) => {
      state.tradesTable = action.payload;
    },

    updateCashPortfolio: (state, action: PayloadAction<number>) => {
      const amount = action.payload;
      const cashPosition = state.current.find(
        (holding) => holding.ticker === '$CASH'
      );
      if (cashPosition) {
        cashPosition.price = amount;
      } else {
        state.current.unshift({
          ticker: '$CASH',
          price: action.payload,
          qty: 1,
          unrealized_capital_gains: 0,
        });
      }
    },

    updateCashTrades: (state, action: PayloadAction<number>) => {
      const cashTrade = state.trades.find((trade) => trade.ticker === '$CASH');
      const amount = action.payload;
      if (!cashTrade) {
        state.trades.unshift({
          ticker: '$CASH',
          volume: amount,
          operation: 'SELL',
          realized_capital_gains: 0,
          unrealized_capital_gains: 0,
        });
      } else {
        cashTrade.volume = amount;
      }
    },

    updateTargetPortfolioPosition: (state, action: PayloadAction<TargetAsset>) => {
      const assetIndex = state.target.findIndex(
        (asset) => asset.ticker === action.payload.ticker
      );
      if (assetIndex !== -1) {
        state.target[assetIndex] = action.payload;
      } else {
        state.target.push(action.payload);
      }
    },

    deleteTargetPortfolioPosition: (state, action: PayloadAction<string[]>) => {
      state.target = state.target.filter(
        (asset) => !action.payload.includes(asset.ticker)
      );
    },

    deleteTradesTablePosition: (
      state,
      { payload: ticker }: PayloadAction<string>
    ) => {
      state.current = state.current.filter((holding) => ticker !== holding.ticker);
      state.tradesTable = state.tradesTable.filter(
        (position) => ticker !== position.ticker
      );
    },

    updateHarvestingPosition: (
      state,
      action: PayloadAction<UpdateHarvestingPosition>
    ) => {
      const { ticker } = action.payload;
      const position = state.current.find((asset) => asset.ticker === ticker);
      if (!position) return;

      const data = position.harvesting!;
      position.harvesting = {
        ...data,
        ...action.payload,
      };
    },

    updateHarvestingPositionStatus: (state, action: PayloadAction<string[]>) => {
      const tickers = new Set(action.payload);
      state.current.forEach((asset) => {
        if (tickers.has(asset.ticker)) {
          asset.harvesting!.status = Operations.Buy;
          asset.harvesting!.percentage = 1;
        } else {
          asset.harvesting!.status = Operations.Sell;
          asset.harvesting!.percentage = 0;
        }
      });
    },

    resetPortfolioPositions: () => initialState,
  },
});

export const {
  setPortfolioLoaded,
  deleteTargetPortfolioPosition,
  deleteTradesTablePosition,
  resetPortfolioPositions,
  setCurrentPortfolioPositions,
  setTargetPortfolioPositions,
  setTradesPortfolioPositions,
  setTradesTablePortfolioPositions,
  updateCashPortfolio,
  updateCashTrades,
  updateHarvestingPosition,
  updateHarvestingPositionStatus,
  updateTargetPortfolioPosition,
} = portfolioPositionsSlice.actions;

export const portfolioPositionsReducer = portfolioPositionsSlice.reducer;
