// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Button, Slide } from '@mui/material';
import { useAppSelector } from 'store/store';
import { useAppNavigate } from 'hooks';
import { useMemo } from 'react';
import { selectExpirationDate } from 'store/slices/user';

export const BarTrialPeriod = () => {
  const navigate = useAppNavigate();

  const expirationDate = useAppSelector(selectExpirationDate);
  const today = new Date().getTime();

  const daysLeft = useMemo(() => {
    if (!expirationDate) return 0;
    return Math.floor((expirationDate - today) / 86400000);
  }, [expirationDate, today]);

  return daysLeft > 15 ? null : (
    <Slide direction='down' in={!!expirationDate} mountOnEnter unmountOnExit>
      <div
        className='bg-[#0BACA9]/20 p-3 flex justify-center'
        data-testid='container-trial'
      >
        <div className='flex justify-center items-center'>
          <p className='text-[#56606D] mr-2'>
            <strong className='text-[20px] mr-2'>
              {daysLeft < 0 ? 0 : daysLeft} days left
            </strong>
            {''} of your current license
          </p>
          <Button
            className='p-2 !rounded-md !font-bold'
            onClick={() => navigate('/account')}
            data-testid='suscribe-button'
          >
            Manage your license
          </Button>
        </div>
      </div>
    </Slide>
  );
};
