// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { getLatestPrices } from 'features/brokers/utils/get-latest-prices';
import { getTradesToOrders } from 'features/brokers/utils/get-trades-to-orders';
import { TradeDetails } from 'features/utils/interfaces/redux/trades';
import { getTradesToOrderAlpaca, OrderAlpaca } from './trades-to-order-alpaca';
import { portfolioApi as brokerPortfolioApi } from 'api/brokerage/portfolio-api';
import { numberToFixed } from 'features/utils/to-fixed-number';

export function separateTrades(data: OrderAlpaca[]) {
  const sell: OrderAlpaca[] = [];
  const buy: OrderAlpaca[] = [];

  data.map((stock: any) => {
    if (stock.side == 'sell') sell.push(stock);
    else buy.push(stock);
  });

  return { sell, buy };
}

export function getTickers(data: TradeDetails[]) {
  const tickers: string[] = [];

  data.map((stock) => {
    tickers.push(stock.ticker);
  });

  return tickers;
}

export function removeCash(data: TradeDetails[]) {
  const filteredData: TradeDetails[] = [];

  data.map((stock) => {
    if (stock.ticker != '$CASH') filteredData.push(stock);
  });

  return filteredData;
}

export function getMape(data: TradeDetails[]) {
  let sumAbsoluteDelta = 0;
  let totalVolume = 0;
  let mape = 0;

  if (data.length == 0) {
    return mape;
  }

  data.map((stock: any) => {
    sumAbsoluteDelta += Math.abs(stock.delta_volume);
    totalVolume += stock.volume;
  });

  if (totalVolume != 0) {
    mape = Math.round((sumAbsoluteDelta / totalVolume) * 10000) / 100;
  }

  return mape;
}

export function unavailableTrades(data: TradeDetails[]) {
  if (data.length === 0) {
    return false;
  }

  const areSomeTradeUnavailable = data.some(
    (trade) => !numberToFixed(trade.expected_price as number, 5)
  );

  return areSomeTradeUnavailable;
}

export async function getAllStatusSales(broker: any, ordersIds: string[]) {
  const statusOrders: string[] = [];
  const date = new Date();

  try {
    const order_filter = {
      start_date: date.toLocaleDateString('en-US'),
      end_date: new Date(date.setDate(date.getDate() + 1)).toLocaleDateString(
        'en-US'
      ),
      side: 'sell',
    };
    const params = {
      broker_key: broker.brokerConnected.toLowerCase(),
      type_account: broker.accountTypeSelect,
    };

    const payload = {
      account_id: broker.account.accountId,
      order_filter,
    };
    const resp = await brokerPortfolioApi.ordersSearch(params, payload);

    const orders = resp;
    for (const id of ordersIds) {
      if (id == 'error') {
        statusOrders.push(id);
      } else {
        orders.map((order: any) => {
          if (order.order_id == id) {
            statusOrders.push(order.status);
          }
        });
      }
    }
    return statusOrders;
  } catch (error: any) {
    if (error.response) {
      console.log('error', error.response.data);
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
    return statusOrders;
  }
}

export function processBuy(statusOrders: string[]) {
  let toProcess = true;
  let i = 0;
  while (toProcess && i < statusOrders.length) {
    if (statusOrders[i] !== 'filled') {
      toProcess = false;
    }
    i++;
  }
  return toProcess;
}

export async function executeOperations(broker: any, data: any) {
  const filteredData = removeCash(data);

  const tickers = getTickers(filteredData);

  const latestPrices = await getLatestPrices(broker, tickers);

  const dataPrepared = {
    trades: filteredData,
    latest_prices: latestPrices,
  };

  const tradesToOrders = await getTradesToOrders(dataPrepared);

  const tradesToOrdersAlpaca = getTradesToOrderAlpaca(tradesToOrders);

  const { sell, buy } = separateTrades(tradesToOrdersAlpaca);

  return { sell, buy };
}

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
