// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { LoaderFunction } from 'react-router-dom';
import { appStore } from 'store/store';
import { resetRebalanceConfig } from 'store/slices/parameters';
import { resetPortfolioPositions } from 'store/slices/portfolio-positions';
import { setWorkflow } from 'store/slices/workflow';
import { TypeWorkflow, WorkflowAsset } from './interfaces/redux/workflow-state';

export const workflowLoader =
  (type: TypeWorkflow = '', asset: WorkflowAsset = ''): LoaderFunction =>
  async ({ request }) => {
    const params = new URL(request.url).searchParams;

    if (type !== 'load') appStore.dispatch(resetRebalanceConfig());

    const workflows = ['load', 'rebalance', 'analysis', 'tax-harvesting'];

    if (
      !workflows.includes(type) &&
      !workflows.includes(params.get('workflow') as string)
    ) {
      appStore.dispatch(resetPortfolioPositions());
    }

    appStore.dispatch(setWorkflow({ asset, type }));

    return { ok: true };
  };
