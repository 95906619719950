// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AppIcon } from 'components/app-icon';
import { classNames } from 'features/utils/classnames';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import type { SortingColumn } from '@tanstack/react-table';
import styles from './sort-header.module.css';

export interface SortHeaderProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly sort: SortingColumn<any>;
}

export const SortHeader = forwardRef<HTMLButtonElement, SortHeaderProps>(
  ({ sort, className, ...props }, ref) => (
    <button
      ref={ref}
      type='button'
      className={classNames(styles.button, className)}
      aria-sort={
        !sort.getIsSorted()
          ? 'none'
          : sort.getIsSorted() === 'asc'
          ? 'ascending'
          : 'descending'
      }
      aria-hidden={!sort.getCanSort()}
      {...props}
    >
      <AppIcon
        icon={
          !sort.getIsSorted()
            ? 'Sort'
            : sort.getIsSorted() === 'asc'
            ? 'SortAsc'
            : 'SortDesc'
        }
      />
    </button>
  )
);

SortHeader.displayName = 'SortHeader';
