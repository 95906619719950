// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AssetsInfoResp } from 'api/interfaces/ai/assets';
import { MyIdeas } from 'features/utils/interfaces/my-ideas';
import { SavedPortfolio } from 'features/utils/interfaces/redux/my-research-state';

export function buildDataIdeas(
  ideas: SavedPortfolio[],
  positions: AssetsInfoResp[]
) {
  const portfolios_saved: MyIdeas[] = [];

  ideas.forEach((idea) => {
    const subrows: any[] = [];

    const totalInvestment = idea.holdings.reduce(
      (curr, prev) => curr + prev.outstanding_balance,
      0
    );

    if (idea.holdings.length === 0) return;

    idea.holdings.forEach((position) => {
      const result = positions.filter((obj) => {
        return obj.ticker === position.ticker;
      });

      if (result.length === 0) return;

      const item = result[0];

      const assetClass =
        'market_cap' in item
          ? 'Stock'
          : 'nav' in item
          ? 'Mutual Fund'
          : 'aum' in item
          ? 'ETF'
          : '-';

      subrows.push({
        portfolio_id: idea.portfolio_id,
        portfolio: position, // Name Ticker
        benchmark: idea.benchmark,
        investment: position.outstanding_balance, // Investment each ticker
        weight: position.outstanding_balance / totalInvestment,
        unrealized_capital_gains: position.unrealized_capital_gains,
        creation_date: idea.creation_date,
        columnName: result[0],
        asset_class: assetClass, //here there is position info: logo_url, name, ticker....
        subRows: undefined,
      });
    });

    const investment = idea.holdings.reduce(
      (accumulator, curr) => accumulator + curr.outstanding_balance,
      0
    );

    if (subrows.length === 0) return;

    const assetType = subrows[0].asset_class;
    const isMultiAsset = !subrows.every(
      (position) => position.asset_class === assetType
    );

    portfolios_saved.push({
      portfolio_id: idea.portfolio_id,
      portfolio: idea.portfolio,
      benchmark: idea.benchmark,
      investment: investment,
      weight: 1,
      creation_date: idea.creation_date,
      columnName: idea.portfolio,
      asset_class: isMultiAsset ? 'Multi-asset' : assetType,
      subRows: subrows,
      organizations: idea.organizations,
      owner: idea.owner,
    });
  });

  return portfolios_saved;
}
