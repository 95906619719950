// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { forwardRef, HTMLAttributes, useMemo } from 'react';
import { makeContentId, makeTriggerId, useTabsContext } from './tabs-context';
import styles from './tabs.module.css';

export interface TabsContent extends HTMLAttributes<HTMLDivElement> {
  readonly value: string;
}

export const TabsContent = forwardRef<HTMLDivElement, TabsContent>(
  ({ children, value, className, ...props }, ref) => {
    const context = useTabsContext();

    const isSelected = useMemo(
      () => context.value === value,
      [value, context.value]
    );

    const triggerId = makeTriggerId(context.baseId, value);

    const contentId = makeContentId(context.baseId, value);

    return (
      <div
        data-state={isSelected ? 'active' : 'inactive'}
        aria-labelledby={triggerId}
        id={contentId}
        data-testid={contentId}
        role='tabpanel'
        tabIndex={0}
        hidden={!isSelected}
        className={`${styles['tabs-content']} ${className}`}
        {...props}
        ref={ref}
      >
        {isSelected && children}
      </div>
    );
  }
);

TabsContent.displayName = 'TabsContent';
