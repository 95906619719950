// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { formatToMoney } from 'features/utils/format-to-money';
import { FC } from 'react';

type GainLossProps = {
  readonly value: number;
  readonly percentage: number;
  readonly className?: string;
  readonly showPercentage?: boolean;
};

export const GainLoss: FC<GainLossProps> = ({
  percentage,
  value,
  className = '',
  showPercentage = true,
}) => {
  const color = percentage > 0 ? 'text-[#2DCC89]' : 'text-[#E24646]';
  const displayValue = formatToMoney(value);
  const displayPercentage = (percentage * 100).toFixed(2).padStart(5, ' ');
  return (
    <div className={`line-clamp-1 ${color} ${className}`}>
      {displayValue} {showPercentage && `(${displayPercentage}%)`}
    </div>
  );
};
