/* eslint-disable @typescript-eslint/no-empty-function */
// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { TagManagerEditor } from './tag-manager-editor';
import { AssignTagsEditor } from './assign-tags-editor';
import { TagItem } from './tag-item';
import { Tag } from 'features/utils/interfaces/redux/tag-manager-state';
import { TagSegment } from './tag-segment';

export interface ManageTagsProps {
  tags: Tag[];
  tickerTags: Array<{
    ticker: string;
    url_logo?: string;
    tags: string[];
  }>;
  suggestions: string[];
  onCreateTag: (tag: string, update?: boolean) => void;
  onRemoveTag: (tag: string) => void;
  onAddTicker?: (tag: string, assetType: string) => void;
  onCreateTickerTag: (tag: string, ticker: string) => void;
  onRemoveTickerTag: (tag: string, ticker: string) => void;
  assetsInput?: boolean;
}

export const ManageTags = ({
  tags,
  tickerTags,
  onCreateTag,
  onCreateTickerTag,
  onRemoveTag,
  onRemoveTickerTag,
  suggestions,
  assetsInput,
  onAddTicker,
}: ManageTagsProps) => {
  return (
    <>
      <TagManagerEditor onTag={onCreateTag} suggestions={suggestions}>
        {Array.from(tags, ({ name }, key) => (
          <TagItem
            key={`${name}-${key}`}
            label={name}
            tag={name}
            onDelete={onRemoveTag}
          />
        ))}
      </TagManagerEditor>

      <hr />

      <AssignTagsEditor assetsInput={assetsInput} onAddTicker={onAddTicker}>
        {Array.from(tickerTags, ({ ticker, url_logo, tags: selectedTags }) => (
          <TagSegment
            key={ticker}
            ticker={ticker}
            logo={url_logo}
            options={tags}
            selectedTags={selectedTags}
            onTickerTag={onCreateTickerTag}
            onTickerTagDelete={onRemoveTickerTag}
          />
        ))}
      </AssignTagsEditor>
    </>
  );
};
