// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { RetryOptions } from '@reduxjs/toolkit/dist/query/retry';
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  retry,
} from '@reduxjs/toolkit/query/react';
import { auth0 } from 'api/auth0';
import QueryString from 'qs';

export interface FetchQueryArgs extends FetchBaseQueryArgs {
  auth0?: 'header' | `query-${'user' | 'email'}` | 'none';
}

export interface FetchBaseQueryExtraOptions {
  authenticated?: FetchQueryArgs['auth0'];
}

type FetchQueryFn = BaseQueryFn<
  FetchArgs,
  unknown,
  FetchBaseQueryError,
  FetchBaseQueryExtraOptions & RetryOptions,
  FetchBaseQueryMeta
>;

export const fetchQuery = ({
  auth0: authentication = 'none',
  ...options
}: FetchQueryArgs): FetchQueryFn => {
  const baseQuery = retry(
    fetchBaseQuery({
      ...options,
      prepareHeaders: async (headers, api) => {
        if (
          [
            (api.extra as FetchBaseQueryExtraOptions)?.authenticated,
            authentication,
          ].includes('header')
        ) {
          const client = await auth0;

          const token = await client.getTokenSilently();

          headers.set('Authorization', `Bearer ${token}`);
        }

        return options?.prepareHeaders?.(headers, api) ?? headers;
      },
      paramsSerializer(params) {
        return QueryString.stringify(params, { encode: false });
      },
    }),
    process.env.NODE_ENV === 'test'
      ? { retryCondition: () => false }
      : {
          maxRetries: 2,
        }
  ) as FetchQueryFn;

  return async (args, api, extraOptions) => {
    api.extra = extraOptions;

    const includeUser = [
      authentication,
      (api.extra as FetchBaseQueryExtraOptions)?.authenticated,
    ].includes('query-user');

    const includeEmail = [
      authentication,
      (api.extra as FetchBaseQueryExtraOptions)?.authenticated,
    ].includes('query-email');

    if (
      includeUser ||
      (includeEmail &&
        ['delete', 'put'].includes(args.method?.toLocaleLowerCase() ?? 'get'))
    ) {
      const client = await auth0;

      const user = await client.getUser();

      return baseQuery(
        {
          ...args,
          params: { ...args.params, [includeUser ? 'user' : 'email']: user?.email },
        },
        api,
        extraOptions
      );
    }

    return baseQuery(args, api, extraOptions);
  };
};
