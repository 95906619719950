// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { appStore as store } from 'store';
import { groupInsights } from './group-insights';
import { InsightPayload, Intent } from 'api/interfaces/copilot/chat';
import { montecarloApi } from 'store/api/ai/montecarlo-api';
import { PortfolioAnalysis } from 'api/interfaces/ai/portfolio-analysis';
import { reportingApi } from 'api/ai/reporting-api';
import { reportingApi as rtkReportingApi } from 'store/api/ai/reporting-api';
import { WorkflowAsset } from 'features/utils/interfaces/redux/workflow-state';
import { portfolioAnalysisApi } from 'api/copilot/portfolio-analysis-api';
import { EnhancedPortfolioResponse } from 'api/interfaces/copilot/portfolio';

export const getCopilotContext = async (
  intent: Intent,
  payload: InsightPayload,
  assetType: WorkflowAsset,
  onEnhancement?: (enhancement: EnhancedPortfolioResponse) => void
) => {
  const validContextIntents = ['asset_comparison', 'portfolio', 'montecarlo'];

  if (!validContextIntents.includes(intent)) {
    return '';
  }

  let context = '';

  try {
    if (intent === 'portfolio' && payload.portfolio) {
      const insightsApiResp = await reportingApi.insights(
        payload.portfolio as PortfolioAnalysis,
        assetType
      );

      const groupedInsights = groupInsights(insightsApiResp);

      context = groupedInsights;

      portfolioAnalysisApi
        .enhancement(insightsApiResp, {
          html_response: false,
        })
        .then(onEnhancement, (error) => console.error('[getCopilotContext]', error));
    } else if (intent === 'montecarlo' && payload.simulation) {
      const action = montecarloApi.endpoints.insights.initiate({
        payload: payload.simulation,
      });
      const insightsApiResp = await store.dispatch(action).unwrap();

      const groupedInsights = groupInsights(insightsApiResp);

      context = groupedInsights;
    } else if (intent === 'asset_comparison' && payload.comparison) {
      const action = rtkReportingApi.endpoints.comparisonInsights.initiate(
        payload.comparison
      );
      const insightsApiResp = await store.dispatch(action).unwrap();

      const groupedInsights = groupInsights(insightsApiResp);

      context = groupedInsights;
    }
  } catch (error) {
    console.error('[getCopilotContext]', error);
  }

  return context;
};
