// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { createSelector } from '@reduxjs/toolkit';
import { ComparisonAsset } from 'features/utils/interfaces/comparison-asset';
import { AppState } from 'store/store';
import {
  savedPortfoliosEntityAdapter,
  screenersEntityAdapter,
  watchListEntityAdapter,
} from './my-research-slice';

const watchListSelectors = watchListEntityAdapter.getSelectors();

const savedPortfoliosSelectors = savedPortfoliosEntityAdapter.getSelectors();

const screenersSelectors = screenersEntityAdapter.getSelectors();

export const selectMyResearchState = createSelector(
  ({ myResearch }: AppState) => myResearch,
  (state) => state
);

export const selectWatchList = createSelector(
  selectMyResearchState,
  ({ watchList }) => watchList
);

export const selectSavedPortfolios = createSelector(
  selectMyResearchState,
  ({ savedPortfolios }) => savedPortfolios
);

export const selectIdeas = createSelector(
  selectSavedPortfolios,
  savedPortfoliosSelectors.selectAll
);

export const selectIdeasTableData = createSelector(
  selectSavedPortfolios,
  (state) => state.ideasTableData
);

export const selectWatchListPositions = createSelector(
  selectWatchList,
  watchListSelectors.selectAll
);

export const selectScreeners = createSelector(
  selectMyResearchState,
  ({ screeners }) => screeners
);

export const selectScreenersLoading = createSelector(
  selectScreeners,
  ({ loading }) => loading
);

export const selectScreenersPositions = createSelector(
  selectScreeners,
  screenersSelectors.selectAll
);

export const selectComparisonState = createSelector(
  selectMyResearchState,
  ({ comparison }) => comparison
);

export const selectComparisonBenchmark = createSelector(
  selectComparisonState,
  ({ benchmark }) => benchmark
);

export const selectComparisonHist = createSelector(
  selectComparisonState,
  ({ w_hist }) => w_hist
);

export const selectComparisonTickers = createSelector(
  selectComparisonState,
  ({ tickers }) => tickers
);

export const selectComparisonAssets = createSelector(
  selectComparisonState,
  ({ assetsComparison }) => assetsComparison
);

export const selectComparisonTable = createSelector(
  selectComparisonState,
  ({ assetsInfo, assetsComparison }) =>
    assetsInfo
      .map(({ ticker, name, expenses_ratio: ter, aum, ...props }) => {
        const comparison = assetsComparison.asset_analysis.find(
          (value) => value.ticker === ticker
        )!;

        const backtest = assetsComparison.asset_backtest.find(
          (value) => value.ticker === ticker
        )!;

        if (typeof comparison === 'undefined') return;

        const assetType =
          'url_logo' in props
            ? 'stock'
            : 'etf_company' in props
            ? 'etf'
            : 'mutual_fund';

        return {
          ticker,
          name,
          ter,
          aum,
          currency: 'currency' in props ? props.currency : null,
          url_logo: 'url_logo' in props ? props.url_logo : 'missing',
          returns_1M: comparison.returns.returns_1M,
          returns_3M: comparison.returns.returns_3M,
          returns_6M: comparison.returns.returns_6M,
          returns_YTD: comparison.returns.returns_YTD,
          vol: comparison.risk.volatility,
          sharpe: comparison.risk.sharpe,
          beta: comparison.risk.beta,
          alpha: comparison.risk.alpha,
          max_drawdown: Math.min(...backtest.ts_drawdowns.portfolio),
          assetType,
        } as ComparisonAsset;
      })
      .filter((value): value is ComparisonAsset => typeof value !== 'undefined')
);

export const selectComparisonCorrMatrix = createSelector(
  selectComparisonState,
  ({ assetsComparison }) => assetsComparison.corr_matrix
);

export const selectComparisonRisk = createSelector(
  selectComparisonState,
  ({ assetsComparison }) => assetsComparison.risk_returns
);

export const selectComparisonBacktest = createSelector(
  selectComparisonState,
  ({ assetsComparison }) => assetsComparison.asset_backtest
);

export const selectComparisonLoading = createSelector(
  selectComparisonState,
  ({ loading }) => loading
);

export const selectComparisonHistoricalData = createSelector(
  selectComparisonState,
  ({ assetsComparison }) => assetsComparison.historical_data
);

export const selectComparisonAssetsInfo = createSelector(
  selectComparisonState,
  ({ assetsInfo }) => assetsInfo
);

export const selectMutualFundsFilters = createSelector(
  selectScreeners,
  ({ mutualFundsFilters }) => mutualFundsFilters
);

export const selectEtfsFilters = createSelector(
  selectScreeners,
  ({ etfsFilters }) => etfsFilters
);
