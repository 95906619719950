// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { createSelector } from '@reduxjs/toolkit';
import { TickerTagFeature } from 'api/interfaces/engine/citec';
import { groupMap } from 'features/utils/groupby';
import { selectFeature } from 'features/utils/toggle-feature';
import { AppState } from 'store/store';
import {
  tickerTagEntityAdapter,
  tagManagerEntityAdapter,
} from './tag-manager-slice';

const tickerTagSelectors = tickerTagEntityAdapter.getSelectors();

const tagManagerSelectors = tagManagerEntityAdapter.getSelectors();

export const selectTagManagerState = createSelector(
  ({ tagManager }: AppState) => tagManager,
  (state) => state
);

export const selectTagManagerFeature = createSelector(
  [selectTagManagerState, (_, feature: TickerTagFeature) => feature],
  (state, feature) => tagManagerSelectors.selectById(state, feature)
);

export const selectSelectTag = createSelector(
  selectTagManagerFeature,
  (state) => state?.selectedTag
);

export const selectLoadingTag = createSelector(
  selectTagManagerFeature,
  (state) => state?.isLoading
);

export const selectTags = createSelector(
  selectTagManagerFeature,
  (state) => state?.tags
);

export const selectAllTags = createSelector(selectTags, (state) =>
  tickerTagSelectors.selectAll(state!).map((tag) => ({
    ...tag,
    positions: tag.positions.filter(
      (position) => !['', 'ALL'].includes(position.ticker)
    ),
  }))
);

export const selectTag = createSelector(
  [
    selectTagManagerState,
    (_, feature: TickerTagFeature, tag: string) => ({ feature, tag }),
  ],
  (state, { tag, feature }) =>
    tickerTagSelectors.selectById(
      tagManagerSelectors.selectById(state, feature)!.tags,
      tag
    )
);

export const selectTagTickers = createSelector(
  [
    selectTagManagerState,
    (_, feature: TickerTagFeature, tag: string) => ({ feature, tag }),
  ],
  (state, { tag, feature }) =>
    tickerTagSelectors
      .selectById(tagManagerSelectors.selectById(state, feature)!.tags, tag)
      ?.positions.map((position) => position.ticker) ?? []
);

export const selectTagsNames = createSelector(selectTags, (state) =>
  tickerTagSelectors
    .selectAll(state!)
    .map(({ name }) => name)
    .filter((tag) => !['', 'ALL'].includes(tag))
);

export const selectTagsByTicker = createSelector(
  [
    selectTagManagerState,
    (
      _,
      feature: TickerTagFeature,
      tickers: Array<{ ticker: string; url_logo?: string }>
    ) => ({ feature, tickers }),
  ],
  (state, { feature, tickers }) => {
    const tags = tickerTagSelectors.selectAll(
      tagManagerSelectors.selectById(state!, feature)!.tags
    );

    return groupMap(
      ({ ticker }) => ticker,
      ({ ticker, url_logo }) => ({
        ticker,
        url_logo,
        tags: tags
          .filter((tag) =>
            tag.positions.some((position) => position.ticker === ticker)
          )
          .map((tag) => tag.name),
      }),
      (_, tickerTags) => tickerTags,
      tickers
    ).flat();
  }
);

export const selectTagSuggestions = createSelector(
  [selectTagManagerState, (_, feature: TickerTagFeature) => feature],
  (state, feature) => {
    const portfolioTags = tickerTagSelectors
      .selectAll(state.entities[TickerTagFeature.PortfolioPositions]!.tags)
      .map(({ name }) => name);

    const watchListTags = tickerTagSelectors
      .selectAll(state.entities[TickerTagFeature.WatchList]!.tags)
      .map(({ name }) => name);

    const suggestions = selectFeature(
      {
        'portfolio-positions': watchListTags.filter(
          (tag) => !portfolioTags.includes(tag)
        ),
        watchlist: portfolioTags.filter((tag) => !watchListTags.includes(tag)),
      },
      feature
    );

    return suggestions.filter((tag) => !['', 'ALL'].includes(tag));
  }
);
