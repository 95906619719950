// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import * as XLSX from 'xlsx';
import { useEffect, useState } from 'react';
import { UploaderExcelProps } from '../components/uploader-excel';
import { PortfolioLoaded } from 'features/utils/interfaces/portfolio-loaded';

type useUploaderExcelProps = Pick<UploaderExcelProps, 'setExcelData'>;

export const useUploaderExcel = (
  { setExcelData }: useUploaderExcelProps,
  createFileReader = () => new FileReader()
) => {
  const [excelFile, setExcelFile] = useState<unknown>(null);

  const [excelFileError, setExcelFileError] = useState<string | null>(null);

  const handleFileChange = (file: File) => {
    setExcelFile(null);
    const reader = createFileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      setExcelFileError(null);
      setExcelFile(e?.target?.result);
    };
  };

  const handleAddNewsPositions = () => {
    if (excelFile) {
      const workbook = XLSX.read(excelFile, { type: 'buffer', cellDates: true });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, {
        dateNF: 'YYYY-MM-DD',
      }) as PortfolioLoaded[];
      const purgedData = data.map((position) => {
        const outstanding_balance = isNaN(position.outstanding_balance as number)
          ? 0
          : position.outstanding_balance;

        const unrealized_capital_gains = isNaN(
          position.unrealized_capital_gains as number
        )
          ? 0
          : position.unrealized_capital_gains;

        const currentDate = Date.parse(position?.date ?? '');

        const date = new Date(currentDate || Date.now()).toISOString().split('T')[0];

        return {
          ...position,
          date,
          outstanding_balance,
          unrealized_capital_gains,
        };
      });

      setExcelData(purgedData);
    }
  };

  useEffect(() => {
    handleAddNewsPositions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excelFile]);

  return {
    handleFileChange,
    excelFileError,
    setExcelFileError,
  };
};
