// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

export const getEndpoint = (
  typeAsset: string,
  suffix: string,
  prefix: string,
  typeSetting?: string
): string => {
  const currency = 'us';
  let result = '';

  if (['portfolio', 'assets'].includes(prefix))
    result = `/${prefix}/${typeAsset}/${suffix}/`;

  if (prefix === 'screener')
    if (typeAsset === 'stock' || typeAsset == 'etf')
      result = `/${prefix}/${typeAsset}/${suffix}/`;
    else result = `/${prefix}/${typeAsset}/${currency}/${suffix}/`;
  if (typeSetting) result = result + `${typeSetting}/`;

  return result;
};
