// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { ResponsiveLine } from '@nivo/line';
import { Backtest } from 'api/interfaces/ai/portfolio';
import { Ts } from 'api/interfaces/commons';
import { ChartContainer } from 'components';
import {
  transformAssetsTsData,
  transformTsData,
} from 'features/utils/transform-backtest-data';
import { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { Legend } from './charts/legend';
import { PerformanceLineChartTooltip } from './performance-line-chart-tooltip';
import { SERIES_COLORS } from 'features/utils/series-colors';

export interface PerformanceLineChartProps {
  data: Ts | Array<Backtest & { ticker: string }>;
  isReturns: boolean;
  name: string;
  crossType?: 'bottom' | 'top';
  enableArea?: boolean;
  benchmark?: string;
  toggleSerie?: boolean;
}

export const PerformanceLineChart: FC<PerformanceLineChartProps> = ({
  data,
  isReturns,
  name,
  crossType = 'bottom',
  enableArea = true,
  benchmark = '',
}) => {
  const [activeSeries, setActiveSeries] = useState<Record<string, boolean>>(
    () => ({})
  );

  const chartData = useMemo(
    () =>
      (Array.isArray(data)
        ? transformAssetsTsData(data, benchmark)
        : transformTsData(data)
      ).map((serie, index) => ({ ...serie, color: SERIES_COLORS[index] })),
    [benchmark, data]
  );

  const filteredData = useMemo(
    () => chartData.filter((data) => !activeSeries?.[data.id]),
    [activeSeries, chartData]
  );

  const areaBaseLine = useMemo(
    () =>
      isReturns
        ? Array.isArray(data)
          ? Math.min(
              ...data.flatMap(({ ts_backtest }) =>
                ts_backtest.benchmark.concat(ts_backtest.portfolio)
              )
            )
          : Math.min(...data.benchmark, ...data.portfolio) * 100
        : 0,
    [data, isReturns]
  );

  const onToggleSerie: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    const serie = e.currentTarget.dataset['serie'];

    if (serie) {
      setActiveSeries((prev) => ({
        ...prev,
        [serie]: !prev[serie],
      }));
    }
  }, []);

  const legends = useMemo(
    () =>
      Array.from(chartData, ({ variation, id, color }) => (
        <Legend
          key={id}
          label={id}
          color={!activeSeries[id] ? color : '#79828D'}
          variation={variation}
          onClick={onToggleSerie}
        />
      )),
    [activeSeries, chartData, onToggleSerie]
  );

  return (
    <ChartContainer
      header={name}
      popupName={isReturns ? 'charts.returns' : 'kpis.maxDrawdown'}
      tooltip
    >
      <div className='flex gap-x-7 gap-y-2 justify-end flex-wrap'>{legends}</div>
      <ResponsiveLine
        data={filteredData}
        margin={{ top: 50, right: 25, bottom: 50, left: 50 }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false,
          clamp: true,
          nice: false,
        }}
        yFormat=' >-.2f'
        curve='linear'
        axisTop={null}
        axisRight={null}
        animate={false}
        axisBottom={{
          tickSize: 0,
          tickPadding: 20,
          tickRotation: 0,
          tickValues: 7,
          legend: '',
          format: '%m-%Y',
        }}
        xFormat='time:%Y-%m-%d'
        xScale={{
          format: '%Y-%m-%d',
          precision: 'day',
          type: 'time',
          useUTC: false,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 18,
          tickRotation: 0,
          legend: '',
        }}
        enableGridX={false}
        colors={{ datum: 'color' }}
        enablePoints={false}
        enableArea={enableArea}
        areaBaselineValue={areaBaseLine}
        areaOpacity={0.3}
        enableSlices={false}
        useMesh={true}
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: 12,
                fontFamily: 'poppins',
                fill: '#515F70',
              },
            },
          },
          grid: {
            line: {
              stroke: '#DBDFEA',
              strokeWidth: 1,
              strokeDasharray: '8 12',
            },
          },
        }}
        tooltip={PerformanceLineChartTooltip}
        crosshairType={crossType}
        defs={[
          {
            colors: [
              {
                color: 'inherit',
                offset: 30,
              },
              {
                color: 'inherit',
                offset: 100,
                opacity: 0.37,
              },
            ],
            id: 'gradientA',
            type: 'linearGradient',
          },
        ]}
        fill={[
          {
            id: 'gradientA',
            match: '*',
          },
        ]}
      />
    </ChartContainer>
  );
};
