// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import Swal from 'sweetalert2';
import type { ModalOptions } from './show-modal';
import styles from 'features/ui/modal/modal.module.css';
import { AppIcon } from 'components/app-icon';

const inputProps = {
  type: 'text',
  className: styles.input,
};

export const MODAL_TEMPLATES_OPTIONS = {
  UNLINK_BROKER_ACCOUNT: {
    title: 'Unlink broker account',
    content: 'Are you sure you want to unlink your broker account?',
    variant: 'overflow',
    icon: <AppIcon icon='Unlink' size='2xl' color='main' />,
    buttons: {
      cancel: {
        show: true,
        text: 'No, Cancel',
      },
      confirm: {
        show: true,
        text: 'Yes, Unlink',
      },
    },
  } as ModalOptions,
  SAVE_PORTFOLIO: {
    title: 'Save Portfolio',
    content: 'Save as:',
    variant: 'overflow',
    icon: <AppIcon icon='Save' size='2xl' color='main' />,
    input: {
      placeholder: 'Name',
      type: 'text',
    },
    buttons: {
      cancel: {
        show: true,
        text: 'Cancel',
      },
      confirm: {
        show: true,
        text: 'Save',
      },
    },
  } as ModalOptions,
  EDIT_SAVED_PORTFOLIO: {
    title: 'Save Changes',
    content: 'Are you sure you want to update your saved portfolio?',
    variant: 'overflow',
    icon: <AppIcon icon='Save' size='2xl' color='main' />,
    buttons: {
      cancel: {
        show: true,
        text: 'Cancel',
      },
      confirm: {
        show: true,
        text: 'Update',
      },
    },
  } as ModalOptions,
  EXECUTE_TRADES: {
    title: 'Trade Execution',
    variant: 'box',
  } as ModalOptions,
  DOWNLOAD_REPORTS: {
    title: 'Download Analysis Report',
    variant: 'overflow',
    icon: <AppIcon icon='Reporting' size='2xl' color='main' />,
    content: (
      <div>
        <input placeholder='Client Name' id='client-name' {...inputProps} />
        <input placeholder='Client Address' id='client-address' {...inputProps} />
        <input placeholder='Client Phone' id='client-phone' {...inputProps} />
        <input placeholder='Report Name' id='report-name' {...inputProps} />
      </div>
    ),
    buttons: {
      cancel: {
        show: true,
        text: 'Cancel',
      },
      confirm: {
        show: true,
        text: 'Download',
      },
      allowOutsideClick: () => !Swal.isLoading(),
    },
  } as ModalOptions,
  UNHANDLED_ERROR: {
    icon: <AppIcon icon='ExclamationMark' size='2xl' color='main' />,
    title: 'Error',
    buttons: {
      cancel: {
        show: true,
        text: '<a href="/">Home</a>',
      },
      confirm: {
        show: true,
        text: 'Back',
      },
    },
    reverseButtons: false,
    variant: 'overflow',
  } as ModalOptions,
  WARN: {
    icon: <AppIcon icon='Warning' size='2xl' color='main' />,
    variant: 'overflow',
    allowOutsideClick: false,
  },
  CONFIRM_DELETE: {
    icon: <AppIcon icon='ExclamationMark' size='2xl' color='main' />,
    variant: 'overflow',
    buttons: {
      cancel: {
        show: true,
        text: 'No, cancel',
      },
      confirm: {
        show: true,
        text: 'Yes, Delete!',
      },
    },
    reverseButtons: true,
    allowOutsideClick: () => !Swal.isLoading(),
  },
} as const;

export const BASE_POPUP: ModalOptions = {
  variant: 'box',
  icon: <AppIcon icon='Info' size='md' color='main' />,
  buttons: {
    close: { show: true },
  },
  width: 540,
};
