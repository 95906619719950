// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { createTheme } from '@mui/material';

export const typography = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins',
          lineHeight: 'normal',
          fontStyle: 'normal',
          display: 'inline-flex',
          alignItems: 'center',
        },
      },
      variants: [
        {
          props: { variant: 'h1' },
          style: {
            fontSize: '1.785em',
            fontWeight: '600',
            color: '#2D3745',
          },
        },
        {
          props: { variant: 'h2' },
          style: {
            color: '#2D3745',
            fontSize: '1.429em',
            fontWeight: '700',
          },
        },
        {
          props: { variant: 'h3' },
          style: {
            color: '#1072BA',
            fontSize: '1.429em',
            fontWeight: '600',
          },
        },
        {
          props: { variant: 'h4' },
          style: {
            color: '#515F70',
            fontSize: '1.285em',
            fontWeight: '700',
          },
        },
        {
          props: { variant: 'h5' },
          style: {
            color: '#243750',
            fontSize: '1em',
            fontWeight: '600',
          },
        },
      ],
    },
  },
});
