// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { PortfolioLoaded } from 'features/utils/interfaces/portfolio-loaded';
import { Dispatch, FC, SetStateAction } from 'react';

import { AppIcon } from 'components/app-icon';
import { AutocompleteInput } from 'components/autocomplete';
import { IconButton } from 'features/ui/icon-button';
import { NumericFormat } from 'react-number-format';
import { useAppSelector } from 'store/store';
import { selectWorkflowAsset } from 'store/slices/workflow';
import { useTableRow } from '../hooks/use-table-row';
import { classNames } from 'features/utils/classnames';

export interface TableRowProps {
  readonly id: number;

  readonly portfolio: PortfolioLoaded;

  readonly initialValue: Required<PortfolioLoaded>;

  readonly setInitialValue: Dispatch<SetStateAction<Required<PortfolioLoaded>>>;

  readonly setData: Dispatch<SetStateAction<PortfolioLoaded[]>>;

  readonly updateMyData: (rowIndex: number, columnId: string, value: any) => void;

  readonly invalidPositions?: string[];

  readonly autocomplete?: boolean;
}

export const TableRow: FC<TableRowProps> = (props) => {
  const {
    isInvalid,
    onAddRow,
    onBlur,
    onChange,
    onInputClick,
    onRemoveRow,
    onValueChange,
    workflowType,
  } = useTableRow(props);

  const active = props.portfolio.outstanding_balance > 0 && !!props.portfolio.ticker;
  const formattedTicker = props.portfolio.ticker.toUpperCase();

  const assetType = useAppSelector(selectWorkflowAsset);

  return (
    <div
      aria-invalid={isInvalid}
      data-testid={`table-positions-row-${props.id}`}
      className={classNames(
        'grid grid-cols-[repeat(4,minmax(180px,1fr))_20px] gap-x-2.5 aria-[invalid=false]:focus-within:bg-[#1072BA]/20 aria-[invalid=true]:bg-[#e2464626] p-[5px] transition-all last-of-type:rounded-bl-lg last-of-type:rounded-br-lg relative',
        {
          'rounded-tl-lg rounded-tr-lg': props.id === 0,
          'rounded-lg': props.id === -1,
        }
      )}
    >
      {workflowType !== 'rebalance' && props.autocomplete ? (
        <AutocompleteInput
          onChangeValue={onChange}
          value={formattedTicker}
          name='ticker'
          error={!isInvalid}
          onBlur={onBlur('ticker', formattedTicker)}
          noMargin
          data-testid='search-ticker-input'
          assetType={assetType ? `${assetType}s` : undefined}
        />
      ) : (
        <input
          className='input-common'
          type='text'
          placeholder='Ticker'
          value={formattedTicker}
          name='ticker'
          onChange={onChange}
          onBlur={onBlur('ticker', formattedTicker)}
          data-testid='ticker-input'
        />
      )}

      <NumericFormat
        value={props.portfolio.outstanding_balance}
        thousandSeparator={true}
        min={1}
        onValueChange={({ value }) => {
          onValueChange(value, 'outstanding_balance');
        }}
        onBlur={onBlur('outstanding_balance', props.portfolio.outstanding_balance)}
        className='input-common'
        placeholder='$'
        name='outstanding_balance'
        required
        data-testid='outstanding-balance-input'
      />

      <NumericFormat
        value={props.portfolio.unrealized_capital_gains}
        thousandSeparator={true}
        min={1}
        onValueChange={({ value }) => {
          onValueChange(value, 'unrealized_capital_gains');
        }}
        onBlur={onBlur(
          'unrealized_capital_gains',
          props.portfolio.unrealized_capital_gains
        )}
        className='input-common'
        placeholder='$'
        name='unrealized_capital_gains'
        required
        data-testid='unrealized-capital-gains-input'
      />

      <div className='relative'>
        <input
          className='input-common'
          type='date'
          placeholder='mm/dd/yy'
          value={props.portfolio.date}
          name='date'
          onChange={onChange}
          onBlur={onBlur('date', props.portfolio.date!)}
          onClick={onInputClick}
        />
        <AppIcon
          icon='Calendar'
          className='absolute top-0 right-0 !m-3 w-3 h-3 pointer-events-none'
        />
      </div>

      <div className='grid w-5 items-center justify-center'>
        {props.id === -1 ? (
          <IconButton
            variant='icon'
            color='success'
            icon='Add'
            size='lg'
            onClick={onAddRow}
            tabIndex={-1}
            data-testid='btn-add-row'
            disabled={!active}
          />
        ) : (
          <IconButton
            variant='icon'
            icon='Eliminate'
            size='lg'
            color='danger'
            onClick={onRemoveRow}
            tabIndex={-1}
            data-testid={`btn-delete-row-${props.id}`}
          />
        )}
      </div>
    </div>
  );
};
