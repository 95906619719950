// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { ReactNode, useCallback, useState } from 'react';
import { DynamicDialogProps } from './dynamic-dialog';

export interface DynamicDialogConfig {
  content: ReactNode;
  open: boolean;
  props: Partial<DynamicDialogProps>;
}

export const useDialogActions = () => {
  const initialState: DynamicDialogConfig = {
    open: false,
    content: undefined,
    props: {},
  };

  const [dialogConfig, setDialogConfig] =
    useState<DynamicDialogConfig>(initialState);

  /**
   * Opens a dialog with the specified content and props.
   *
   * @param content - The content to be displayed in the dialog.
   * @param props - Additional props for the dialog.
   */
  const openDialog = useCallback(
    (content: ReactNode, props: DynamicDialogConfig['props']) =>
      void setDialogConfig({ open: true, content, props }),
    []
  );

  /**
   * Closes the dialog by updating the dialog configuration state.
   */
  const closeDialog = useCallback(
    () => void setDialogConfig((prev) => ({ ...prev, open: false })),
    []
  );

  return {
    dialogConfig,
    openDialog,
    closeDialog,
  };
};
