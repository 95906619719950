// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { TradeDetails } from 'features/utils/interfaces/redux/trades';
import { OptionsObject, useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import { assetsApi } from 'store/api/ai/assets-api';
import {
  selectTaxPositions,
  selectTradesTable,
} from 'store/slices/portfolio-positions';
import { selectLoadingTrades } from 'store/slices/trader';
import { getOrderPrices } from 'store/slices/trader/trader-thunk';
import { selectWorkflowAsset } from 'store/slices/workflow';

export type TradeInputParameters = Pick<
  TradeDetails,
  'ticker' | 'operation' | 'volume' | 'sourceMyInvestments'
> & {
  tickerInfo?: any;
  asset_type?: string;
  unrealized_capital_gains?: number;
};

const commonSnackProps: OptionsObject = {
  variant: 'success',
  anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
};

export const useSendToTrader = () => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();

  const trades = useAppSelector(selectTradesTable);

  const taxHarvestingPositions = useAppSelector(selectTaxPositions);

  const assetType = useAppSelector(selectWorkflowAsset);

  const isLoadingTrades = useAppSelector(selectLoadingTrades);

  const [getAssetsInfo] = assetsApi.useLazyAssetsInfoQuery();

  const sendTradesTickers = useCallback(
    async (trades: TradeDetails[]) => {
      return dispatch(getOrderPrices(trades));
    },
    [dispatch]
  );

  const sendTradeTicker = useCallback(
    async (trade: TradeInputParameters) => {
      enqueueSnackbar(`${trade.ticker} was added`, {
        ...commonSnackProps,
      });

      if (trade.sourceMyInvestments) {
        return dispatch(
          getOrderPrices([
            {
              ticker: trade?.ticker,
              operation: trade?.operation,
              volume: trade?.volume,
              realized_capital_gains: 0,
              unrealized_capital_gains: trade.unrealized_capital_gains ?? 0,
              contracts_number: trade.tickerInfo?.quantity,
              columnName: {
                url_logo: trade?.tickerInfo?.url_logo,
                name: trade?.ticker,
                asset_type: trade.asset_type,
              },
            },
          ])
        );
      }

      const { data: assetInfo, isError } = await getAssetsInfo(
        {
          payload: [trade.ticker],
          params: { detail: true },
          assetType: trade.asset_type,
        },
        true
      );

      if (isError) {
        return;
      }

      return dispatch(
        getOrderPrices([
          {
            operation: trade.operation,
            ticker: trade.ticker,
            volume: trade.volume,
            isin: assetInfo?.[0]?.isin,
            realized_capital_gains: 0,
            unrealized_capital_gains: trade.unrealized_capital_gains ?? 0,
            columnName: {
              asset_type: trade.asset_type,
              name: assetInfo?.[0]?.name,
              url_logo: trade?.tickerInfo?.url_logo,
            },
          },
        ])
      );
    },
    [dispatch, enqueueSnackbar, getAssetsInfo]
  );

  const sendRebalanceTickers = useCallback(async () => {
    return dispatch(
      getOrderPrices(
        trades.map((trade) => ({
          ticker: trade.ticker,
          operation: trade.operation,
          volume: trade.volume,
          isin: trade.isin,
          realized_capital_gains: trade.realized_capital_gains,
          unrealized_capital_gains: trade.unrealized_capital_gains,
          expected_volume: trade.volume,
          columnName: {
            url_logo: trade.url_logo,
            url_morningstar: trade.url_morningstar,
            name: trade.ticker,
            asset_type: trade.asset_type,
          },
          gain_loss: [0, 0],
          local_currency_values: {
            currency: trade.currency,
            volume: trade.volume,
          },
          exchange: trade.exchange,
        }))
      )
    );
  }, [dispatch, trades]);

  const sendPortfolioPositions = useCallback(async () => {
    return dispatch(
      getOrderPrices(
        trades.map(
          ({
            exchange,
            ticker,
            url_logo,
            url_morningstar,
            asset_type,
            unrealized_capital_gains,
            isin,
            outstanding_balance,
            operation,
          }) => {
            return {
              operation: operation ?? 'BUY',
              realized_capital_gains: 0,
              ticker,
              unrealized_capital_gains,
              volume: outstanding_balance,
              columnName: {
                url_logo: url_logo,
                url_morningstar: url_morningstar,
                name: ticker,
                asset_type: asset_type ?? assetType,
              },
              exchange,
              isin,
            };
          }
        )
      )
    );
  }, [assetType, dispatch, trades]);

  const sendTaxPositions = useCallback(async () => {
    return dispatch(
      getOrderPrices(
        taxHarvestingPositions
          .map(
            ({ price, qty, ticker, unrealized_capital_gains = 0, harvesting }) => {
              const assetInfo = trades.find((asset) => asset.ticker === ticker);

              const volumeTaxHarvesting =
                price * qty * (harvesting?.percentage || 1);

              const trade = {
                ticker: assetInfo?.ticker ?? ticker,
                isin: assetInfo?.isin,
                operation: 'SELL' as const,
                volume: volumeTaxHarvesting,
                realized_capital_gains: 0,
                unrealized_capital_gains,
                exchange: assetInfo?.exchange || '-',
                columnName: {
                  url_logo: assetInfo?.url_logo,
                  url_morningstar: assetInfo?.url_morningstar,
                  name: ticker,
                  asset_type: assetType,
                },
              };

              if (harvesting?.replacement) {
                const replacement = harvesting.replacement;
                return [
                  trade,
                  {
                    ...trade,
                    operation: 'BUY' as const,
                    volume: volumeTaxHarvesting,
                    ticker: replacement.ticker,
                    isin: replacement.isin,
                    columnName: {
                      ...trade.columnName,
                      url_logo: replacement.url_logo,
                    },
                  },
                ];
              }

              return trade;
            }
          )
          .flat()
      )
    );
  }, [assetType, dispatch, taxHarvestingPositions, trades]);

  return {
    sendTradeTicker,
    sendTradesTickers,
    sendRebalanceTickers,
    sendPortfolioPositions,
    sendTaxPositions,
    isLoadingTrades,
  };
};
