// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import * as XLSX from 'xlsx';

export type DataToDownload = { [key: string]: any }[];

export const exportTableToExcel = (
  data: DataToDownload,
  fileName: string,
  opts?: XLSX.JSON2SheetOpts
) => {
  if (data.length > 0) {
    const wordBook = XLSX.utils.book_new();
    const wordSheet = XLSX.utils.json_to_sheet(data, opts);

    XLSX.utils.book_append_sheet(wordBook, wordSheet);

    XLSX.writeFile(wordBook, `${fileName}.xlsx`);
  }
};
