// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { NumericFormat } from 'react-number-format';
import { formatToMoney } from 'features/utils/format-to-money';
import { useAppSelector } from 'store/store';
import { Slider } from './slider/slider';
import { selectWorkflowType } from 'store/slices/workflow';

interface SliderCashProps {
  value: number;
  onInputBlur?: () => void;
  onChangeCommitted?: () => void;
  onChangeInputValue: (value: number) => void;
  onChangeSlider: (value: number) => void;
  min?: number;
}

export const SliderCash = ({
  onChangeCommitted,
  onChangeInputValue,
  onChangeSlider,
  onInputBlur,
  value,
  min = 0,
}: SliderCashProps): JSX.Element => {
  const workflow = useAppSelector(selectWorkflowType);

  const specificValue = Math.trunc(value);

  let minVal = 5000;
  if (workflow !== 'new') {
    minVal = 0;
  }

  minVal = Math.max(minVal, min);

  const inputMaxVal = 100_000_000;
  const sliderMaxVal = 10_000_000;

  return (
    <>
      <NumericFormat
        value={specificValue}
        thousandSeparator
        min={minVal}
        maxLength={11}
        isAllowed={({ value }) => {
          return Number(value) <= inputMaxVal;
        }}
        onValueChange={({ value }) => onChangeInputValue(Number(value))}
        onBlur={onInputBlur}
        prefix='$'
        placeholder='investment-input'
        className='input-common mt-3'
        id='investment-input'
      />
      <Slider
        valueLabelDisplay='off'
        onChange={(_, newValue) => {
          onChangeSlider(newValue as number);
        }}
        onChangeCommitted={onChangeCommitted}
        value={value}
        min={minVal}
        max={sliderMaxVal}
        step={1000}
        valueLabelFormat={(val) => formatToMoney(val)}
        data-testid='slider'
        className='!block mt-4'
      />
    </>
  );
};
