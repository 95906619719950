// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { SavingHolding } from 'api/interfaces/engine/citec';
import { AppIcon } from 'components/app-icon';
import { EditPortfolioConfig } from 'features/my-research';
import { editSavedPortfolio } from 'features/my-research/utils/edit-saved-portfolio';
import { MODAL_TEMPLATES_OPTIONS, showModal } from 'features/ui/modal';
import { PortfolioLoaded } from 'features/utils/interfaces/portfolio-loaded';
import { savePortfolio } from 'features/utils/save-portfolio';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store/store';
import { selectBenchmark } from 'store/slices/parameters';

export interface UseAddPortfolioModalProps {
  onClose?: VoidFunction;
  editPortfolioConfig: EditPortfolioConfig;
}

export const useAddPortfolioModal = ({
  onClose,
  editPortfolioConfig,
}: UseAddPortfolioModalProps) => {
  const holdings = editPortfolioConfig.portfolioHoldings;

  const benchmark = useAppSelector((state) => selectBenchmark(state, 'ai-builder'));

  const [positions, setPositions] = useState<PortfolioLoaded[]>(holdings);

  const [updatedPortfolioName, setUpdatedPortfolioName] = useState('');

  const [hasChanges, setHasChanges] = useState(false);

  const filteredPositions = useMemo(
    () => positions.filter(({ ticker }) => ticker !== '$CASH'),
    [positions]
  );

  const updatePosition = useCallback(
    (rowIndex: number, columnId: string, value: any) => {
      let newValue: string | number;

      if (
        columnId === 'outstanding_balance' ||
        columnId === 'unrealized_capital_gains'
      ) {
        newValue = Number(value);

        if (isNaN(value)) {
          return;
        }
      } else {
        newValue = value;
      }

      setPositions((prev) =>
        prev.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...prev[rowIndex],
              [columnId]: newValue,
            };
          }
          return row;
        })
      );

      setHasChanges(true);
    },
    []
  );

  const onDeletePositions = useCallback(async () => {
    const { isConfirmed } = await showModal({
      ...MODAL_TEMPLATES_OPTIONS.CONFIRM_DELETE,
      icon: <AppIcon icon='ExclamationMark' fill='#1072BA' />,
      title: 'Delete positions',
      content: 'Are you sure you want to delete your positions?',
    });

    if (isConfirmed) {
      setUpdatedPortfolioName('');
      setPositions([]);
    }
  }, []);

  const onSavePortfolio = useCallback(() => {
    const savingHoldings: SavingHolding[] = positions.map((holding) => ({
      ticker: holding.ticker,
      outstanding_balance: holding.outstanding_balance,
      unrealized_capital_gains: holding.unrealized_capital_gains,
    }));

    savePortfolio({
      onClose: () => {
        setUpdatedPortfolioName('');
        onClose?.();
      },
      benchmark,
      savingHoldings,
    });
  }, [benchmark, onClose, positions]);

  const onEditPortfolio = useCallback(() => {
    editSavedPortfolio({
      editPortfolioConfig,
      updatedPortfolioName,
      positions,
      benchmark,
      onClose: () => {
        setUpdatedPortfolioName('');
        onClose?.();
      },
    });
  }, [editPortfolioConfig, positions, onClose, updatedPortfolioName, benchmark]);

  const onCloseAddPortfolioModal = useCallback(() => {
    setHasChanges(false);
    onClose?.();
    setPositions([]);
    setUpdatedPortfolioName('');
  }, [onClose]);

  const handleEditPortfolioName = useCallback((text: string) => {
    setHasChanges(true);
    setUpdatedPortfolioName(text);
  }, []);

  useEffect(() => {
    if (holdings.length > 0) {
      setPositions(holdings);
    }
  }, [holdings, setPositions]);

  return {
    positions,
    setPositions,
    filteredPositions,
    updatedPortfolioName,
    updatePosition,
    onDeletePositions,
    onSavePortfolio,
    onEditPortfolio,
    onCloseAddPortfolioModal,
    handleEditPortfolioName,
    hasChanges,
  };
};
