// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import {
  AuthorizationToken,
  IBKRAuthorizationToken,
} from 'api/brokers-authentication/broker-authentication-api';
import { queryStringToJSON } from './query-string-to-json';

type BrokerAuthorizationPopUpResponse = AuthorizationToken | IBKRAuthorizationToken;

export const brokerAuthorizationPopUp = (
  uri: string
): Promise<BrokerAuthorizationPopUpResponse> => {
  return new Promise((resolve, reject) => {
    const authWindow = window.open(
      uri,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=140,left=650,width=600,height=700, location=yes'
    );

    const interval = setInterval(() => {
      try {
        if (!authWindow || authWindow.closed) {
          reject('Authorization window was closed or failed to open.');
          clearInterval(interval);
          return;
        }

        const query = authWindow.location?.search;

        if (query) {
          const values = queryStringToJSON(query);
          authWindow.close();
          resolve(values);
          clearInterval(interval);
        }
      } catch (e) {
        console.log((e as Error).message);
      }
    }, 100);
  });
};
