// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

const w = 1000;
const h = 500;

const dualScreenLeft =
  window.screenLeft != undefined ? window.screenLeft : window.screenX;
const dualScreenTop =
  window.screenTop != undefined ? window.screenTop : window.screenY;

const width = window.innerWidth
  ? window.innerWidth
  : document.documentElement.clientWidth
  ? document.documentElement.clientWidth
  : window.screen.width;
const height = window.innerHeight
  ? window.innerHeight
  : document.documentElement.clientHeight
  ? document.documentElement.clientHeight
  : window.screen.height;

const left = width / 2 - w / 2 + dualScreenLeft;
const top = height / 2 - h / 3 + dualScreenTop;

export const windowOpenPopup = (url: any) => {
  window.open(
    url,
    '_blank',
    `toolbar=no,
      popup=yes,
      scrollbars=no,
      location=no,
      statusbar=no,
      menubar=no,
      resizable=0,
      width=${w}
      height=${h}
      left=${left}
      top=${top}`
  );
};
