// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Heading } from 'components/heading';
import { SectionContainer } from 'components/section-container';
import { FC } from 'react';

interface AssetProfileKeyStatisticsProps {
  statisticsProperties: {
    section: string;
    isVisible: boolean;
    properties: {
      label: string;
      value: any;
    }[];
  }[];
}

export const AssetProfileKeyStatistics: FC<AssetProfileKeyStatisticsProps> = ({
  statisticsProperties,
}) => {
  return (
    <SectionContainer title='Key Statistics' testId='statistics' className='!p-4'>
      <div className='grid grid-cols-[repeat(auto-fit,_minmax(100px,_1fr))] gap-8 mt-4'>
        {statisticsProperties.map((column, index) => (
          <div key={`${column.section}-${index}`} data-testid='statistics-column'>
            <Heading
              key={`${column.section}-${index}-heading`}
              title={column.section}
              variant='h5'
              className='!text-[#515F70] !font-normal pb-4'
            />
            <dl key={`${column.section}-${index}-list`} className='grid gap-4'>
              {column.properties.map((property) => (
                <div key={property.label}>
                  <dt>
                    <Heading title={property.label} variant='h5' />
                  </dt>
                  <dd>{property.value}</dd>
                </div>
              ))}
            </dl>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};
