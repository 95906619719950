// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Typography } from '@mui/material';
import { PopupsName } from 'features/popups';
import { FC } from 'react';
import { PopupButton } from './popup-button';

export type TitleVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

export const Heading: FC<{
  title: string;
  popupName?: PopupsName;
  variant?: TitleVariant;
  className?: string;
  tooltip?: boolean;
}> = ({ title, popupName, variant, className = '', tooltip }) => {
  return (
    <Typography variant={variant} className={className}>
      {title}
      {popupName && <PopupButton popupName={popupName} tooltip={tooltip} />}
    </Typography>
  );
};
