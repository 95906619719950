// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Heading } from 'components';
import { FC, PropsWithChildren, ReactNode } from 'react';

interface PageContainerProps extends PropsWithChildren {
  actionBtn?: ReactNode;
  className?: string;
  titlePage?: string;
  subtitlePage?: string;
  alertTitle?: () => void;
  showBottomButtons?: boolean;
}

export const PageContainer: FC<PageContainerProps> = ({
  actionBtn,
  children,
  className = '',
  titlePage,
  showBottomButtons = false,
}) => {
  return (
    <div
      className={`relative mx-16 my-8 rounded-lg ${className} focus-visible:!outline-none`}
      data-testid='pagecontainer'
      id='pagecontainer'
    >
      {titlePage ? (
        <div className='flex justify-between items-center mb-6 relative'>
          {titlePage && <Heading variant='h1' title={titlePage} />}
          {actionBtn}
        </div>
      ) : (
        actionBtn && (
          <div className='absolute right-0 flex gap-x-5'>{actionBtn} </div>
        )
      )}

      {children}
      {showBottomButtons && <div className='flex mt-8 justify-end'>{actionBtn}</div>}
    </div>
  );
};
