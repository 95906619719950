// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Holding } from 'api/interfaces/ai/holding';
import { getCurrentDate } from './get-current-date';

export interface DestructuredHoldings {
  tickers: string[];
  outstandingBalance: number[];
  unrealizedCapitalGains: number[];
  prices: number[];
  dates: string[];
}

export const destructureHoldings = (holdings: Holding[]): DestructuredHoldings => {
  const tickers: string[] = [];
  const outstandingBalance: number[] = [];
  const unrealizedCapitalGains: number[] = [];
  const prices: number[] = [];
  const dates: string[] = [];

  holdings.forEach((holding) => {
    const { qty, price = 1 } = holding;
    tickers.push(holding.ticker);
    outstandingBalance.push(qty * price);
    unrealizedCapitalGains.push(holding.unrealized_capital_gains);
    prices.push(holding.price);
    dates.push(holding.date || getCurrentDate());
  });

  return {
    tickers,
    outstandingBalance,
    unrealizedCapitalGains,
    prices,
    dates,
  };
};
