// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

export const SERIES_COLORS = [
  ['#6DC4F1', '#1072BA', '#AE3D7C', '#F9CC2C', '#0C2B53'],
  ['#3959E5', '#A746CC', '#4B96B0', '#FF7D6B', '#442B81'],
  ['#B5C1F4', '#76EAF4', '#2116C0', '#0E82D7', '#4CAB11'],
  ['#11AB86', '#3011AB', '#F79E19', '#BD21A4', '#2143BD'],
  ['#7E8EC5', '#C57E7E', '#A64646', '#98A646', '#CAD390'],
  ['#92D390', '#E05E00', '#755238', '#756C38', '#38756A'],
  ['#385475', '#7492B4', '#B374B4', '#7F5A80', '#510853'],
  ['#53081A', '#E53560', '#E2E535', '#A2A36D', '#647ED6'],
  ['#CD64D6', '#D6B664', '#7D7C78', '#9C9B98', '#403F3D'],
  ['#000000', '#FABCB4', '#DFB4FA', '#B4DDFA', '#6096BC'],
].flat();
