// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import {
  auth0,
  setupAuthInterceptor,
  useAuthUserQueryParamInterceptor,
} from './auth0';
import { EnhancedPortfolioParams } from './interfaces/copilot/portfolio';
import { UserParam } from './interfaces/commons';
import axios from 'axios';
import QueryString from 'qs';

const baseURL = process.env.REACT_APP_COPILOT_API_URL;
const token = process.env.REACT_APP_AI_API_TOKEN;

export type CopilotParams = UserParam | EnhancedPortfolioParams;

type FetchDataFuncProps = {
  endpoint: string;
  method: 'GET' | 'POST' | 'DELETE' | 'PATCH';
  payload?: any;
  params: CopilotParams;
  signal?: AbortSignal;
};

export const copilotApi = setupAuthInterceptor(
  auth0,
  axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
    timeout: 1000 * 80,
    paramsSerializer: (params) => QueryString.stringify(params, { encode: false }),
  }),
  [useAuthUserQueryParamInterceptor]
);

export const fetchCopilotApi = <T>({
  method,
  endpoint,
  payload,
  params,
  signal,
}: FetchDataFuncProps) => {
  return copilotApi.request<T>({
    method,
    url: endpoint,
    data: payload,
    params,
    signal,
  });
};
