// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { formatDateString } from 'features/utils/format-date';
import { FC, useMemo } from 'react';

interface LastUpdatedInfoProps {
  readonly data: Array<{
    last_update?: string;
    subRows?: Array<{ last_update?: string }>;
  }>;
}

export const LastUpdatedInfo: FC<LastUpdatedInfoProps> = ({ data }) => {
  const lastUpdated = useMemo(() => {
    const firstItemMain = data[0]?.last_update;
    const hasSubRows = !!data[0]?.subRows;

    if (hasSubRows) {
      const firstItemSub = data[0]?.subRows?.[0]?.last_update;
      return firstItemSub && firstItemSub !== 'missing'
        ? firstItemSub
        : data[0]?.subRows?.find((i) => i.last_update !== 'missing')?.last_update;
    }

    return firstItemMain && firstItemMain !== 'missing'
      ? firstItemMain
      : data.find((i) => i.last_update !== 'missing')?.last_update;
  }, [data]);

  const lastUpdatedDate = useMemo(
    () => formatDateString(lastUpdated as string, 'long'),
    [lastUpdated]
  );

  return lastUpdated ? (
    <p className='absolute text-primary italic -top-8 right-0'>
      Data as of {lastUpdatedDate}
    </p>
  ) : null;
};
