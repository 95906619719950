// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { lazy } from 'react';

export const Account = lazy(() => import('./account'));

export const Accounts = lazy(() => import('./accounts'));

export const AssetAllocation = lazy(() => import('./asset-allocation'));

export const AiBuilder = lazy(() => import('./ai-builder'));

export const NotFound = lazy(() => import('./not-found'));

export const Dashboard = lazy(() => import('./dashboard'));

export const Portfolio = lazy(() => import('./portfolio'));

export const Rebalance = lazy(() => import('./rebalance'));

export const RebalanceSummary = lazy(() => import('./rebalance-summary'));

export const Research = lazy(() => import('./research'));

export const Positions = lazy(() => import('./positions'));

export const TaxManager = lazy(() => import('./tax-manager'));

export const Trader = lazy(() => import('./trader'));
