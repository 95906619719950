// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  WorkflowState,
  TypeWorkflow,
} from 'features/utils/interfaces/redux/workflow-state';

const initialState: WorkflowState = {
  type: '',
  asset: '',
};

export const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    setWorkflow: (state, action: PayloadAction<WorkflowState>) => {
      state.type = action.payload.type;
      state.asset = action.payload.asset;
    },
    setWorkflowType: (state, action: PayloadAction<TypeWorkflow>) => {
      state.type = action.payload;
    },
    resetWorkflow: () => initialState,
  },
});

export const {
  actions: { setWorkflow, setWorkflowType, resetWorkflow },
  reducer,
} = workflowSlice;
