// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { useCallback, useMemo } from 'react';
import { TickerTagFeature } from 'api/interfaces/engine/citec';
import { MODAL_TEMPLATES_OPTIONS, showModal } from 'features/ui/modal';
import { useSafeUpdate } from 'hooks';
import { AppIcon } from 'components/app-icon';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  selectAllTags,
  selectLoadingTag,
  selectSelectTag,
  selectTag,
  selectTagsByTicker,
  selectTagsNames,
  selectTagSuggestions,
  selectTagTickers,
  setSelectTag,
} from 'store/slices/tag-manager';
import {
  createTickerTag,
  deleteTickerTag,
} from 'store/slices/tag-manager/tag-manager-thunk';
import { MyInvestments } from 'features/utils/interfaces/my-investment';
import { WatchListPosition } from 'features/utils/interfaces/redux/my-research-state';
import { useHandleApiErrors } from 'hooks/use-handle-api-errors';
import { addWatchListPosition } from 'store/slices/my-research';

export type ManageTagsOptions =
  | {
      feature: TickerTagFeature.PortfolioPositions;
      dataSource: MyInvestments[];
    }
  | { feature: TickerTagFeature.WatchList; dataSource: WatchListPosition[] };

export const useHandlerManageTags = ({ feature, dataSource }: ManageTagsOptions) => {
  const handleApiErrors = useHandleApiErrors();
  const runSafeUpdate = useSafeUpdate();

  const dispatch = useAppDispatch();

  const tagNames = useAppSelector((state) => selectTagsNames(state, feature));

  const tags = useAppSelector((state) => selectAllTags(state, feature));

  const tagName = useAppSelector((state) => selectSelectTag(state, feature)!);

  const tag = useAppSelector((state) => selectTag(state, feature, tagName));

  const tickers = useAppSelector((state) =>
    selectTagTickers(state, feature, tagName)
  );

  const tickerTags = useAppSelector(
    useCallback(
      (state) =>
        selectTagsByTicker(
          state,
          feature,
          feature === TickerTagFeature.PortfolioPositions
            ? dataSource.flatMap(({ subRows }) =>
                subRows.map(({ ticker, url_logo }) => ({ ticker, url_logo }))
              )
            : dataSource.map(({ ticker, url_logo }) => ({ ticker, url_logo }))
        ),
      [dataSource, feature]
    )
  );

  const positions = useMemo(() => {
    if (typeof tag === 'undefined') {
      switch (feature) {
        case TickerTagFeature.WatchList:
          return tags
            .map(({ name, positions }) => ({
              name: name,
              subRows: dataSource
                .filter(({ ticker }) =>
                  positions.map((position) => position.ticker).includes(ticker)
                )
                .map((asset) => ({ ...asset, tag: name })),
            }))
            .concat({
              name: 'Non-tagged',
              subRows: dataSource
                .filter(
                  ({ ticker }) =>
                    !tags
                      .flatMap((tag) =>
                        tag.positions.map((position) => position.ticker)
                      )
                      .includes(ticker)
                )
                .map((asset) => ({ ...asset, tag: '' })),
            })
            .filter((tag) => tag.subRows.length);
        default:
          return dataSource;
      }
    }

    switch (feature) {
      case TickerTagFeature.PortfolioPositions: {
        return dataSource
          .filter(({ subRows }) =>
            subRows.some(({ ticker }) => tickers.includes(ticker))
          )
          .map((investment) => {
            const subRows = investment.subRows.filter(({ ticker }) =>
              tickers.includes(ticker)
            );

            return {
              ...investment,
              subRows: subRows,
              quantity: subRows.reduce(
                (current, next) => current + next.quantity,
                0
              ),
              investment: subRows.reduce(
                (current, next) => current + next.investment,
                0
              ),
              total_gain_loss: [
                subRows.reduce(
                  (current, next) =>
                    current + next.total_gain_loss.reduce((x, y) => x + y),
                  0
                ),
              ],
            };
          });
      }
      case TickerTagFeature.WatchList:
        return [
          {
            name: tag.name,
            subRows: dataSource
              .filter(({ ticker }) =>
                tag.positions.map((position) => position.ticker).includes(ticker)
              )
              .map((asset) => ({ ...asset, tagName: tag.name })),
          },
        ].filter((tag) => tag.subRows.length);
    }
  }, [dataSource, feature, tag, tags, tickers]);

  const suggestions = useAppSelector(
    useCallback((state) => selectTagSuggestions(state, feature), [feature])
  );

  const isLoadingTags = useAppSelector((state) => selectLoadingTag(state, feature)!);

  const showConfirmationModal = useCallback(
    (onConfirm?: () => Promise<void>, onDeny?: () => void) => {
      showModal({
        ...MODAL_TEMPLATES_OPTIONS.CONFIRM_DELETE,
        icon: <AppIcon icon='ExclamationMark' fill='#1072BA' />,
        title: 'Delete tags',
        content: 'Are you sure you want to delete your tags?',
        preConfirm: onConfirm,
      }).then(({ isConfirmed }) => !isConfirmed && runSafeUpdate(() => onDeny?.()));
    },
    [runSafeUpdate]
  );

  const onChangeTag = useCallback(
    (tag: string) => dispatch(setSelectTag([feature, tag])),
    [dispatch, feature]
  );

  const onCreateTag = useCallback(
    (tag: string, update = false) => {
      if (update)
        showModal({
          ...MODAL_TEMPLATES_OPTIONS.CONFIRM_DELETE,
          title: 'Tag Creation Confirmation',
          content: (
            <>
              Are you sure you want to create this tag? <br /> All associated
              positions will be assigned automatically.
            </>
          ),
          buttons: {
            cancel: {
              show: true,
              text: 'No, Just add the tag',
            },
            confirm: {
              show: true,
              text: 'Yes, Assign positions',
            },
          },
          preConfirm: async () => {
            try {
              await dispatch(
                createTickerTag({
                  feature,
                  tag,
                  ticker: '',
                  update,
                  includeTickers: true,
                })
              )
                .unwrap()
                .then(({ positions }) =>
                  Promise.allSettled(
                    positions.map(({ ticker }) =>
                      dispatch(createTickerTag({ feature, tag, ticker, update }))
                    )
                  )
                );
            } catch (error: any) {
              handleApiErrors(error);

              console.log('[useHandlerManageTags]: ', error);
            }
          },
        }).then(async ({ isDismissed, isConfirmed }) => {
          if (!isConfirmed) {
            await dispatch(createTickerTag({ feature, tag, ticker: '', update }));
          }

          if (isDismissed) return;
        });
      else dispatch(createTickerTag({ feature, tag, ticker: '', update }));
    },
    [dispatch, feature, handleApiErrors]
  );

  const onRemoveTag = useCallback(
    (tag: string) => {
      showConfirmationModal(async () => {
        await dispatch(deleteTickerTag({ tag, feature }));
      });
    },
    [dispatch, feature, showConfirmationModal]
  );

  const onCreateTickerTag = useCallback(
    (tag: string, ticker: string) => {
      dispatch(createTickerTag({ feature, tag, ticker }));
    },
    [dispatch, feature]
  );

  const onRemoveTickerTag = useCallback(
    (tag: string, ticker: string) => {
      showConfirmationModal(async () => {
        await dispatch(deleteTickerTag({ tag, ticker, feature }));
      });
    },
    [dispatch, feature, showConfirmationModal]
  );

  const onAddTicker = useCallback(
    (ticker: string, assetType: string) => {
      if (typeof dataSource.find(({ ticker: t }) => t === ticker) !== 'undefined') {
        showModal({
          ...MODAL_TEMPLATES_OPTIONS.WARN,
          content: (
            <>
              <p>The asset {ticker} has already been added to your watchlist.</p>
              <p>
                Please choose another asset or remove one from your list to add this
                new one.
              </p>
            </>
          ),
          title: 'Asset Already Exists',
          buttons: {
            confirm: {
              show: true,
              text: 'Got it',
            },
          },
        });

        return;
      }

      dispatch(addWatchListPosition({ ticker, assetType }));
    },
    [dataSource, dispatch]
  );

  return {
    selectTag: tagName,
    tag,
    tags,
    tagNames,
    onCreateTag,
    onRemoveTag,
    onCreateTickerTag,
    onRemoveTickerTag,
    onChangeTag,
    onAddTicker,
    isLoadingTags,
    positions,
    tickerTags,
    suggestions,
  };
};
