// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  MyResearchState,
  SavedPortfolio,
  Screener,
  WatchListPosition,
} from 'features/utils/interfaces/redux/my-research-state';
import {
  getSavedPortfolios,
  getWatchListPositions,
  addWatchListPosition,
  removeWatchListPosition,
  getScreeners,
  getComparison,
  updateSavedPortfolio,
} from './my-research-thunk';
import { EtfFilters } from 'api/interfaces/ai/etf';
import { MutualFundFilters } from 'api/interfaces/ai/mutual-funds';

export const savedPortfoliosEntityAdapter = createEntityAdapter<SavedPortfolio>({
  selectId: ({ portfolio_id }) => portfolio_id,
  sortComparer: /* istanbul ignore next */ (current, next) =>
    Date.parse(next.creation_date) - Date.parse(current.creation_date),
});

export const watchListEntityAdapter = createEntityAdapter<WatchListPosition>({
  selectId: ({ ticker }) => ticker,
  sortComparer: (current, next) =>
    Date.parse(next.saved_at) - Date.parse(current.saved_at),
});

export const screenersEntityAdapter = createEntityAdapter<Screener>({
  selectId: ({ universe_id }) => universe_id,
  sortComparer: /* istanbul ignore next */ (current, next) =>
    Date.parse(next.creation_date) - Date.parse(current.creation_date),
});

export const initialState: MyResearchState = {
  savedPortfolios: savedPortfoliosEntityAdapter.getInitialState({
    ideasTableData: [],
  }),
  watchList: watchListEntityAdapter.getInitialState(),
  screeners: screenersEntityAdapter.getInitialState({
    loading: false,
    mutualFundsFilters: {
      asset_class: ['Equity'],
      location: ['United States'],
      segment: [],
      text_search: '',
      category: [],
      min_nav: '',
      max_nav: '',
      min_expenses_ratio: '',
      max_expenses_ratio: '',
      asset_manager: [],
      currency: ['USD'],
      ter: '',
      aum: '',
    },
    etfsFilters: {
      asset_class: [],
      location: [],
      investment_segment: [],
      text_search: '',
      min_aum: '',
      max_aum: '',
      min_expenses_ratio: '',
      max_expenses_ratio: '',
      asset_manager: [],
      currency: [],
      ter: '',
      aum: '',
    },
  }),
  comparison: {
    benchmark: 'SPY',
    tickers: [],
    w_hist: 365,
    loading: false,
    assetsComparison: {
      historical_data: {
        end_date: '',
        start_date: '',
      },
      asset_analysis: [],
      asset_backtest: [],
      corr_matrix: { ticker: [], correlation_cols: {} },
      risk_returns: [],
    },
    assetsInfo: [],
  },
};

export const myResearchSlice = createSlice({
  name: 'myResearch',
  initialState: () => initialState,
  reducers: {
    resetSavedPortfolios: (state) => {
      savedPortfoliosEntityAdapter.removeAll(state.savedPortfolios);

      state.savedPortfolios.ideasTableData = [];
    },
    removeSavedPortfolio: (state, { payload }: PayloadAction<number>) => {
      savedPortfoliosEntityAdapter.removeOne(state.savedPortfolios, payload);

      state.savedPortfolios.ideasTableData =
        state.savedPortfolios.ideasTableData.filter(
          (item) => item.portfolio_id !== payload
        );
    },
    resetWatchList: (state) => {
      watchListEntityAdapter.removeAll(state.watchList);
    },
    resetScreeners: (state) => {
      screenersEntityAdapter.removeAll(state.screeners);
      state.screeners.mutualFundsFilters = initialState.screeners.mutualFundsFilters;
      state.screeners.etfsFilters = initialState.screeners.etfsFilters;
    },
    resetMutualFundsFilters: (state) => {
      state.screeners.mutualFundsFilters = initialState.screeners.mutualFundsFilters;
    },
    resetEtfsFilters: (state) => {
      state.screeners.etfsFilters = initialState.screeners.etfsFilters;
    },
    setScreeners: (state, { payload }: PayloadAction<Screener[]>) => {
      screenersEntityAdapter.setAll(state.screeners, payload);
    },
    setComparisonHist: (state, { payload }: PayloadAction<number>) => {
      state.comparison.w_hist = payload;
    },
    setComparisonTickers: (state, { payload }: PayloadAction<string[]>) => {
      state.comparison.tickers = Array.from(
        new Set(state.comparison.tickers.concat(payload))
      );
    },
    removeComparisonTickers: (state, { payload }: PayloadAction<string[]>) => {
      state.comparison.tickers = state.comparison.tickers.filter(
        (ticker) => !payload.includes(ticker)
      );
    },
    setComparisonBenchmark: (state, { payload }: PayloadAction<string>) => {
      state.comparison.benchmark = payload;
    },
    setMutualFundsFilters: (
      state,
      { payload }: PayloadAction<MutualFundFilters>
    ) => {
      state.screeners.mutualFundsFilters = payload;
    },
    setEtfsFilters: (state, { payload }: PayloadAction<EtfFilters>) => {
      state.screeners.etfsFilters = payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getSavedPortfolios.fulfilled, (state, { payload }) => {
        savedPortfoliosEntityAdapter.setAll(
          state.savedPortfolios,
          payload.savedPortfolios
        );

        state.savedPortfolios.ideasTableData = payload.ideas;
      })
      .addCase(updateSavedPortfolio.fulfilled, (state, { payload }) => {
        savedPortfoliosEntityAdapter.updateOne(state.savedPortfolios, {
          changes: payload.portfolio,
          id: payload.portfolio.portfolio_id,
        });

        state.savedPortfolios.ideasTableData =
          state.savedPortfolios.ideasTableData.map((idea) =>
            idea.portfolio_id === payload.portfolio.portfolio_id
              ? payload.idea
              : idea
          );
      })
      .addCase(getWatchListPositions.fulfilled, (state, { payload }) => {
        watchListEntityAdapter.setAll(state.watchList, payload);
      })
      .addCase(addWatchListPosition.fulfilled, (state, { payload }) => {
        watchListEntityAdapter.setOne(state.watchList, payload);
      })
      .addCase(removeWatchListPosition.fulfilled, (state, { payload }) => {
        watchListEntityAdapter.removeOne(state.watchList, payload);
      })
      .addCase(getScreeners.pending, (state) => {
        state.screeners.loading = true;
      })
      .addCase(getScreeners.fulfilled, (state, { payload }) => {
        state.screeners.loading = false;

        screenersEntityAdapter.setAll(state.screeners, payload);
      })
      .addCase(getScreeners.rejected, (state) => {
        state.screeners.loading = false;
      })
      .addCase(getComparison.pending, (state) => {
        state.comparison.loading = true;
      })
      .addCase(getComparison.rejected, (state) => {
        state.comparison.loading = false;
      })
      .addCase(
        getComparison.fulfilled,
        (state, { meta: { arg }, payload: [assetsComparison, assetsInfo] }) => {
          state.comparison.tickers = assetsComparison.asset_analysis
            .map((asset) => asset.ticker)
            .filter((ticker) =>
              arg.includes(state.comparison.benchmark)
                ? true
                : ticker !== state.comparison.benchmark
            );

          state.comparison.assetsComparison = assetsComparison;
          state.comparison.assetsInfo = assetsInfo;
          state.comparison.loading = false;
        }
      ),
});

export const {
  actions: {
    removeSavedPortfolio,
    resetSavedPortfolios,
    resetWatchList,
    resetScreeners,
    setScreeners,
    setComparisonHist,
    setComparisonTickers,
    setComparisonBenchmark,
    removeComparisonTickers,
    setMutualFundsFilters,
    setEtfsFilters,
    resetEtfsFilters,
    resetMutualFundsFilters,
  },
  reducer,
} = myResearchSlice;
