// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Column, OnChangeFn, VisibilityState } from '@tanstack/react-table';
import { PopupSelector } from 'components/popup-selector';
import { IconButton } from 'features/ui/icon-button';
import { ButtonHTMLAttributes, forwardRef, useCallback, useMemo } from 'react';

export interface ColumnVisibilityProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  readonly columns: Column<any, any>[];
  readonly onColumnVisibilityChange: OnChangeFn<VisibilityState>;
}

export const ColumnVisibilitySelector = forwardRef<
  HTMLButtonElement,
  ColumnVisibilityProps
>(({ columns, onColumnVisibilityChange }, ref) => {
  const visibleColumns = useMemo(
    () =>
      columns.filter((column) => column.getIsVisible()).map((column) => column.id),
    [columns]
  );

  const options = useMemo(
    () =>
      columns
        .filter(
          (column) =>
            column.id !== 'options' &&
            column.getCanHide() &&
            typeof column.columnDef.header == 'string' // ignore columns without a header
        )
        .map((column) => ({
          value: column.id,
          label: (column.columnDef.header as string).toLocaleUpperCase(),
        })),
    [columns]
  );

  const handleSaveColumns = useCallback(
    (selectedColumns: string[]) => {
      const visibilityState = options
        .filter((column) => !selectedColumns.includes(column.value))
        .reduce((acc, column) => {
          acc[column.value] = false;
          return acc;
        }, {} as VisibilityState);
      onColumnVisibilityChange(visibilityState);
    },
    [onColumnVisibilityChange, options]
  );

  return (
    <PopupSelector
      title='Edit Columns'
      options={options}
      initialSelectedOptions={visibleColumns}
      onSave={handleSaveColumns}
    >
      <IconButton
        variant='ghost'
        icon='Settings'
        ref={ref}
        aria-label='Column visibility'
      />
    </PopupSelector>
  );
});

ColumnVisibilitySelector.displayName = 'ColumnVisibility';
