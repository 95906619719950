// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Box } from '@mui/material';
import { IconButton } from 'features/ui/icon-button';
import { useEffect, useRef } from 'react';
import { useCopilotChat } from '../hooks/use-copilot-chat';
import { CopilotChatInput } from './copilot-chat-input';
import { CopilotChatMessage } from './copilot-chat-message';
import { ResizableChatWindow } from './resizable-chat-window';

export const CopilotChat = () => {
  const {
    isChatOpen,
    messages,
    toggleChat,
    handleSendMessage,
    loading: isLoadingMessage,
    notification,
  } = useCopilotChat();

  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContainerRef.current !== null) {
      chatContainerRef.current.scrollTo({
        behavior: 'smooth',
        top: chatContainerRef.current.scrollHeight,
      });
    }
  }, [messages, isChatOpen]);

  return (
    <Box>
      <div className='fixed bottom-7 right-6 z-50'>
        <IconButton
          variant='icon'
          icon='CopilotChatButton'
          className=' !text-[#4563E7] hover:!text-[#263C9C] hover:!opacity-100 hover:!text-opacity-100'
          size={48}
          onClick={toggleChat}
        />
        <span
          className='absolute -top-1 -right-1 flex h-6 w-6 shadow-lg aria-hidden:hidden animate-fade'
          aria-hidden={!notification}
          data-testid='copilot-notification'
        >
          <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-[#E44646] opacity-75'></span>
          <span className='relative inline-flex rounded-full h-6 w-6 bg-[#E44646]'></span>
        </span>
      </div>

      {isChatOpen && (
        <ResizableChatWindow>
          <div className='flex flex-col flex-1 gap-y-2 m-2 pb-2.5 overflow-y-auto bg-white rounded-lg'>
            <div
              className='flex flex-col grow overflow-y-scroll'
              data-testid='copilot-chat-test-id'
              data-scrollable={false}
              ref={chatContainerRef}
            >
              {messages.map((msg, index) => (
                <CopilotChatMessage
                  key={index}
                  message={msg.message}
                  sender={msg.sender}
                  allowCopy={index !== 0}
                />
              ))}
              {isLoadingMessage && (
                <CopilotChatMessage message='' sender='CITEC' loading />
              )}
            </div>
            <div className='flex items-center pl-2 pr-6 gap-x-2.5'>
              <CopilotChatInput handleSendMessage={handleSendMessage} />
              <IconButton
                icon='CopilotChatBackButton'
                className='!rounded-full'
                onClick={toggleChat}
              />
            </div>
          </div>
        </ResizableChatWindow>
      )}
    </Box>
  );
};
