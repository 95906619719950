// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AssetsSearchResp } from 'api/interfaces/ai/assets';
import { AppIcon, Heading } from 'components';
import { AutocompleteInput } from 'components/autocomplete';
import { assetTypeMap } from 'features/brokers/utils';
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react';

export const AssignTagsEditor: FC<
  PropsWithChildren<{
    assetsInput?: boolean;
    onAddTicker?: (ticker: string, assetType: string) => void;
  }>
> = ({ children, assetsInput, onAddTicker }) => {
  const [ticker, setTicker] = useState('');

  const onChangeValue: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setTicker(e.target.value.toUpperCase());
  }, []);

  const onSelectValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>, value?: AssetsSearchResp) => {
      setTicker('');
      onAddTicker?.(
        e.target.value.toUpperCase(),
        assetTypeMap[value!.asset_type as keyof typeof assetTypeMap]
      );
    },
    [onAddTicker]
  );

  return (
    <div className='assign-tags-editor pt-8'>
      <div className='flex gap-x-16'>
        <Heading className='mt-1' title='Assign Tags' variant='h3' />
        {assetsInput && (
          <div>
            <AutocompleteInput
              error={true}
              name='ticker'
              placeholder='Add Assets'
              onChangeValue={onChangeValue}
              onSelectValue={onSelectValue}
              value={ticker}
              rightIcon={
                <AppIcon
                  icon='Add'
                  size='lg'
                  color='disabled'
                  className='hover:!text-primary'
                />
              }
              className='!rounded-full'
            />
          </div>
        )}
      </div>
      <div className='grid grid-cols-[200px_200px_1fr] gap-2 mt-7'>
        <div className='col-span-1 font-normal text-[#CACED8] ml-2'>NAME</div>
        <div className='col-span-1 font-normal text-[#CACED8] ml-2'>TAG</div>
        <div className='col-span-1'></div>
        {children}
      </div>
    </div>
  );
};
