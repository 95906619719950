// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
export type ClassName =
  | string
  | number
  | null
  | undefined
  | false
  | ClassName[]
  | { [prop: string]: boolean | null | undefined };

export const classNames = (...values: ClassName[]): string | undefined => {
  const classNamesRec = (classes: ClassName[]): string =>
    classes.reduce<string>((current, next) => {
      switch (typeof next) {
        case 'object':
          if (next === null) return current;

          if (Array.isArray(next)) return classNamesRec([current, ...next]);

          return Object.entries(next).reduce(
            (className, [key, value]) =>
              className.concat(value ? ` ${key}` : '').trim(),
            current
          );
        case 'string':
        case 'number':
          if (next) return `${current} ${next}`.trim();
          return current.trim();

        case 'undefined':
        case 'boolean':
          return current;
      }
    }, '');

  return classNamesRec(values) || void 0;
};
