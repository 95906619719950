// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { Button, CircularProgress } from '@mui/material';
import { brokerAuthenticationApi } from 'api/brokers-authentication/broker-authentication-api';
import { Broker } from 'api/interfaces/brokerage/account';
import { BrokerCredentials } from 'api/interfaces/brokerage/commons';
import { useSafeUpdate } from 'hooks';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { accountApi } from 'store/api/brokerage/accounts-api';
import { setBrokerAccount, setOpenLinkAccountModal } from 'store/slices/broker';

export const allowedBrokers: Record<Broker, string> = {
  [Broker.Alpaca]: 'Alpaca',
  [Broker.Tradier]: 'Tradier',
  [Broker.IBKR]: 'Interactive Brokers',
};

export const BrokersList = () => {
  const runSafeUpdate = useSafeUpdate();

  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [postBrokerCredentials] = accountApi.usePostBrokerCredentialsMutation();

  const connectToBroker = async (broker: Broker) => {
    const errorMessage = `Invalid ${allowedBrokers[broker]} authentication`;

    runSafeUpdate(() => {
      setLoading(true);

      setError('');
    });

    try {
      if (process.env.NODE_ENV !== 'development') {
        const state = (Math.random() + 1).toString(36).substring(2);

        let authorizationCode: BrokerCredentials = {} as any;

        if (broker === Broker.IBKR) {
          authorizationCode = await brokerAuthenticationApi.interactive_brokers();
        } else {
          authorizationCode = await brokerAuthenticationApi[broker](state);
        }

        const credentials = await postBrokerCredentials({
          brokerKey: broker,
          brokerCredentials: authorizationCode,
        }).unwrap();

        if (!credentials.connected) {
          throw new Error('Broker not connected');
        }
        runSafeUpdate(() => {
          dispatch(
            setBrokerAccount({
              broker: broker,
              accountType: credentials.type_account || 'PAPER',
              account: {
                name: '',
                accountId: '',
                currency: '',
              },
            })
          );

          dispatch(setOpenLinkAccountModal(false));
          setLoading(false);
        });
      } else {
        runSafeUpdate(() => {
          dispatch(
            setBrokerAccount({
              broker: broker,
              accountType: 'PAPER',
              account: {
                name: '',
                accountId: '',
                currency: '',
              },
            })
          );

          dispatch(setOpenLinkAccountModal(false));
          setLoading(false);
        });
      }
    } catch (error: any) {
      runSafeUpdate(() => {
        setError(errorMessage);
        setLoading(false);
      });
    }
  };

  return (
    <>
      {loading ? (
        <div className='flex justify-center'>
          <CircularProgress />
        </div>
      ) : (
        <div className='grid grid-cols-1 gap-2'>
          {Object.entries(allowedBrokers).map(([broker, brokerName]) => {
            const brokerLogo = require(`assets/img/brokers/${broker}.png`);
            return (
              <div
                className='bg-[#EDF0F9] rounded p-6 grid grid-cols-[32px_1fr] gap-[12px] items-center'
                key={brokerName}
              >
                <img
                  src={brokerLogo}
                  alt={`${brokerName} logo`}
                  className='w-[32px] h-[32px] object-contain'
                />
                <div className='flex justify-between items-center'>
                  <p className='text-[#56606D]'>{brokerName}</p>
                  <Button
                    data-testid={`link-${broker}-oauth`}
                    color='secondary'
                    onClick={() => connectToBroker(broker as Broker)}
                  >
                    + Link
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {error && (
        <div className='text-red-500 bg-red-100 px-6 py-3 rounded mt-2'>{error}</div>
      )}
    </>
  );
};
