// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Tooltip } from '@mui/material';
import { capitalizeString } from 'features/utils/capitalize-string';
import { classNames } from 'features/utils/classnames';
import { formatDateString } from 'features/utils/format-date';
import { formatToMoney } from 'features/utils/format-to-money';
import { numberToFixed } from 'features/utils/to-fixed-number';

export type TextFormat =
  | 'currency'
  | 'percentage'
  | 'date'
  | 'number'
  | 'currencyStyled'
  | 'capitalized'
  | 'performance';

interface TableCellTextProps {
  value: string | number | null | undefined;
  safeText?: string;
  format?: TextFormat;
  className?: string;
  showTooltip?: boolean;

  /** @description If true, the text will be truncated to one line and will show a tooltip with the full text */
  oneLine?: boolean;

  /** @description If true, the value will be multiplied by 100 */
  applyPercentageTransform?: boolean;

  /** @description Number of decimal places to show */
  decimalPlaces?: number;

  /** @description Validation to compare a given value; if true, will display safeText. */
  safeValue?: string | number;
}

export const TableCellText = ({
  value,
  format,
  safeText = '-',
  className: cssClassName = '',
  showTooltip = false,
  oneLine = false,
  applyPercentageTransform = true,
  decimalPlaces = 2,
  safeValue,
}: TableCellTextProps) => {
  let displayValue = value;

  let className = classNames(
    'text-inherit',
    { 'line-clamp-1': oneLine },
    cssClassName
  );

  if ([null, undefined, '', safeValue].includes(value)) {
    return <p className={className}>{safeText}</p>;
  }

  switch (format) {
    case 'currency': {
      const valueNumber = Number(displayValue);
      displayValue = formatToMoney(valueNumber);
      break;
    }

    case 'currencyStyled': {
      const valueNumber = Number(displayValue);
      displayValue = formatToMoney(valueNumber);
      className += ` ${
        valueNumber > 0 ? 'text-green-500' : 'text-red-500'
      } font-medium`;
      break;
    }

    case 'percentage': {
      displayValue = `${numberToFixed(
        Number(displayValue) * (applyPercentageTransform ? 100 : 1),
        decimalPlaces
      )}%`;
      break;
    }

    case 'date': {
      displayValue = formatDateString(displayValue as string);
      break;
    }

    case 'number': {
      const valueNumber = Number(displayValue);
      displayValue = valueNumber.toLocaleString('en-US', {
        maximumFractionDigits: decimalPlaces,
      });
      break;
    }

    case 'capitalized': {
      displayValue = capitalizeString(`${displayValue}`);
      break;
    }

    case 'performance': {
      const value = displayValue as number;
      // Check if the value is -100% to display safeText
      if (value === -1) {
        displayValue = safeText;
      } else {
        const percentage = (value * 100).toFixed(1);
        displayValue = value >= 0 ? `+${percentage}%` : `${percentage}%`;
        className += ` ${value >= 0 ? '!text-positive' : '!text-negative'}`;
      }
      break;
    }

    default:
      break;
  }

  return showTooltip || oneLine ? (
    <Tooltip title={value} placement='top'>
      <p className={className}>{displayValue}</p>
    </Tooltip>
  ) : (
    <p className={className}>{displayValue}</p>
  );
};
