// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistStore,
} from 'redux-persist';
import assetType from './slices/assets-type';
import broker from './slices/broker';
import parameters from './slices/parameters';
import portfolioPositions from './slices/portfolio-positions';
import availableParameters from './slices/available-parameters';
import tagManager from './slices/tag-manager';
import trader from './slices/trader';
import user from './slices/user';
import workflow from './slices/workflow';
import myResearch from './slices/my-research';
import copilotBuffer from './slices/copilot-buffer';
import storage from 'redux-persist/lib/storage';
import isEqual from 'lodash/isEqual'; // replace by fast-equals;
import { aiApi } from './api/ai/ai-api';
import { brokerageApi } from './api/brokerage/brokerage-api';

/**
 * Combines all reducers into a single root reducer.
 * @returns The root reducer.
 */
const rootReducer = combineReducers({
  assetType,
  availableParameters,
  broker,
  copilotBuffer,
  parameters,
  portfolioPositions,
  tagManager,
  trader,
  user,
  workflow,
  myResearch,
  [aiApi.reducerPath]: aiApi.reducer,
  [brokerageApi.reducerPath]: brokerageApi.reducer,
});

/**
 * The version number for the persisted store.
 * Generated from build-number-generator.
 * @link https://github.com/prantlf/build-number-generator#readme
 * @link https://prantlf.github.io/build-number-generator/
 */
const STORE_VERSION = 240604490;

/**
 * Configures the persisted reducer with the root reducer.
 * @returns The persisted reducer.
 */
const persistedReducer = persistReducer(
  {
    key: 'root',
    version: STORE_VERSION,
    debug: process.env.NODE_ENV === 'development',
    storage,
    blacklist: [
      'errors',
      'staticData',
      'myInvestment',
      'brokers',
      'investments',
      'tickerTags',
      'universe',
      'portfolio',
      'ideas',
      'watchlist',
      'trades',
      'openTrades',
      'portfolioRebalance',
      'staticData',
      'taxHarvesting',
      'summary',
      'rebalance',
      'user',
      // APIs should not be persisted
      aiApi.reducerPath,
      brokerageApi.reducerPath,
    ],
  },
  rootReducer
);

/**
 * Sets up the Redux store with persistence and optional listeners.
 * @param setupStoreListeners - Enables refetchOnFocus and refetchOnReconnect behaviors.
 * @returns The Redux store and persistor.
 */
export const setupStore = (setupStoreListeners = false, preloadState = {}) => {
  const store = configureStore({
    devTools: {
      maxAge: 25,
      trace: true,
    },
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
        immutableCheck: true,
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat([aiApi.middleware, brokerageApi.middleware]),
    enhancers: [],
    preloadedState: preloadState,
  });

  if (setupStoreListeners) {
    setupListeners(store.dispatch);
  }

  const persistor = persistStore(store);

  store.subscribe(() => {
    const state = store.getState();

    if (state._persist.version !== STORE_VERSION) {
      persistor.purge();
    }
  });

  return [store, persistor] as const;
};

export const [appStore, persistor] = setupStore();

export type AppStore = ReturnType<typeof setupStore>[0];

export type AppState = ReturnType<typeof rootReducer>;

export type AppDispatch = AppStore['dispatch'];

/**
 * Custom hook to use the Redux store's dispatch function.
 * @returns The dispatch function.
 */
export const useAppDispatch = (): AppDispatch => useDispatch();

/**
 * Custom hook to use the Redux store's state.
 * @param selector - The selector function to extract data from the state.
 * @param equalityFn - The function to determine if the selected data has changed.
 * @returns The selected data from the state.
 */
export const useAppSelector: TypedUseSelectorHook<AppState> = (
  selector,
  equalityFn = isEqual
) => useSelector(selector, equalityFn);

/**
 * Custom hook to use the Redux store.
 * @returns The Redux store.
 */
export const useAppStore = (): AppStore => useStore();
