// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { forwardRef } from 'react';

interface ColoredCircleProps {
  /**
   * The color of the circle.
   */
  readonly color: string;

  /**
   * The radius of the circle.
   */
  readonly radius: number;

  /**
   * Optional test ID for testing purposes.
   */
  readonly testId?: string;
}

/**
 * Renders a colored circle SVG element.
 *
 * @component
 * @param {string} color - The color of the circle.
 * @param {number} radius - The radius of the circle.
 * @param {React.Ref<SVGSVGElement>} ref - The ref to attach to the SVG element.
 * @param {string} [testId] - Optional test ID for testing purposes.
 * @returns {JSX.Element} The rendered colored circle SVG element.
 */
export const ColoredCircle = forwardRef<SVGSVGElement, ColoredCircleProps>(
  ({ color, radius, testId }, ref) => {
    return (
      <svg data-testid={testId} ref={ref} width={radius * 2} height={radius * 2}>
        <circle cx={radius} cy={radius} r={radius} fill={color} />
      </svg>
    );
  }
);

ColoredCircle.displayName = 'ColoredCircle';
