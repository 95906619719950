// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Button, Fade } from '@mui/material';
import { useAppNavigate } from 'hooks';
import { useLocation } from 'react-router-dom';

interface FreeTrialExpiresProps {
  canUserUseApp: boolean;
  expirationDate: number;
}

export const FreeTrialExpires = ({
  canUserUseApp,
  expirationDate,
}: FreeTrialExpiresProps) => {
  const { pathname } = useLocation();
  const isOnSusbcriptionPage = pathname.includes('account');

  const navigate = useAppNavigate();

  const redirecToSusbcriptions = () => {
    navigate('/account');
  };

  return !canUserUseApp ? (
    <Fade
      in={!isOnSusbcriptionPage}
      className='fixed top-0 left-0 z-[999999] bg-slate-400/50 w-full h-full backdrop-blur-sm overflow-hidden flex justify-center items-center'
      onClick={redirecToSusbcriptions}
    >
      <div>
        <div className='p-10 rounded-lg flex flex-col items-center bg-white'>
          <p className='text-center pb-4'>
            <h3 className='text-2xl font-bold text-[#2d405a] mb-6'>
              Expired subscription
            </h3>
            <span className='text-[#445a7a]'>
              Your last day of access was <br /> on{' '}
              {new Date(expirationDate).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
              })}
            </span>
          </p>

          <Button color='secondary' onClick={redirecToSusbcriptions}>
            Update Subscription
          </Button>
        </div>
      </div>
    </Fade>
  ) : null;
};
