// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Skeleton } from '@mui/material';
import { Tabs } from 'components';
import { Heading } from 'components/heading';
import { WatchlistToggleButton } from 'features/ui/table/components';
import { FC } from 'react';
import { useAssetProfile } from '../hooks/use-asset-profile';
import { useAssetProfileTopHoldings } from '../hooks/use-asset-profile-top-holdings';
import { AssetProfileDescription } from './asset-profile-description';
import { AssetProfileKeyStatistics } from './asset-profile-key-statistics';
import { AssetProfileTopHoldings } from './asset-profile-top-holdings';

export type SupportedAssetTypes = 'etf' | 'mutual_fund';

export interface AssetProfileProps {
  readonly ticker: string;
  readonly assetType: SupportedAssetTypes;
}

export const AssetProfile: FC<AssetProfileProps> = ({ ticker, assetType }) => {
  const { data, error, infoProperties, loading, statisticsProperties } =
    useAssetProfile({ ticker, assetType });

  const {
    pagination,
    holdings,
    loading: loadingHoldings,
    onAnalyzeHoldings,
    onDownloadHoldings,
  } = useAssetProfileTopHoldings({
    assetType,
    ticker,
  });

  if (loading) {
    return <Skeleton variant='rounded' height={583} width={940} />;
  }

  if (error) {
    return (
      <p data-testid='error' className='text-[#243750] '>
        Something went wrong while fetching the asset profile. Please try again.
      </p>
    );
  }

  return (
    <div className='text-[#243750] w-[940px]'>
      <div className='flex space-x-4 mb-6 items-center'>
        <Heading title={data && `${data.name} | ${data.ticker}`} variant='h2' />
        <WatchlistToggleButton ticker={ticker} assetType={assetType} />
      </div>

      <Tabs.Root defaultValue='profile'>
        <Tabs.List>
          <Tabs.Trigger value='profile'>Profile</Tabs.Trigger>
          <Tabs.Trigger value='positions'>Positions</Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value='profile'>
          <div className='grid gap-4 mt-4'>
            <AssetProfileDescription
              description={data?.description}
              infoProperties={infoProperties}
            />
            <AssetProfileKeyStatistics statisticsProperties={statisticsProperties} />
          </div>
        </Tabs.Content>

        <Tabs.Content value='positions' className='flex justify-center mt-4'>
          <AssetProfileTopHoldings
            pagination={pagination}
            holdings={holdings}
            loading={loadingHoldings}
            onAnalyzeHoldings={onAnalyzeHoldings}
            onDownloadHoldings={onDownloadHoldings}
          />
        </Tabs.Content>
      </Tabs.Root>
    </div>
  );
};
