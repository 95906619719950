// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

export const SCREENER_STOCKS = {
  smartBetaFactors: (
    <p className='text-justify'>
      Creating stock or bond portfolios that overweight companies based on
      fundamental factors (examples detailed below). Smart beta fund managers try to
      beat the market by predicting what types of companies (e.g., value vs. growth)
      will perform better, while traditional fund managers try to beat the market by
      analyzing details company by company.
    </p>
  ),

  esgScreener: (
    <p className='text-justify'>
      Apply filters associated with a company’s Environmental, Social, and Governance
      risk.
    </p>
  ),

  includeExclude: (
    <p className='text-justify'>
      Add or remove positions from your universe of stocks.
    </p>
  ),
};

export type ScreenerPopupName = keyof typeof SCREENER_STOCKS;
