// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Heading, SectionContainer } from 'components';
import { formatToMoney } from 'features/utils/format-to-money';

interface BrokerBuyingPowerProps {
  buyingPower: number | undefined;
  cash: number | undefined;
}

export const BrokerBuyingPower = ({ buyingPower, cash }: BrokerBuyingPowerProps) => {
  return (
    <SectionContainer>
      {buyingPower && cash ? (
        <div className='flex justify-between'>
          <Heading
            variant='h2'
            title='Cash / Buying Power'
            popupName='dashboard.cashAndBuyPower'
            tooltip
          />
          <Heading
            variant='h2'
            title={`${formatToMoney(cash)} / ${formatToMoney(buyingPower)}`}
          />
        </div>
      ) : (
        <span className='text-lg text-neutral-500 font-semibold block text-center'>
          No exist data
        </span>
      )}
    </SectionContainer>
  );
};
