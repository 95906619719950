// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { createAsyncThunk } from '@reduxjs/toolkit';
import { traderApi } from 'api/ai/trader-api';
import { equityApi } from 'api/brokerage/equity-api';
import { TraderOrderPayload } from 'api/interfaces/ai/trader';
import { LatestPricesResp } from 'api/interfaces/commons';
import { arrayToMap } from 'features/utils/array-to-map';
import { TradeDetails } from 'features/utils/interfaces/redux/trades';
import type { AppState as RootState } from 'store/store';
import { selectBrokerApiParams } from '../broker';
import { addUpdateTrades } from './trader-slice';

export type TradeParams = Pick<
  TradeDetails,
  | 'ticker'
  | 'operation'
  | 'realized_capital_gains'
  | 'unrealized_capital_gains'
  | 'volume'
  | 'exchange'
  | 'columnName'
  | 'isin'
> & { contracts_number?: number };

export const getOrderPrices = createAsyncThunk(
  'trader/getOrderPrices',
  async (trades: TradeParams[], { getState, dispatch }): Promise<TradeDetails[]> => {
    const broker = selectBrokerApiParams(getState() as RootState);

    const tickers = trades.map(({ ticker }) => ticker);

    const latestPrices = await (broker.brokerConnected
      ? equityApi.postPrices(
          {
            broker_key: broker.brokerConnected.toLowerCase(),
            type_account: broker.accountTypeSelect,
          },
          tickers
        )
      : equityApi.citecPrice(tickers));

    const traderOrderPayload: TraderOrderPayload = {
      latest_prices: latestPrices.map(({ ticker, price }) => ({
        ticker,
        price: price ?? 0.000001,
      })),
      trades: trades.map(
        ({
          ticker,
          operation,
          realized_capital_gains,
          unrealized_capital_gains,
          volume,
          contracts_number,
          isin,
        }) => ({
          operation,
          realized_capital_gains,
          ticker,
          unrealized_capital_gains,
          volume,
          contracts_number,
          isin,
        })
      ),
    };

    const traderOrders = await traderApi.order({ payload: traderOrderPayload });

    const traderDetails: TradeDetails[] = traderOrders.map((trade, index) => {
      const tradePrice = latestPrices.find((price) => price.ticker === trade.ticker);

      const currencyRate =
        (tradePrice?.local_currency_values?.price || 1) / (tradePrice?.price || 1);

      const localCurrencyValues = {
        ...latestPrices[index].local_currency_values,
        expected_volume: trade.expected_volume * currencyRate,
        volume: trade.volume * currencyRate,
        delta_volume: trade.delta_volume * currencyRate,
      };

      return {
        ...trade,
        exchange: trades[index].exchange,
        currency: latestPrices[index].currency,
        columnName: {
          name: trade.ticker,
          url_logo: trades[index].columnName?.url_logo,
          url_morningstar: trades[index].columnName?.url_morningstar,
          asset_type: trades[index].columnName?.asset_type,
        },
        gain_loss: [0, 0],
        local_currency_values: localCurrencyValues,
      } as TradeDetails;
    });

    dispatch(addUpdateTrades(traderDetails));

    return traderDetails;
  }
);

export const getLatestPrices = createAsyncThunk(
  'trader/getLatestPrices',
  async (
    trades: TradeDetails[],
    { getState, dispatch }
  ): Promise<TradeDetails[]> => {
    const broker = selectBrokerApiParams(getState() as RootState);

    const tickers = trades.map(({ ticker }) => ticker);

    const latestPrices: LatestPricesResp[] = await (broker.brokerConnected
      ? equityApi.postPrices(
          {
            broker_key: broker.brokerConnected.toLowerCase(),
            type_account: broker.accountTypeSelect,
          },
          tickers
        )
      : equityApi.citecPrice(tickers));

    const prices = arrayToMap(latestPrices, 'ticker');

    const traderDetails = trades.map((trade) => {
      const asset = prices.get(trade.ticker);
      const price = (asset?.price as number) ?? 0.000001;
      const localPrice = asset?.local_currency_values?.price as number;
      const localVolume = trade.local_currency_values?.volume as number;
      const contracts = localPrice ? trade.contracts_number : 0;
      const expectedLocalVolume = contracts * localPrice;

      return {
        ...trade,
        expected_price: price,
        contracts_number: contracts,
        local_currency_values: {
          volume: localVolume,
          currency: asset?.local_currency_values?.currency ?? '',
          price: localPrice,
          expected_volume: expectedLocalVolume,
          delta_volume: localPrice ? expectedLocalVolume - localVolume : 0,
        },
      };
    });

    dispatch(addUpdateTrades(traderDetails));

    return traderDetails;
  }
);
