// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from 'routes';
import { useAppSelector } from 'store/store';
import { useValidationPortfolioLoaded } from './use-validate-portfolio-loaded';
import { useValidateSoldPosition } from './use-validate-sold-position';
import { MODAL_TEMPLATES_OPTIONS, showModal } from 'features/ui/modal';
import { selectWorkflowType } from 'store/slices/workflow';
import { useEffect } from 'react';

export const useLayoutValidations = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const workflow = useAppSelector(selectWorkflowType);

  const { isPortfolioLoaded, pagesThatNoNeedPortfolioLoaded } =
    useValidationPortfolioLoaded();

  const { pagesThatNeedSoldPosition, isSomeSellPosition, is10PercentSold } =
    useValidateSoldPosition();

  useEffect(() => {
    const workflowWithSoldValidation = ['load', 'rebalance'];

    const renderPopup = (text: string) => {
      showModal({
        ...MODAL_TEMPLATES_OPTIONS.UNHANDLED_ERROR,
        title: 'Oops...',
        content: text,
      });
    };

    if (
      !isPortfolioLoaded &&
      !pagesThatNoNeedPortfolioLoaded.includes(pathname as Paths)
    ) {
      renderPopup('You need to upload a portfolio');

      navigate('/');
    }

    if (
      workflowWithSoldValidation.includes(workflow) &&
      pagesThatNeedSoldPosition.includes(pathname as Paths)
    ) {
      if (!is10PercentSold) {
        renderPopup('You need to sell at least 10% of the portfolio to continue');
        navigate('/tax-manager');
      }

      if (!isSomeSellPosition) {
        renderPopup('You need to sell some position');

        navigate('/tax-manager');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
};
