// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { fetchAiApi } from 'api/ai-api';
import { ReportingApi } from 'api/interfaces/ai/reporting';
import { PortfolioInsight } from 'api/interfaces/copilot/portfolio';

export const reportingApi: ReportingApi = {
  analysis: async ({ payload, assetType }) => {
    const { data } = await fetchAiApi<ArrayBuffer>({
      endpoint: `/reporting/portfolio/${assetType}/analysis/`,
      method: 'POST',
      payload,
      responseType: 'arraybuffer',
    });

    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    const downloadLink: HTMLAnchorElement = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${payload.client_data.report_name}.docx`;
    downloadLink.click();
  },

  insights: async (payload, assetType) => {
    const { data } = await fetchAiApi<PortfolioInsight[]>({
      method: 'POST',
      endpoint: `/reporting/portfolio/${assetType}/insights/`,
      payload,
    });

    return data;
  },
};
