// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { MODAL_TEMPLATES_OPTIONS, showModal } from 'features/ui/modal';

export const alertUnavailableTrades = () => {
  const errorMessage = (
    <>
      Some positions are not available in your broker or there was a connection
      error.
      <br />
      <br />
      To address this issue, you can either refresh the request, remove positions, or
      try with a different portfolio.
    </>
  );
  showModal({
    ...MODAL_TEMPLATES_OPTIONS.CONFIRM_DELETE,
    buttons: {
      cancel: {
        show: true,
      },
      confirm: {
        show: true,
        text: 'Reload',
      },
    },
    content: errorMessage,
    title: 'Unavailable Positions',
  }).then(({ isConfirmed }) => {
    /* istanbul ignore next */
    if (isConfirmed) window.location.reload();
  });
};
