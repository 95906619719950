// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { FC } from 'react';
import styles from './pagination.module.css';

export interface PaginationProps {
  readonly pageIndex: number;
  readonly pageSize: number;
  readonly pageCount: number;
  readonly canPreviousPage: boolean;
  readonly canNextPage: boolean;
  readonly onNextPage: VoidFunction;
  readonly onPreviousPage: VoidFunction;
}

export const Pagination: FC<PaginationProps> = ({
  pageIndex,
  pageCount,
  canNextPage,
  canPreviousPage,
  onNextPage,
  onPreviousPage,
}) => (
  <div className={styles.container}>
    <p className={styles.title}>
      Page {pageIndex + 1} of {pageCount}
    </p>
    <button
      type='button'
      className={styles.button}
      disabled={!canPreviousPage}
      onClick={onPreviousPage}
    >
      Previous
    </button>
    <button
      type='button'
      className={styles.button}
      disabled={!canNextPage}
      onClick={onNextPage}
    >
      Next
    </button>
  </div>
);
