// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

export const groupMap = <T, TKey, TElement, TResult>(
  keySelector: (x: T) => TKey,
  elementSelector: (x: T) => TElement,
  resultSelector: (x: TKey, xs: TElement[]) => TResult,
  source: T[]
) =>
  Array.from(
    source
      .reduce((map, value) => {
        const key = keySelector(value);

        const values = map.get(key) ?? [];

        return new Map([...map, [key, values.concat(elementSelector(value))]]);
      }, new Map() as ReadonlyMap<TKey, TElement[]>)
      .entries()
  ).map(([key, values]) => resultSelector(key, values)) as TResult extends any[]
    ? TResult
    : TResult[];

export const groupBy = <T, TKey extends keyof T>(
  data: T[],
  key: TKey
): Map<T[TKey], T[]> =>
  data.reduce((group, value) => {
    const keyValue = value[key];

    const values = group.get(keyValue) ?? [];

    const entry = [keyValue, values.concat(value)] as const;

    return new Map([...group, entry]);
  }, new Map<T[TKey], T[]>());
