// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AccountInfo } from 'api/interfaces/brokerage/account';
import {
  selectAccount,
  selectAccountType,
  selectBroker,
  selectBrokerConnected,
  selectLoadingAccounts,
  setAccount,
  setAccountsLoading,
  setAllPositions,
  setInvestmentsTableData,
  updateSummary,
} from 'store/slices/broker';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import { portfolioApi } from 'api/brokerage/portfolio-api';
import { equityApi } from 'api/brokerage/equity-api';
import { setOpenTrades } from 'store/slices/trader';
import { buildInvestmentTable } from 'features/brokers/utils';
import { accountApi } from 'store/api/brokerage/accounts-api';

export const useAccounts = () => {
  const dispatch = useDispatch();

  const isLoadingAccounts = useAppSelector(selectLoadingAccounts);

  const broker = useAppSelector(selectBroker);
  const accountType = useAppSelector(selectAccountType);
  const accountInRedux = useAppSelector(selectAccount);
  const isBrokerConnected = useAppSelector(selectBrokerConnected);
  const [getAccounts] = accountApi.useLazyAccountAllQuery();

  const getAccountsInfo = async (
    broker: string,
    account: string
  ): Promise<AccountInfo[]> => {
    dispatch(setAccountsLoading(true));
    const { data: accounts } = await getAccounts({
      params: {
        broker_key: broker,
        type_account: account,
      },
    });

    dispatch(setAccountsLoading(false));
    return accounts || [];
  };

  const getOpenTrades = useCallback(async () => {
    /* istanbul ignore next */
    if (!isBrokerConnected) return;

    try {
      const params = {
        broker_key: broker.toLowerCase(),
        type_account: accountType,
      };

      const payload = {
        account_id: accountInRedux.accountId,
        order_filter: {
          status: 'open',
        },
      };

      const orders = await portfolioApi.ordersSearch(params, payload);

      const orderTickers = orders.map(({ ticker }) => ticker);

      const prices = await equityApi.postPrices(params, orderTickers);

      const openTrades = orders.map(({ ticker, stocks_number, status, side }) => ({
        openDate: new Intl.DateTimeFormat('en-CA', {
          dateStyle: 'short',
        }).format(new Date()),
        symbol: ticker,
        price: prices.find((price) => price.ticker === ticker)?.price ?? 0,
        volume: stocks_number,
        status: status,
        side,
      }));

      dispatch(setOpenTrades(openTrades));
    } catch (error) {
      /* istanbul ignore next */
      console.error(error);
    }
  }, [isBrokerConnected, broker, accountType, accountInRedux.accountId, dispatch]);

  const dataInvest = useCallback(
    async (accountInfo: AccountInfo) => {
      if (!isBrokerConnected) return;

      try {
        const response = await buildInvestmentTable({
          account: accountInfo,
          accountTypeSelect: accountType,
          brokerConnected: broker,
        });

        dispatch(setInvestmentsTableData(response.dataTable));

        dispatch(
          updateSummary({
            stock: response.summary.stock,
            etf: response.summary.etf,
            mutual_fund: response.summary.mutual_fund,
            other: response.summary.other,
          })
        );

        dispatch(
          setAllPositions({
            etf: response.investments.etf,
            mutual_fund: response.investments.mutual_fund,
            other: response.investments.other,
            stock: response.investments.stock,
          })
        );
      } catch (error: any) {
        /* istanbul ignore next */
        console.error('[dataInvest]', error);
      }
    },
    [isBrokerConnected, accountType, broker, dispatch]
  );

  const setAccountOnRedux = useCallback(
    async (accountInfo: AccountInfo) => {
      dispatch(
        setAccount({
          name: accountInfo.name,
          accountId: accountInfo.account_id,
          currency: accountInfo.currency,
        })
      );

      dispatch(
        updateSummary({
          aum: accountInfo?.aum || 0,
          buyingPower: accountInfo?.buying_power || 0,
          cash: accountInfo?.cash || 0,
          equity: accountInfo?.investment || 0,
        })
      );

      await dataInvest(accountInfo);

      getOpenTrades();
    },

    [dataInvest, dispatch, getOpenTrades]
  );

  return {
    getAccountsInfo,
    getOpenTrades,
    setAccountOnRedux,
    isLoadingAccounts,
    dataInvest,
  };
};
