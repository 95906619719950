// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Outlet } from 'react-router-dom';
import { useLayoutValidations } from 'hooks';
import { FC, Suspense } from 'react';
import { Disclaimer, NavigationSidebar } from 'components';
import { Topbar } from './topbar';
import { CircularProgress } from '@mui/material';

export const Layout: FC = () => {
  useLayoutValidations();

  return (
    <>
      <div className='flex flex-row min-h-screen overflow-hidden relative z-0'>
        <NavigationSidebar />
        <main className='flex flex-col flex-1 overflow-auto relative'>
          <Topbar />
          <Suspense
            fallback={
              <div className='flex justify-center items-center w-full h-full'>
                <CircularProgress size={75} />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </main>
      </div>
      <Disclaimer />
    </>
  );
};
