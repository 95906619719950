// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { FC, useMemo } from 'react';
import { ResponsiveLine } from '@nivo/line';
import {
  formatDate,
  getMinMaxValues,
  tooltipDate,
  transformChartData,
} from './transform-performance-chart-data';
import { formatToMoney } from 'features/utils/format-to-money';
import { PerformanceChartTooltip } from './performance-chart-tooltip';

interface PerformanceChartProps {
  data: number[][];
  period: string;
  loading: boolean;
}

const tickValues = {
  '1D': 'every 30 minutes',
  '1W': 'every 1 days',
  '1M': 'every 3 days',
  '3M': 'every 10 days',
  '1Y': 'every 30 days',
  YTD: undefined,
};

export const PerformanceChart: FC<PerformanceChartProps> = ({
  data,
  period,
  loading,
}) => {
  const dataChart = useMemo(() => transformChartData(data), [data]);

  const { min: lowestValue, max: highestValue } = useMemo(
    () => getMinMaxValues(data),
    [data]
  );

  const yScaleMin = useMemo(
    () =>
      ['1D', '1W', '1M', '3M'].includes(period)
        ? 'auto'
        : lowestValue - lowestValue * 0.0025,
    [period, lowestValue]
  );

  const yScaleMax = useMemo(
    () => highestValue + highestValue * 0.0025,
    [highestValue]
  );

  return (
    <ResponsiveLine
      data={dataChart}
      margin={{ top: 5, right: 25, bottom: 35, left: 80 }}
      yScale={{
        type: 'linear',
        min: yScaleMin,
        max: yScaleMax,
        stacked: false,
        reverse: false,
        clamp: true,
        nice: true,
      }}
      yFormat=' >-$,.2f'
      curve='basis'
      axisTop={null}
      axisRight={null}
      animate={false}
      axisBottom={{
        tickValues: /* istanbul ignore next */ loading
          ? []
          : tickValues[period as keyof typeof tickValues],
        tickSize: 0,
        tickPadding: 15,
        tickRotation: 0,
        legend: '',
        format: /* istanbul ignore next */ (value) => formatDate(value, period),
      }}
      xFormat={
        /* istanbul ignore next */ (value) => tooltipDate(Number(value), period)
      }
      xScale={{
        type: 'time',
        format: '%Q',
        useUTC: true,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 12,
        tickRotation: 0,
        tickValues: 5,
        legend: '',
        format: /* istanbul ignore next */ (value) => formatToMoney(value),
      }}
      enableGridX={false}
      enableGridY={false}
      colors={'rgba(16, 114, 186, 1)'}
      enablePoints={false}
      enableArea={true}
      areaBaselineValue={15}
      areaOpacity={0.3}
      enableSlices={false}
      crosshairType='bottom'
      tooltip={({ point }) => <PerformanceChartTooltip point={point} />}
      useMesh={true}
      layers={[
        'areas',
        'axes',
        'crosshair',
        'grid',
        'legends',
        'lines',
        'markers',
        'mesh',
        'points',
        'slices',
      ]}
      theme={{
        axis: {
          ticks: {
            text: {
              fontSize: 12,
              fontFamily: 'poppins',
              fill: '#515F70',
            },
          },
        },
      }}
    />
  );
};
