// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { createTheme } from '@mui/material';
import { AppIcon } from 'components/app-icon';
import { buttons } from './buttons';
import { baseTheme } from './base';
import { typography } from './typography';
import { slider } from './slider';

const components = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '1em',
          color: '#79828D',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: '1em',
          backgroundColor: '#EDF0F9',
          height: '32px',
          borderRadius: '8px',
          color: '#79828D !important',
          '> fieldset': {
            border: '1px solid #CACED8 !important',
          },
        },
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottom: '2px solid green',
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: '2px solid blue',
          },
          '&:after': {
            borderBottom: '3px solid purple',
          },
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <AppIcon icon='ArrowDown' color='inherit' />,
      },
    },
    MuiAccordion: {
      defaultProps: {
        square: true,
      },
      styleOverrides: {
        root: {
          marginBottom: '10px',
          boxShadow: 'none',
          borderRadius: '10px',
          backgroundColor: '#edf0f9',
          border: 'none',
          '&.MuiAccordion-root:before': {
            backgroundColor: 'transparent',
          },
          '&.Mui-disabled': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <AppIcon icon='Checked' size='base' />,
        icon: <AppIcon icon='Checkbox' size='base' />,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'Poppins',
          backgroundColor: '#515F70',
          fontSize: 'calc(14rem/16)',
          fontWeight: 400,
        },
        arrow: {
          color: '#515F70',
        },
      },
      defaultProps: {
        placement: 'top',
        arrow: true,
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          boxSizing: 'border-box',
          backgroundColor: '#FFFFFF',
          border: '1px solid #CACED8',
          height: '30px',
          fontFamily: 'Poppins',
          fontSize: '1em',
          color: '#79828D',
          borderRadius: '8px',
          cursor: 'pointer',
          paddingRight: '10px !important',
        },
        input: {
          padding: '0px !important',
          border: 'none',
          marginInline: '1rem',
        },
        listbox: {
          backgroundColor: '#F6F7FC',
          color: '#79828D',
          fontSize: '1em',
          borderRadius: '8px',
          '::-webkit-scrollbar': {
            boxSizing: 'border-box',
            width: '3px',
            border: '1px solid red',
          },
          '::-webkit-scrollbar-track': {
            borderRadius: '5px',
            background: '#f6f7fc',
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#9fadbe',
            borderRadius: '5px',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: '#9fadbe90',
          },
        },
        paper: {
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05) !important',
        },
        noOptions: {
          color: '#79828D',
          fontSize: '1rem',
        },
        groupLabel: {
          color: '#515F70',
          fontWeight: 700,
          lineHeight: '24px',
          fontSize: '1em',
          fontFamily: 'Poppins',
          backgroundColor: '#F6F7FC',
        },
        groupUl: {
          padding: '0px',
          margin: '0px',
          paddingLeft: '0px !important',
        },
        option: {
          paddingLeft: '15px !important',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
          boxShadow: 'none',
          border: '1px solid #CACED8',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '1em',
          fontFamily: 'Poppins',
          color: '#79828D',
          '&:hover': {
            color: '#79828d',
            backgroundColor: '#F6F7FC',
          },
        },
      },
    },
    MuiSkeleton: {
      variants: [
        {
          props: { variant: 'rectangular' },
          style: { borderRadius: '8px', background: '#edf0f9' },
        },
      ],
    },
  },
});

export const theme = createTheme(components, buttons, slider, typography, baseTheme);
