// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { retryConfig } from './retry-config';

export type Auth0Interceptor = (
  client: Auth0Client,
  config: AxiosRequestConfig<any>
) => AxiosRequestConfig<any> | Promise<AxiosRequestConfig<any>>;

export const auth0 = createAuth0Client({
  domain: process.env.REACT_APP_AUTH0_APPLICATION_DOMAIN as string,
  client_id: process.env.REACT_APP_AUTH0_APPLICATION_CLIENT_ID as string,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  scope: 'read:current_user offline_access',
  redirect_uri: window.location.origin,
});

export const setupAuthInterceptor = (
  authClient: Promise<Auth0Client>,
  axiosInstance: AxiosInstance,
  ...interceptors: Array<
    [
      onFulfilled: (
        client: Auth0Client,
        config: AxiosRequestConfig<any>
      ) => AxiosRequestConfig<any> | Promise<AxiosRequestConfig<any>>,
      onRejected?: (client: Auth0Client, error: Error) => any
    ]
  >
) => {
  axiosInstance.interceptors.request.use((config) => {
    const url = config.url!;

    if (url[url.length - 1] !== '/') {
      config.url = `${config.url}/`;
    }

    return config;
  });

  interceptors.forEach(([onFulfilled, onRejected]) => {
    axiosInstance.interceptors.request.use(
      async (config) => {
        const client = await authClient;

        return onFulfilled(client, config);
      },
      async (error) => {
        return onRejected?.(await authClient, error);
      }
    );
  });

  retryConfig(axiosInstance);
  return axiosInstance;
};

export const useAuthTokenInterceptor: Auth0Interceptor = async (client, config) => {
  const token = await client.getTokenSilently();

  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`,
  };

  return config;
};

export const useAuthEmailQueryParamInterceptor: Auth0Interceptor = async (
  client,
  config
) => {
  if (config.params?.email || config.data?.email) {
    const user = await client.getUser();

    switch (config.method?.toLowerCase()) {
      case 'get':
      case 'delete':
        config.params = {
          ...config.params,
          email: user?.email,
        };
        break;
      case 'post':
      case 'put':
        config.data = {
          ...config.data,
          email: user?.email,
        };

        break;
    }
  }
  return config;
};

export const useAuthUserQueryParamInterceptor: Auth0Interceptor = async (
  client,
  config
) => {
  if (!config.url?.includes('settings')) {
    const user = await client.getUser();

    config.params = {
      ...config.params,
      user: user?.email,
    };
  }

  return config;
};
