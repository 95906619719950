// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AssetsSearchResp } from 'api/interfaces/ai/assets';
import { useDebounce, useFetchApi } from 'hooks';
import React, { FocusEventHandler, useState } from 'react';
import { ListSuggestions } from './list-suggestion';

interface AutocompleteInputProps<T> {
  className?: string;
  error: boolean;
  name: string;
  noMargin?: boolean;
  onChangeValue: (arg: T) => void;
  placeholder?: string;
  value: string;
  width?: string;
  onSelectValue?: (arg: T, value?: AssetsSearchResp) => void;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  delay?: number;
  onChangeDetails?: (value: any) => void;
  assetType?: string;
}

export const AutocompleteInput = ({
  error,
  name,
  onChangeValue,
  onSelectValue,
  value,
  className,
  leftIcon,
  rightIcon,
  placeholder = 'Ticker',
  onBlur,
  disabled = false,
  delay = 300,
  onChangeDetails,
  assetType,
}: AutocompleteInputProps<React.ChangeEvent<HTMLInputElement>>) => {
  const [renderSuggestions, setRenderSuggestions] = useState(false);
  const [suggestionIndex, setSuggestionIndex] = useState(-1);

  const debounceValue = useDebounce(value, delay);
  const dependency = `${assetType}${debounceValue}`;

  const { resp: allTickers, isLoading } = useFetchApi<AssetsSearchResp[]>({
    fetchFrom: 'aiApi',
    endpoint: '/assets/search/',
    method: 'GET',
    params: {
      ticker: debounceValue.trim().toUpperCase(),
      name: debounceValue.trim().toUpperCase(),
      asset_type: assetType,
    },
    startCallInmediatly: debounceValue.length > 1,
    dependency,
  });

  const handleSelectValue = (value: string) => {
    const e = {
      target: {
        value,
        name,
      },
    };

    onChangeValue(e as never);

    onChangeDetails?.(allTickers?.find(({ ticker }) => ticker === value));

    if (onSelectValue)
      onSelectValue(
        e as never,
        allTickers?.find(({ ticker }) => ticker === value)
      );

    setRenderSuggestions(false);
    //simulated event
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!allTickers) return;

    if (e.key === 'ArrowUp') {
      if (suggestionIndex === -1) {
        return;
      }
      setSuggestionIndex(suggestionIndex - 1);
    } else if (e.key === 'ArrowDown') {
      if (suggestionIndex + 1 === allTickers.length) {
        return;
      }
      setSuggestionIndex(suggestionIndex + 1);
    } else if (e.key === 'Enter') {
      setSuggestionIndex(suggestionIndex + 1);

      const e = {
        target: {
          value: allTickers[suggestionIndex + 1].ticker,
          name,
        },
      };

      onChangeValue(e as never);

      onChangeDetails?.(allTickers[suggestionIndex + 1]);

      setSuggestionIndex(-1);
      setRenderSuggestions(false);
    }
  };

  const handleOverList = () => {
    setSuggestionIndex(-1);
  };

  return (
    <div className='relative min-w-full'>
      <input
        className={`input-common ${className} input-${error ? 'valid' : 'invalid'} ${
          leftIcon && 'pl-10'
        }`}
        value={value}
        onFocus={() => setRenderSuggestions(true)}
        onChange={onChangeValue}
        onBlur={(e) => {
          setRenderSuggestions(false);
          onBlur?.(e);
        }}
        data-testid='ticker'
        onKeyDown={handleKeyDown}
        name={name}
        placeholder={placeholder}
        required
        type='text'
        autoComplete='off'
        disabled={disabled}
      />
      {leftIcon && <div className='absolute top-3 left-4'>{leftIcon}</div>}
      {rightIcon && <div className='absolute top-3 right-4'>{rightIcon}</div>}

      <ListSuggestions
        allTickers={allTickers}
        handleSelectValue={handleSelectValue}
        isLoading={isLoading}
        renderSuggestions={renderSuggestions}
        suggestionIndex={suggestionIndex}
        handleOverList={handleOverList}
      />
    </div>
  );
};
