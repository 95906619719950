// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { FC, PropsWithChildren, ReactNode } from 'react';

export interface ModalProps {
  readonly title: ReactNode;

  readonly icon?: ReactNode;

  readonly variant?: 'overflow' | 'box';
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  title,
  children,
  icon,
  variant = 'box',
}) => (
  <div role='dialog' data-variant={variant} className='group ui-modal'>
    {variant === 'overflow' && icon ? (
      <figure className='ui-modal-icon'>{icon}</figure>
    ) : null}
    <div className='ui-modal-container'>
      <header className='ui-modal-header'>
        {variant === 'box' && icon ? (
          <figure className='ui-modal-icon'>{icon}</figure>
        ) : null}
        {title ? <h2 className='ui-modal-title'>{title}</h2> : null}
      </header>
      <section className='ui-modal-content'>{children}</section>
    </div>
  </div>
);
