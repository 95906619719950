// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { PopupsName } from 'features/popups';
import { classNames } from 'features/utils/classnames';
import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import { Heading, TitleVariant } from './heading';

interface SectionContainerProps {
  className?: string;
  testId?: string;
  variant?: TitleVariant;
  title?: string;
  actions?: ReactNode;
  popupName?: PopupsName;
  headerClass?: string;
  tooltip?: boolean;
}

export const SectionContainer: FC<PropsWithChildren<SectionContainerProps>> = ({
  children,
  className = '',
  headerClass = '',
  testId = 'default',
  variant = 'h2',
  actions,
  title,
  popupName,
  tooltip,
}) => {
  const _headerClass = useMemo(
    () =>
      classNames(
        'flex items-center',
        {
          'justify-end': !title,
          'justify-between': !!title && !!actions,
        },
        headerClass
      ),
    [title, actions, headerClass]
  );

  return (
    <section
      data-testid={`${testId}-section`}
      className={`relative bg-[#edf0f9] rounded-lg p-8 min-w-full ${className}`}
    >
      {(title || actions) && (
        <div data-testid='header' className={_headerClass}>
          {title && (
            <Heading
              title={title}
              variant={variant}
              popupName={popupName}
              tooltip={tooltip}
            />
          )}
          {actions}
        </div>
      )}

      {children}
    </section>
  );
};
