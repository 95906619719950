// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AxiosError, AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';

const AXIOS_RETRY_LIMIT = 2;

export const retryConfig = (axiosInstance: AxiosInstance) => {
  axiosRetry(axiosInstance, {
    retries: AXIOS_RETRY_LIMIT,
    retryDelay: calculateRetryDelay,
    onRetry: handleRetryAttempt,
    retryCondition: shouldRetryRequest,
  });
};

const calculateRetryDelay = () => 1000; // Delay in milliseconds

const handleRetryAttempt = (retryCount: number, error: AxiosError) => {
  /* istanbul ignore else */
  if (process.env.NODE_ENV === 'development') {
    const { method, url } = error.config;
    console.warn(
      `Retry attempt ${retryCount} for [${method?.toUpperCase()}] ${url}`
    );
  }
};

const shouldRetryRequest = (error: AxiosError) => {
  const status = error.response?.status as number;
  return status !== 200;
};
