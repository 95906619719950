// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { useCallback, useEffect, useMemo } from 'react';
import {
  fetchAssetClasses,
  fetchAvailableUniverseData,
  fetchStaticRebalanceData,
  selectAvailableParametersAssetClasses,
  selectAvailableParametersUniverses,
} from 'store/slices/available-parameters';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  getSavedPortfolios,
  getScreeners,
  getWatchListPositions,
} from 'store/slices/my-research';
import { useResetAppStore } from './use-reset-app-store';
import { getAllTickerTags } from 'store/slices/tag-manager/tag-manager-thunk';
import { TickerTagFeature } from 'api/interfaces/engine/citec';
import { useSafeUpdate } from './use-safe-update';
import {
  selectAccount,
  selectAccountId,
  selectAccountType,
  selectBroker,
  selectBrokerConnected,
  setAccounts,
} from 'store/slices/broker';
import { AccountInfo } from 'api/interfaces/brokerage/account';
import { useAccounts } from 'features/accounts';

export const useAppReduxLoader = () => {
  const dispatch = useAppDispatch();

  const runSafeUpdate = useSafeUpdate();

  const universes = useAppSelector(selectAvailableParametersUniverses);

  const assetClasses = useAppSelector(selectAvailableParametersAssetClasses);

  const accountId = useAppSelector(selectAccountId);

  const isUniversesOnRedux = useMemo(() => universes.length > 1, [universes.length]);

  const isAssetsLoaded = useMemo(
    () => assetClasses.length > 3,
    [assetClasses.length]
  );

  const isBrokerConnected = useAppSelector(selectBrokerConnected);

  const broker = useAppSelector(selectBroker);
  const accountType = useAppSelector(selectAccountType);
  const account = useAppSelector(selectAccount);

  const { getAccountsInfo, setAccountOnRedux } = useAccounts();

  const getAccounts = useCallback(async () => {
    const accountsData = await getAccountsInfo(broker, accountType);

    runSafeUpdate(() => dispatch(setAccounts(accountsData)));

    return accountsData;
  }, [getAccountsInfo, broker, accountType, runSafeUpdate, dispatch]);

  useResetAppStore();

  useEffect(() => {
    dispatch(getSavedPortfolios())
      .unwrap()
      .then(() =>
        runSafeUpdate(() =>
          dispatch(
            getAllTickerTags({ feature: TickerTagFeature.PortfolioPositions })
          )
        )
      );
  }, [accountId, dispatch, runSafeUpdate]);

  useEffect(() => {
    dispatch(getWatchListPositions())
      .unwrap()
      .then(() =>
        runSafeUpdate(() =>
          dispatch(getAllTickerTags({ feature: TickerTagFeature.WatchList }))
        )
      );

    dispatch(getScreeners());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isUniversesOnRedux) {
      setTimeout(() => {
        dispatch(fetchAvailableUniverseData());
        dispatch(fetchStaticRebalanceData());
      }, 500); // Timeout for Testing app.test.tsx
    }

    if (!isAssetsLoaded) dispatch(fetchAssetClasses());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [universes]);

  useEffect(() => {
    if (isBrokerConnected) {
      getAccounts().then((accounts: AccountInfo[]) => {
        if (accounts?.length > 0 && !account.accountId) {
          runSafeUpdate(() => {
            setAccountOnRedux(accounts[0]);
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrokerConnected]);
};
