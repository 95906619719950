// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import stockLogo from 'assets/img/default_stock.png';
import etfLogo from 'assets/img/etf.png';
import mutualFundLogo from 'assets/img/mutual_funds.png';
import cryptoLogo from 'assets/img/crypto_logo.png';

/**
 * Returns the logo URL for an asset based on its type. If the provided logo URL is not valid, the default logo for the asset type is returned.
 * @param logoUrl - The URL of the asset's logo.
 * @param assetType - The type of the asset.
 * @returns The URL of the asset's logo.
 */

export const getAssetLogo = (logoUrl?: string, assetType = 'stock') => {
  if (logoUrl && logoUrl !== 'missing' && logoUrl !== '') {
    return logoUrl;
  }

  const type = assetType.toLocaleLowerCase();

  if (type.includes('stock')) return stockLogo;
  if (type.includes('etf') || type.includes('etp')) return etfLogo;
  if (type.includes('mutual fund') || type.includes('mutual_fund'))
    return mutualFundLogo;
  if (type.includes('crypto')) return cryptoLogo;

  return stockLogo;
};
