// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Tooltip } from '@mui/material';
import { IconButton } from 'features/ui/icon-button';

interface TagItemProps {
  label: string;
  tag: string;
  ticker?: string;
  'data-testid'?: string;
  onDelete:
    | ((tag: string, ticker: string) => void)
    | ((tag: string, ticker?: string) => void);
}

export const TagItem = ({
  label,
  tag,
  ticker,
  'data-testid': testId,
  onDelete,
}: TagItemProps) => {
  return (
    <div className='ticker-tag-item' data-testid={testId}>
      <Tooltip title={label}>
        <span className='line-clamp-1'>{label}</span>
      </Tooltip>
      <Tooltip title='Delete Tag'>
        <IconButton
          variant='icon'
          icon='Trashcan'
          color='light'
          size='base'
          data-testid='delete-tag-button'
          onClick={() => onDelete(tag, ticker!)}
        />
      </Tooltip>
    </div>
  );
};
