// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import {
  FC,
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
  cloneElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { AppIcon, SvgIconName } from './app-icon';
import { Menu, MenuItem } from '@mui/material';

type PortfolioActionName =
  | 'Edit'
  | 'Share'
  | 'Delete'
  | 'Analyze'
  | 'Download'
  | 'Trader'
  | 'TaxManager'
  | 'Rebalance';

type PortfolioEventHandlers = `on${Capitalize<PortfolioActionName>}`;

type PortfolioAction = Uncapitalize<PortfolioActionName>;

type PortfolioActions = Partial<
  Record<PortfolioAction, boolean> &
    Record<PortfolioEventHandlers, MouseEventHandler<HTMLElement>> &
    Record<`${PortfolioAction}Wrapper`, ReactElement<PropsWithChildren>>
>;

type PortfolioActionOption = {
  enabled?: boolean;
  text: string;
  onClick?: MouseEventHandler<HTMLElement>;
  icon: SvgIconName;
  wrapper?: ReactElement<PropsWithChildren>;
};

export interface PortfolioActionsMenu extends PortfolioActions {
  organizations?: boolean;
}

export const PortfolioActionsMenu: FC<PortfolioActionsMenu> = ({
  organizations,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const options = useMemo(
    () =>
      new Map<PortfolioAction, PortfolioActionOption>(
        Object.entries({
          edit: {
            enabled: props.edit,
            text: 'Edit',
            onClick: props.onEdit,
            icon: 'Pen',
            wrapper: props.editWrapper,
          },
          share: {
            enabled: props.share,
            text: 'Share',
            onClick: props.onShare,
            icon: organizations ? 'OpenLock' : 'Lock',
            wrapper: props.shareWrapper,
          },
          analyze: {
            enabled: props.analyze,
            text: 'Analyze',
            onClick: props.onAnalyze,
            icon: 'Analysis',
            wrapper: props.analyzeWrapper,
          },
          taxManager: {
            enabled: props.taxManager,
            text: 'Tax Manager',
            onClick: props.onTaxManager,
            icon: 'TaxManager',
            wrapper: props.taxManagerWrapper,
          },
          rebalance: {
            enabled: props.rebalance,
            text: 'Rebalance',
            onClick: props.onRebalance,
            icon: 'Rebalance',
            wrapper: props.rebalanceWrapper,
          },
          trader: {
            enabled: props.trader,
            text: 'Trader',
            onClick: props.onTrader,
            icon: 'Trader',
            wrapper: props.traderWrapper,
          },
          download: {
            enabled: props.download,
            text: 'Download',
            onClick: props.onDownload,
            icon: 'Download',
            wrapper: props.downloadWrapper,
          },
          delete: {
            enabled: props.delete,
            text: 'Delete',
            onClick: props.onDelete,
            icon: 'Trashcan',
            wrapper: props.deleteWrapper,
          },
        }) as Array<[PortfolioAction, PortfolioActionOption]>
      ),
    [organizations, props]
  );

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onItemClick = useCallback(
    (option: PortfolioAction): MouseEventHandler<HTMLElement> =>
      (event) => {
        onClose();

        return options.get(option)?.onClick?.(event);
      },
    [onClose, options]
  );

  const Wrapper: FC<PropsWithChildren<{ option: PortfolioAction }>> = useCallback(
    ({ option, children }) => {
      const wrapper = options.get(option)?.wrapper ?? <></>;

      return cloneElement(wrapper, undefined, children);
    },
    [options]
  );

  return (
    <div
      className='text-[#515F70]'
      onClick={(event) => event.stopPropagation()}
      data-testid='portfolio-actions-menu'
    >
      <button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={onClick}
        className='flex gap-x-2.5 items-center justify-center'
        data-testid='portfolio-actions'
      >
        <span className='text-sm leading-6 font-normal'>Actions</span>
        <AppIcon icon='ArrowDown' color='inherit' size='base' />
      </button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        data-testid='basic-menu'
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {Array.from(options, ([key, option]) =>
          option.enabled ? (
            <MenuItem
              key={key}
              data-testid={`action-${key}`}
              onClick={onItemClick(key)}
            >
              <Wrapper option={key}>
                <div className='flex gap-x-3 items-center justify-center'>
                  <AppIcon icon={option.icon} size='lg' data-testid={option.icon} />
                  <span>{option.text}</span>
                </div>
              </Wrapper>
            </MenuItem>
          ) : null
        )}
      </Menu>
    </div>
  );
};
