// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { Autocomplete } from '@mui/material';
import { AppIcon, Heading } from 'components';
import { FC, PropsWithChildren, useCallback, useState } from 'react';

interface TagManagerEditorProps extends PropsWithChildren {
  onTag: (tag: string, update?: boolean) => void;
  suggestions: string[];
}

export const TagManagerEditor: FC<TagManagerEditorProps> = ({
  onTag,
  children,
  suggestions,
}) => {
  const [text, setText] = useState('');

  const onButtonClick = useCallback(() => {
    onTag(text, suggestions.includes(text));

    setText('');
  }, [onTag, suggestions, text]);

  return (
    <div className='flex flex-col space-y-9 pb-8'>
      <div className='w-full'>
        <div className='grid grid-cols-4 gap-2'>
          <div className='col-span-1'>
            <Heading variant='h2' title='Tag Manager' />
          </div>
          <div className='col-span-1 pr-2'>
            <Autocomplete
              freeSolo
              id='autocomplete-tag'
              disableClearable
              options={suggestions}
              onChange={(_, value) => setText(value)}
              onInputChange={(_, value) => setText(value)}
              value={text}
              renderInput={(params) => (
                <div
                  className='input-ticker relative'
                  data-testid='search-tag'
                  ref={params.InputProps.ref}
                >
                  <input
                    {...params.inputProps}
                    className='input-rounded'
                    name='search_text'
                    placeholder='Add Tag'
                    required
                    type='text'
                    autoComplete='off'
                  />
                  <div className='absolute right-3 top-1.5'>
                    <button
                      type='submit'
                      data-testid='create-global-tag-button'
                      className='inline-flex h-7 w-7 items-center justify-center hover:cursor-pointer'
                      disabled={!params.inputProps.value}
                      onClick={onButtonClick}
                    >
                      <AppIcon
                        icon='Add'
                        size='lg'
                        color='disabled'
                        className='hover:!text-primary'
                      />
                    </button>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <div
        className='text-white flex flex-wrap gap-x-6 gap-y-3.5 items-center'
        data-testid='tag-manager-editor-child'
      >
        {children}
      </div>
    </div>
  );
};
