// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppIcon, OptionsSelector, SectionContainer } from 'components';
import { ManageTagsModal, useHandlerManageTags } from 'features/tags-manager';
import { dispatchInvestment } from 'features/dashboard/utils/dispatch-investment';
import { exportTableToExcel } from 'features/utils/export-table-to-excel';
import { MyInvestments, SubRows } from 'features/utils/interfaces/my-investment';
import { useAppNavigate, useSelectUniverse } from 'hooks';
import { setWorkflow } from 'store/slices/workflow';
import { formatInvestmentData } from '../utils/format-investment-data';
import { WorkflowState } from 'features/utils/interfaces/redux/workflow-state';
import { TickerTagFeature } from 'api/interfaces/engine/citec';
import { useAppSelector } from 'store/store';
import { selectInvestmentsTableData } from 'store/slices/broker';
import { useSendToTrader } from 'hooks/use-sent-to-trader';
import { InvestmentTable } from './investment-table';
import { Paths } from 'routes';

export const BrokerMyInvestments = () => {
  const [openTagsModal, setOpenTagsModal] = useState(false);

  const navigate = useAppNavigate();
  const dispatch = useDispatch();

  const { handleSelectBenckmark } = useSelectUniverse('ai-builder');
  const { sendTradeTicker } = useSendToTrader();

  const investmentData = useAppSelector(selectInvestmentsTableData);

  const {
    onChangeTag,
    selectTag,
    tagNames,
    tags,
    positions,
    tickerTags,
    onCreateTag,
    onCreateTickerTag,
    onRemoveTag,
    onRemoveTickerTag,
    suggestions,
  } = useHandlerManageTags({
    feature: TickerTagFeature.PortfolioPositions,
    dataSource: investmentData,
  });

  const loadPortfolioOnRedux = useCallback(
    (flow: WorkflowState, data: SubRows[]) => {
      dispatch(setWorkflow(flow));

      dispatchInvestment(flow.asset, selectTag, data);
      // istanbul ignore next
      if (flow.asset === 'stock' || flow.asset === 'etf') {
        handleSelectBenckmark('SPY');
      }

      navigate(
        (flow.type === 'tax-harvesting'
          ? `/tax-manager/${flow.asset}`
          : flow.type === 'rebalance'
          ? `/rebalance`
          : `/analysis/${flow.asset}`) as Paths
      );
    },
    [dispatch, handleSelectBenckmark, navigate, selectTag]
  );

  const handleOnExportInvestments = useCallback((row: any) => {
    try {
      const filename = `My Investments - ${row.asset_class}`;
      const portfolio = row.subRows.map((i: any) => formatInvestmentData(i));
      exportTableToExcel(portfolio, filename);
    } catch (error) {
      // istanbul ignore next
      return error;
    }
  }, []);

  const handleOpenModal = useCallback(() => {
    setOpenTagsModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenTagsModal(false);
  }, []);

  return (
    <>
      <ManageTagsModal
        onClose={handleCloseModal}
        openModal={openTagsModal}
        tags={tags}
        tickerTags={tickerTags}
        onCreateTag={onCreateTag}
        onCreateTickerTag={onCreateTickerTag}
        onRemoveTag={onRemoveTag}
        onRemoveTickerTag={onRemoveTickerTag}
        suggestions={suggestions}
      />

      <SectionContainer
        title='Portfolio Positions'
        popupName='dashboard.portfolioPositions'
        tooltip
        actions={
          <div className='flex space-x-4 items-center'>
            <div className='w-52'>
              <OptionsSelector
                optionsAvailable={tagNames}
                onChangeValueSelected={onChangeTag}
                optionSelected={selectTag}
                defaultText='Select tag'
                selectType='white'
              />
            </div>

            <button className='flex' onClick={handleOpenModal}>
              <h5 className='text-[#79828D] pr-2'>Manage tags</h5>
              <AppIcon icon='Lines' size='lg' color='disabled' />
            </button>
          </div>
        }
      >
        <InvestmentTable
          data={positions as MyInvestments[]}
          onLoad={loadPortfolioOnRedux}
          onExport={handleOnExportInvestments}
          onSendToTrader={sendTradeTicker}
        />
      </SectionContainer>
    </>
  );
};
