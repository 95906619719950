// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { PayloadAction, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import type {
  HistoricalWindow,
  OptimizationConfig,
  ParametersState,
  Rebalance,
  SetFactorsFilter,
  TaxHarvesting,
  Universe,
  WithdrawalOptimization,
} from 'features/utils/interfaces/redux/parameters-state';
import { fetchAvailableUniverseData } from '../available-parameters';

export const universeFiltersAdapter = createEntityAdapter<Universe>({
  selectId: ({ id }) => id,
  sortComparer: (current, next) => current.id.localeCompare(next.id),
});

export const optimizationConfigAdapter = createEntityAdapter<OptimizationConfig>({
  selectId: ({ id }) => id,
  sortComparer: (current, next) => current.id.localeCompare(next.id),
});

const initialState: ParametersState = {
  historicalWindow: 365,
  optimizationConfig: optimizationConfigAdapter.getInitialState({
    entities: {
      'ai-builder': {
        id: 'ai-builder',
        benchmark: 'SPY',
        max_weight: 100,
        min_weight: 0,
        number_assets: [1, 120],
        objective: { name: 'Direct Indexing', key: 'min_tracking_error' },
      },
      screener: {
        id: 'screener',
        benchmark: 'SPY',
        max_weight: 100,
        min_weight: 0,
        number_assets: [1, 120],
        objective: { name: 'Direct Indexing', key: 'min_tracking_error' },
      },
    },
    ids: ['ai-builder', 'screener'],
  }),
  taxHarvesting: {
    taxObjective: 'Manually',
  },
  rebalance: {
    type: 'deposit',
    amount: 20000,
    withdrawalOpt: {
      targetError: 0.05,
      withdrawalBoundaries: [-0.05, 0.05],
      orderStrategy: 'FIFO',
    },
  },
  universe: universeFiltersAdapter.getInitialState({
    entities: {
      'ai-builder': {
        id: 'ai-builder',
        factors_filter: [
          { factor: 'style', ranges: [0, 100] },
          { factor: 'size', ranges: [0, 100] },
          { factor: 'yield', ranges: [0, 100] },
          { factor: 'quality', ranges: [0, 100] },
          { factor: 'volatility', ranges: [0, 100] },
          { factor: 'momentum', ranges: [0, 100] },
          { factor: 'liquidity', ranges: [0, 100] },
        ],
        sectors_filter: [
          'Industrials',
          'Financials',
          'Health Care',
          'Consumer Staples',
          'Communication Services',
          'Information Technology',
          'Consumer Discretionary',
          'Materials',
          'Cash',
          'Others',
          'Real Estate',
          'Energy',
          'Utilities',
        ],
        esg_exclusions: [],
        esg_scoring: [
          { factor: 'co2_emissions', ranges: [0, 100] },
          { factor: 'environment_score', ranges: [0, 100] },
          { factor: 'governance_score', ranges: [0, 100] },
          { factor: 'social_score', ranges: [0, 100] },
        ],
        esg_not_informed: true,
        to_exclude: [],
        to_include: [],
        universe_base: 'SPDR S&P 500',
      },
      screener: {
        id: 'screener',
        factors_filter: [
          { factor: 'style', ranges: [0, 100] },
          { factor: 'size', ranges: [0, 100] },
          { factor: 'yield', ranges: [0, 100] },
          { factor: 'quality', ranges: [0, 100] },
          { factor: 'volatility', ranges: [0, 100] },
          { factor: 'momentum', ranges: [0, 100] },
          { factor: 'liquidity', ranges: [0, 100] },
        ],
        sectors_filter: [
          'Industrials',
          'Financials',
          'Health Care',
          'Consumer Staples',
          'Communication Services',
          'Information Technology',
          'Consumer Discretionary',
          'Materials',
          'Cash',
          'Others',
          'Real Estate',
          'Energy',
          'Utilities',
        ],
        esg_exclusions: [],
        esg_scoring: [
          { factor: 'co2_emissions', ranges: [0, 100] },
          { factor: 'environment_score', ranges: [0, 100] },
          { factor: 'governance_score', ranges: [0, 100] },
          { factor: 'social_score', ranges: [0, 100] },
        ],
        esg_not_informed: true,
        to_exclude: [],
        to_include: [],
        universe_base: 'SPDR S&P 500',
      },
    },
    ids: ['ai-builder', 'screener'],
  }),
};

const parametersSlice = createSlice({
  name: 'parameters',
  initialState,
  reducers: {
    updateOptimizationConfig: (
      state,
      {
        payload: [id, payload],
      }: PayloadAction<[id: string, payload: Partial<OptimizationConfig>]>
    ) => {
      optimizationConfigAdapter.updateOne(state.optimizationConfig, {
        id,
        changes: payload,
      });
    },
    updateTaxHarvesting: (state, action: PayloadAction<Partial<TaxHarvesting>>) => {
      state.taxHarvesting = { ...state.taxHarvesting, ...action.payload };
    },
    updateRebalanceConfig: (state, action: PayloadAction<Partial<Rebalance>>) => {
      state.rebalance = { ...state.rebalance, ...action.payload };
    },

    updateWithdrawalOptimization: (
      state,
      action: PayloadAction<Partial<WithdrawalOptimization>>
    ) => {
      state.rebalance.withdrawalOpt = {
        ...state.rebalance.withdrawalOpt,
        ...action.payload,
      };
    },

    updateUniverseFilters: (
      state,
      {
        payload: [id, payload],
      }: PayloadAction<[id: string, payload: Partial<Universe>]>
    ) => {
      universeFiltersAdapter.updateOne(state.universe, {
        id,
        changes: payload,
      });
    },
    setHistoricalWindow: (state, action: PayloadAction<HistoricalWindow>) => {
      state.historicalWindow = action.payload;
    },
    setTaxObjective: (state, action: PayloadAction<string>) => {
      state.taxHarvesting.taxObjective = action.payload;
    },
    setFactors: (
      state,
      {
        payload: [id, payload],
      }: PayloadAction<[id: string, value: SetFactorsFilter]>
    ) => {
      universeFiltersAdapter.updateOne(state.universe, {
        id,
        changes: {
          factors_filter: state.universe.entities[id]!.factors_filter.map(
            (value, index) =>
              index === payload.index ? { ...value, ranges: payload.value } : value
          ),
        },
      });
    },
    setEsgFactors: (
      state,
      {
        payload: [id, payload],
      }: PayloadAction<[id: string, value: SetFactorsFilter]>
    ) => {
      universeFiltersAdapter.updateOne(state.universe, {
        id,
        changes: {
          esg_scoring: state.universe.entities[id]!.esg_scoring.map((value, index) =>
            index === payload.index ? { ...value, ranges: payload.value } : value
          ),
        },
      });
    },
    resetParameters: () => initialState,
    resetUniverseFilters: (state) => {
      state.universe = initialState.universe;
    },
    resetRebalanceConfig: (state) => {
      state.rebalance = initialState.rebalance;
    },
    resetOptimizationConfig: (state) => {
      state.optimizationConfig = initialState.optimizationConfig;
    },
    resetTaxHarvesting: (state) => {
      state.taxHarvesting = initialState.taxHarvesting;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableUniverseData.fulfilled, (state, action) => {
      if (typeof action.payload.id !== 'undefined') {
        universeFiltersAdapter.updateOne(state.universe, {
          id: action.payload.id,
          changes: {
            factors_filter: action.payload.factors_filter,
            sectors_filter: action.payload.sectors,
          },
        });
      } else {
        universeFiltersAdapter.updateMany(
          state.universe,
          state.universe.ids.map((id) => ({
            id,
            changes: {
              factors_filter: action.payload.factors_filter,
              sectors_filter: action.payload.sectors,
            },
          }))
        );
      }
    });
  },
});

export const {
  resetOptimizationConfig,
  resetParameters,
  resetRebalanceConfig,
  resetTaxHarvesting,
  resetUniverseFilters,
  setEsgFactors,
  setFactors,
  setHistoricalWindow,
  setTaxObjective,
  updateOptimizationConfig,
  updateRebalanceConfig,
  updateTaxHarvesting,
  updateUniverseFilters,
  updateWithdrawalOptimization,
} = parametersSlice.actions;

export const parametersReducer = parametersSlice.reducer;
