// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { createSelector } from '@reduxjs/toolkit';
import {
  BrokerPosition,
  BrokerPositionsAssetType,
} from 'features/utils/interfaces/redux/broker';
import { AppState } from 'store/store';
import { accountsEntityAdapter, positionsEntityAdapter } from './broker-slice';

const positionsSelectors = positionsEntityAdapter.getSelectors();

const accountsSelectors = accountsEntityAdapter.getSelectors();

export const selectBrokerState = createSelector(
  ({ broker }: AppState) => broker,
  (state) => state
);

export const selectBroker = createSelector(
  selectBrokerState,
  ({ broker }) => broker
);

export const selectBrokerConnected = createSelector(
  selectBroker,
  (broker) => !!broker
);

export const selectAccountType = createSelector(
  selectBrokerState,
  ({ accountType }) => accountType
);

export const selectAccount = createSelector(
  selectBrokerState,
  ({ account }) => account
);

export const selectAccountId = createSelector(
  selectAccount,
  ({ accountId }) => accountId
);

export const selectOpenLinkAccountModal = createSelector(
  selectBrokerState,
  ({ openLinkAccountModal }) => openLinkAccountModal
);

export const selectPositions = createSelector(
  selectBrokerState,
  ({ positions }) => positions
);

export const selectAllPositions = createSelector(selectPositions, (positions) =>
  positionsSelectors.selectAll(positions).flatMap((assets) => assets.positions)
);

export const selectAssets = createSelector(
  selectPositions,
  (positions): Partial<Record<BrokerPositionsAssetType, BrokerPosition[]>> =>
    Object.fromEntries(
      Object.entries(positionsSelectors.selectEntities(positions)).map(
        /* istanbul ignore next */ ([key, value]) => [key, value?.positions ?? []]
      )
    )
);

export const selectEtfPositions = createSelector(selectPositions, (positions) =>
  positionsSelectors.selectById(positions, 'etf')
);

export const selectStockPositions = createSelector(selectPositions, (positions) =>
  positionsSelectors.selectById(positions, 'stock')
);

export const selectMutualFundPositions = createSelector(
  selectPositions,
  (positions) => positionsSelectors.selectById(positions, 'mutual_fund')
);

export const selectOtherPositions = createSelector(selectPositions, (positions) =>
  positionsSelectors.selectById(positions, 'other')
);

export const selectSummary = createSelector(
  selectBrokerState,
  ({ summary }) => summary
);

export const selectBrokerApiParams = createSelector(selectBrokerState, (state) => ({
  brokerConnected: state.broker,
  accountTypeSelect: state.accountType,
  account: state.account,
}));

export const selectAvailableCash = createSelector(selectSummary, ({ cash }) => cash);

export const selectBrokerConfiguration = createSelector(
  selectBrokerState,
  ({ broker, accountType }) => ({
    broker_key: broker,
    type_account: accountType,
  })
);

export const selectInvestmentsTableData = createSelector(
  selectBrokerState,
  ({ investmentsTableData }) => investmentsTableData
);

export const selectAccounts = createSelector(selectBrokerState, ({ accounts }) =>
  accountsSelectors.selectAll(accounts)
);

export const selectLoadingAccounts = createSelector(
  selectBrokerState,
  ({ accounts }) => accounts.loading
);
