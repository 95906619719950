// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Ts } from 'api/interfaces/commons';
import { Backtest } from 'api/interfaces/ai/portfolio';

interface DataPoint {
  x: string;
  y: number;
  variation: number;
}

interface TransformedObject {
  id: string;
  data: DataPoint[];
  variation: number;
}

export const transformTsData = (ts: Ts): TransformedObject[] => {
  const transformedData: TransformedObject[] = [
    {
      id: 'BENCHMARK',
      data: clusterByAdjacentVariation(
        ts.date.map((date, index) => ({
          x: date,
          y: ts.benchmark[index] * 100,
          variation: getVariation(ts, 'benchmark', index),
        }))
      ),
      variation: getVariation(ts, 'benchmark'),
    },
    {
      id: 'PORTFOLIO',
      data: clusterByAdjacentVariation(
        ts.date.map((date, index) => ({
          x: date,
          y: ts.portfolio[index] * 100,
          variation: getVariation(ts, 'portfolio', index),
        }))
      ),
      variation: getVariation(ts, 'portfolio'),
    },
  ];

  return transformedData;
};

export const transformAssetsTsData = (
  ts: Array<Backtest & { ticker: string }>,
  benchmark: string
): TransformedObject[] => {
  const transformedData: TransformedObject[] = ts.map(({ ticker, ts_backtest }) => ({
    id: ticker === benchmark ? `BENCHMARK` : ticker,
    data: clusterByAdjacentVariation(
      ts_backtest.date.map((date, index) => ({
        x: date,
        y: ts_backtest.portfolio[index] * 100,
        variation: getVariation(ts_backtest, 'portfolio', index),
      }))
    ),
    variation: getVariation(ts_backtest, 'portfolio'),
  }));

  return transformedData;
};

const clusterByAdjacentVariation = (data: DataPoint[]) =>
  data.reduce(
    ({ variation, items }, serie) =>
      variation === null || serie.variation !== variation
        ? { variation: serie.variation, items: items.concat(serie) }
        : { items, variation },
    { variation: null, items: [] } as {
      variation: number | null;
      items: DataPoint[];
    }
  ).items;

export const getVariation = (
  ts: Ts,
  key: 'portfolio' | 'benchmark',
  index?: number
) => {
  const denominator = ts[key][0] === 0 ? 1 : ts[key][0];

  return typeof index !== 'undefined'
    ? (ts[key][index] - ts[key][0] / denominator) * 100
    : (ts[key][ts[key].length - 1] - ts[key][0] / denominator) * 100;
};
