// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { unstable_useId as useId } from '@mui/material/utils';
import { Dispatch, useEffect, useState } from 'react';

export interface TabsController {
  readonly id: string;
  readonly tab: string | undefined;
  readonly setTab: Dispatch<string>;
}

export const useTabsController = (
  initialState: string | undefined | (() => string | undefined),
  onTabChange?: (value: string) => void
): TabsController => {
  /**
   * @todo replace by useId hook from react@18
   */
  const id = useId()!;

  const [tab, setTab] = useState(initialState);

  useEffect(() => {
    if (typeof tab !== 'undefined') onTabChange?.(tab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return {
    id,
    tab,
    setTab,
  };
};
