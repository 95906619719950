// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Dialog, DialogContent, DialogProps } from '@mui/material';
import { FC, useCallback } from 'react';
import { CloseButton } from './close-button';
import { useDynamicDialog } from './dynamic-dialog-context';

export interface DynamicDialogProps extends DialogProps {
  showCloseButton?: boolean;
  disableBackdropClick?: boolean;
  testId?: string;
}

export const DynamicDialog: FC = () => {
  const { closeDialog, dialogConfig } = useDynamicDialog();
  const { content, props, open } = dialogConfig;
  const {
    showCloseButton = true,
    disableBackdropClick,
    testId = 'dynamic-dialog',
    ...dialogProps
  } = props;

  const handleClose = useCallback(
    (_: any, reason: 'backdropClick' | 'escapeKeyDown' | 'closeButton') => {
      if (reason === 'backdropClick' && disableBackdropClick) return;

      closeDialog();
    },
    [closeDialog, disableBackdropClick]
  );

  return (
    <Dialog open={open} onClose={handleClose} data-testid={testId} {...dialogProps}>
      {showCloseButton && <CloseButton onClick={closeDialog} />}
      <DialogContent classes={{ root: '!p-10' }}>{content}</DialogContent>
    </Dialog>
  );
};
