// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

export const SIMULATION_PARAMETERS = {
  expectedReturns: (
    <p className='text-justify'>
      Annualized returns expected during the defined period.
    </p>
  ),

  expectedVolatility: (
    <p className='text-justify'>
      Annual volatility expected during the defined period.
    </p>
  ),

  annualDepositWithdrawal: (
    <p className='text-justify'>
      Annual amount expected to be deposited or withdrawn.
    </p>
  ),

  numberOfScenarios: (
    <p className='text-justify'>Number of Monte Carlo Simulation runs.</p>
  ),
};

export const SIMULATION_PERFORMANCE_SUMMARY = {
  sortinoRatio: (
    <p className='text-justify'>
      Risk-adjusted measure used to evaluate the performance of an investment
      relative to its downside risk.
    </p>
  ),
};

export type SimulationPopupName =
  | keyof typeof SIMULATION_PARAMETERS
  | keyof typeof SIMULATION_PERFORMANCE_SUMMARY;
