// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useSafeUpdate } from 'hooks';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AppIcon } from './app-icon';

export type SelectorProps<T> = {
  options?: T[];
  optionKey: keyof T;
  valueKey: keyof T;
  placeholder: string;
  liveSearch?: boolean;
  noOptionsText?: string;
  groupBy?: (option: T) => string;
  onSelectValue?: (value: T) => void;
  sourceData?: (searchText?: string) => Promise<T[]>;
  value?: T | null;
};

export const Selector = <T,>({
  options = [],
  groupBy,
  optionKey,
  valueKey,
  onSelectValue,
  placeholder,
  sourceData,
  liveSearch = false,
  noOptionsText,
  value,
}: SelectorProps<T>) => {
  const [open, setOpen] = useState(false);
  const [listOptions, setListOptions] = useState<T[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const runSafeUpdate = useSafeUpdate();

  const toggleOpen = useCallback(() => setOpen((prev) => !prev), []);

  const handleOptionValue = useCallback(
    (option: any, currentValue: any) => {
      return option[valueKey] === currentValue[valueKey];
    },
    [valueKey]
  );

  const handleOptionLabel = useCallback(
    (option: any) => {
      return option[optionKey];
    },
    [optionKey]
  );

  const handleInputChange = useCallback(
    (_: any, newValue: string) => {
      if (!newValue && liveSearch) {
        setListOptions([]);
      }
      setInputValue(newValue);
    },
    [liveSearch]
  );

  const handleSelectValue = useCallback(
    (_: any, newValue: any) => {
      /* istanbul ignore next */
      if (!newValue) return;
      setInputValue(newValue[optionKey]);
      /* istanbul ignore next */
      if (onSelectValue) onSelectValue(newValue);
    },
    [onSelectValue, optionKey]
  );

  const handleFilterOptions = useCallback(
    (options: any, { inputValue }: any) => {
      if (!inputValue) return options;
      return options.filter((option: any) => {
        const label = option[optionKey];
        return label.toLowerCase().includes(inputValue.toLowerCase());
      });
    },
    [optionKey]
  );

  const fetchOptions = useMemo(
    () =>
      debounce(async (inputValue?: string) => {
        if (!sourceData) return;
        try {
          runSafeUpdate(() => setIsLoading(true));
          const response = await sourceData(inputValue);
          runSafeUpdate(() => setListOptions(response));
        } catch (error) {
          console.error('Failed to fetch data:', error);
        } finally {
          runSafeUpdate(() => setIsLoading(false));
        }
      }, 300),
    [runSafeUpdate, sourceData]
  );

  useEffect(() => {
    if (inputValue && open && liveSearch) {
      fetchOptions(inputValue);
    }
  }, [inputValue, open, liveSearch, fetchOptions]);

  useEffect(() => {
    if (options.length > 0) {
      setListOptions(() => options);
    }
  }, [options]);

  useEffect(() => {
    fetchOptions();
  }, [sourceData, fetchOptions]);

  return (
    <Autocomplete
      data-testid='selector'
      groupBy={groupBy}
      open={open}
      value={value}
      onOpen={toggleOpen}
      onClose={toggleOpen}
      onChange={handleSelectValue}
      disablePortal
      fullWidth
      getOptionLabel={handleOptionLabel}
      isOptionEqualToValue={handleOptionValue}
      options={listOptions}
      onInputChange={handleInputChange}
      filterOptions={handleFilterOptions}
      noOptionsText={noOptionsText}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            value: inputValue,
            disableUnderline: true,
            classes: { input: '!placeholder-[#79828D]/70' },
            endAdornment: isLoading ? (
              <CircularProgress size={20} data-testid='selector-loading' />
            ) : (
              <InputAdornment position='end' className='cursor-pointer'>
                <AppIcon
                  icon='ArrowDown'
                  className='text-[#CACED8]'
                  onClick={toggleOpen}
                />
              </InputAdornment>
            ),
          }}
          size='small'
          variant='standard'
          type='text'
        />
      )}
    />
  );
};
