// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

export interface DateFilter {
  start_date?: string;
  end_date?: string;
}

export interface LocalPriceValues {
  price: number;
  currency: string;
}
export interface LatestPricesResp {
  ticker: string;
  price: number;
  status?: string;
  local_currency_values?: LocalPriceValues;
  currency?: string;
}

export interface BaseHolding {
  ticker: string;
  unrealized_capital_gains: number;
}

export interface UserParam {
  user: string;
}

export interface PaginationParams {
  assets_page: number;
  page: number;
}

export interface HistoricalData {
  start_date: string;
  end_date: string;
}

export interface Ts {
  date: string[];
  benchmark: number[];
  portfolio: number[];
}

export enum Operations {
  Sell,
  Buy,
}

export interface PaginatedResp<T> {
  data: T[];
  pages: number;
}

export interface PaginationParams {
  assets_page: number;
  page: number;
}
