// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { Button, Modal } from '@mui/material';
import { Heading, OptionsSelector } from 'components';
import { IconButton } from 'features/ui/icon-button';
import {
  AllBenchmarks,
  formattedBenchmarks,
} from 'features/utils/benchmark-formatter';
import { useBenchmarkList } from 'hooks';
import { FC } from 'react';

export interface BenchmarkListProps {
  value?: string;
  onChange?: (value: string) => void;
}

export const BenchmarkList: FC<BenchmarkListProps> = ({ value, onChange }) => {
  const {
    isOpen,
    benchmarkSelected,
    openModal,
    closeModal,
    modalBenchmark,
    setModalBenchmark,
    handleSaveBenchmark,
    benchmarks,
  } = useBenchmarkList({ value, onChange });

  let formattedBenchmarkSelected = 'S&P 500';

  /* istanbul ignore next */
  if (Object.keys(formattedBenchmarks).includes(value ?? benchmarkSelected))
    formattedBenchmarkSelected =
      formattedBenchmarks[(value ?? benchmarkSelected) as AllBenchmarks];

  const options = benchmarks?.map((benchmark) => ({
    value: benchmark.key,
    label: formattedBenchmarks[benchmark.ticker as AllBenchmarks],
  }));

  return (
    <div className='flex'>
      <Heading title={`Benchmark: ${formattedBenchmarkSelected}`} variant='h4' />
      <IconButton
        variant='icon'
        icon='Pen'
        size='lg'
        className='ml-2'
        onClick={openModal}
        data-testid='edit-benchmark'
      />

      <Modal open={isOpen}>
        <div className='absolute left-1/2 top-1/2 w-[300px] -translate-x-2/4 -translate-y-2/4 rounded-lg bg-white p-10'>
          <Heading
            variant='h2'
            title='Select benchmark'
            className='!flex justify-center pb-4'
          />
          <OptionsSelector
            optionsAvailable={options}
            onChangeValueSelected={setModalBenchmark}
            optionSelected={modalBenchmark}
            defaultText='Select Benchmark'
            selectType='gray'
          />

          <div className='grid grid-cols-2 gap-8 mt-8'>
            <Button variant='outlined' color='secondary' onClick={closeModal}>
              Cancel
            </Button>
            <Button
              color='secondary'
              onClick={handleSaveBenchmark}
              disabled={!modalBenchmark}
            >
              Next
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
