// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { PointTooltipProps } from '@nivo/line';
import { classNames } from 'features/utils/classnames';
import { numberToFixed } from 'features/utils/to-fixed-number';
import { FC, useMemo } from 'react';

export const PerformanceLineChartTooltip: FC<PointTooltipProps> = ({
  point: {
    serieId,
    data: { yFormatted, xFormatted, ...extraData },
  },
}) => {
  const variation = useMemo(
    () => (extraData as { variation?: number })?.variation,
    [extraData]
  );

  const date = useMemo(
    () =>
      new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(
        new Date(xFormatted)
      ),
    [xFormatted]
  );

  return (
    <div className='flex flex-col bg-white rounded-md p-1.5 font-poppins text-sm text-[#2D405A] text-center shadow-lg'>
      <span className='font-semibold'>{serieId}</span>
      <span>
        {yFormatted}&nbsp;&nbsp;
        {variation && (
          <span
            className={classNames({
              'text-[#0D9795]': variation > 0,
              'text-[#D80027]': variation <= 0,
            })}
          >
            {variation > 0 && '+'}
            {numberToFixed(variation)}%
          </span>
        )}
      </span>
      <span>{date}</span>
    </div>
  );
};
