// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Tabs as Root } from './tabs';
import { TabsContent as Content } from './tabs-content';
import { TabsList as List } from './tabs-list';
import { TabsTrigger as Trigger } from './tabs-trigger';
import { TabsSlot as Slot } from './tabs-slot';

export const Tabs = {
  Root,
  Content,
  List,
  Trigger,
  Slot,
};

export * from './tabs';
export * from './tabs-content';
export * from './tabs-list';
export * from './tabs-trigger';
export * from './tabs-slot';
export * from './use-tabs-controller';
