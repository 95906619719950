// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Button } from '@mui/material';
import { AppIcon, SvgIconName } from 'components/app-icon';
import { useDynamicDialog } from 'components/dynamic-dialog';
import { Heading } from 'components/heading';
import { useCallback } from 'react';

export interface ConfirmPopupProps {
  title: string;
  message: string;
  icon?: SvgIconName;
  onConfirm?: () => void;
  confirmText?: string;
  cancelText?: string;
  onCancel?: () => void;
}

export const useConfirmPopup = () => {
  const { openDialog, closeDialog } = useDynamicDialog();

  const confirmPopup = useCallback(
    (props: ConfirmPopupProps) => {
      /* istanbul ignore next */
      const handleConfirm = () => {
        props.onConfirm?.();
        closeDialog();
      };

      /* istanbul ignore next */
      const handleCancel = () => {
        props.onCancel?.();
        closeDialog();
      };

      openDialog(
        <ConfirmPopup
          {...props}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />,
        {
          showCloseButton: false,
          classes: { paper: '!w-[335px] !overflow-y-visible' },
        }
      );
    },
    [closeDialog, openDialog]
  );

  return confirmPopup;
};

export const ConfirmPopup = ({
  title,
  message,
  onConfirm,
  confirmText = 'Ok',
  cancelText = 'Cancel',
  onCancel,
  icon = 'ExclamationMark',
}: ConfirmPopupProps) => {
  return (
    <div className='text-center '>
      <div className='grid place-content-center h-20 w-20 absolute -top-10 right-[calc(50%_-_40px)] bg-white rounded-full border border-[#1072BA] '>
        <AppIcon icon={icon} size='2xl' color='main' />
      </div>
      <Heading variant='h2' className='!mb-5 !mt-6' title={title} />
      <p className='text-[#515F70] px-2 text-pretty'>{message}</p>
      <div className='grid grid-cols-2 gap-8 mt-10'>
        <Button variant='outlined' color='secondary' onClick={onCancel}>
          {cancelText}
        </Button>
        <Button color='secondary' onClick={onConfirm}>
          {confirmText}
        </Button>
      </div>
    </div>
  );
};
