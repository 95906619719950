// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AppIcon } from 'components/app-icon';
import InputNumber from 'rc-input-number';
import { FC, FocusEventHandler, KeyboardEventHandler } from 'react';

export interface InputPercentageProps {
  value: number;
  className?: string;
  onChange: (value: number | null) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  max?: number;
  step?: number;
  precision?: number;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  controls?: boolean;
}

export const InputPercentage: FC<InputPercentageProps> = ({
  value,
  onChange,
  max = 100,
  step = 0.5,
  className = '',
  precision = 2,
  onBlur,
  onKeyPress,
  controls,
  ...props
}) => {
  return (
    <InputNumber
      className={className}
      data-testid='input-percentage'
      value={value}
      min={0}
      max={max}
      step={step}
      precision={precision}
      formatter={(value, { userTyping, input }) =>
        userTyping ? input : `${value}%`
      }
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyPress}
      controls={controls}
      decimalSeparator='.'
      upHandler={
        <div className='absolute bottom-0 right-3' data-testid='increment'>
          <AppIcon icon='CaretUp' color='disabled' size='sm' />
        </div>
      }
      downHandler={
        <div className='absolute top-0 right-3' data-testid='decrement'>
          <AppIcon icon='CaretDown' color='disabled' size='sm' />
        </div>
      }
      changeOnWheel
      {...props}
    />
  );
};
