// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AppIcon } from 'components/app-icon';
import { InfoTooltip, PopupsName, PopupsRegistry } from 'features/popups';
import { useInfoPopup } from 'hooks/use-info-popup';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';

export const PopupButton: FC<{
  popupName: PopupsName;
  className?: string;
  tooltip?: boolean;
}> = ({ popupName, className = '', tooltip }) => {
  const infoPopups = useInfoPopup();
  const isPopupNameValid = useMemo(() => PopupsRegistry.has(popupName), [popupName]);

  const handlePopupClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      popupName && infoPopups(popupName);
    },
    [infoPopups, popupName]
  );

  return isPopupNameValid ? (
    tooltip ? (
      <InfoTooltip title={popupName}>
        <button
          data-testid={`${popupName}-info`}
          className={`cursor-pointer px-2 py-1 ${className}`}
        >
          <AppIcon icon='Info' size='base' color='disabled' />
        </button>
      </InfoTooltip>
    ) : (
      <button
        title='Open Info Popup'
        data-testid={`${popupName}-info`}
        className={`cursor-pointer px-2 py-1 ${className}`}
        onClick={handlePopupClick}
      >
        <AppIcon icon='Info' size='base' color='disabled' />
      </button>
    )
  ) : null;
};
