// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import {
  BrokerAccountResp,
  AccountInfo,
  TransactionInfo,
  Broker,
} from 'api/interfaces/brokerage/account';
import {
  BrokerConfiguration,
  BrokerCredentials,
  BrokerCredentialsResp,
  BrokerageCredentials,
} from 'api/interfaces/brokerage/commons';
import { brokerageApi } from './brokerage-api';

export const accountApi = brokerageApi.injectEndpoints({
  endpoints: (build) => ({
    account: build.query<
      BrokerAccountResp,
      { params: BrokerConfiguration; brokerCredentials: BrokerageCredentials }
    >({
      query: ({ params, brokerCredentials }) => ({
        url: '/v2/account/',
        method: 'POST',
        params: params,
        body: brokerCredentials,
      }),
      providesTags: [{ type: 'Account', id: 'Info' }],
    }),

    accountAll: build.query<AccountInfo[], { params: BrokerConfiguration }>({
      query: ({ params }) => ({
        url: '/v2/account/all/',
        method: 'POST',
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ account_id }) => ({
                type: 'Account' as const,
                id: account_id,
              })),
              { type: 'Account', id: 'List' },
            ]
          : [{ type: 'Account', id: 'List' }],
    }),

    accountTransaction: build.query<
      TransactionInfo[],
      { params: BrokerConfiguration; payload: BrokerageCredentials }
    >({
      query: ({ params, payload }) => ({
        url: '/v2/account/transaction/',
        method: 'POST',
        params,
        body: payload,
      }),
      providesTags: [{ type: 'Account', id: 'Transaction' }],
    }),

    postBrokerCredentials: build.mutation<
      BrokerCredentialsResp,
      { brokerKey: Broker; brokerCredentials: BrokerCredentials }
    >({
      query: ({ brokerCredentials, brokerKey }) => ({
        url: '/v2/account/brokers/credentials/',
        method: 'POST',
        params: {
          broker_key: brokerKey,
        },
        body: brokerCredentials,
      }),
      invalidatesTags: ['Account'],
    }),
  }),
});
