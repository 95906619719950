// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store/store';
import {
  optimizationConfigAdapter,
  universeFiltersAdapter,
} from './parameters-slice';

const universeFiltersSelectors = universeFiltersAdapter.getSelectors();

const optimizationConfigSelectors = optimizationConfigAdapter.getSelectors();

export const selectParameters = createSelector(
  (state: AppState) => state.parameters,
  (parameters) => parameters
);

export const selectOptimizationConfig = createSelector(
  selectParameters,
  (parameters) => parameters.optimizationConfig
);

export const selectOptimizationConfigValues = createSelector(
  [selectOptimizationConfig, (_, id: string) => id],
  (state, id) => optimizationConfigSelectors.selectById(state, id)!
);

export const selectHistoricalWindow = createSelector(
  selectParameters,
  (parameters) => parameters.historicalWindow
);

export const selectUniverse = createSelector(
  selectParameters,
  (parameters) => parameters.universe
);

export const selectUniverseFilters = createSelector(
  [selectUniverse, (_, id: string) => id],
  (state, id) => universeFiltersSelectors.selectById(state, id)
);

export const selectBenchmark = createSelector(
  [selectOptimizationConfig, (_, id: string) => id],
  (state, id) => optimizationConfigSelectors.selectById(state, id)!.benchmark
);

export const selectObjectiveKey = createSelector(
  [selectOptimizationConfig, (_, id: string) => id],
  (state, id) => optimizationConfigSelectors.selectById(state, id)!.objective.key
);

export const selectSectorsFilter = createSelector(
  [selectUniverse, (_, id: string) => id],
  (state, id) => universeFiltersSelectors.selectById(state, id)?.sectors_filter ?? []
);

export const selectTaxHarvesting = createSelector(
  selectParameters,
  (parameters) => parameters.taxHarvesting
);

export const selectTaxObjective = createSelector(
  selectTaxHarvesting,
  (taxHarvesting) => taxHarvesting.taxObjective
);

export const selectRebalanceConfig = createSelector(
  (state: AppState) => state.parameters.rebalance,
  (rebalance) => rebalance
);

export const selectRebalanceAmount = createSelector(
  selectRebalanceConfig,
  (rebalance) => rebalance.amount
);
