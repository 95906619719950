// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { ETF, MutualFund } from 'api/interfaces/ai/screener';
import { formatToMoney } from 'features/utils/format-to-money';
import { numberToFixed } from 'features/utils/to-fixed-number';
import { useMemo } from 'react';
import { AssetProfileProps } from '../components/asset-profile';
import { assetsApi } from 'store/api/ai/assets-api';

type AssetInfo = MutualFund | ETF | any;

export const useAssetProfile = ({ assetType, ticker }: AssetProfileProps) => {
  const {
    data: [data] = [] as unknown as [AssetInfo],
    isLoading,
    error,
  } = assetsApi.useAssetsInfoQuery({
    params: { detail: true },
    payload: [ticker],
    assetType,
  });

  const infoProperties = useMemo(() => {
    return [
      { label: 'Asset Manager', value: data?.manager ?? data?.etf_company ?? '-' },
      {
        label: 'Web',
        value: (
          <>
            {data?.website ? (
              <a
                href={data.website}
                target='_blank'
                rel='noreferrer'
                className='hover:underline hover:underline-offset-4 hover:text-[#243750]/80'
              >
                {data.website}
              </a>
            ) : (
              '-'
            )}
          </>
        ),
      },
      { label: 'Asset Class', value: data?.asset_class ?? '-' },
      { label: 'Segment', value: data?.investment_segment ?? '-' },
      {
        label: 'Benchmark / Index',
        value: data?.benchmark ?? data?.tracking_index ?? '-',
      },
    ];
  }, [data]);

  const statisticsProperties = useMemo(
    () =>
      [
        {
          section: 'FUNDAMENTALS',
          isVisible: true,
          properties: [
            { label: 'ISIN /TICKER', value: data?.ticker ?? '-' },
            { label: 'CURRENCY', value: data?.currency ?? '-' },
            {
              label: 'CLASS ASSETS',
              value: data?.aum ? formatToMoney(data?.aum) : formatToMoney(data?.nav),
            },
          ],
        },
        {
          section: 'FUNDAMENTALS',
          isVisible: assetType === 'etf',
          properties: [
            { label: 'PE', value: data?.pe ? `${numberToFixed(data.pe, 1)}` : '-' },
            { label: 'PB', value: data?.pb ? `${numberToFixed(data.pb, 1)}` : '-' },
            {
              label: 'YIELD',
              value: data?.dividend_yield
                ? `${numberToFixed(data.dividend_yield, 1)}%`
                : '-',
            },
          ],
        },
        {
          section: 'PERFORMANCE',
          isVisible: true,
          properties: [
            {
              label: 'YTD Returns',
              value: percentFormat(data?.ytd_return),
            },
            {
              label: '1Y Returns',
              value: percentFormat(data?.y1_return),
            },
            {
              label: '3Y Returns',
              value: percentFormat(data?.y3_return),
            },
            {
              label: '5Y Returns',
              value: percentFormat(data?.y5_return),
            },
          ],
        },
        {
          section: 'RISK',
          isVisible: true,
          properties: [
            { label: '3Y VOLATILITY', value: percentFormat(data?.y3_vol) },
            {
              label: 'MAX DRAWDOWN',
              value: percentFormat(data?.max_draw_down),
            },
            {
              label: 'CONCENTRATION TOP 10',
              value: percentFormat(
                data?.top_holdings?.reduce(
                  (acc: number, holding: any) => acc + holding.concentration,
                  0
                )
              ),
            },
          ],
        },
        {
          section: 'COST',
          isVisible: true,
          properties: [
            {
              label: 'TOTAL EXPENSE RATIO',
              value: data?.expenses_ratio ? `${data.expenses_ratio}` : '-',
            },
            {
              label: 'FEE 12B1',
              value: '1.5%',
            },
            {
              label: 'FRONT LOAD',
              value: '0.0%',
            },
            {
              label: 'MIN INVESTMENT',
              value: '$ 1,000',
            },
          ],
        },
      ].filter((section) => section.isVisible),
    [data, assetType]
  );

  return {
    error,
    data,
    loading: isLoading,
    infoProperties,
    statisticsProperties,
  };
};

const percentFormat = (value: number | undefined) =>
  typeof value === 'number'
    ? new Intl.NumberFormat('en-US', {
        style: 'percent',
        maximumFractionDigits: 1,
      }).format(value)
    : '-';
