// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { equityApi } from 'api/brokerage/equity-api';
// import { cryptoApi } from 'api/brokerage/crypto-api';
import { LatestPricesResp } from 'api/interfaces/commons';

export const getLatestPrices = async (
  broker: any,
  data: string[]
): Promise<LatestPricesResp[]> => {
  const response: LatestPricesResp[] = [];

  // const dataCrypto = data.filter((ticker) => ticker.includes('-USD'));
  const dataStocks = data.filter((ticker) => !ticker.includes('-USD'));

  const params = {
    broker_key: broker.brokerConnected.toLowerCase(),
    type_account: broker.accountTypeSelect,
  };

  if (dataStocks.length > 0) {
    const resp = await (broker.brokerConnected
      ? equityApi.postPrices(params, dataStocks)
      : equityApi.citecPrice(dataStocks));

    resp.forEach((assetPrice) => {
      response.push({
        ...assetPrice,
        price: assetPrice.price ?? 0.000001,
      });
    });
  }
  return response;
};
