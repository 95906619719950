// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import {
  MontecarloApiProps,
  MontecarloInsightsPayload,
  MontecarloSimulationResp,
} from 'api/interfaces/ai/montecarlo';
import { aiApi } from './ai-api';
import { PortfolioInsight } from 'api/interfaces/copilot/portfolio';

export const montecarloApi = aiApi.injectEndpoints({
  endpoints: (build) => ({
    simulation: build.query<MontecarloSimulationResp[], MontecarloApiProps>({
      query: ({ payload }) => ({
        url: '/montecarlo/simulation/',
        method: 'POST',
        body: payload,
      }),
      providesTags: [{ type: 'Montecarlo', id: 'Simulation' }],
    }),
    insights: build.query<
      PortfolioInsight[],
      { payload: MontecarloInsightsPayload }
    >({
      query: ({ payload }) => ({
        url: '/reporting/assets/montecarlo/insights/',
        method: 'POST',
        body: payload,
      }),
      providesTags: [{ type: 'Montecarlo', id: 'Insights' }],
    }),
  }),
});
