// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { ReactNode, useMemo } from 'react';
import {
  Column,
  useTable,
  useFlexLayout,
  useSortBy,
  usePagination,
  useExpanded,
} from 'react-table';
import { classNames } from 'features/utils/classnames';
import { AppIcon } from 'components/app-icon';

export type InitialSortBy = {
  id: string;
  desc: boolean;
}[];
interface TableProps<T extends object> {
  children?: ReactNode;
  columns: Column<T>[];
  data: T[];
  messageNoData?: string;
  showMessageNoData?: boolean;
  sortable?: boolean;
  showPagination?: boolean;
  noMargin?: boolean;
  pageSize?: number;
  rowValidator?: string;
  initialSortBy?: InitialSortBy;
}

export const Table = <T extends object>({
  children,
  columns,
  data,
  messageNoData = 'There is no data',
  showMessageNoData = true,
  showPagination = false,
  noMargin = false,
  pageSize = 10,
  rowValidator = '',
  initialSortBy,
}: TableProps<T>) => {
  const dataMemoized = useMemo(() => data, [data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnsMemoized = useMemo(() => columns, [data]);

  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const sortBy = initialSortBy ?? [{ id: 'options' }];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    prepareRow,
  } = useTable(
    {
      columns: columnsMemoized,
      data: dataMemoized,
      defaultColumn,
      initialState: {
        pageSize,
        sortBy,
      },
    },
    useFlexLayout,
    useSortBy,
    useExpanded,
    usePagination
  );

  const noData = dataMemoized.length === 0;

  let rowsData = rows;
  let pageIndex = 0;

  if (showPagination) {
    rowsData = page;
    pageIndex = state.pageIndex;
  }

  return (
    <>
      {noData && showMessageNoData ? (
        <div className='p-6 flex justify-center items-center'>
          <span
            className='text-base text-[#79828D] font-semibold'
            data-testid='message-no-data'
          >
            {messageNoData}
          </span>
        </div>
      ) : (
        <div className={noMargin ? 'rounded-lg' : 'rounded-lg overflow-auto !my-4'}>
          <table {...getTableProps()} className=' w-full' data-testid='table-test'>
            <thead className='bg-[#EDF0F9]'>
              {
                // Loop over the header rows
                headerGroups.map((headerGroup) => (
                  // Apply the header row props
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column) => (
                        // Apply the header cell props
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className='py-3 px-4 text-[#56606D] font-normal uppercase'
                        >
                          <div className='flex h-full items-end'>
                            {
                              // Render the header
                              column.render('Header')
                            }
                            {column.id !== 'options' && (
                              <div className=' text-neutral-400 flex flex-col mx-2'>
                                <div className='flex flex-col justify-center items-center'>
                                  <AppIcon
                                    icon={
                                      column.isSorted && !column.isSortedDesc
                                        ? 'SortAsc'
                                        : 'SortDesc'
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </th>
                      ))
                    }
                  </tr>
                ))
              }
            </thead>

            <tbody {...getTableBodyProps()}>
              {children && (
                <tr role='row' className='bg-[#CBEBEB] children-table'>
                  <td>{children}</td>
                </tr>
              )}

              {
                // Loop over the table rows
                rowsData.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  const isObject = typeof row.original === 'object';

                  const hasSelectedAccount =
                    isObject &&
                    'account_id' in row.original &&
                    rowValidator == row.original.account_id;

                  const noTraderPrice =
                    isObject &&
                    'local_currency_values' in row.original &&
                    (row.original.local_currency_values as { price: number })
                      .price === null;

                  return (
                    // Apply the row props
                    <tr
                      className={classNames('hover:bg-[#1072BA21] bg-[#EDF0F9]', {
                        'bg-red-200': noTraderPrice,
                        'selected-account': hasSelectedAccount,
                      })}
                      {...row.getRowProps()}
                    >
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td
                              {...cell.getCellProps()}
                              className='py-3 px-4 text-neutral-500'
                            >
                              {
                                // Render the cell contents
                                cell.render('Cell')
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
          {showPagination ? (
            <>
              <span className='inline flex justify-center my-4 text-[#2D3745]'>
                {`Page ${pageIndex + 1} of ${pageOptions.length}`}
              </span>
              <div className='inline flex justify-center items-center'>
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                  data-testid='previous'
                  className='pagination_button rounded-l'
                >
                  Previous
                </button>
                <button
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                  data-testid='next'
                  className='pagination_button rounded-r'
                >
                  Next
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};
