// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Button,
  CircularProgress,
  Paper,
  PaperProps,
  Tooltip,
} from '@mui/material';
import { AppIcon, AssetBrand } from 'components';
import { capitalizeString } from 'features/utils/capitalize-string';
import { useAppNavigate, useConst, useDebounce } from 'hooks';
import {
  Dispatch,
  FC,
  HTMLAttributes,
  MouseEventHandler,
  ReactElement,
  SetStateAction,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { VariableSizeList as List, ListChildComponentProps } from 'react-window';
import { AssetsSearchResp } from 'api/interfaces/ai/assets';
import { classNames } from 'features/utils/classnames';
import { getComparison, selectComparisonTickers } from 'store/slices/my-research';
import { useAppDispatch, useAppSelector } from 'store/store';
import { assetsApi } from 'store/api/ai/assets-api';

type ItemData = [
  HTMLAttributes<HTMLLIElement>,
  AssetsSearchResp,
  AutocompleteRenderOptionState,
  (ticker: string) => void,
  boolean
];

type RowProps = ListChildComponentProps<Array<ItemData>>;

const Row: FC<RowProps> = ({ data, index, style }) => {
  const dataSet = data[index];

  const props = dataSet[0];

  const option = dataSet[1];

  const onCompare = dataSet[3];

  const disabled = dataSet[4];

  return (
    <li
      {...props}
      key={index}
      style={style}
      className={classNames(props.className, 'items-center')}
      onClick={(e) => e.preventDefault()}
    >
      <div className='flex flex-1 gap-x-2 text-base'>
        <AssetBrand
          ticker={option.ticker}
          assetType={option.asset_type}
          logoUrl={option.url_logo}
        />
        <span>-</span>
        <span className='line-clamp-1'>{capitalizeString(option.name)}</span>
        {option.asset_type !== 'stock' && (
          <Tooltip
            title={disabled ? 'Already added to comparison' : 'Add to comparison'}
          >
            <span className='ml-auto'>
              <Button
                type='button'
                className='!rounded-3xl'
                variant='contained'
                color='secondary'
                size='small'
                data-testid={`compare-button-${option.ticker}`}
                disabled={disabled}
                onClick={() => onCompare(option.ticker)}
              >
                Compare
              </Button>
            </span>
          </Tooltip>
        )}
      </div>
    </li>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);

  return <div ref={ref} {...props} {...outerProps} />;
});

const StateContext = createContext<[string, Dispatch<SetStateAction<string>>]>([
  '',
  () => {},
]);

const ListboxComponent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(({ children, ...props }, ref) => {
  const ITEM_HEIGHT = useConst(35);

  const DISPLAYED_OPTIONS = useConst(10);

  const itemData = useMemo(() => (children as ReactElement[]) ?? [], [children]);

  const itemCount = useMemo(() => itemData.length, [itemData.length]);

  const listHeight = useMemo(
    () =>
      itemCount > DISPLAYED_OPTIONS
        ? ITEM_HEIGHT * DISPLAYED_OPTIONS
        : ITEM_HEIGHT * itemCount,
    [DISPLAYED_OPTIONS, ITEM_HEIGHT, itemCount]
  );

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={props}>
        <List
          itemData={itemData as unknown as ItemData[]}
          itemCount={itemCount}
          width='100%'
          height={listHeight}
          itemSize={() => ITEM_HEIGHT}
          innerElementType='ul'
          outerElementType={OuterElementType}
          className='!overflow-x-hidden'
        >
          {Row}
        </List>
      </OuterElementContext.Provider>
    </div>
  );
});

const PaperComponent = forwardRef<HTMLDivElement, PaperProps>(
  ({ children, ...props }, ref) => {
    const [filter, setFilter] = useContext(StateContext);

    const filters = useMemo(
      () => [
        { value: '', label: 'All' },
        { value: 'stock', label: 'Stocks' },
        { value: 'etf', label: 'ETFs' },
        { value: 'mutual_fund', label: 'Mutual Funds' },
      ],
      []
    );

    const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
      (e) => {
        setFilter(e.currentTarget.value);
      },
      [setFilter]
    );

    return (
      <Paper {...props} ref={ref} onMouseDown={(e) => e.preventDefault()}>
        <div className='flex gap-x-4 py-2 px-3 bg-[#f5f6fa]'>
          {Array.from(filters, ({ value, label }, index) => (
            <Button
              key={index}
              value={value}
              className='!rounded-3xl'
              type='button'
              role='tab'
              size='medium'
              variant={value === filter ? 'contained' : 'outlined'}
              onClick={onClick}
              aria-selected={value === filter}
            >
              {label}
            </Button>
          ))}
        </div>

        {children}
      </Paper>
    );
  }
);

export const AssetsSearch: FC = () => {
  const dispatch = useAppDispatch();

  const navigate = useAppNavigate();

  const [open, setOpen] = useState(true);

  const [searchValue, setSearchValue] = useState('');

  const filterState = useState('');

  const debouncedValue = useDebounce(searchValue, 1000);

  const { data, isFetching } = assetsApi.useSearchQuery(
    { name: debouncedValue, ticker: debouncedValue },
    { skip: !debouncedValue }
  );

  const options = useMemo(() => (isFetching ? [] : data ?? []), [data, isFetching]);

  const isOpen = useMemo(
    () => isFetching || (!!debouncedValue && open),
    [debouncedValue, isFetching, open]
  );

  const tickers = useAppSelector(selectComparisonTickers);

  const filterOptions = useCallback(
    (options: AssetsSearchResp[]) =>
      isFetching
        ? []
        : options.filter(({ asset_type }) => asset_type.includes(filterState[0])),
    [filterState, isFetching]
  );

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onCompare = useCallback(
    (ticker: string) => {
      dispatch(getComparison(tickers.concat(ticker)));

      onClose();

      navigate({
        pathname: '/research',
        search: '?tab=comparison',
      });
    },
    [dispatch, navigate, onClose, tickers]
  );

  return (
    <div className='w-full max-w-[600px] mr-auto'>
      <StateContext.Provider value={filterState}>
        <Autocomplete
          data-testid='assets-search'
          open={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          fullWidth
          options={options}
          onInputChange={(_, value) => {
            setSearchValue(value);
          }}
          filterOptions={filterOptions}
          disableCloseOnSelect
          selectOnFocus={false}
          noOptionsText='No results'
          loading={isFetching}
          loadingText='Searching...'
          ListboxComponent={ListboxComponent}
          PaperComponent={PaperComponent}
          disableListWrap
          clearOnBlur={false}
          getOptionLabel={(option) => option.ticker}
          renderOption={(props, option, state) =>
            [
              props,
              option,
              state.index,
              onCompare,
              tickers.includes(option.ticker),
            ] as React.ReactNode
          }
          renderInput={(params) => (
            <div
              className='input-ticker relative !w-full group'
              data-testid='search-ticker-input'
              ref={params.InputProps.ref}
            >
              <div className='absolute top-3 left-4'>
                <AppIcon
                  icon='Search'
                  size='md'
                  color='disabled'
                  className='group-focus-within:!text-primary'
                />
              </div>
              <input
                {...params.inputProps}
                className='input-rounded pl-10'
                name='search_text'
                placeholder='Search'
                required
                type='text'
                autoComplete='off'
                data-testid='search-input'
              />
              {isFetching && (
                <CircularProgress size={16} className='absolute top-2 right-4' />
              )}
            </div>
          )}
        />
      </StateContext.Provider>
    </div>
  );
};
