// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

type DateFormat = 'standard' | 'short' | 'long' | 'full' | 'medium' | 'year';

const dateFormatOptions: Record<DateFormat, Intl.DateTimeFormatOptions> = {
  standard: { day: '2-digit', month: '2-digit', year: 'numeric' }, // 02/09/2024
  short: { day: '2-digit', month: '2-digit', year: '2-digit' }, // 02/09/24
  medium: { dateStyle: 'medium' }, // Feb 9, 2024
  long: { dateStyle: 'long' }, // February 9, 2024
  full: { dateStyle: 'full' }, // Friday, February 9, 2024
  year: { year: 'numeric' }, // 2024
};

/**
 * Formats a date string into a specified format using Intl.DateTimeFormat.
 * @param dateString The date string to format in the format "YYYY-MM-DD".
 * @param format The format to use. Defaults to "standard".
 * @param options Additional options to pass to Intl.DateTimeFormat.
 * @returns The formatted date string.
 */
export const formatDateString = (
  dateString: string | null | undefined,
  format: DateFormat = 'standard',
  options?: Intl.DateTimeFormatOptions
) => {
  if (typeof dateString !== 'string') return dateString;

  const datePattern = /^(\d{4})-(\d{2})-(\d{2})/;
  const match = datePattern.exec(dateString);

  if (match) {
    const [, year, month, day] = match;
    const date = new Date(`${month}, ${day} ${year}`);
    const formatOptions = options ?? dateFormatOptions[format];
    return date.toLocaleDateString('en-US', formatOptions);
  } else {
    return dateString;
  }
};
