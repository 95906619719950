// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { Modal } from '@mui/material';
import { CloseButton } from 'components/dynamic-dialog';
import { EditableText } from './editable-text';
import { EditPortfolioConfig } from 'features/my-research';
import { Heading } from 'components/heading';
import { PortfolioPositions } from 'features/portfolio';
import { IconButton } from 'features/ui/icon-button';
import { FC } from 'react';
import { useAddPortfolioModal } from './use-add-portfolio-modal';

export interface AddPortfolioModalProps {
  open: boolean;
  onClose?: VoidFunction;
  editPortfolioConfig: EditPortfolioConfig;
  isEdit: boolean;
}

export const AddPortfolioModal: FC<AddPortfolioModalProps> = ({
  open,
  onClose,
  editPortfolioConfig,
  isEdit,
}) => {
  const {
    positions,
    setPositions,
    filteredPositions,
    updatePosition,
    onDeletePositions,
    onSavePortfolio,
    onEditPortfolio,
    onCloseAddPortfolioModal,
    handleEditPortfolioName,
    hasChanges,
  } = useAddPortfolioModal({ onClose, editPortfolioConfig });

  const { portfolioName } = editPortfolioConfig;

  return (
    <Modal open={open} sx={{ overflowY: 'scroll' }} disableEnforceFocus>
      <div className='justify-center items-center max-w-[1205px] !rounded-2xl max-h-[565px] inset-0 absolute m-auto bg-white'>
        <div className='justify-between items-center rounded-lg relative !p-5 bg-white'>
          <CloseButton onClick={onCloseAddPortfolioModal} />
          <div className='px-6 mt-4'>
            <div className='flex justify-between'>
              <Heading
                variant='h1'
                title={isEdit ? 'Edit Portfolio' : 'Portfolio Upload'}
              />
              <IconButton
                className='mr-4'
                icon='Save'
                variant='button'
                disabled={!positions.length || !hasChanges}
                onClick={isEdit ? onEditPortfolio : onSavePortfolio}
                data-testid='save-portfolio-button'
              />
            </div>
            <div className='relative'>
              {isEdit && (
                <EditableText
                  text={portfolioName}
                  onEdit={handleEditPortfolioName}
                  className='absolute top-20 left-8 z-10'
                />
              )}
              <PortfolioPositions
                filteredDataTable={filteredPositions}
                handleDeleteAllPositions={onDeletePositions}
                invalidTickers={[]}
                isSomeInvalidTicker={false}
                positionsTableData={positions}
                setPositionTableData={setPositions}
                portfolio={[]}
                typeAsset=''
                updateMyData={updatePosition}
                workflowType=''
                tableHeaderText={isEdit ? '' : 'Insert Portfolio'}
                scroll
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
