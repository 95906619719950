// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { FC, PropsWithChildren, ReactNode, createContext, useContext } from 'react';
import type { DynamicDialogConfig } from './use-dialog-actions';
import { useDialogActions } from './use-dialog-actions';

const EMPTY_VALUE = Symbol('@@empty_value');

interface DynamicDialogContextValue {
  dialogConfig: DynamicDialogConfig;
  openDialog: (content: ReactNode, props: DynamicDialogConfig['props']) => void;
  closeDialog: () => void;
}

/**
 * Context for managing dynamic dialogs.
 */
export const DynamicDialogContext = createContext(
  EMPTY_VALUE as unknown as DynamicDialogContextValue
);

/**
 * Provides the context for dynamic dialog actions.
 * @param children - The child components.
 * @returns The dynamic dialog provider component.
 */
export const DynamicDialogProvider: FC<PropsWithChildren> = ({ children }) => {
  const dialogActions = useDialogActions();

  return (
    <DynamicDialogContext.Provider value={dialogActions}>
      {children}
    </DynamicDialogContext.Provider>
  );
};

/**
 * Custom hook that provides access to the dynamic dialog context.
 * @returns The dynamic dialog context.
 * @throws {Error} If used outside of a DynamicDialogContext.
 */
export const useDynamicDialog = () => {
  const context = useContext(DynamicDialogContext);

  if (context === (EMPTY_VALUE as unknown as DynamicDialogContextValue)) {
    throw new Error(
      'useDynamicDialog must be used within a DynamicDialogProvider. Ensure that your component is wrapped in a <DynamicDialogProvider>.'
    );
  }

  return context;
};
