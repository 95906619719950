// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import { selectAvailableParametersBenchmarks } from 'store/slices/available-parameters';
import {
  selectBenchmark,
  updateOptimizationConfig,
  updateUniverseFilters,
} from 'store/slices/parameters';

export const useSelectUniverse = (id: string) => {
  const dispatch = useDispatch();
  const objectives = useAppSelector(selectAvailableParametersBenchmarks);

  const benchmarkSelected = useAppSelector((state) => selectBenchmark(state, id));

  const handleSelectBenckmark = (benchmark: string) => {
    const propsUniverseTicker = objectives.find(
      (_benchmark) => _benchmark.ticker === benchmark
    );
    if (!propsUniverseTicker) return;

    const { description: universe_base } = propsUniverseTicker;
    dispatch(updateUniverseFilters([id, { universe_base }]));
    dispatch(updateOptimizationConfig([id, { benchmark }]));
  };

  const handleSelectUniverse = (universeName: string) => {
    const propsUniverseTicker = objectives.find(
      (universe) => universe.description === universeName
    );

    if (!propsUniverseTicker) return;

    const { ticker: benchmark, description: universe_base } = propsUniverseTicker;
    dispatch(updateUniverseFilters([id, { universe_base }]));
    dispatch(updateOptimizationConfig([id, { benchmark }]));
  };

  const allowedUniverses = [
    'Invesco QQQ Trust Series 1',
    'ISHARES RUSSELL 1000 ETF',
    'iShares Russell 2000',
    'ISHARES RUSSELL 3000 ETF',
    'SPDR S&P 500',
    'Vanguard Total Stock Market ETF',
    'Xtrackers S&P 500 ESG ETF',
  ];

  return {
    allowedUniverses,
    benchmarkSelected,
    handleSelectBenckmark,
    handleSelectUniverse,
  };
};
