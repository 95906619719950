// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { Button } from '@mui/material';
import { Heading, Slider } from 'components';
import { BasePortfolio } from 'features/utils/interfaces/base-portfolio';
import { Holding } from 'features/utils/interfaces/redux/portfolio-rebalance-state';
import { numberToFixed } from 'features/utils/to-fixed-number';
import { useAppNavigate, useSafeUpdate } from 'hooks';
import { useCallback, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { Paths } from 'routes';
import {
  setCurrentPortfolioPositions,
  setPortfolioLoaded,
} from 'store/slices/portfolio-positions/portfolio-positions-slice';

interface AddCashProps {
  dataPortfolio: BasePortfolio;
  portfolioName?: string;
  to: Paths;
}

export const AddCash = ({ dataPortfolio, portfolioName, to }: AddCashProps) => {
  const dispatch = useDispatch();
  const navigate = useAppNavigate();
  const runSafeUpdate = useSafeUpdate();

  const [investment, setInvestment] = useState(50000);

  const MAX_VAL = 10000000;

  const sendPortfolioToLoader = useCallback(() => {
    if (!dataPortfolio.ticker.length) {
      return;
    }

    const ticker: string[] = [];
    const holdings: Holding[] = [];
    dataPortfolio.ticker.forEach((symbol, idx) => {
      const outstandingBalance = numberToFixed(
        dataPortfolio.weight[idx] * investment,
        2
      );
      const unrealizedCapitalGains = dataPortfolio.unrealized_capital_gains[idx];
      ticker.push(symbol);
      holdings.push({
        ticker: symbol,
        price: 1,
        qty: outstandingBalance,
        unrealized_capital_gains: unrealizedCapitalGains,
      });
    });

    runSafeUpdate(() => {
      dispatch(setCurrentPortfolioPositions(holdings));

      dispatch(setPortfolioLoaded(portfolioName as string));
    });

    navigate(to);
  }, [
    dataPortfolio.ticker,
    dataPortfolio.weight,
    dataPortfolio.unrealized_capital_gains,
    runSafeUpdate,
    navigate,
    to,
    investment,
    dispatch,
    portfolioName,
  ]);

  const onChangeInputValue = useCallback(
    (value: number) => setInvestment(value),
    []
  );

  const onChangeCommitted = useCallback(
    (value: number) => setInvestment(value as number),
    []
  );

  return (
    <div className='row-boxes-area-investment bg-white min-w-[320px]'>
      <div className='box !bg-white py-3'>
        <Heading title='How much money do you want to invest?' variant='h2' />

        <div className='inner_box !bg-[#EDF0F9]'>
          <Heading variant='h3' title='Investment' />

          <NumericFormat
            value={investment}
            thousandSeparator={true}
            min={0}
            maxLength={11}
            isAllowed={({ value }) => {
              return Number(value) <= MAX_VAL;
            }}
            onValueChange={({ value }) => onChangeInputValue(Number(value))}
            prefix='$'
            placeholder='investment-input'
            className='price_area w-full mb-2 !text-base !font-medium'
            id='investment-input'
            data-testid='add-cash-modal-input'
          />
          <Slider
            valueLabelDisplay='off'
            onChange={(_, value) => onChangeCommitted(value as number)}
            onChangeCommitted={(_, value) => onChangeCommitted(value as number)}
            value={investment}
            min={5000}
            max={MAX_VAL}
            step={1000}
            data-testid='add-cash-modal-slider'
          />
        </div>
        <div className='flex justify-end pt-4'>
          <Button
            color='secondary'
            onClick={sendPortfolioToLoader}
            data-testid='add-cash-next-id'
          >
            Invest
          </Button>
        </div>
      </div>
    </div>
  );
};
