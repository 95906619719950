// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AssetTypeClassfier, AssetsClassifierResp } from 'api/interfaces/ai/assets';

export type InvestmentAssetType = 'stock' | 'etf' | 'mutual_fund' | 'other';
export type GroupedPositions = Record<
  InvestmentAssetType,
  Array<AssetsClassifierResp & { asset_class: InvestmentAssetType }>
>;

const categories: Record<InvestmentAssetType, Array<AssetTypeClassfier>> = {
  stock: ['us_stocks', 'eu_stocks', 'apac_stocks', 'latam_stocks', 'na_stocks'],
  etf: ['us_etfs', 'inter_etfs'],
  mutual_fund: ['us_mutual_funds', 'eu_mutual_funds'],
  other: ['usd_crypto', 'Unknown'],
};

export const assetTypeMap: Record<AssetTypeClassfier, InvestmentAssetType> =
  {} as any;
Object.entries(categories).forEach(([category, values]) =>
  values.forEach(
    (assetType) => (assetTypeMap[assetType] = category as InvestmentAssetType)
  )
);

/**
 * Groups an array of assets by their asset type.
 *
 * @param assets An array of assets to group.
 * @returns An object containing arrays of assets grouped by their asset type.
 */
export const groupPositionsByAssetType = (
  assets: AssetsClassifierResp[]
): GroupedPositions => {
  const assetsByAssetType: GroupedPositions = {
    stock: [],
    etf: [],
    mutual_fund: [],
    other: [],
  };

  assets.forEach((asset) => {
    const assetType = assetTypeMap?.[asset.asset_type] ?? 'other';
    assetsByAssetType[assetType].push({
      ...asset,
      asset_class: assetType,
    });
  });

  return assetsByAssetType;
};
