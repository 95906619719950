// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

export const AI_BUILDER = {
  selectMutualFunds: (
    <p className='text-justify'>
      Select one or multiple Mutual Funds and launch an optimization to allocate the
      money among them.
    </p>
  ),
  selectETFs: (
    <p className='text-justify'>
      Select one or multiple ETFs and launch an optimization to allocate the money
      among them.
    </p>
  ),
  selectUniverse: (
    <p className='text-justify'>
      Pick one group of stocks from more than 300 different indexes, sectors, and
      thematics.
    </p>
  ),
};

export const AI_BUILDER_THEMATICS = {
  XBI: (
    <p className='text-justify'>
      XBI was created on 2006-01-31 by SPDR. The fund&apos;s investment portfolio
      concentrates primarily on healthcare equity. The ETF currently has $7885.21 M
      in AUM and 153 holdings. XBI tracks an equal-weighted index of US biotechnology
      stocks.
    </p>
  ),

  ARKK: (
    <p className='text-justify'>
      ARKK was created on 2014-10-31 by ARK. The fund&apos;s investment portfolio
      concentrates primarily on theme equity. The ETF currently has $7804.74 M in AUM
      and 33 holdings. ARKK is an actively managed fund that seeks long-term capital
      growth from companies globally involved with, or that benefit from, disruptive
      innovation.
    </p>
  ),

  XSD: (
    <p className='text-justify'>
      XSD was created on 2006-01-31 by SPDR. The fund&apos;s investment portfolio
      concentrates primarily on information technology equity. The ETF currently has
      $1084.76 M in AUM and 39 holdings. XSD tracks an equal-weighted index of
      semiconductor stocks.
    </p>
  ),

  CIBR: (
    <p className='text-justify'>
      CIBR was created on 2015-07-07 by First Trust. The fund&apos;s investment
      portfolio concentrates primarily on theme equity. The ETF currently has
      $4854.46 M in AUM and 39 holdings. CIBR tracks a liquidity-weighted index that
      targets companies engaged in the cybersecurity industry.
    </p>
  ),

  ACES: (
    <p className='text-justify'>
      ACES was created on 2018-06-29 by ALPS. The fund&apos;s investment portfolio
      concentrates primarily on theme equity. The ETF currently has $698.42 M in AUM
      and 47 holdings. ACES tracks an index of US- and Canada-listed companies in the
      clean energy industry.
    </p>
  ),

  IDRV: (
    <p className='text-justify'>
      IDRV was created on 2019-04-16 by iShares. The fund&apos;s investment portfolio
      concentrates primarily on theme equity. The ETF currently has $445.49 M in AUM
      and 118 holdings. IDRV tracks a market-cap-selected and weighted index of
      equities related to self-driving vehicles.
    </p>
  ),

  AQWA: (
    <p className='text-justify'>
      AQWA was created on 2021-04-08 by Global X. The fund&apos;s investment
      portfolio concentrates primarily on theme equity. The ETF currently has $7.35 M
      in AUM and 41 holdings. AQWA tracks a market-cap-weighted index of global
      companies that are efficient in treating, purifying, and managing water.
    </p>
  ),

  METV: (
    <p className='text-justify'>
      METV was created on 2021-06-30 by Roundhill. The fund&apos;s investment
      portfolio concentrates primarily on theme equity. The ETF currently has $418.94
      M in AUM and 47 holdings. METV is a passively managed ETF investing in globally
      listed equities spanning various industries, all relating to what it defines as
      a future iteration of the internet Metaverse. Stocks are selected by a
      committee and weighted in tiers.
    </p>
  ),
};

export const AI_BUILDER_SELECT_OBJETIVE = {
  /** directIndexing */
  min_tracking_error: (
    <p className='text-justify'>
      Replicating the performance of an index by selecting the combination of stocks
      and weights among a given universe that allows you to minimize the tracking
      error.
    </p>
  ),

  equal_weights: (
    <p className='text-justify'>
      Allocate an equal percentage of the total investment amount to each of the
      assets in the portfolio.
    </p>
  ),

  market_cap: (
    <p className='text-justify'>
      Allocate investment amount based on the company&apos;s market capitalization.
    </p>
  ),

  max_diversification_ratio: (
    <p className='text-justify'>
      Maximize diversification ratio.
      <br />
      Diversification Ratio = Sum(Stock weight * Stock Standard Deviation) /
      Portfolio Standard Deviation
    </p>
  ),

  min_volatility: (
    <p className='text-justify'>
      Minimize portfolio volatility (Standard Deviation).
      <br />
      Standard Deviation = σ = ∑ ( x i − μ ) 2 N
    </p>
  ),

  min_risk_parity: (
    <p className='text-justify'>
      Allocate the investment amount based on the company&apos;s risk. This means
      that we will allocate more capital to stocks with less volatility, and vice
      versa.
    </p>
  ),

  min_neg_sharpe_ratio: (
    <p className='text-justify'>Maximize your portfolio&apos;s risk vs. return.</p>
  ),
};

export const AI_BUILDER_OPTIMIZATION_PARAMETERS = {
  selectOptimizationParameters: (
    <p className='text-justify'>
      Further customize the optimization by defining the number of positions, max and
      min weights, and historical window used.
    </p>
  ),

  numberOfPositions: (
    <p className='text-justify'>
      Establish the maximum and minimum number of positions the optimization engine
      can use to get as close as possible to the objective.
    </p>
  ),

  positionsWeights: (
    <p className='text-justify'>
      Establish the maximum and minimum weights each position can have on the
      portfolio, which the optimization engine can use to get as close as possible to
      the objective.
    </p>
  ),

  optimizationHistoricalWindow: (
    <p className='text-justify'>
      Establish the historical period used to generate the simulations. The
      simulations are generated with bootstrapping.
    </p>
  ),
};

export type AiBuilderPopupName =
  | keyof typeof AI_BUILDER
  | keyof typeof AI_BUILDER_THEMATICS
  | keyof typeof AI_BUILDER_SELECT_OBJETIVE
  | keyof typeof AI_BUILDER_OPTIMIZATION_PARAMETERS;
