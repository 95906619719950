// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { AppIcon, IconProps, SvgIconName, classes } from 'components/app-icon';
import { ButtonHTMLAttributes, forwardRef, useMemo } from 'react';
import styles from './icon-button.module.css';
import { classNames } from 'features/utils/classnames';

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly icon: SvgIconName;
  readonly variant?: 'button' | 'icon' | 'fill' | 'ghost';
  readonly size?: IconProps['size'];
  readonly color?: IconProps['color'];
  readonly hoverable?: boolean;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      icon,
      className,
      disabled,
      variant = 'button',
      type = 'button',
      size = 'xl',
      color,
      hoverable = true,
      ...props
    },
    ref
  ) => {
    const buttonClasses = useMemo(
      () =>
        classNames(
          styles.button,
          styles[`button-${variant}`],
          { 'hover:!text-opacity-50': hoverable },
          color ? classes.color[color] : null,
          className
        ),
      [variant, hoverable, color, className]
    );

    return (
      <button
        className={buttonClasses}
        type={type}
        disabled={disabled}
        {...props}
        ref={ref}
      >
        <AppIcon icon={icon} size={size} color={disabled ? 'disabled' : 'inherit'} />
      </button>
    );
  }
);

IconButton.displayName = 'IconButton';
