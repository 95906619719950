// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AxiosError } from 'axios';
import { useConfirmPopup } from 'components/popups/confirm-popup';
import { messages } from 'features/utils/handled-error-messages';
import { useCallback } from 'react';

export const useHandleApiErrors = () => {
  const showConfirm = useConfirmPopup();

  const handleError = useCallback(
    (error: AxiosError) => {
      const { code, config } = error;
      const { method, url } = config;
      const handledEndpoint = messages.get(`${method}-${url}`);
      const isTimeout = code === 'ECONNABORTED';

      const message =
        handledEndpoint ||
        (isTimeout && 'This process is taking too long, please refresh the page') ||
        'Please go back and run it again';

      showConfirm({
        title: 'Unable to load',
        message,
        confirmText: 'Reload',
        onConfirm: () => window.location.reload(),
        cancelText: 'Home',
        onCancel: () => window.location.assign('/'),
      });
    },
    [showConfirm]
  );

  return handleError;
};
