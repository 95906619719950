// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { ReactElement, useMemo } from 'react';

export type CellValue = string | number | Date | undefined | null;

export type FormatOptions<T extends CellValue> = T extends Date
  ? Intl.DateTimeFormatOptions
  : T extends number
  ? Intl.NumberFormatOptions
  : Record<string, unknown>;

export interface TableDataProps<T extends CellValue = CellValue> {
  value?: T;
  format: 'currency' | 'number' | 'decimal' | 'percentage' | 'date';
  formatOptions?: FormatOptions<T>;
  safeValue?: string;
  emptyValue?: CellValue;
  validate?: ((value?: T) => boolean) | boolean;
}

export interface TableDataComponent {
  <T extends CellValue = CellValue>(
    props: TableDataProps<T>,
    context?: any
  ): ReactElement<any, any> | null;
}

export const CellData: TableDataComponent = ({
  value,
  format,
  formatOptions,
  safeValue = '-',
  emptyValue,
  validate = false,
}) => {
  const formattedValue = useMemo(() => {
    if (
      typeof value === 'undefined' ||
      value === null ||
      (typeof value === 'number' && isNaN(value as number)) ||
      value === emptyValue ||
      (value instanceof Date && isNaN(value.getTime())) ||
      (typeof value === 'string' &&
        format === 'date' &&
        isNaN(new Date(value).getTime()))
    ) {
      return safeValue;
    }

    switch (format) {
      case 'date':
        return new Intl.DateTimeFormat('en-US', {
          ...((formatOptions as Intl.DateTimeFormatOptions) ?? {}),
        }).format(typeof value === 'string' ? new Date(value) : (value as Date));
      case 'currency':
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          ...(formatOptions ?? {}),
        }).format(value as number);
      case 'number':
      case 'decimal':
        return new Intl.NumberFormat('en-US', {
          minimumFractionDigits: format === 'decimal' ? 2 : 0,
          maximumFractionDigits: format === 'decimal' ? 2 : 0,
          ...(formatOptions ?? {}),
        }).format(value as number);
      case 'percentage':
        return new Intl.NumberFormat('en-US', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          ...(formatOptions ?? {}),
        }).format(value as number);
    }
  }, [emptyValue, format, formatOptions, safeValue, value]);

  const isInvalid = useMemo(() => {
    switch (typeof validate) {
      case 'function':
        return validate(value);
      case 'boolean': {
        if (
          validate &&
          typeof value === 'number' &&
          ['currency', 'number', 'decimal', 'percentage'].includes(format)
        )
          return value <= 0;
        return undefined;
      }
      default:
        return undefined;
    }
  }, [format, validate, value]);

  return (
    <span
      className='aria-[invalid=true]:text-[#e24646] aria-[invalid=false]:text-[#2dcc89]'
      aria-invalid={isInvalid}
    >
      {formattedValue}
    </span>
  );
};
