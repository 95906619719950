// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { ButtonProps, alpha, createTheme } from '@mui/material';
import { colorVariants } from './palette';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    filled: true;
    outlined: true;
    tonal: true;
  }
}

const createFilledVariants = () =>
  colorVariants.map((color) => ({
    props: {
      variant: 'filled' as ButtonProps['variant'],
      color: color as ButtonProps['color'],
    },
    style: ({ theme }: any) => ({
      backgroundColor: theme.palette[color].main,
      color: theme.palette[color].contrastText,
      '&:hover': {
        backgroundColor: theme.palette[color].dark,
      },
      '&:disabled': {
        backgroundColor: '#CACED8',
        color: '#79828D',
      },
    }),
  }));

const createOutlinedVariants = () =>
  colorVariants.map((color) => ({
    props: {
      variant: 'outlined' as ButtonProps['variant'],
      color: color as ButtonProps['color'],
    },
    style: ({ theme }: any) => ({
      backgroundColor: 'transparent',
      color: theme.palette[color].main,
      border: `1px solid ${theme.palette[color].main}`,
      '&:hover': {
        backgroundColor: alpha(theme.palette[color].main, 0.3),
      },
      '&:disabled': {
        color: '#79828D',
        border: '1px solid #79828D',
      },
    }),
  }));

const createTonalVariants = () =>
  colorVariants.map((color) => ({
    props: {
      variant: 'tonal' as ButtonProps['variant'],
      color: color as ButtonProps['color'],
    },
    style: ({ theme }: any) => ({
      backgroundColor: alpha(theme.palette[color].main, 0.3),
      color: theme.palette[color].main,
      '&:hover': {
        backgroundColor: alpha(theme.palette[color].main, 0.5),
      },
    }),
  }));

export const buttons = createTheme({
  components: {
    MuiButton: {
      variants: [
        ...createFilledVariants(),
        ...createOutlinedVariants(),
        ...createTonalVariants(),

        {
          props: { variant: 'outlined', color: 'secondary', size: 'small' },
          style: ({ theme }) => ({
            fontSize: '10px',
            backgroundColor: 'transparent',
            color: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.secondary.main}`,
            padding: '0px 8px',
            minWidth: '30px',
            borderRadius: '4px',
            height: '18px',
            lineHeight: '1em',
            alignItems: 'center',
            '&:hover': {
              backgroundColor: alpha(theme.palette.secondary.main, 0.3),
            },
          }),
        },

        {
          props: { variant: 'contained', color: 'secondary', size: 'small' },
          style: () => ({
            fontSize: '10px',
            padding: '0px 8px',
            minWidth: '30px',
            borderRadius: '4px',
            height: '18px',
            lineHeight: '1em',
            alignItems: 'center',
          }),
        },
      ],
      defaultProps: {
        disableElevation: true,
        variant: 'filled',
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          textTransform: 'none',
          border: '1px solid transparent',
          borderRadius: '6px',
          fontWeight: '500',
          fontSize: '1rem',
          height: '30px',
          padding: '8px 16px',
          transition: 'all 0.3s',
          textWrap: 'nowrap',
        },
      },
    },
  },
});
