// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { useState, useEffect, Dispatch, SetStateAction, useMemo } from 'react';
import { calculateDataGraphic } from '../utils/calculate-data-graphic';
import { calculateProfits } from 'features/utils/calculate-profits';
import { PerformanceChart } from './performance-chart/performance-chart';
import { PortfolioPerformanceResp } from 'api/interfaces/brokerage/portfolio';
import { useAppSelector } from 'store/store';
import { WrapperEffect } from 'features/ui/wrapper-effect';
import { useFetchApi } from 'hooks';
import { capitalizeString } from 'features/utils/capitalize-string';
import { selectBroker, selectAccountType, selectAccount } from 'store/slices/broker';

interface HistoryPortfolioProps {
  equity: number | undefined;

  profitsAndPercentage: {
    profit: number;
    percentage: number;
  };

  setProfitsAndPercentage: Dispatch<
    SetStateAction<{
      profit: number;
      percentage: number;
    }>
  >;
}

export const HistoryPortfolio = ({
  setProfitsAndPercentage,
}: HistoryPortfolioProps) => {
  const broker = useAppSelector(selectBroker);
  const accountType = useAppSelector(selectAccountType);
  const account = useAppSelector(selectAccount);
  const initialPeriod = useMemo(
    () => (broker === 'interactive_brokers' ? '1W' : '1D'),
    [broker]
  );
  const [period, setPeriod] = useState(initialPeriod);
  const [dataHistory, setDataHistory] = useState<number[][]>([[]]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const today = new Date();
  const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
  const pastDays = Math.ceil(
    (today.valueOf() - firstDayOfYear.valueOf()) / 86400000
  );

  const buttons = [
    { key: '1D', value: '1D' },
    { key: '1W', value: '1W' },
    { key: '1M', value: '1M' },
    { key: '3M', value: '3M' },
    { key: '1Y', value: '1A' },
    { key: 'YTD', value: `${pastDays}D` },
  ];

  const displayButtons = buttons.filter(({ value }) => {
    if (broker === 'interactive_brokers') {
      return value !== '1D';
    }
    return true;
  });

  const { resp, error } = useFetchApi<PortfolioPerformanceResp>({
    fetchFrom: 'brokerApi',
    endpoint: '/v2/portfolio/performance/',
    payload: {
      account_id: account.accountId,
    },
    params: {
      broker_key: broker.toLowerCase(),
      type_account: accountType || 'PAPER',
      period,
    },
    method: 'POST',
    makeIntervalCall: true,
    dependency: period,
  });

  useEffect(() => {
    if (resp) {
      setDataHistory(
        calculateDataGraphic(
          resp?.outstanding_balance as number[],
          resp?.datetime as string[]
        )
      );
      // istanbul ignore next
      calculateProfits(resp.outstanding_balance, (vals) =>
        setProfitsAndPercentage({
          profit: vals[0],
          percentage: vals[1],
        })
      );
      setIsLoading(false);
      setLoading(false);
    }
    // istanbul ignore next
    if (error) setIsLoading(false);
  }, [resp, error, setProfitsAndPercentage]);

  const updateData = (timeline: string) => {
    setPeriod(timeline);
    setLoading(true);
  };

  return (
    <div className='box100-container px-2'>
      <div className='box100-head-broker'>
        {!error ? (
          <div className='box100-toolbar-history flex'>
            {displayButtons.map(({ key, value }) => (
              <button
                key={key}
                onClick={() => updateData(value)}
                className={
                  period === value
                    ? 'btn-filter-history active-graphic-period'
                    : 'btn-filter-history'
                }
              >
                {key}
              </button>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
      <WrapperEffect showIn={!isLoading}>
        {dataHistory?.[0]?.length > 0 ? (
          <div className='h-80'>
            <PerformanceChart data={dataHistory} period={period} loading={loading} />
          </div>
        ) : (
          <span className='text-lg text-neutral-500 font-semibold block text-center'>
            {`The performance graph is not available for ${capitalizeString(
              broker
            )} at the moment`}
          </span>
        )}
      </WrapperEffect>
    </div>
  );
};
