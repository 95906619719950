// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store/store';

export const selectAvailableParameters = createSelector(
  (state: AppState) => state.availableParameters,
  (availableParameters) => availableParameters
);

export const selectAvailableParametersUniverses = createSelector(
  selectAvailableParameters,
  (availableParameters) => availableParameters.universe.universes
);

export const selectAvailableParametersSectors = createSelector(
  selectAvailableParameters,
  (availableParameters) => availableParameters.universe.sectors
);

export const selectAvailableParametersEsgSectors = createSelector(
  selectAvailableParameters,
  (availableParameters) => availableParameters.universe.esgSectors
);

export const selectAvailableParametersObjectives = createSelector(
  selectAvailableParameters,
  (availableParameters) => availableParameters.rebalance.objectives
);

export const selectAvailableParametersBenchmarks = createSelector(
  selectAvailableParameters,
  (availableParameters) => availableParameters.rebalance.benchmarks
);

export const selectAvailableParametersAssetClasses = createSelector(
  selectAvailableParameters,
  (availableParameters) => availableParameters.assetClasses
);

export const selectUniverseEsgFactors = createSelector(
  selectAvailableParameters,
  (availableParameters) => availableParameters.universe.esgFactors
);
