// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import Switch from '@mui/material/Switch';
import { brokerAuthenticationApi as brokerAuth } from 'api/brokers-authentication/broker-authentication-api';
import { Broker } from 'api/interfaces/brokerage/account';
import { AccountType } from 'api/interfaces/brokerage/commons';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import { accountApi } from 'store/api/brokerage/accounts-api';
import {
  selectAccountType,
  selectBroker,
  selectBrokerConnected,
  setAccountType,
  setOpenLinkAccountModal,
} from 'store/slices/broker';

export const SwitchAccountType: FC = () => {
  const dispatch = useDispatch();
  const broker = useAppSelector(selectBroker);
  const isBrokerConnected = useAppSelector(selectBrokerConnected);
  const accountType = useAppSelector(selectAccountType);
  const isPaperAccount = useMemo(() => accountType === 'PAPER', [accountType]);
  const isLiveAccount = useMemo(() => accountType === 'LIVE', [accountType]);
  const isInteractiveBrokers = useMemo(
    () => broker === 'interactive_brokers',
    [broker]
  );
  const [postBrokerCredentials] = accountApi.usePostBrokerCredentialsMutation();

  const authenticateWithInteractiveBrokers = useCallback(async () => {
    try {
      const authorizationCode = await brokerAuth.interactive_brokers();
      const response = await postBrokerCredentials({
        brokerKey: Broker.IBKR,
        brokerCredentials: authorizationCode,
      }).unwrap();

      const { connected, type_account } = response;

      if (!connected) {
        throw new Error('Failed to connect to Interactive Brokers');
      }

      dispatch(setAccountType(type_account));
    } catch (error) {
      console.error('Error authenticating with Interactive Brokers', error);
    }
  }, [dispatch, postBrokerCredentials]);

  const handleToggle = useCallback(
    async (accountType?: AccountType) => {
      if (!isBrokerConnected) {
        dispatch(setOpenLinkAccountModal(true));
        return;
      }

      const newAccountType: AccountType =
        accountType || (isPaperAccount ? 'LIVE' : 'PAPER');

      if (accountType === newAccountType) return;
      if (isInteractiveBrokers) {
        await authenticateWithInteractiveBrokers();
        return;
      }

      dispatch(setAccountType(newAccountType));
    },
    [
      authenticateWithInteractiveBrokers,
      dispatch,
      isBrokerConnected,
      isInteractiveBrokers,
      isPaperAccount,
    ]
  );

  return (
    <div
      className='flex items-center cursor-pointer'
      data-testid='switch-account-type'
    >
      <span
        className={`px-2 ${isPaperAccount ? 'text-[#79828D]' : 'text-[#C6D0DD]'}`}
        onClick={() => handleToggle('PAPER')}
      >
        Paper
      </span>
      <Switch
        checked={isLiveAccount}
        onChange={() => handleToggle()}
        color='primary'
        disableRipple
        inputProps={{
          'aria-label': 'controlled',
          role: 'switch',
          'aria-checked': isLiveAccount,
        }}
        className='!w-[38px] !h-[19px] !p-0'
        classes={{
          colorPrimary: '!text-[#97A0AB]',
          track: '!bg-[#C6D0DD] !rounded-full ',
          thumb: '!h-[18px] !w-[18px] box-border ',
          switchBase: '!p-0 !rounded-full !m-0',
        }}
      />
      <span
        className={`px-2 ${isLiveAccount ? 'text-[#79828D]' : 'text-[#C6D0DD]'}`}
        onClick={() => handleToggle('LIVE')}
      >
        Live
      </span>
    </div>
  );
};
