// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { useAppSelector } from 'store/store';
import { useEffect, useState } from 'react';
import { useSelectUniverse } from './use-select-universe';
import {
  selectPopularUsIndexes,
  selectPopularInternationalIndexes,
  selectPopularThematics,
} from 'features/utils/benchmark-formatter';
import { selectWorkflowAsset } from 'store/slices/workflow';

export const useBenchmarkList = ({
  onChange,
}: {
  value?: string;
  onChange?: (value: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [modalBenchmark, setModalBenchmark] = useState<string>('');

  const { handleSelectBenckmark, benchmarkSelected } =
    useSelectUniverse('ai-builder');

  const asset_type = useAppSelector(selectWorkflowAsset);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  let benchmarks = useAppSelector(selectPopularUsIndexes);
  benchmarks = benchmarks.concat(useAppSelector(selectPopularInternationalIndexes));
  benchmarks = benchmarks.concat(useAppSelector(selectPopularThematics));

  const handleSaveBenchmark = () => {
    /* istanbul ignore next */
    if (modalBenchmark) (onChange ?? handleSelectBenckmark)(modalBenchmark);
    setIsOpen(false);
  };

  useEffect(() => {
    setModalBenchmark('');
  }, [asset_type]);

  return {
    isOpen,
    benchmarkSelected,
    openModal,
    closeModal,
    modalBenchmark,
    setModalBenchmark,
    handleSaveBenchmark,
    benchmarks,
  };
};
