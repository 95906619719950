// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import {
  AiBuilderPopupName,
  AI_BUILDER,
  AI_BUILDER_OPTIMIZATION_PARAMETERS,
  AI_BUILDER_SELECT_OBJETIVE,
  AI_BUILDER_THEMATICS,
} from './ai-builder';
import { ChartPopupName, CHARTS } from './charts';
import { COMMON, CommonPopupName } from './common';
import { DashboardPopupName, DASHBOARD } from './dashboard';
import { KPIPopupName, KPIS } from './kpis';
import { MyResearchPopupName, MY_RESEARCH } from './my-research';
import {
  SimulationPopupName,
  SIMULATION_PARAMETERS,
  SIMULATION_PERFORMANCE_SUMMARY,
} from './simulation';
import { ScreenerPopupName, SCREENER_STOCKS } from './screener';
import { ReactNode } from 'react';

export type PopupsName =
  | `dashboard.${DashboardPopupName}`
  | `kpis.${KPIPopupName}`
  | `charts.${ChartPopupName}`
  | `common.${CommonPopupName}`
  | `my-research.${MyResearchPopupName}`
  | `simulation.${SimulationPopupName}`
  | `ai-builder.${AiBuilderPopupName}`
  | `screener.${ScreenerPopupName}`;

const registerPopups = <T1 extends string, T2 extends Record<string, ReactNode>>(
  prefix: T1,
  popups: T2
) => {
  return Object.entries(popups).map(([key, value]) => {
    return [
      `${prefix}.${key}` as `${T1}.${Extract<keyof T2, string>}`,
      value,
    ] as const;
  });
};

export const PopupsRegistry = new Map<PopupsName, ReactNode>([
  ...registerPopups('dashboard', DASHBOARD),
  ...registerPopups('kpis', KPIS),
  ...registerPopups('charts', CHARTS),
  ...registerPopups('common', COMMON),
  ...registerPopups('my-research', MY_RESEARCH),
  ...registerPopups('simulation', SIMULATION_PARAMETERS),
  ...registerPopups('simulation', SIMULATION_PERFORMANCE_SUMMARY),
  ...registerPopups('ai-builder', AI_BUILDER),
  ...registerPopups('ai-builder', AI_BUILDER_OPTIMIZATION_PARAMETERS),
  ...registerPopups('ai-builder', AI_BUILDER_SELECT_OBJETIVE),
  ...registerPopups('ai-builder', AI_BUILDER_THEMATICS),
  ...registerPopups('screener', SCREENER_STOCKS),
]);

export const getPopup = (popup: PopupsName) => {
  return PopupsRegistry.get(popup);
};
