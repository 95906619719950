// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { portfolioApi } from 'api/brokerage/portfolio-api';
import { AssetsInfoResp } from 'api/interfaces/ai/assets';
import { BrokerPosition } from 'api/interfaces/brokerage/portfolio';
import { MyInvestments, SubRows } from 'features/utils/interfaces/my-investment';
import { appStore as store } from 'store/store';
import {
  GroupedPositions,
  InvestmentAssetType,
  groupPositionsByAssetType,
} from './group-positions-by-asset-type';
import { assetsApi } from 'store/api/ai/assets-api';

type AssetInvestment = Record<InvestmentAssetType, MyInvestments>;

export const buildInvestmentTable = async (broker: any) => {
  const fetchPortfolioPositions = async () => {
    const { accountTypeSelect, brokerConnected, account } = broker;
    const params = {
      broker_key: brokerConnected,
      type_account: accountTypeSelect,
    };
    const payload = {
      account_id: account.account_id,
    };
    return portfolioApi.positions(params, payload);
  };

  const classifyPositions = async (positions: BrokerPosition[]) => {
    const tickers = positions.map((position) => position.ticker);
    const action = assetsApi.endpoints.classifier.initiate({ payload: tickers });
    const response = await store.dispatch(action).unwrap();
    return groupPositionsByAssetType(response);
  };

  const fetchAssetsInfo = async (positions: GroupedPositions) => {
    const requests = Object.entries(positions)
      .map(([assetType, assets]) => {
        if (assets.length === 0 || assetType === 'other') return null;
        const action = assetsApi.endpoints.assetsInfo.initiate({
          payload: assets.map(({ ticker }) => ticker),
          params: { detail: true },
          assetType: assetType as InvestmentAssetType,
        });
        return store.dispatch(action).unwrap();
      })
      .filter(Boolean) as Promise<AssetsInfoResp[]>[];
    const response = await Promise.all(requests);
    return response.flat();
  };

  const buildTable = (
    portfolio: BrokerPosition[],
    assetsInfo: AssetsInfoResp[],
    classifiedPositions: GroupedPositions
  ) => {
    const dataTable = {} as AssetInvestment;

    const summary = {
      stock: 0,
      etf: 0,
      mutual_fund: 0,
      other: 0,
    };

    for (const [assetClass, positions] of Object.entries(classifiedPositions)) {
      let stocksQuantity = 0;
      let totalUnrealizedCapitalGains = 0;
      const subRows = positions.map((item) => {
        const assetInfo = assetsInfo.find(
          ({ ticker }) => ticker === item.ticker
        ) as any;

        const position = portfolio.find(
          ({ ticker }) => ticker === item.ticker
        ) as any;

        summary[assetClass as keyof typeof summary] += position.outstanding_balance;
        stocksQuantity += position.stocks_number;
        totalUnrealizedCapitalGains += position.unrealized_capital_gains;

        const localCurrencyValues = position.local_currency_values;

        return {
          name: assetInfo?.name,
          asset_class: assetClass,
          investment: position.outstanding_balance,
          price: position.outstanding_balance / position.stocks_number,
          ticker: position.ticker,
          quantity: position.stocks_number,
          currency: position.currency,
          local_currency_values: {
            ...localCurrencyValues,
            price: localCurrencyValues.outstanding_balance / position.stocks_number,
          },
          total_gain_loss: [position.unrealized_capital_gains, 0],
          url_logo: assetInfo?.url_logo,
          url_morningstar: assetInfo?.url_morningstar || 'missing',
          subRows: [],
          unrealized_capital_gains: position.unrealized_capital_gains,
        };
      });

      dataTable[assetClass as InvestmentAssetType] = {
        subRows,
        quantity: stocksQuantity,
        asset_class: assetClass,
        investment: summary[assetClass as keyof typeof summary],
        price: 0,
        total_gain_loss: [totalUnrealizedCapitalGains, 0],
      };
    }

    return { dataTable, summary };
  };

  const buildInvestments = (dataTable: AssetInvestment) => {
    return Object.entries(dataTable).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: value.subRows,
      };
    }, {} as Record<InvestmentAssetType, SubRows[]>);
  };

  const positions = await fetchPortfolioPositions();
  const classifiedPositions = await classifyPositions(positions);
  const assetsInfo = await fetchAssetsInfo(classifiedPositions);

  const { dataTable, summary } = buildTable(
    positions,
    assetsInfo,
    classifiedPositions
  );
  const investments = buildInvestments(dataTable);

  const table = Object.values(dataTable).filter((item) => item.subRows.length > 0);

  return { investments, summary, dataTable: table };
};
