// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Button, MenuItem, Select } from '@mui/material';
import { CustomModal } from './custom-modal';
import { NumericFormat } from 'react-number-format';
import { Operations } from './form-add-ticker';

interface TickerPopupSelectorProps {
  closePopup: () => void;
  onAddTicker: () => void;
  showPopupSelector: boolean;
  selectOperation: (operation: Operations) => void;
  selectedOperation: Operations;
  ticker?: string;
  onChangeVolume: (name: string, value: string) => void;
  valueVolume: number;
}

export const TickerPopupSelector = ({
  closePopup,
  selectedOperation,
  selectOperation,
  onAddTicker,
  showPopupSelector,
  ticker,
  onChangeVolume,
  valueVolume,
}: TickerPopupSelectorProps) => {
  const operations: Operations[] = ['BUY', 'SELL'];

  return (
    <CustomModal
      title={ticker as string}
      handleClose={closePopup}
      showModal={showPopupSelector}
    >
      <div className='grid grid-cols-2 gap-4 my-4 w-full justify-center items-center text-[#515F70]'>
        <span>Type:</span>
        <div>
          <Select
            className='bg-white !border-none !rounded-lg !pb-4 w-full h-10'
            disableUnderline
            variant='filled'
            size='small'
            defaultValue='1'
            disabled
          >
            <MenuItem value='1'>At market</MenuItem>
          </Select>
        </div>

        {operations.map((operation, i) => (
          <Button
            key={i}
            variant={operation === selectedOperation ? 'filled' : 'outlined'}
            color='secondary'
            onClick={() => selectOperation(operation)}
            data-testid={`button-${operation}`}
          >
            {operation}
          </Button>
        ))}

        <span>Investment:</span>
        <NumericFormat
          className='p-2 rounded-lg !border outline-none focus:border-[#1072ba]'
          data-testid='input-investment'
          value={valueVolume}
          onValueChange={({ value }) => {
            onChangeVolume('volume', value);
          }}
          name='volume'
          prefix={'$'}
          thousandSeparator={true}
        />
      </div>
      <div className='flex justify-center pt-4 '>
        <Button color='secondary' data-testid='add-trade' onClick={onAddTicker}>
          Add to trades
        </Button>
      </div>
    </CustomModal>
  );
};
