// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Tooltip } from '@mui/material';
import { ColoredCircle } from 'components/colored-circle';
import { classNames } from 'features/utils/classnames';
import { numberToFixed } from 'features/utils/to-fixed-number';
import { FC } from 'react';

interface LegendProps {
  label: string;
  color: string;
  variation?: number;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  tooltip?: string;
}

export const Legend: FC<LegendProps> = ({
  label,
  color,
  variation,
  onClick,
  tooltip,
}) => {
  return (
    <button
      key={label}
      type='button'
      className={classNames(
        'flex items-center space-x-2 text-[#79828D] transition-colors',
        { 'cursor-default': !onClick }
      )}
      data-serie={label}
      onClick={onClick}
    >
      <ColoredCircle testId={`legend-${label}`} color={color} radius={5} />
      <Tooltip title={tooltip ?? label} placement='top'>
        <span>
          {label} &nbsp;
          {typeof variation === 'number' && (
            <span
              className={classNames({
                '!text-[#0D9795]': variation > 0,
                '!text-[#D80027]': variation <= 0,
              })}
            >
              {variation > 0 && '+'}
              {numberToFixed(variation)}%
            </span>
          )}
        </span>
      </Tooltip>
    </button>
  );
};
