// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { selectCopilotWidth, setCopilotWidth } from 'store/slices/copilot-buffer';
import { useAppSelector } from 'store/store';
import { useDispatch } from 'react-redux';
import { useSafeUpdate } from 'hooks';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const ResizableChatWindow: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const runSafeUpdate = useSafeUpdate();

  const chatWidth = useAppSelector(selectCopilotWidth);

  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(chatWidth);

  const handleMouseDown = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    const tolerance = 8;
    const rect = (event.target as HTMLElement).getBoundingClientRect();
    const cursorX = event.clientX - rect.left;
    /* istanbul ignore next */
    if (cursorX <= tolerance) {
      setIsResizing(true);
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsResizing(false);
  }, []);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      /* istanbul ignore next */
      if (isResizing) {
        const newWidth = Math.max(
          Math.min(
            window.innerWidth - event.clientX - 16, // Get the difference
            window.innerWidth - (232 + 16) // Sidebar width
          ),
          365
        );

        setWidth(newWidth);
      }
    },
    [isResizing]
  );

  const handleResize = useCallback(() => {
    const maxWidth = window.innerWidth - (232 + 16);

    if (width > maxWidth) {
      setWidth(maxWidth);
    }
  }, [width]);

  const style = useMemo(
    () =>
      ({
        width,
      } as React.CSSProperties),
    [width]
  );

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResizing]);

  useEffect(() => {
    if (width !== chatWidth) {
      runSafeUpdate(() => {
        dispatch(setCopilotWidth(width));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return (
    <div
      className='resizable-window-container'
      style={style}
      data-testid='resizable-window-container-test-id'
    >
      <span
        className='absolute top-0 left-0 w-1 bg-transparent h-[95%] cursor-e-resize'
        onMouseDown={handleMouseDown}
        data-testid='resizable-window-handle-test-id'
      />
      {children}
    </div>
  );
};
