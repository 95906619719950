// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { createSelector } from '@reduxjs/toolkit';
import { AppState } from 'store/store';

export const selectUserState = createSelector(
  ({ user }: AppState) => user,
  (state) => state
);

export const selectExpirationDate = createSelector(
  selectUserState,
  ({ expiration_date }) => expiration_date
);

export const selectUserStatus = createSelector(
  selectUserState,
  ({ status }) => status
);

export const selectUserData = createSelector(selectUserState, ({ user }) => user);

export const selectIsAuthenticatedUser = createSelector(
  selectUserState,
  ({ token }) => token !== null
);

export const selectIsLoadingAuthentication = createSelector(
  selectUserState,
  ({ isLoading }) => isLoading
);

export const selectOrganizations = createSelector(
  selectUserState,
  ({ organizations }) => organizations
);

export const selectAdminOrganizations = createSelector(
  selectOrganizations,
  (organizations) => organizations.filter((org) => org.flag_admin)
);
