// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { type QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import { aiApi } from './ai-api';
import { getEndpoint } from 'api/ai/get-endpoint';
import {
  AssetTypeClassfier,
  AssetsClassifierResp,
  AssetsComparison,
  AssetsInfoResp,
  AssetsSearchResp,
} from 'api/interfaces/ai/assets';
import { assetTypeMap } from 'features/brokers/utils';
import { Stock } from 'api/interfaces/ai/screener';

export const assetsApi = aiApi.injectEndpoints({
  endpoints: (build) => ({
    assetsInfo: build.query<
      AssetsInfoResp[],
      { params: { detail: boolean }; payload: string[]; assetType?: string }
    >({
      query: ({ payload, params, assetType = 'stock' }) => ({
        url: getEndpoint(assetType, 'info', 'assets'),
        method: 'POST',
        body: payload,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ ticker }) => ({
                type: 'Assets' as const,
                id: ticker,
              })),
              { type: 'Assets', id: 'Info' },
            ]
          : [{ type: 'Assets', id: 'Info' }],
    }),
    classifier: build.query<AssetsClassifierResp[], { payload: string[] }>({
      query: ({ payload }) => ({
        url: 'assets/classifier/',
        method: 'POST',
        body: payload,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ ticker }) => ({
                type: 'Assets' as const,
                id: ticker,
              })),
              { type: 'Assets', id: 'Classifier' },
            ]
          : [{ type: 'Assets', id: 'Classifier' }],
    }),
    search: build.query<
      AssetsSearchResp[],
      { ticker: string; name: string; assetType?: string }
    >({
      queryFn: async (
        { name, ticker, assetType },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const searchResult = (await fetchWithBQ({
          url: 'assets/search/',
          method: 'GET',
          params: {
            ticker,
            name,
            asset_type: assetType,
          },
        })) as QueryReturnValue<
          AssetsSearchResp[],
          FetchBaseQueryError,
          FetchBaseQueryMeta
        >;

        if (searchResult.error)
          return {
            error: searchResult.error as FetchBaseQueryError,
          };

        const assets = searchResult.data.map((asset) => ({
          ...asset,
          asset_type: assetTypeMap[asset.asset_type as AssetTypeClassfier],
        }));

        const assetsInfoResult = (await fetchWithBQ({
          url: getEndpoint('stock', 'info', 'assets'),
          method: 'POST',
          body: assets
            .filter(({ asset_type }) => asset_type.includes('stock'))
            .map(({ ticker }) => ticker),
          params: {
            detail: true,
          },
        })) as QueryReturnValue<Stock[], FetchBaseQueryError, FetchBaseQueryMeta>;

        if (assetsInfoResult.error)
          return {
            error: assetsInfoResult.error as FetchBaseQueryError,
          };

        const data = assets.map((asset) => ({
          ...asset,
          url_logo:
            asset.asset_type === 'stock'
              ? assetsInfoResult.data?.find(({ ticker }) => ticker === asset.ticker)
                  ?.url_logo
              : 'missing',
        })) as AssetsSearchResp[];

        return {
          data,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ ticker }) => ({
                type: 'Assets' as const,
                id: ticker,
              })),
              { type: 'Assets' as const, id: 'Search' },
            ]
          : [{ type: 'Assets', id: 'Search' }],
    }),

    comparison: build.query<AssetsComparison, { hist_w: number; tickers: string[] }>(
      {
        query: ({ hist_w, tickers }) => ({
          url: 'assets/comparison/',
          method: 'POST',
          body: tickers,
          params: { hist_w },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.asset_analysis.map(({ ticker }) => ({
                  type: 'Assets' as const,
                  id: ticker,
                })),
                { type: 'Assets', id: 'Comparison' },
              ]
            : [{ type: 'Assets', id: 'Comparison' }],
      }
    ),
  }),
});
