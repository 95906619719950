// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { createColumnHelper } from '@tanstack/react-table';
import { AccountInfo } from 'api/interfaces/brokerage/account';
import { Table } from 'components';
import { capitalizeString } from 'features/utils/capitalize-string';
import { FC, useMemo } from 'react';

export interface AccountsTable {
  readonly data: AccountInfo[];
  readonly onSetAccount: (accountInfo: AccountInfo[]) => void;
  readonly broker: string;
  readonly accountId?: string;
}

const columnHelper = createColumnHelper<AccountInfo>();

export const AccountsTable: FC<AccountsTable> = ({
  data,
  broker,
  onSetAccount,
  accountId,
}) => {
  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        cell: (info) => <span>{capitalizeString(info.getValue() || broker)}</span>,
        minSize: 200,
      }),
      columnHelper.accessor('account_manager', {
        header: 'Account Manager',
        cell: (info) => <span>{capitalizeString(info.getValue() || broker)}</span>,
        minSize: 200,
      }),
      columnHelper.accessor('currency', {
        header: 'Currency',
        cell: (info) => info.getValue() || '-',
        maxSize: 200,
      }),
      columnHelper.accessor('investment', {
        header: 'Equity',
        cell: (info) => <Table.Data format='currency' value={info.getValue()} />,
        maxSize: 200,
      }),
      columnHelper.accessor('aum', {
        header: 'Investment',
        cell: (info) => <Table.Data format='currency' value={info.getValue()} />,
        maxSize: 200,
      }),
      columnHelper.accessor('cash', {
        header: 'Cash',
        cell: (info) => <Table.Data format='currency' value={info.getValue()} />,
        maxSize: 200,
      }),
      columnHelper.accessor('unrealized_capital_gains', {
        header: 'Gain / loss',
        cell: (info) => (
          <Table.Data validate format='currency' value={info.getValue()} />
        ),
      }),
    ],
    [broker]
  );

  const initialRowSelection = useMemo(
    () =>
      data.length > 0
        ? {
            [accountId ?? data[0].account_id]: true,
          }
        : {},
    [accountId, data]
  );

  return (
    <div className='overflow-x-auto'>
      <Table.Grouped
        data={data}
        columns={columns}
        emptyState={
          broker
            ? 'There is currently no account data available. Please reconnect a broker or reload this page.'
            : 'There is currently no account data available. To access this information, please establish a connection with a broker.'
        }
        onRowSelectionChange={onSetAccount}
        enableRowSelection
        getRowId={(row) => row.account_id}
        initialRowSelection={initialRowSelection}
        disableDeselect
      />
    </div>
  );
};
