// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { createContext, FC, PropsWithChildren, useContext } from 'react';

const EMPTY_VALUE = Symbol('@@empty_value');

interface TabsContextValue {
  readonly baseId: string;
  readonly value?: string;
  readonly onValueChange: (value: string) => void;
}

const TabsContext = createContext(EMPTY_VALUE as unknown as TabsContextValue);

export const useTabsContext = () => {
  const context = useContext(TabsContext);

  if (context === (EMPTY_VALUE as unknown as TabsContextValue))
    throw new Error(
      'could not find tabs context value; please ensure the component is wrapped in a <TabsProvider>'
    );

  return context;
};

export const TabsProvider: FC<PropsWithChildren<TabsContextValue>> = ({
  children,
  ...context
}) => <TabsContext.Provider value={context}>{children}</TabsContext.Provider>;

export const makeTriggerId = (baseId: string, value: string) =>
  `${baseId}-trigger-${value}`;

export const makeContentId = (baseId: string, value: string) =>
  `${baseId}-content-${value}`;
