// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { useEffect, useState } from 'react';
import { useDebounce } from './use-debounce';
import { assetsApi } from 'store/api/ai/assets-api';

export const useValidateTicker = (
  ticker: string,
  assetType: string,
  delay = 300
) => {
  const [getAssetsInfo, { isLoading: isValidating }] =
    assetsApi.useLazyAssetsInfoQuery();

  const [isValidTicker, setIsValidTicker] = useState(false);
  const [asset, setAsset] = useState({} as any);
  const debouncedValue = useDebounce(ticker, delay);

  useEffect(() => {
    const validateTicker = async () => {
      if (debouncedValue.length === 0) {
        return;
      }

      const { data, isSuccess } = await getAssetsInfo(
        {
          payload: [debouncedValue],
          params: { detail: true },
          assetType,
        },
        true
      );

      if (isSuccess) {
        const isValid = data?.some((asset) => asset.name != 'missing') ?? false;

        setIsValidTicker(isValid);

        setAsset(data.find((asset) => asset.ticker === debouncedValue));
      }
    };

    validateTicker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    setIsValidTicker(false);
  }, [ticker]);

  return { isValidTicker: isValidTicker || isValidating, asset };
};
