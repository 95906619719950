// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { fetchAiApi } from 'api/ai-api';
import { getEndpoint } from 'api/ai/get-endpoint';
import {
  EtfFiltersResp,
  MutualFundFiltersResp,
  ScreenerApi,
  SearchETFResp,
  SearchMutualFundsResp,
  SearchResp,
  SearchUniverses,
  StocksSummaryResp,
  UniverseBasesResp,
} from 'api/interfaces/ai/screener';

export const screenerApi: ScreenerApi = {
  stockSettingsUniverseBase: async () => {
    const { data } = await fetchAiApi<UniverseBasesResp[]>({
      endpoint: '/screener/stock/settings/universe_base/',
      method: 'GET',
    });

    return data;
  },

  stockSettingsUniverseSearch: async ({ params }) => {
    const { data } = await fetchAiApi<SearchUniverses[]>({
      endpoint: '/screener/stock/settings/universe_base/search/',
      method: 'GET',
      params,
    });

    return data;
  },

  stockSettingsBenchmarks: async () => {
    const { data } = await fetchAiApi<SearchUniverses[]>({
      endpoint: '/screener/stock/settings/benchmarks/',
      method: 'GET',
    });

    return data;
  },

  stockSummary: async ({ payload }) => {
    const { data } = await fetchAiApi<StocksSummaryResp>({
      endpoint: '/screener/stock/summary/',
      method: 'POST',
      payload,
    });

    return data;
  },

  search: async ({ payload, assetType, signal }) => {
    const endpoint = getEndpoint(assetType as string, 'search', 'screener');

    const { data } = await fetchAiApi<SearchResp>({
      endpoint,
      method: 'POST',
      payload,
      signal,
    });

    return data;
  },

  settingsByType: async ({ assetType, settingType }) => {
    const assetTypeValue = assetType || '';
    const endpoint = getEndpoint(
      assetTypeValue,
      'settings',
      'screener',
      settingType
    );
    const { data } = await fetchAiApi<string[]>({
      endpoint,
      method: 'GET',
    });

    return data;
  },

  mutualFundFilters: async () => {
    const { data } = await fetchAiApi<MutualFundFiltersResp>({
      endpoint: '/screener/mutual_fund/us/search/settings/filters/',
      method: 'GET',
    });

    data.asset_manager.sort((a, b) => a.localeCompare(b));
    data.segment.sort((a, b) => a.localeCompare(b));

    return data;
  },

  searchMutualFunds: async ({ payload, signal, params }) => {
    const { data } = await fetchAiApi<SearchMutualFundsResp>({
      endpoint: '/screener/mutual_fund/us/search/',
      method: 'POST',
      payload,
      signal,
      params,
    });

    return data;
  },

  etfFilters: async () => {
    const { data } = await fetchAiApi<EtfFiltersResp>({
      endpoint: '/screener/etf/search/settings/filters/',
      method: 'GET',
    });

    data.asset_manager.sort((a, b) => a.localeCompare(b));
    data.segment.sort((a, b) => a.localeCompare(b));

    return data;
  },

  searchEtfs: async ({ payload, signal, params }) => {
    const { data } = await fetchAiApi<SearchETFResp>({
      endpoint: '/screener/etf/search/',
      method: 'POST',
      payload,
      signal,
      params,
    });

    return data;
  },
};
