// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { DynamicDialog } from 'components';
import { ModalLinkAccount } from 'features/brokers';
import { CopilotChat } from 'features/copilot/components';
import { BarTrialPeriod } from 'components/bar-trial-period';
import { FreeTrialExpires, MobileNotSupported } from 'features/ui/advices';
import { hubspotChat } from 'features/utils/hubspot-chat';
import {
  useAppReduxLoader,
  useAppSubscriptions,
  useValidateRegisteredUser,
} from 'hooks';
import TagManager from 'react-gtm-module';
import { useAppSelector } from 'store/store';
import { selectUserData } from 'store/slices/user';

function App() {
  const location = useLocation();

  const { canUserUseApp, expiration_date } = useAppSubscriptions();

  const user = useAppSelector(selectUserData);

  useAppReduxLoader();

  useValidateRegisteredUser();

  useEffect(() => {
    const idHubspotChat = process.env.REACT_APP_HUBSPOT_CHAT_ID as string;

    hubspotChat(idHubspotChat);

    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID as string,
    };

    TagManager.initialize(tagManagerArgs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <output>
      <FreeTrialExpires
        canUserUseApp={canUserUseApp}
        expirationDate={expiration_date!}
      />

      <MobileNotSupported />

      {!location.pathname.includes('/account') && <BarTrialPeriod />}

      <ModalLinkAccount />

      {user?.email?.includes('@citecsolutions.com') && <CopilotChat />}

      <DynamicDialog />
    </output>
  );
}

export default App;
