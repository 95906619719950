// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { forwardRef, HTMLAttributes, ReactElement, ReactNode } from 'react';
import { useTabsContext } from './tabs-context';
import { TabsTriggerProps } from './tabs-trigger';
import styles from './tabs.module.css';

export interface TabsListProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  readonly children?:
    | ReactElement<TabsTriggerProps>
    | ReactElement<TabsTriggerProps>[]
    | Exclude<ReactNode, ReactElement>;
}

export const TabsList = forwardRef<HTMLDivElement, TabsListProps>(
  ({ children, className, ...props }, ref) => {
    const context = useTabsContext();

    return (
      <div
        role='tablist'
        data-testid={`tablist-${context.baseId}`}
        className={`${styles['tabs-list']} ${className}`}
        {...props}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

TabsList.displayName = 'TabsList';
