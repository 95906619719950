// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { destructureHoldings } from 'features/utils/destructure-holdings';
import { Holding } from 'api/interfaces/ai/holding';
import { selectCurrentPortfolio } from 'store/slices/portfolio-positions';
import { useAppSelector } from 'store/store';
import { useMemo } from 'react';

/**
 * Custom hook that destructures an array of holdings into an object with keys for each holding.
 * If no holdings are provided, it uses the holdings from the Redux store.
 * @param holdings An optional array of holdings to destructure.
 * @returns An object with keys for each holding.
 */
export const useDestructuredHoldings = (holdings?: Holding[]) => {
  const portfolioHoldings = useAppSelector(selectCurrentPortfolio);

  const selectedHoldings = holdings ?? portfolioHoldings;

  const destructuredHoldings = useMemo(
    () => destructureHoldings(selectedHoldings),
    [selectedHoldings]
  );

  return {
    ...destructuredHoldings,
    holdings: selectedHoldings,
  };
};
