// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Checkbox, ListSubheader } from '@mui/material';
import { useCallback, useMemo, FC, ChangeEvent, useState } from 'react';
import { AppIcon } from 'components/app-icon';
import { classNames } from 'features/utils/classnames';
import { groupBy } from 'features/utils/groupby';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { SearchBar } from 'components/search-bar';
import { useConst } from 'hooks';

type SelectStyle = 'white' | 'gray';

export type AvailableOptions = Array<
  | { value: string; label: string }
  | { value: string; label: string; group: string }
  | string
>;

type RowData = {
  options: AvailableOptions;
  multiple?: boolean;
  grouping?: boolean;
  checkbox?: boolean;
  selected: string | string[] | undefined;
  onClick: (value: string) => void;
};

interface OptionsSelectorProps<T extends string | string[]> {
  optionsAvailable: AvailableOptions;
  onChangeValueSelected: (newOptionSelected: T) => void;
  optionSelected?: T;
  dataTestId?: string;
  defaultText: string;
  selectType: SelectStyle;
  multiple?: T extends string ? false : true;
  selectedAll?: boolean;
  checkbox?: boolean;
  grouping?: boolean;
  searchBar?: boolean;
}

const Row: FC<ListChildComponentProps<RowData>> = ({ index, style, data }) => {
  const option = data.options[index];

  const value = typeof option === 'string' ? option : option.value;

  const selected =
    typeof data.selected === 'string'
      ? data.selected === value
      : data.selected?.includes(value);

  return (
    <MenuItem
      key={index}
      value={value}
      selected={data.selected?.includes(value)}
      style={style}
      onClick={() => data.onClick(value)}
    >
      {data.checkbox && data.multiple ? <Checkbox checked={selected} /> : null}
      {typeof option === 'string' ? option : option?.label}
    </MenuItem>
  );
};

const containsText = (text: string, searchText: string) => {
  const trimmedSearchText = searchText.trim();

  const regex = new RegExp(`${trimmedSearchText}`, 'i');

  return regex.test(text);
};

export const OptionsSelector = <T extends string | string[]>({
  optionsAvailable,
  onChangeValueSelected,
  optionSelected,
  dataTestId,
  defaultText,
  selectType,
  multiple = false as any,
  checkbox = false,
  grouping = false,
  selectedAll = true,
  searchBar = false,
}: OptionsSelectorProps<T>) => {
  const ITEM_HEIGHT = useConst(35);
  const DISPLAYED_OPTIONS = useConst(8);
  const [searchText, setSearchText] = useState('');

  const displayedOptions = useMemo(
    () =>
      optionsAvailable?.filter((option) =>
        containsText(typeof option === 'string' ? option : option.label, searchText)
      ) ?? [],
    [optionsAvailable, searchText]
  );

  const listHeight = useMemo(
    () =>
      displayedOptions.length > DISPLAYED_OPTIONS
        ? ITEM_HEIGHT * DISPLAYED_OPTIONS
        : ITEM_HEIGHT * displayedOptions.length,
    [DISPLAYED_OPTIONS, ITEM_HEIGHT, displayedOptions.length]
  );

  const allValues = useMemo(
    () =>
      displayedOptions?.map((option) =>
        typeof option === 'string' ? option : option.value
      ),
    [displayedOptions]
  );

  const hasAllValues = useMemo(
    () =>
      typeof optionSelected === 'string'
        ? false
        : [...allValues].sort().join(',') ===
          [...(optionSelected ?? [])]?.sort()?.join(','),
    [optionSelected, allValues]
  );

  const groupedOptions = useMemo(() => {
    if (!grouping) return [];
    return Array.from(
      groupBy(
        displayedOptions as Array<{ value: string; label: string; group: string }>,
        'group'
      )
    );
  }, [displayedOptions, grouping]);

  const selectedGroupValues = useCallback(
    (group: string) => {
      if (!grouping) return false;

      const groupValues = groupedOptions
        .filter(([optionGroup]) => optionGroup === group)
        .flatMap(([, options]) => options.map((option) => option.value));

      return (
        groupValues.sort().join(',') ===
        (optionSelected as string[])
          .filter((option) => groupValues.includes(option))
          .sort()
          .join(',')
      );
    },
    [groupedOptions, grouping, optionSelected]
  );

  const toggleGroup = useCallback(
    (group: string) => {
      const groupValues = groupedOptions
        .filter(([optionGroup]) => optionGroup === group)
        .flatMap(([, options]) => options.map((option) => option.value));

      selectedGroupValues(group)
        ? onChangeValueSelected(
            (optionSelected as string[])?.filter(
              (option) => !groupValues.includes(option)
            ) as any as T
          )
        : onChangeValueSelected(
            Array.from(
              new Set((optionSelected as string[])?.concat(groupValues))
            ) as any as T
          );
    },
    [groupedOptions, onChangeValueSelected, optionSelected, selectedGroupValues]
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent<T>) => {
      const value = event.target.value as T;

      if (multiple && value.includes(undefined as any)) {
        onChangeValueSelected(
          typeof value === 'string'
            ? (value.split(',') as T)
            : ((hasAllValues ? [] : allValues) as T)
        );
      } else {
        onChangeValueSelected(
          multiple && typeof value === 'string' ? (value.split(',') as T) : value
        );
      }
    },
    [allValues, hasAllValues, multiple, onChangeValueSelected]
  );

  const onOptionClick = useCallback(
    (value: string) => {
      const isString = typeof optionSelected === 'string';
      const isArray = Array.isArray(optionSelected);

      const isSelected = isString
        ? optionSelected === value
        : optionSelected?.includes(value);

      if (multiple && isArray) {
        onChangeValueSelected(
          (isSelected
            ? (optionSelected as string[]).filter((option) => option !== value)
            : optionSelected?.concat(value)) as T
        );
      } else {
        onChangeValueSelected(value as T);
      }
    },
    [multiple, onChangeValueSelected, optionSelected]
  );

  const onChangeSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, []);

  return (
    <FormControl fullWidth size='small'>
      <Select
        value={optionSelected}
        IconComponent={(props) => (
          <AppIcon
            icon='ArrowDown'
            size='base'
            color='disabled'
            className='mr-2'
            {...props}
          />
        )}
        onChange={handleChange}
        onClose={() => setSearchText('')}
        displayEmpty
        renderValue={(value: T): React.ReactNode => {
          const values = typeof value === 'string' ? [value] : (value as string[]);

          const labels = optionsAvailable
            ?.filter((option) =>
              values.includes(typeof option === 'object' ? option.value : option)
            )
            .map((option) => (typeof option === 'object' ? option.label : option));

          return Array.from(new Set(labels))?.join(',') || defaultText;
        }}
        data-testid={dataTestId}
        className={classNames({ '!bg-white': selectType === 'white' })}
        multiple={multiple || false}
        MenuProps={{
          autoFocus: false,
          classes: {
            list: '!bg-[#F6F7FC]',
          },
        }}
      >
        {searchBar && (
          <ListSubheader className='!bg-[#F6F7FC] !py-2'>
            <SearchBar
              value={searchText}
              onChangeValue={onChangeSearch}
              placeholder='Search'
              autoFocus
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        {multiple && selectedAll ? (
          <MenuItem style={{ height: ITEM_HEIGHT }}>
            <Checkbox checked={hasAllValues} />
            <span>Select All</span>
          </MenuItem>
        ) : null}
        {!grouping && (
          <List
            itemCount={displayedOptions.length}
            width='100%'
            height={listHeight}
            itemSize={ITEM_HEIGHT}
            innerElementType='ul'
            outerElementType='li'
            className='!overflow-x-hidden'
            itemData={{
              options: displayedOptions,
              multiple,
              grouping,
              checkbox,
              selected: optionSelected,
              onClick: onOptionClick,
            }}
          >
            {Row}
          </List>
        )}
        {grouping &&
          groupedOptions.map(([group, options], groupId) => [
            <ListSubheader className='!text-[#515F70] !font-bold !text-sm !font-poppins !leading-8 !truncate !cursor-default !bg-inherit'>
              <Checkbox
                checked={selectedGroupValues(group)}
                onChange={() => toggleGroup(group)}
              />
              <span>{group}</span>
            </ListSubheader>,
            options.map((option, id) => (
              <MenuItem key={`option-group-${groupId}-${id}`} value={option.value}>
                {checkbox && multiple ? (
                  <Checkbox
                    checked={
                      Array.isArray(optionSelected) &&
                      optionSelected.includes(option.value)
                    }
                  />
                ) : null}
                {option.label}
              </MenuItem>
            )),
          ])}
      </Select>
    </FormControl>
  );
};
