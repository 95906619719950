// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { fetchBrokerageApi } from 'api/brokerage-api';
import { Order } from 'api/interfaces/brokerage/commons';
import {
  BrokerPosition,
  PortfolioApi,
  PortfolioPerformanceResp,
} from 'api/interfaces/brokerage/portfolio';

export const portfolioApi: PortfolioApi = {
  ordersSearch: async (params, payload) => {
    const { data } = await fetchBrokerageApi<Order[]>({
      endpoint: '/v2/portfolio/orders/search/',
      method: 'POST',
      params,
      payload,
    });

    return data;
  },

  postOrders: async (params, payload) => {
    const { data } = await fetchBrokerageApi<Order[]>({
      endpoint: '/v2/portfolio/orders/',
      method: 'POST',
      params,
      payload,
    });
    return data;
  },

  positions: async (params, payload) => {
    const { data } = await fetchBrokerageApi<BrokerPosition[]>({
      endpoint: '/v2/portfolio/positions/',
      method: 'POST',
      params,
      payload,
    });
    return data;
  },

  performance: async (params, payload, signal) => {
    const { data } = await fetchBrokerageApi<PortfolioPerformanceResp>({
      endpoint: '/v2/portfolio/performance/',
      method: 'POST',
      params,
      payload,
      signal,
    });
    return data;
  },
};
