// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { formatToMoney } from 'features/utils/format-to-money';
import { numberToFixed } from 'features/utils/to-fixed-number';
import { useState } from 'react';
import { HistoryPortfolio } from './history-portfolio';
import { Heading, SectionContainer } from 'components';

interface BrokerBalanceProps {
  equity: number | undefined;
}

export const BrokerBalance = ({ equity }: BrokerBalanceProps) => {
  const [profitsAndPercentage, setProfitsAndPercentage] = useState({
    profit: 0,
    percentage: 0,
  });

  return (
    <SectionContainer>
      <div className='broker-home-broker-title'>
        <div className='flex flex-col space-y-2'>
          <Heading
            variant='h2'
            title='Investments'
            popupName='dashboard.investments'
            tooltip
          />
          <div className='flex flex-row space-x-2'>
            <span className='text-[#2D3745] font-semibold font-[poppins] text-3xl'>
              {formatToMoney(Math.trunc(equity || 0), '$')}
            </span>
            <span
              data-profit={profitsAndPercentage.profit >= 0}
              className='font-[poppins] text-[#e24646] data-[profit=true]:text-[#2DCC89] font-semibold leading-[22px] self-end mb-0.5'
            >
              {formatToMoney(profitsAndPercentage.profit)} (
              {numberToFixed(profitsAndPercentage.percentage)}%)
            </span>
          </div>
        </div>
      </div>
      <div className='row-boxes-area-investment'>
        <HistoryPortfolio
          profitsAndPercentage={profitsAndPercentage}
          setProfitsAndPercentage={setProfitsAndPercentage}
          equity={equity}
        />
      </div>
    </SectionContainer>
  );
};
