// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

export const CHARTS = {
  sectorComposition: (
    <p className='text-justify'>
      A stock market sector is a group of stocks that have many aspects in common,
      usually because they are in similar industries. There are 11 different stock
      market sectors, according to the most commonly used classification system; the
      Global Industry Classification Standard (GICS).
      <br />
      <br />
      We categorize stocks into sectors to more easily compare companies that have
      similar business models. When investing, you can choose from stocks within the
      sectors that interest you. Sectors also make it easier to compare which stocks
      are making the most money, which helps you make decisions about what your next
      investments will be.
      <br />
      <br />
      <strong>Energy</strong>: The energy sector covers companies that do business in
      the oil and natural gas industry. It includes oil and gas exploration and
      production companies, as well as producers of other consumable fuels like coal
      and ethanol. The energy sector also includes the related businesses that
      provide equipment, materials, and services to oil and gas producers. Oddly
      enough, though, it doesn&apos;t include many renewable energy companies, which
      instead are considered utilities. The largest U.S. stocks in the energy sector
      are ExxonMobil (NYSE:XOM) and Chevron (NYSE:CVX).
      <br />
      <br />
      <strong>Materials</strong>: The materials sector includes companies that
      provide various goods for use in manufacturing and other applications.
      You&apos;ll find makers of chemicals, construction materials, and containers,
      and packaging within the materials sector, along with mining stocks and
      companies specializing in making paper and forest products. Well-known
      materials stocks include paint maker Sherwin-Williams (NYSE:SHW) and chemicals
      manufacturer DuPont (NYSE:DD).
      <br />
      <br />
      <strong>Industrial</strong>: The industrial sector encompasses a wide range of
      different businesses that generally involve the use of heavy equipment.
      Transportation stocks such as airlines, railroads, and logistics companies are
      found within the industrial sector, as are companies in the aerospace, defense,
      construction, and engineering industries. Companies making building products,
      electrical equipment, and machinery also fall into this sector, as do many
      conglomerates. Boeing (NYSE:BA) and Union Pacific (NYSE:UNP) are among the
      largest U.S. industrial stocks.
      <br />
      <br />
      <strong>Utilities</strong>: The utilities sector encompasses just about every
      different type of utility company you can think of. Within the sector,
      you&apos;ll find utilities specializing in making electrical power available to
      residential and commercial customers and specialists in natural gas
      transmission and distribution. Other utilities are responsible for delivering
      water to customers. Some utility companies engage in more than one of these
      different subspecialties. In addition, independent producers of power and
      renewable electricity also land in the utility sector, even though they
      don&apos;t exactly resemble the traditional regulated utility in an era of
      deregulation. Utilities tend to be regional in scope, so you might have heard
      of Duke Energy (NYSE:DUK) in the Southeast U.S., Consolidated Edison (NYSE:ED)
      in the Northeast, and American Electric Power (NASDAQ:AEP) across much of the
      Ohio Valley and the Southern Plains states.
      <br />
      <br />
      <strong>Healthcare</strong>: The healthcare sector has two primary components.
      One component includes companies that develop pharmaceuticals and treatments
      based on biotechnology, as well as the analytical tools and supplies needed for
      the clinical trials that test those treatments. The other encompasses
      healthcare equipment and services, including surgical supplies, medical
      diagnostic tools, and health insurance. UnitedHealth Group (NYSE:UNH) and
      Johnson & Johnson (NYSE:JNJ) are the two stocks at the top of the healthcare
      sector.
      <br />
      <br />
      <strong>Financials</strong>: The financial sector includes businesses that are
      primarily related to handling money. Banks are a key industry group within the
      sector, but you&apos;ll also find insurance companies, brokerage houses,
      consumer finance providers, and mortgage-related real estate investment trusts
      among financials. Warren Buffett&apos;s Berkshire Hathaway (NYSE:BRK-A)
      (NYSE:BRK-B) and financial giant JPMorgan Chase (NYSE:JPM) are among the
      best-known stocks in the financial sector.
      <br />
      <br />
      <strong>Consumer discretionary</strong>: The consumer discretionary sector
      covers goods and services for which consumer demand depends upon consumer
      financial status. For example, if you make $25,000 per year, you probably buy a
      different car than someone who makes $25 million per year. The sector includes
      companies that sell higher-priced items like automobiles and luxury goods, as
      well as leisure products. You&apos;ll find both brick-and-mortar and
      e-commerce-based retail companies in this category, along with hotel and
      restaurant stocks. Amazon (NASDAQ:AMZN) and McDonald&apos;s (NYSE:MCD) are
      among the biggest stocks in the sector.
      <br />
      <br />
      <strong>Consumer staples</strong>: The consumer staples sector includes goods
      and services that consumers need, regardless of their financial condition. The
      category includes companies in the food, beverage, and tobacco industries, as
      well as household and personal care products. You&apos;ll also find retail
      companies that specialize in selling staples, such as supermarkets, in this
      group. Coca-Cola (NYSE:KO) and Procter & Gamble (NYSE:PG) are two of the most
      valuable consumer staples stocks in the U.S. market.
      <br />
      <br />
      <strong>Information technology</strong>: The information technology sector
      covers companies involved in the different categories of technological
      innovation. Some companies in information technology focus on creating software
      or providing services related to implementing technological solutions, while
      others are more involved in building the equipment, components, and hardware
      that make tech possible. Information technology also includes makers of
      semiconductors and the equipment used to produce semiconductor chips. Apple
      (NASDAQ:AAPL) and Microsoft (NASDAQ:MSFT) have been switching places back and
      forth at the top of the list of large U.S. stocks in the information technology
      sector.
      <br />
      <br />
      <strong>Communication</strong>: The communication services sector is the newest
      of the GICS sectors and includes a couple of major areas that used to be part
      of other sectors. Telecommunication services providers, including both wireless
      telecom networks and providers of old-style landline services, make up one wing
      of the sector. At the other end are media and entertainment companies,
      including both older media like television and radio and interactive media via
      the internet and newer forms of communication. Social media giant Facebook
      (NASDAQ:FB) and search engine pioneer Alphabet (NASDAQ:GOOGL) (NASDAQ:GOOG) are
      among the biggest stocks in communication services.
      <br />
      <br />
      <strong>Real Estate</strong>: The real estate sector generally includes two
      different types of investments related to real estate. Some stocks in the
      sector are responsible for developing new real estate projects and then
      managing them by obtaining tenants for various spaces within the project
      property. In addition, most real estate investment trusts, which are special
      tax-favored business entities that operate in various areas of the real estate
      industry, get counted as within the real estate sector. Among the top stocks in
      the real estate sector, you&apos;ll find cellular communications tower
      specialist American Tower (NYSE:AMT) and major shopping mall owner and operator
      Simon Property Group (NYSE:SPG).
    </p>
  ),

  segmentMatrix: (
    <p className='text-justify'>
      The segment matrix measures two dimensions. The company&apos;s size (small,
      medium, large) is based on market capitalization, and the company&apos;s price
      vs. earnings (value—low price-per-earnings, mixed, or growth—high
      price-per-earnings).
    </p>
  ),

  factors: (
    <p className='text-justify'>
      Investing in specific factors may help investors reach their goals by reducing
      portfolio volatility or improving returns. Factors are the persistent and
      well-documented asset characteristics that have historically driven investment
      risk and return. The most popular smart beta factors are:
      <br />
      <br />
      <ul>
        <li>
          <strong>Size</strong>: Investing in companies based on their market
          capitalization, which refers to the total dollar market value of a
          company’s outstanding stock shares. You calculate this number by
          multiplying the total number of a company’s outstanding shares by the
          current market price of one share.
          <br />
          <br />
        </li>

        <li>
          <strong>Dividend</strong>: Investing in companies is mainly based on the
          dividend yield ratio (dividends paid to investors over the company’s market
          capitalization).
          <br />
          <br />
        </li>
        <li>
          <strong>Style (value vs. growth)</strong>: Investing in companies mainly
          based on the price per earnings. It is important to know that earnings can
          be measured in different ways, such as expected future earnings vs. current
          earnings.
          <br />
          <br />
        </li>
        <li>
          <strong>Volatility</strong>: Investing in companies based on stocks’
          standard deviation or the variance between stocks’ daily returns.
          Volatility is a proxy for risk—the higher the volatility, the higher the
          risk. Stable companies with predictable returns tend to have less volatile
          prices.
          <br />
          <br />
        </li>
        <li>
          <strong>Quality</strong>: Investing in companies based on their financial
          health, which can be measured by combining different leverage ratios.
          <br />
          <br />
        </li>
        <li>
          <strong>Momentum</strong>: Measures the price change rate over some time to
          help investors determine a trend&apos;s strength. Stocks that tend to move
          with the strength of momentum are called momentum stocks.
          <br />
          <br />
        </li>
      </ul>
    </p>
  ),

  valueAtRisk: (
    <p className='text-justify'>
      Quantifies the portfolio’s possible financial losses over one month at a 95%
      confidence level.
    </p>
  ),

  concentration: (
    <p className='text-justify'>
      Helps you understand the level of exposure to the companies in which you are
      investing more money. The graph shows the accumulated exposure to top
      positions, this means if you select 10 stocks in the X axis the Y axis shows
      the percentage of the money invested in the top 10 positions.
    </p>
  ),

  topAssets: (
    <p className='text-justify'>
      List of the assets with a higher level of investment.
    </p>
  ),

  trackingDifference: (
    <p className='text-justify'>
      Measures the difference between the portfolio’s and benchmark’s performances.
    </p>
  ),

  trackingError: (
    <p className='text-justify'>
      Measures the performance vs. benchmark standard deviation. Assuming a normal
      distribution, the difference between a portfolio&#x2019;s return and the
      benchmark will be less than the tracking error at ~70% confidence level.
    </p>
  ),

  returns: (
    <p className='text-justify'>
      Portfolio Value change plus any dividends received, expressed as a percentage
      of the initial investment.
    </p>
  ),

  correlationMatrix: (
    <p className='text-justify'>
      Quantifies the strength and direction of the linear relationship between two
      variables. A 100 indicates a perfect positive correlation and a -100 indicates
      a perfect negative correlation.
    </p>
  ),

  riskVsReturn: (
    <p className='text-justify'>
      This matrix measures the portfolio’s or assets volatility and returns over a
      certain period.
    </p>
  ),
};

export type ChartPopupName = keyof typeof CHARTS;
