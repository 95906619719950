// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Box, Modal } from '@mui/material';
import { ManageTags, ManageTagsProps } from './manage-tags';
import { CloseButton } from 'components';

interface ManageTagsModalProps extends ManageTagsProps {
  openModal: boolean;
  onClose: () => void;
  assetsInput?: boolean;
  onAddTicker?: (ticker: string, assetType: string) => void;
}

export const ManageTagsModal = ({
  openModal,
  onClose,
  ...props
}: ManageTagsModalProps) => {
  return (
    <Modal open={openModal} className='overflow-y-scroll' data-testid='tags-modal'>
      <Box className='flex items-center justify-center fixed inset-0 bg-transparent'>
        <Box className='bg-white rounded-lg py-0 px-4 w-[1104px] max-h-[90vh] h-full overflow-y-auto relative'>
          <div className='px-6 py-10'>
            <ManageTags {...props} />
          </div>
          <CloseButton onClick={onClose} />
        </Box>
      </Box>
    </Modal>
  );
};
