// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './assets/styles/index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/material';
import { theme } from 'assets/theme/mui';
import { SnackbarProvider } from 'notistack';
import { DynamicDialogProvider } from 'components/dynamic-dialog/dynamic-dialog-context';
import { router } from 'routes';
import { Loading } from 'features/ui';
import { appStore, persistor } from 'store/store';

const container = document.getElementById('root');

const root = createRoot(container!);

root.render(
  <Provider store={appStore}>
    <SnackbarProvider classes={{ variantSuccess: '!bg-[#0baca9]' }}>
      <PersistGate persistor={persistor} loading={null}>
        <ThemeProvider theme={theme}>
          <DynamicDialogProvider>
            <Suspense fallback={<Loading />}>
              <RouterProvider router={router} />
            </Suspense>
          </DynamicDialogProvider>
        </ThemeProvider>
      </PersistGate>
    </SnackbarProvider>
  </Provider>
);
