// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { FC, useMemo } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { numberToFixed } from 'features/utils/to-fixed-number';
import { ChartContainer } from 'components';
import { interpolateColorOpacity } from '../../features/overview/utils/transform-countries-data';
import { PopupsName } from 'features/popups';

interface DonutChartProps {
  name: string;
  exposure: string[];
  weight: number[];
  testId?: string;
  popupName?: PopupsName;
  noDataMessage?: string;
}

export const DonutChart: FC<DonutChartProps> = ({
  name,
  exposure,
  weight,
  testId,
  popupName,
  noDataMessage = 'No data available',
}) => {
  const data = useMemo(
    () =>
      exposure
        .map((category, index) => ({
          id: category,
          label: category,
          value: weight[index],
        }))
        .sort((a, b) => b.value - a.value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [weight]
  );

  const highestWeight = Math.max(...weight);

  const chartColors = useMemo(
    () => data.map((item) => interpolateColorOpacity(item.value, highestWeight)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [weight]
  );

  const hasData = data.some((item) => item.value > 0);

  return (
    <ChartContainer header={name} popupName={popupName} tooltip>
      {hasData ? (
        <div className='grid grid-cols-1 gap-0 py-0 px-8' data-testid={testId}>
          <div className='h-80 relative'>
            <div className='absolute top-[42%] left-0 right-0 m-auto'>
              <div className='flex flex-col'>
                <span className='text-center text-[#445A7A] font-bold '>
                  {Math.round(data[0]?.value * 100)}%
                </span>
                <span className='text-center text-[#515F70] '>{data[0]?.label}</span>
              </div>
            </div>
            <ResponsivePie
              data={data}
              margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
              sortByValue={true}
              innerRadius={0.7}
              cornerRadius={5}
              valueFormat=' >-.2p'
              activeOuterRadiusOffset={3}
              borderWidth={1}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 0.2]],
              }}
              colors={chartColors}
              enableArcLinkLabels={false}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor='#333333'
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color' }}
              enableArcLabels={false}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: 'color',
                modifiers: [['darker', 2]],
              }}
              animate={false}
            />
          </div>
          <div className='mt-2 px-2' data-testid='chart-legend'>
            {data.map(({ label, value }, index) => (
              <div key={index}>
                <div className='flex justify-between'>
                  <div className='flex'>
                    <div className={`w-1 mr-4 countries-top-ten-grid-${index}`} />
                    <span className='text-[#79828D]'>{label}</span>
                  </div>
                  <span className='text-[#79828D] mr-4'>
                    {numberToFixed(value * 100)} %
                  </span>
                </div>
                <hr className='bg-[#DADADA] my-1.5' />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          className='mt-5 mx-auto px-8 text-center space-y-4 text-[#515F70] '
          data-testid='no-data-message'
        >
          <p>{noDataMessage}</p>
        </div>
      )}
    </ChartContainer>
  );
};
