// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { GroupedTable as Grouped } from './grouped-table';
import { CellData as Data } from './cell-data';
import { SortHeader } from './sort-header';
import { Expander } from './expander';
import { Pagination } from './pagination';

export const Table = {
  Grouped,
  Data,
  SortHeader,
  Expander,
  Pagination,
};

export * from './cell-data';
export * from './expander';
export * from './grouped-table';
export * from './sort-header';
export * from './pagination';
export * from './use-pagination';
