// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouteObject,
} from 'react-router-dom';
import {
  Account,
  Accounts,
  AssetAllocation,
  AiBuilder,
  NotFound,
  Dashboard,
  Portfolio,
  Rebalance,
  RebalanceSummary,
  Research,
  Positions,
  TaxManager,
  Trader,
} from 'pages';
import { Layout } from 'features/ui/layout';
import { Authenticated } from 'components';
import App from 'pages/app';
import { workflowLoader } from 'features/utils/workflow-loader';

export type Paths =
  | '/'
  | '/account'
  | '/dashboard'
  | '/asset-allocation'
  | '/asset-allocation/positions'
  | '/ai-builder'
  | '/ai-builder/stock'
  | '/ai-builder/etf'
  | '/ai-builder/mutual-fund'
  | '/ai-builder/stock/positions'
  | '/ai-builder/etf/positions'
  | '/ai-builder/mutual-fund/positions'
  | '/analysis'
  | '/analysis/stock'
  | '/analysis/etf'
  | '/analysis/mutual-fund'
  | '/analysis/stock/positions'
  | '/analysis/etf/positions'
  | '/analysis/mutual-fund/positions'
  | '/rebalance'
  | '/rebalance/configuration'
  | '/rebalance/summary'
  | '/research'
  | '/tax-manager'
  | '/tax-manager/stock'
  | '/tax-manager/etf'
  | '/tax-manager/stock/summary'
  | '/tax-manager/etf/summary'
  | '/trader';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <Authenticated>
        <App />
        <Layout />
      </Authenticated>
    ),
    errorElement: <NotFound error />,
    children: [
      { element: <Accounts />, index: true },
      { element: <Dashboard />, path: 'dashboard' },
      { element: <Research />, path: 'research' },
      {
        element: <Outlet />,
        path: 'ai-builder',
        children: [
          {
            element: <Navigate to='stock' />,
            loader: workflowLoader('new', 'stock'),
            index: true,
          },
          ...(['stock', 'etf', 'mutual_fund'] as const).flatMap((asset) => [
            {
              element: <AiBuilder workflow={asset.replace('_', '-') as any} />,
              path: asset.replace('_', '-'),
              loader: workflowLoader('new', asset),
            },
            {
              element: (
                <Positions
                  backTo={`/ai-builder/${asset.replace('_', '-')}` as any}
                />
              ),
              path: `${asset.replace('_', '-')}/positions`,
            },
          ]),
        ],
      },
      {
        element: <Outlet />,
        path: 'analysis',
        children: [
          {
            element: <Navigate to='stock' />,
            index: true,
            loader: workflowLoader('load', 'stock'),
          },
          ...(['stock', 'etf', 'mutual_fund'] as const).flatMap((asset) => [
            {
              element: (
                <Portfolio
                  to={`/analysis/${asset.replace('_', '-')}/positions` as any}
                />
              ),
              path: asset.replace('_', '-'),
              loader: workflowLoader('load', asset),
            },
            {
              element: (
                <Positions backTo={`/analysis/${asset.replace('_', '-')}` as any} />
              ),
              path: `${asset.replace('_', '-')}/positions`,
            },
          ]),
        ],
      },
      {
        element: <Outlet />,
        path: 'rebalance',

        children: [
          {
            element: <Portfolio to='/rebalance/configuration' />,
            index: true,
            loader: workflowLoader('rebalance'),
          },
          { element: <Rebalance />, path: 'configuration' },
          { element: <RebalanceSummary />, path: 'summary' },
        ],
      },
      {
        element: <Outlet />,
        path: 'tax-manager',
        children: [
          {
            element: <Navigate to='stock' />,
            loader: workflowLoader('tax-harvesting', 'stock'),
            index: true,
          },
          ...(['stock', 'etf'] as const).flatMap((asset) => [
            {
              element: (
                <Portfolio
                  to={`/tax-manager/${asset.replace('_', '-')}/summary` as any}
                />
              ),
              path: asset.replace('_', '-'),
              loader: workflowLoader('tax-harvesting', asset),
            },
            {
              element: <TaxManager />,
              path: `${asset.replace('_', '-')}/summary`,
            },
          ]),
        ],
      },
      {
        element: <Outlet />,
        path: 'asset-allocation',
        children: [
          {
            element: <AssetAllocation />,
            index: true,
            loader: workflowLoader('asset-allocation', 'etf'),
          },
          { element: <Positions backTo='/asset-allocation' />, path: 'positions' },
        ],
      },
      { element: <Trader />, path: 'trader' },
    ],
  },
  {
    path: 'account',
    errorElement: <NotFound error />,
    element: (
      <Authenticated>
        <App />
        <Account />
      </Authenticated>
    ),
  },
  {
    path: '*',
    errorElement: <NotFound error />,
    element: (
      <Authenticated>
        <App />
        <NotFound />
      </Authenticated>
    ),
  },
];

export const router = createBrowserRouter(routes);
