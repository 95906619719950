// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { forwardRef, useCallback } from 'react';
import MUISlider, { SliderProps as MUISliderProps } from '@mui/material/Slider';
import { InputPercentage } from 'components/input-percentage';
import styles from './slider.module.css';
import { classNames } from 'features/utils/classnames';

export interface SliderProps extends MUISliderProps {
  readonly enableInput?: boolean;
}

export const Slider = forwardRef<HTMLSpanElement, SliderProps>(
  ({ enableInput, ...props }, ref) => {
    const onLeftInputChange = useCallback(
      (value: number | null) => {
        if (Array.isArray(props.value)) {
          const newValue = [props.value[0]!, value ?? 0];

          props?.onChange?.(new Event('change'), newValue, 1);
        }
      },
      [props]
    );

    const onRightInputChange = useCallback(
      (value: number | null) => {
        if (Array.isArray(props.value)) {
          const newValue = [value ?? 0, props.value[1]!];

          props?.onChange?.(new Event('change'), newValue, 0);
        }
      },
      [props]
    );

    return (
      <>
        {enableInput && Array.isArray(props.value) ? (
          <fieldset
            className={classNames(styles.container, 'group')}
            aria-orientation={props.orientation}
          >
            <InputPercentage
              className={styles.input}
              value={props.value?.[1]}
              onChange={onLeftInputChange}
              controls={false}
            />
            <MUISlider
              {...props}
              ref={ref}
              valueLabelDisplay='off'
              className={classNames(styles.slider)}
            />
            <InputPercentage
              className={styles.input}
              value={props.value?.[0]}
              onChange={onRightInputChange}
              controls={false}
            />
          </fieldset>
        ) : (
          <MUISlider ref={ref} {...props} />
        )}
      </>
    );
  }
);

Slider.displayName = 'Slider';
