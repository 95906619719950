// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

const logo = require('assets/img/dashboard_logo_color.png');

export const MobileNotSupported = () => {
  return (
    <div
      className='lg:hidden fixed top-0 h-full w-full bg-[#EDF0F9] z-[2000] flex justify-center items-center p-5'
      data-testid='mobile-not-supported'
    >
      <div className='flex flex-col items-center max-w-lg'>
        <img src={logo} className='w-28 h-12 mb-4' />

        <h3 className='mb-4 text-xl font-bold'>We're sorry</h3>

        <span className='text-center text-[#2d405a]'>
          Unfortunately Citec platform does not support mobile devices for now. We
          are working on it!
        </span>
      </div>
    </div>
  );
};
