// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Backtest } from 'api/interfaces/ai/portfolio';
import { ChartContainer } from 'components';
import { classNames } from 'features/utils/classnames';
import { formatDateString } from 'features/utils/format-date';
import { SERIES_COLORS } from 'features/utils/series-colors';
import { numberToFixed } from 'features/utils/to-fixed-number';
import {
  ColorType,
  IChartApi,
  LineData,
  LineStyle,
  LineType,
  createChart,
} from 'lightweight-charts';
import {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Legend } from './legend';

interface SeriesData {
  label: string;
  data: LineData[];
  color: string;
  variation: number;
  name: string;
}

interface TooltipData {
  value: number;
  color: string;
  label: string;
  variation: string;
  title: string;
}

export interface LinesChartProps {
  data: Array<Backtest & { ticker: string; name: string }>;
  title: string;
  benchmark: string;
}

export const LinesChart: FC<LinesChartProps> = (props) => {
  const { data, title, benchmark } = props;

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chart = useRef<IChartApi>();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const resizeObserver = useRef<ResizeObserver>();

  const [activeSeries, setActiveSeries] = useState<Record<string, boolean>>({});
  const [tooltipValues, setTooltipValues] = useState<TooltipData[]>();
  const [currentVisibleRange, setCurrentVisibleRange] = useState<any>(null);
  const [time, setTime] = useState<string>();

  const seriesData: SeriesData[] = useMemo(
    () =>
      data.map((serie, index) => {
        // Map the data to avoid duplicates
        const dataMap = new Map<string, number>(
          serie.ts_backtest.date.map((date, index) => [
            date,
            serie.ts_backtest.portfolio[index] * 100, // Convert to percentage
          ])
        );

        return {
          label: serie.ticker === benchmark ? 'BENCHMARK' : serie.ticker,
          data: Array.from(dataMap).map(([time, value]) => ({ time, value })), // Create the LineData array
          color: SERIES_COLORS[index],
          variation: numberToFixed((serie.ts_backtest.portfolio.at(-1)! - 1) * 100), // Calculate last value variation
          name: serie.name,
        };
      }),
    [benchmark, data]
  );

  const displaySeries = useMemo(
    () => seriesData.filter((serie) => !activeSeries[serie.label]),
    [activeSeries, seriesData]
  );

  useEffect(() => {
    /* istanbul ignore if */
    if (!chartContainerRef.current) return;

    chart.current = createChart(chartContainerRef.current!, {
      layout: {
        background: { type: ColorType.Solid, color: '#edf0f9' },
        textColor: '#515F70',
        fontFamily: 'Poppins',
        fontSize: 12,
      },
      timeScale: {
        allowBoldLabels: false,
        borderVisible: false,
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          style: LineStyle.LargeDashed,
        },
      },
      rightPriceScale: {
        visible: false,
      },
      leftPriceScale: {
        visible: true,
        borderVisible: false,
      },
      crosshair: {
        horzLine: { visible: false, labelVisible: false },
        vertLine: { labelVisible: false },
      },
      width: chartContainerRef.current!.clientWidth,
      height: chartContainerRef.current!.clientHeight,
    });

    // Add the series to the chart
    const series = displaySeries.map((series) => {
      const lineSeries = chart.current!.addLineSeries({
        priceScaleId: 'left',
        lineStyle: LineStyle.Solid,
        lineWidth: 2,
        color: series.color,
        lineType: LineType.Simple,
        priceLineVisible: false,
        priceFormat: {
          type: 'custom',
          formatter: /* istanbul ignore next */ (price: number) =>
            `${price?.toFixed(2)}%`,
        },
      });

      lineSeries.setData(series.data);

      return {
        ...series,
        line: lineSeries,
      };
    });

    chart.current.timeScale().fitContent();

    /* istanbul ignore if */
    // Restore the visible range when the series change to avoid zooming out
    if (currentVisibleRange) {
      chart.current.timeScale().setVisibleLogicalRange(currentVisibleRange);
    }

    /* istanbul ignore next */
    // Subscribe to the crosshair move event to display the tooltip
    chart.current.subscribeCrosshairMove((param) => {
      if (param.point === undefined || !param.time) {
        tooltipRef.current!.style.display = 'none';
      } else {
        param.time && setTime(param.time as string);
        const data = series.map((serie) => {
          const data = param.seriesData.get(serie.line) as LineData;
          const value = data?.value;
          const label = serie.line.priceFormatter().format(value);
          const title = serie.label;
          const { color } = serie.line.options();
          const variation = `${(value - 100).toFixed(2)}`;
          return { value, title, color, label, variation };
        });

        setTooltipValues(data);

        if (!tooltipRef.current || !chart.current) return;

        const container = chartContainerRef.current!;

        const toolTipWidth = tooltipRef.current.clientWidth;

        const toolTipHeight = tooltipRef.current.clientHeight;

        const toolTipMargin = 15;

        const y = param.point.y;

        let left = param.point.x + toolTipMargin;

        if (left > container.clientWidth - toolTipWidth) {
          left = param.point.x - toolTipMargin - toolTipWidth;
        }

        let top = y + toolTipMargin;

        if (top > container.clientHeight - toolTipHeight) {
          top = y - toolTipHeight - toolTipMargin;
        }

        tooltipRef.current.style.left = left + 'px';

        tooltipRef.current.style.top = top + 'px';

        tooltipRef.current.style.display = 'block';
      }
    });

    return () => {
      chart.current!.remove();
    };
  }, [seriesData, currentVisibleRange, displaySeries]);

  useEffect(() => {
    resizeObserver.current = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      chart.current?.applyOptions({ width });
      setTimeout(() => void chart.current?.timeScale().fitContent(), 0);
    });

    resizeObserver.current.observe(chartContainerRef.current!);

    return () => resizeObserver.current!.disconnect();
  }, []);

  const onToggleSerie: MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    // Get the current visible range to restore it when the series change
    const currentVisibleRange = chart.current!.timeScale().getVisibleLogicalRange();
    setCurrentVisibleRange(currentVisibleRange);

    const serie = e.currentTarget.dataset['serie'];
    if (serie) setActiveSeries((prev) => ({ ...prev, [serie]: !prev[serie] }));
  }, []);

  const legends = useMemo(() => {
    return seriesData.map(({ label, color, variation, name }) => (
      <Legend
        key={label}
        label={label}
        color={!activeSeries[label] ? color : '#79828D'}
        variation={variation}
        onClick={onToggleSerie}
        tooltip={name}
      />
    ));
  }, [activeSeries, onToggleSerie, seriesData]);

  const formattedTime = useMemo(() => formatDateString(time!, 'medium'), [time]);

  return (
    <ChartContainer header={title} popupName='charts.returns' tooltip>
      <div className='flex gap-x-7 gap-y-2 justify-end flex-wrap'>{legends}</div>
      <div
        ref={chartContainerRef}
        className='h-full relative'
        data-testid='lines-chart'
      >
        {time && (
          <div
            ref={tooltipRef}
            className='bg-white z-50 absolute p-2 rounded-lg min-w-32 shadow-lg top-4'
            data-testid='tooltip'
          >
            <span className='text-lg block text-[#2D405A] text-center font-medium'>
              {formattedTime}
            </span>
            <div className='grid gap-1'>
              {tooltipValues?.map((serie, index) => (
                <div
                  key={index}
                  className='grid gap-x-2 grid-cols-[1fr_50px_50px] text-sm'
                  style={{ color: serie.color }}
                >
                  <span>{serie.title}</span>
                  <span>{serie.label} </span>
                  <span
                    className={classNames({
                      'text-[#0D9795]': serie.value > 100,
                      'text-[#D80027]': serie.value < 100,
                    })}
                  >
                    {serie.value > 100 ? '+' : ''}
                    {serie.variation}%
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </ChartContainer>
  );
};
