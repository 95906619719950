// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Menu, MenuItem } from '@mui/material';
import { useAuthentication } from 'hooks';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppLink } from 'routes/AppLink';
import { resetBroker } from 'store/slices/broker';
import { resetSavedPortfolios } from 'store/slices/my-research';

export const TopbarUserMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { user, logout } = useAuthentication();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(resetBroker());
    dispatch(resetSavedPortfolios());
    logout();
  };

  return (
    <div data-testid='topbar-user-menu'>
      <div onClick={handleMenu} className='flex items-center space-x-4'>
        <p className='font-medium text-[#2D405A]'>{user?.name}</p>
        <img src={user?.picture} alt='' className='size-10 rounded-full' />
      </div>

      <Menu
        classes={{ list: 'w-40' }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={AppLink} to='/account'>
          My Account
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  );
};
