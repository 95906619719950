// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AppIcon } from 'components/app-icon';
import { useDynamicDialog } from 'components/dynamic-dialog';
import { getPopup, PopupsName } from 'features/popups';
import { FC, useCallback } from 'react';

interface InfoPopupProps {
  name: PopupsName;
}

export const InfoPopupContent: FC<InfoPopupProps> = ({ name }) => {
  const content = getPopup(name);

  return (
    <div className='flex flex-col items-center space-y-8 text-[#515F70]'>
      <AppIcon icon='Info' size='2xl' color='main' />
      {content}
    </div>
  );
};

export const useInfoPopup = () => {
  const { openDialog } = useDynamicDialog();

  const showInfoPopup = useCallback(
    (name: PopupsName) => {
      openDialog(<InfoPopupContent name={name} />, {
        maxWidth: 'sm',
        fullWidth: true,
        testId: 'info-popup',
      });
    },
    [openDialog]
  );

  return showInfoPopup;
};
