// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import { selectBenchmark, updateOptimizationConfig } from 'store/slices/parameters';
import {
  selectCashPosition,
  selectPortfolioTrades,
  selectTaxHarvestingCash,
} from 'store/slices/portfolio-positions';
import { selectWorkflowAsset } from 'store/slices/workflow';
import { useValidateSoldPosition } from './use-validate-sold-position';

export const useSliderCash = () => {
  const assetType = useAppSelector(selectWorkflowAsset);
  const benchmark = useAppSelector((state) => selectBenchmark(state, 'ai-builder'));
  const isCashOnPortfolio = !!useAppSelector(selectCashPosition);
  const taxCash = useAppSelector(selectTaxHarvestingCash);
  const trades = useAppSelector(selectPortfolioTrades);
  const cashTrade = trades.find((trade) => trade.ticker === '$CASH');

  const investment = useMemo(
    () => taxCash + (cashTrade?.volume || 0),
    [cashTrade?.volume, taxCash]
  );

  const { soldCashOnTaxHarvesting } = useValidateSoldPosition();

  const dispatch = useDispatch();

  const handleInvestmentChange = (value: number) => {
    soldCashOnTaxHarvesting(value - taxCash);
  };

  const setPortfolioCash = () => {
    soldCashOnTaxHarvesting(investment - taxCash);
  };

  useEffect(() => {
    /* istanbul ignore next */
    if (assetType == 'etf' && benchmark == '') {
      dispatch(updateOptimizationConfig(['ai-builder', { benchmark: 'SPY' }]));
    }
  }, [assetType, benchmark, dispatch]);

  return {
    handleInvestmentChange,
    setPortfolioCash,
    investment,
    isCashOnPortfolio,
    taxCash,
  };
};
