// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAvailableUniverseData,
  fetchStaticRebalanceData,
  fetchAssetClasses,
} from './available-parameters-thunk';

const initialState = {
  universe: {
    universes: [
      {
        name: 'SPDR S&P 500',
        description: 'SPDR S&P 500',
      },
    ],
    sectors: [
      'Cash',
      'Communication Services',
      'Consumer Discretionary',
      'Consumer Staples',
      'Energy',
      'Financials',
      'Health Care',
      'Industrials',
      'Information Technology',
      'Materials',
      'Others',
      'Real Estate',
      'Utilities',
    ],
    factors: [
      'style',
      'size',
      'yield',
      'quality',
      'volatility',
      'momentum',
      'liquidity',
    ],
    esgSectors: [
      'adult_content',
      'alcoholic',
      'animal_testing',
      'coal_energy',
      'firearms',
      'fur_leather',
      'gambling',
      'gmo',
      'human_rights_policy',
      'military_contract',
      'nuclear',
      'palm_oil',
      'pesticides',
      'tobacco',
      'weapons',
    ],
    esgFactors: ['CO2 Emissions', 'Environment', 'Governance', 'Social'],
  },
  rebalance: {
    objectives: {
      stock: [
        {
          key: 'min_tracking_error',
          name: 'Minimize tracking error',
        },
        {
          key: 'min_risk_parity',
          name: 'Minimize risk parity',
        },
        {
          key: 'min_neg_sharpe_ratio',
          name: 'Maximize sharpe ratio',
        },
        {
          key: 'min_volatility',
          name: 'Minimize volatility',
        },
        {
          key: 'min_neg_excess_returns',
          name: 'Maximize excess returns',
        },
      ],
      etf: [
        {
          key: 'min_risk_parity',
          name: 'Minimize risk parity',
        },
        {
          key: 'min_neg_sharpe_ratio',
          name: 'Maximize sharpe ratio',
        },
        {
          key: 'min_volatility',
          name: 'Minimize volatility',
        },
        {
          key: 'min_neg_excess_returns',
          name: 'Maximize excess returns',
        },
        {
          key: 'min_neg_returns',
          name: 'Maximize returns',
        },
        {
          key: 'max_diversification_ratio',
          name: 'Maximize diversification ratio',
        },
      ],
      mutual_fund: [
        {
          key: 'min_risk_parity',
          name: 'Minimize risk parity',
        },
        {
          key: 'min_neg_sharpe_ratio',
          name: 'Maximize sharpe ratio',
        },
        {
          key: 'min_volatility',
          name: 'Minimize volatility',
        },
        {
          key: 'min_neg_excess_returns',
          name: 'Maximize excess returns',
        },
        {
          key: 'min_neg_returns',
          name: 'Maximize returns',
        },
        {
          key: 'max_diversification_ratio',
          name: 'Maximize diversification ratio',
        },
      ],
    },
    benchmarks: [
      {
        key: 'SPY',
        description: 'SPDR S&P 500',
        ticker: 'SPY',
      },
      {
        key: 'QQQ',
        description: 'Invesco QQQ Trust Series 1',
        ticker: 'QQQ',
      },
    ],
  },
  assetClasses: [
    {
      asset_category: 'Fixed Income',
      asset_class: 'US total bond market',
      signal_ticker: 'BND',
      etf: 'BND',
    },
    {
      asset_category: 'Equity',
      asset_class: 'US total stock market',
      signal_ticker: 'VTI',
      etf: 'VTI',
    },
  ],
};

export const availableParametersSlice = createSlice({
  name: 'availableParameters',
  initialState,
  reducers: {
    resetAvailableParameters: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableUniverseData.fulfilled, (state, action) => {
      state.universe.universes = action.payload.universes;
      state.universe.sectors = action.payload.sectors;
      state.universe.factors = action.payload.factors;
    });
    builder.addCase(fetchStaticRebalanceData.fulfilled, (state, action) => {
      state.rebalance.objectives.stock = action.payload.stock_objectives;
      state.rebalance.objectives.etf = action.payload.etf_objectives;
      state.rebalance.benchmarks = action.payload.benchmarks;
    });
    builder.addCase(fetchAssetClasses.fulfilled, (state, action) => {
      state.assetClasses = action.payload;
    });
  },
});

export const { resetAvailableParameters } = availableParametersSlice.actions;

export const availableParametersReducer = availableParametersSlice.reducer;
