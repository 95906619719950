// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AssetsComparison } from 'api/interfaces/ai/assets';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MontecarloInsightsPayload } from 'api/interfaces/ai/montecarlo';
import { PortfolioAnalysis } from 'api/interfaces/ai/portfolio-analysis';
import {
  ChatMessage,
  CopilotBufferState,
  InsightPayload,
  Intent,
} from 'api/interfaces/copilot/chat';

const initialState: CopilotBufferState = {
  messages: [],
  insightPayload: { comparison: null, portfolio: null, simulation: null },
  intent: 'citec',
  context: '',
  chatWidth: 615,
};

export const copilotBufferSlice = createSlice({
  name: 'copilotBuffer',
  initialState,
  reducers: {
    setCopilotMessages: (state, action: PayloadAction<ChatMessage[]>) => {
      const updatedMessages = [...state.messages, ...action.payload];
      state.messages = updatedMessages.slice(-3);
    },
    setCopilotInsightPayload: (
      state,
      action: PayloadAction<Partial<InsightPayload>>
    ) => {
      state.insightPayload = { ...state.insightPayload, ...action.payload };
    },
    setCopilotComparisonInsightPayload: (
      state,
      { payload }: PayloadAction<AssetsComparison>
    ) => {
      state.insightPayload.comparison = payload;
    },
    setCopilotPortfolioInsightPayload: (
      state,
      { payload }: PayloadAction<PortfolioAnalysis>
    ) => {
      state.insightPayload.portfolio = payload;
    },
    setCopilotSimulationInsightPayload: (
      state,
      { payload }: PayloadAction<MontecarloInsightsPayload>
    ) => {
      state.insightPayload.simulation = payload;
    },
    resetCopilotInsightPayload: (state) => {
      state.insightPayload = initialState.insightPayload;
    },
    setCopilotIntent: (state, action: PayloadAction<Intent>) => {
      state.intent = action.payload;
    },
    setCopilotContext: (state, action: PayloadAction<string>) => {
      state.context = action.payload;
    },
    setCopilotWidth: (state, action: PayloadAction<number>) => {
      state.chatWidth = action.payload;
    },
    resetCopilot: () => initialState,
  },
});

export const {
  setCopilotMessages,
  setCopilotInsightPayload,
  setCopilotComparisonInsightPayload,
  setCopilotPortfolioInsightPayload,
  setCopilotSimulationInsightPayload,
  resetCopilotInsightPayload,
  setCopilotIntent,
  setCopilotContext,
  setCopilotWidth,
  resetCopilot,
} = copilotBufferSlice.actions;

export const copilotBufferReducer = copilotBufferSlice.reducer;
