// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { PortfolioLoaded } from 'features/utils/interfaces/portfolio-loaded';
import { useConst } from 'hooks';
import { FC, Dispatch, SetStateAction, useState } from 'react';
import { useMemo } from 'react';
import { TableRow } from './table-row';

export interface TablePositionsProps {
  excelData: PortfolioLoaded[];

  setExcelData: Dispatch<SetStateAction<PortfolioLoaded[]>>;

  updateMyData(rowIndex: number, columnId: string, value: any): void;

  invalidPositions?: string[];
}

export const TablePositions: FC<TablePositionsProps> = ({
  excelData,
  setExcelData: setData,
  updateMyData,
  invalidPositions,
}) => {
  const headers = useConst(() => [
    'Ticker/ISIN',
    'Investment',
    'Capital gains',
    'Acquisition date',
  ]);

  const currentDate = useConst(() => new Date().toISOString().split('T')[0]);

  const [initialValue, setInitialValue] = useState<Required<PortfolioLoaded>>({
    ticker: '',
    outstanding_balance: 0,
    unrealized_capital_gains: 0,
    date: currentDate,
    selectedAsset: false,
  });

  const data = useMemo(
    () =>
      excelData.map((value) => ({
        ...value,
        date: new Date(value?.date ?? Date.now()).toISOString().split('T')[0],
      })),
    [excelData]
  );

  return (
    <>
      <div
        className='grid [grid-auto-rows:minmax(24px,auto)] mt-4 relative'
        data-testid='table-positions'
      >
        <div className='grid grid-cols-[repeat(4,minmax(180px,1fr))_20px] gap-2.5 sticky top-0 !bg-[#edf0f9] z-[1]'>
          {Array.from(headers, (title, key) => (
            <span key={key} className='uppercase text-[#79828D] font-normal'>
              {title}
            </span>
          ))}
          <div />
        </div>
        <TableRow
          id={-1}
          initialValue={initialValue}
          portfolio={initialValue}
          setData={setData}
          setInitialValue={setInitialValue}
          updateMyData={updateMyData}
          autocomplete
        />
        {Array.from(data, (portfolio, key) => (
          <TableRow
            key={key}
            id={key}
            initialValue={initialValue}
            portfolio={portfolio}
            setData={setData}
            setInitialValue={setInitialValue}
            updateMyData={updateMyData}
            invalidPositions={invalidPositions}
          />
        ))}
      </div>
    </>
  );
};
