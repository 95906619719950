// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

export const segmentsFormatted = (segment: string) => {
  const segmentList = {
    Commodities: 'Commodities',
    EAFEstocks: 'Equity EAFE',
    Emergingmarkets: 'Equity emerging markets',
    Emergingmarketsbonds: 'Equity emerging markets bonds',
    Europe: 'Equity Europe',
    Highyieldbonds: 'Bonds US high-yield',
    Gold: 'Gold',
    TIPS: 'TIPS',
    Internationalbondmarket: 'Bonds international market',
    Investmentgradebonds: 'Bonds US investment grade',
    Latinamerica: 'Equity Latam',
    Longtermtreasuries: 'Treasuries long term',
    Pacific: 'Equity Pacific',
    Preferredstocks: 'Equity preferred stocks',
    Shorttermtreasuries: 'Treasuries short term',
    UScash: 'Cash',
    USlargecap: 'Equity US large cap',
    USREITs: 'US REITs',
    USsmallcap: 'Equity small cap',
    UStechnology: 'Equity US technology',
    UStotalbondmarket: 'Bonds US total market',
    UStotalstockmarket: 'Stock US total market',
    Worldallcountries: 'Equity world all countries',
  };

  let formattedSegment = segmentList[segment as keyof typeof segmentList];

  if (formattedSegment == undefined && segment.includes('7-10')) {
    formattedSegment = 'Treasuries intermediate term 3 to 7 years';
  }
  if (formattedSegment == undefined && segment.includes('3-7')) {
    formattedSegment = 'Treasuries intermediate term 7 to 10 years';
  }

  return formattedSegment;
};
