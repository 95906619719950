// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AssetBrand, OptionsSelector } from 'components';
import { FC, useCallback, useMemo } from 'react';
import { Tag } from 'features/utils/interfaces/redux/tag-manager-state';
import { TagItem } from './tag-item';

export interface TagSegmentProps {
  ticker: string;
  logo?: string | null;
  options: Tag[];
  selectedTags: string[];
  onTickerTag: (tag: string, ticker: string) => void;
  onTickerTagDelete: (tag: string, ticker: string) => void;
}

export const TagSegment: FC<TagSegmentProps> = ({
  ticker,
  logo,
  options,
  selectedTags,
  onTickerTagDelete,
  onTickerTag,
}: TagSegmentProps) => {
  const optionValues = useMemo(
    () => options.map((option) => option.name),
    [options]
  );

  const onChangeValueSelected = useCallback(
    (value: string) => {
      if (!selectedTags.includes(value)) {
        onTickerTag(value, ticker);
      }
    },
    [onTickerTag, selectedTags, ticker]
  );

  return (
    <>
      <div className='col-span-1 p-2 rounded-lg flex mb-2.5 text-[#515F70] items-start'>
        <AssetBrand logoUrl={logo!} ticker={ticker} />
      </div>

      <div className='col-span-1 p-1 assign-tag-selector flex mb-2.5 items-start'>
        <OptionsSelector
          optionsAvailable={optionValues}
          onChangeValueSelected={onChangeValueSelected}
          optionSelected={''}
          defaultText='Ticker tags'
          selectType='white'
        />
      </div>

      <div className='col-span-1 p-1 rounded-lg flex items-start'>
        <div className='grid grid-cols-3 gap-x-6 gap-y-3.5 w-full'>
          {Array.from(selectedTags, (tag, key) => (
            <TagItem
              key={`${key}-${tag}`}
              label={tag}
              tag={tag}
              ticker={ticker}
              data-testid={`${key}-${tag}`}
              onDelete={onTickerTagDelete}
            />
          ))}
        </div>
      </div>
    </>
  );
};
