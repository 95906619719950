// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import React, { useCallback, useState } from 'react';
import { TextField } from '@mui/material';
import { Heading } from 'components/heading';
import { IconButton } from 'features/ui/icon-button';

export interface EditableTextProps {
  text: string;
  onEdit: (text: string) => void;
  className?: string;
}

export const EditableText: React.FC<EditableTextProps> = ({
  text,
  onEdit,
  className = '',
}) => {
  const [editing, setEditing] = useState(false);
  const [editedText, setEditedText] = useState(text);

  const saveChanges = useCallback(() => {
    setEditing(false);
    onEdit(editedText);
  }, [editedText, onEdit]);

  const handleEditClick = useCallback(() => {
    setEditing(true);
  }, []);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEditedText(event.target.value);
    },
    []
  );

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      /* istanbul ignore next */
      if (event.key === 'Enter') {
        saveChanges();
      }
    },
    [saveChanges]
  );

  const handleBlur = useCallback(() => {
    saveChanges();
  }, [saveChanges]);

  return (
    <div className={className}>
      {editing ? (
        <TextField
          variant='standard'
          InputProps={{
            classes: {
              root: 'input-edit-saved-portfolio',
            },
          }}
          value={editedText}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          onBlur={handleBlur}
          autoFocus
        />
      ) : (
        <Heading title={editedText} variant='h3' />
      )}
      <IconButton
        className='ml-3'
        icon='Pen'
        variant='icon'
        onClick={handleEditClick}
        size='lg'
        data-testid='edit-text-btn'
      />
    </div>
  );
};
