// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { FC } from 'react';

export const Disclaimer: FC = () => (
  <footer
    className='text-center bg-[#020125] text-white py-4 px-24'
    data-testid='disclaimer'
  >
    <h6 className='text-xs font-semibold'>DISCLAIMER</h6>
    <p className='text-[9px]'>
      Citec Solutions makes no warranties, expressed or implied, and hereby disclaims
      and negates all other warranties, including without limitation, implied
      warranties or conditions of merchantability, fitness for a particular purpose,
      or non-infringement of intellectual property or other violation of rights.
      Further, CITEC Solutions does not warrant or make any representations
      concerning the accuracy, likely results, or reliability of the use of the
      materials on its Internet web site or otherwise relating to such materials or
      on any sites linked to this site.”
    </p>
    <p className='text-[9px]'>
      We do not provide financial advice. We do not and cannot guarantee the future
      performance of your portfolio nor the success of investment strategies we may
      use or suggest. Historical performances used in our platform do not serve as
      warrants for future performances. We do not provide legal nor fiscal advice,
      our optimizations have been prepared for informational purposes only. We do not
      warrant accuracy, reliability or responsibility around possible results. We
      only serve as an additional intelligence layer over your broker account, no
      recommendations given by these partners are applied within Citec.
    </p>
  </footer>
);
