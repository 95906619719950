// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { ReactNode } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Modal } from './modal';
import styles from './modal.module.css';
import { AppIcon } from 'components/app-icon';

export type ModalButton = 'close' | 'cancel' | 'deny' | 'confirm';

export interface ModalOptions
  extends Pick<SweetAlertOptions, 'preConfirm' | 'allowOutsideClick' | 'didOpen'> {
  readonly title?: ReactNode;

  readonly content?: ReactNode;

  readonly icon?: ReactNode;

  readonly variant?: 'overflow' | 'box';

  readonly buttons?: Partial<Record<ModalButton, { show?: boolean; text?: string }>>;

  readonly input?: Partial<{
    type: SweetAlertOptions['input'];
    placeholder: string;
  }>;

  readonly reverseButtons?: boolean;

  readonly width?: number | `${number}${'px' | '%' | undefined}`;
}

export const showModal = <T,>({
  title,
  content,
  icon,
  variant = 'box',
  buttons = {},
  input = {},
  reverseButtons = true,
  width,
  ...props
}: ModalOptions) =>
  Swal.fire<T>({
    html: renderToStaticMarkup(
      <Modal title={title} variant={variant} icon={icon}>
        {content}
      </Modal>
    ),
    showCancelButton: buttons?.cancel?.show,
    showDenyButton: buttons?.deny?.show,
    showCloseButton: buttons?.close?.show,
    showConfirmButton: buttons?.confirm?.show,
    cancelButtonText: buttons?.cancel?.text ?? 'Cancel',
    denyButtonText: buttons?.deny?.text ?? 'No',
    confirmButtonText: buttons?.confirm?.text ?? 'OK',
    input: input?.type,
    inputPlaceholder: input?.placeholder,
    reverseButtons,
    width,
    showLoaderOnConfirm: !!props.preConfirm,
    showLoaderOnDeny: !!props.preConfirm,
    closeButtonHtml: renderToStaticMarkup(<AppIcon icon='CloseOutline' size={20} />),
    customClass: {
      popup: [
        styles.popup,
        variant === 'overflow' ? styles['popup-overflow'] : '',
      ].filter(Boolean),
      title: [styles.title],
      htmlContainer: styles['html-container'],
      actions: styles.actions,
      closeButton: styles['close-button'],
      confirmButton: [styles.button, styles['button-confirm']],
      denyButton: [styles.button, styles['button-cancel']],
      cancelButton: [styles.button, styles['button-cancel']],
      input: [styles.input],
    },
    ...props,
  });
