// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { traderApi } from 'api/ai/trader-api';
import { portfolioApi } from 'api/brokerage/portfolio-api';
import type { TraderOrderPayload } from 'api/interfaces/ai/trader';
import type { BaseOrder, Order } from 'api/interfaces/brokerage/commons';
import type { LatestPricesResp } from 'api/interfaces/commons';
import type { TradeDetails as Trade } from 'features/utils/interfaces/redux/trades';
import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import { selectAccountId, selectBrokerConfiguration } from 'store/slices/broker';
import { setTrades } from 'store/slices/trader';
import { delay } from '../utils';

export interface OrderStatus {
  ticker: string;
  qty: number;
  status: string;
  volume: number;
  logo: string;
  operation: string;
}

export const useExecuteTrades = (trades: Trade[]) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [orders, setOrders] = useState<OrderStatus[]>([]);
  const broker = useAppSelector(selectBrokerConfiguration);
  const brokerAccountId = useAppSelector(selectAccountId);

  // calculate the orders based on the latest prices
  const getOrders = useCallback(
    async (prices: LatestPricesResp[]): Promise<BaseOrder[]> => {
      const payload: TraderOrderPayload = {
        latest_prices: prices,
        trades,
      };
      const ordersResp = await traderApi.order({ payload });
      const orders = ordersResp.map((order) => {
        return {
          ticker: order.ticker,
          stocks_number: order.contracts_number,
          side: order.operation.toLowerCase(),
        };
      });

      // sort orders by sell first then buy orders
      const sortedOrders = orders.sort((a) => (a.side === 'sell' ? -1 : 1));
      return sortedOrders;
    },
    [trades]
  );

  const executeOrders = useCallback(
    async (orders: BaseOrder[]) => {
      const resp = await portfolioApi.postOrders(broker, {
        orders,
        account_id: brokerAccountId,
      });
      return resp;
    },
    [broker, brokerAccountId]
  );

  const checkOrdersStatus = useCallback(
    async (executedOrders: Order[]) => {
      // await 1.5 seconds then check the orders status
      await delay(1500);

      const today = new Date();
      const tomorrow = new Date(new Date().setDate(today.getDate() + 1));
      const order_filter = {
        start_date: today.toLocaleDateString('en-US'),
        end_date: tomorrow.toLocaleDateString('en-US'),
      };

      const todayOrders = await portfolioApi.ordersSearch(broker, {
        order_filter,
        account_id: brokerAccountId,
      });

      const executedOrdersIds = executedOrders.map(({ order_id }) => order_id);
      const executedOrdersStatus = todayOrders.filter(({ order_id }) =>
        executedOrdersIds.includes(order_id)
      );
      return executedOrdersStatus;
    },
    [broker, brokerAccountId]
  );

  const updateOrdersStatus = useCallback(
    (executedOrders: Order[]) => {
      const updatedOrders = trades.map((trade) => {
        const executedOrder = executedOrders.find(
          (executedOrder) => executedOrder.ticker === trade.ticker
        );

        return {
          ticker: trade.ticker,
          qty: executedOrder?.stocks_number || trade.contracts_number,
          status: executedOrder?.status || 'error',
          volume: trade.expected_volume,
          logo: trade.columnName?.url_logo as string,
          operation: trade.operation.toLocaleLowerCase(),
        };
      });
      setOrders(updatedOrders);
    },
    [trades]
  );

  const setInitialOrders = useCallback(() => {
    const orders = trades.map((trade) => {
      return {
        ticker: trade.ticker,
        qty: trade.contracts_number,
        status: 'pending',
        volume: trade.expected_volume,
        logo: trade.columnName?.url_logo as string,
        operation: trade.operation.toLocaleLowerCase(),
      };
    });
    setOrders(orders);
  }, [trades]);

  const updateTrader = useCallback(
    (executedOrders: Order[]) => {
      const failedOrders = executedOrders.filter(
        (order) => order.status === 'error'
      );

      const failedTrades = failedOrders
        .map((order) =>
          trades.find(
            (trade) =>
              trade.ticker === order.ticker &&
              trade.operation === order.side.toUpperCase()
          )
        )
        .filter(Boolean) as Trade[];

      dispatch(setTrades(failedTrades));
    },
    [dispatch, trades]
  );

  const executeTrades = useCallback(
    async (prices: LatestPricesResp[]) => {
      setInitialOrders();
      setOpen(true);
      setLoading(true);
      const orders = await getOrders(prices);
      const executedOrders = await executeOrders(orders);
      const executedOrdersStatus = await checkOrdersStatus(executedOrders);
      updateOrdersStatus(executedOrdersStatus);
      updateTrader(executedOrders);
      setLoading(false);
    },
    [
      checkOrdersStatus,
      executeOrders,
      getOrders,
      setInitialOrders,
      updateOrdersStatus,
      updateTrader,
    ]
  );

  const closeModal = useCallback(() => setOpen(false), []);

  return { executeTrades, closeModal, open, orders, loading };
};
