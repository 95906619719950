// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { useState } from 'react';

type UseCheckboxResult = {
  selected: string[];
  toggleCheckbox: (value: string) => void;
  selectAll: () => void;
  clearSelection: () => void;
  isAllSelected: boolean;
};

export const useCheckbox = (
  options: string[],
  defaultSelected = []
): UseCheckboxResult => {
  const [selected, setSelected] = useState<string[]>(defaultSelected);

  const toggleCheckbox = (value: string) => {
    setSelected((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((v) => v !== value)
        : [...prevSelected, value]
    );
  };

  const selectAll = () => setSelected(options);

  const clearSelection = () => setSelected([]);

  const isAllSelected = options.length === selected.length;

  return {
    selected,
    toggleCheckbox,
    selectAll,
    clearSelection,
    isAllSelected,
  };
};
