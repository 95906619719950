// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { AssetTypes, resetAssetType } from 'store/slices/assets-type';
import { resetAvailableParameters } from 'store/slices/available-parameters';
import { resetCopilot } from 'store/slices/copilot-buffer';
import { resetParameters } from 'store/slices/parameters';
import {
  resetSavedPortfolios,
  resetScreeners,
  resetWatchList,
} from 'store/slices/my-research';
import { resetTagManager } from 'store/slices/tag-manager';

export const useResetAppStore = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetAvailableParameters());
    dispatch(resetParameters());
    dispatch(resetTagManager());
    dispatch(resetAssetType(AssetTypes.ETFS));
    dispatch(resetAssetType(AssetTypes.MUTUAL_FUNDS));
    dispatch(resetWatchList());
    dispatch(resetSavedPortfolios());
    dispatch(resetScreeners());
    dispatch(resetCopilot());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
