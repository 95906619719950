// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import {
  type HeatMapDataProps,
  ResponsiveHeatMap,
  type HeatMapDatum,
} from '@nivo/heatmap';
import { type CorrMatrix as CorrelationMatrixValues } from 'api/interfaces/ai/assets';
import { FC, useMemo } from 'react';
import { ChartContainer } from './chart-container';
import { Tooltip } from '@mui/material';

export interface CorrelationMatrixProps {
  readonly values: CorrelationMatrixValues & { name?: string[] };
}

type HeatMapData = HeatMapDataProps<HeatMapDatum, Record<string, unknown>>['data'];

export const CorrelationMatrix: FC<CorrelationMatrixProps> = ({ values }) => {
  const data = useMemo<HeatMapData>(
    () =>
      values.ticker.map((ticker, index) => ({
        id: ticker,
        name: values?.name?.[index] ?? ticker,
        data: values.correlation_cols[ticker].map((value, index) => ({
          x: values.ticker[index],
          y: Math.round(value * 100),
        })),
      })),
    [values]
  );

  const colors = useMemo(
    () =>
      ({
        type: 'sequential',
        interpolator: (t: number) => {
          if (t < 0.5) return `rgba(121, 130, 141, ${(0.555555 - t) / 0.555555})`;

          if (t <= 1) return `rgba(16, 114, 186, ${(t - 0.454545) / 0.555555})`;
        },
        minValue: -100,
        maxValue: 100,
      } as const),
    []
  );

  const enableLabels = values.ticker.length <= 20;

  const axis = enableLabels
    ? {
        tickSize: 0,
        tickPadding: 8,
        tickRotation: 0,
        legend: '',
      }
    : null;

  const labels = useMemo(
    () =>
      Array.from(data, ({ id, name }) => (
        <div className='flex justify-center items-center' key={id}>
          <Tooltip title={name as string} placement='top'>
            <span className='text-[#515F70]'>{id}</span>
          </Tooltip>
        </div>
      )),
    [data]
  );

  return (
    <ChartContainer
      header='Correlation Matrix'
      popupName='charts.correlationMatrix'
      tooltip
    >
      <div className='py-4'>
        <div className="grid grid-cols-[auto_1fr] grid-rows-[auto_450px] [grid-template-areas:'._axis-x'_'axis-y_chart'] gap-2">
          <div
            className={`[grid-area:axis-x] grid grid-flow-col ${
              axis ? '' : 'hidden'
            }`}
          >
            {labels}
          </div>
          <div className={`[grid-area:axis-y] grid ${axis ? '' : 'hidden'}`}>
            {labels}
          </div>
          <div className='[grid-area:chart] overflow-hidden'>
            <ResponsiveHeatMap
              data-testid='responsive-heat-map'
              data={data}
              labelTextColor='#FFFFFF'
              colors={colors}
              margin={{ left: 0, top: 0, bottom: 0, right: 0 }}
              axisTop={null}
              axisLeft={null}
              theme={{
                axis: {
                  ticks: {
                    text: {
                      fontFamily: 'Poppins',
                      fontSize: 14,
                      color: '#515F70',
                      lineHeight: 14,
                    },
                  },
                },
                labels: {
                  text: {
                    fontFamily: 'Poppins',
                    fontSize: '1em',
                  },
                },
              }}
              enableLabels={enableLabels}
              animate={false}
            />
          </div>
        </div>
      </div>
    </ChartContainer>
  );
};
