// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import {
  ButtonHTMLAttributes,
  FocusEventHandler,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';
import { makeContentId, makeTriggerId, useTabsContext } from './tabs-context';
import styles from './tabs.module.css';

export interface TabsTriggerProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly value: string;
}

export const useTabsTrigger = (props: TabsTriggerProps) => {
  const context = useTabsContext();

  const triggerId = makeTriggerId(context.baseId, props.value);

  const contentId = makeContentId(context.baseId, props.value);

  const isSelected = useMemo(
    () => props.value === context.value,
    [props.value, context.value]
  );

  const onTabFocus: FocusEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      if (!isSelected && !props.disabled) {
        context.onValueChange(props.value);
      }

      props.onFocus?.(event);
    },
    [isSelected, props, context]
  );

  return {
    onTabFocus,
    isSelected,
    triggerId,
    contentId,
  };
};

export const TabsTrigger = forwardRef<HTMLButtonElement, TabsTriggerProps>(
  ({ children, disabled, className, ...props }, ref) => {
    const { contentId, isSelected, onTabFocus, triggerId } = useTabsTrigger({
      disabled,
      ...props,
    });

    return (
      <button
        type='button'
        role='tab'
        aria-selected={isSelected}
        aria-controls={contentId}
        data-state={isSelected ? 'active' : 'inactive'}
        aria-disabled={disabled}
        disabled={disabled}
        id={triggerId}
        onFocus={onTabFocus}
        className={`${styles['tabs-trigger']} ${className}`}
        data-testid={triggerId}
        {...props}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

TabsTrigger.displayName = 'TabsTrigger';
