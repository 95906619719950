// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { classNames } from 'features/utils/classnames';
import { getAssetLogo } from 'features/utils/get-asset-logo';
import { FC } from 'react';

interface AssetLogoProps {
  assetType?: string;
  logoUrl?: string;
  className?: string;
  onClick?: () => void;
}

export const AssetLogo: FC<AssetLogoProps> = ({
  assetType,
  logoUrl = '',
  className = '',
  onClick: handleClick,
}) => {
  const logo = getAssetLogo(logoUrl, assetType);

  return (
    <img
      src={logo}
      alt={`${assetType} Logo`}
      className={classNames('size-6 rounded-full object-fill', className)}
      onClick={handleClick}
      data-testid='asset-logo'
    />
  );
};
