// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AutocompleteInput } from 'components/autocomplete';
import { useValidateTicker } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import {
  selectCurrentPortfolio,
  updateHarvestingPosition,
} from 'store/slices/portfolio-positions';
import { selectWorkflowAsset } from 'store/slices/workflow';
import { Portfolio } from 'features/tax-manager/table';

interface ReplaceAssetPositionProps {
  item: Portfolio;
}

export const ReplaceAssetPosition = ({ item }: ReplaceAssetPositionProps) => {
  const [ticker, setTicker] = useState('');
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const assetType = useAppSelector(selectWorkflowAsset);
  const portfolio = useAppSelector(selectCurrentPortfolio);

  const position = useMemo(
    () => portfolio.find((asset) => asset.ticker === item.ticker),
    [portfolio, item.ticker]
  );

  const { isValidTicker, asset } = useValidateTicker(ticker, assetType);

  const onSelectValue = () => {
    setShowError(!isValidTicker && ticker !== '');
    let replacement;
    if (position && isValidTicker && asset) {
      replacement = {
        ticker: asset.ticker,
        isin: asset.isin || '-',
        url_logo: asset.url_logo || 'missing',
      };
    }

    dispatch(
      updateHarvestingPosition({
        ticker: item.ticker,
        replacement,
      })
    );
  };

  useEffect(() => {
    if (!position) {
      setShowError(false);
      setTicker('');
    }
  }, [position]);

  return (
    <AutocompleteInput
      onChangeValue={(e) => setTicker(e.target.value.toUpperCase())}
      onSelectValue={onSelectValue}
      onBlur={onSelectValue}
      value={ticker}
      name='ticker'
      noMargin
      error={true}
      className={`w-full -my-[5px] text-primary-medium disabled:bg-white outline-none
       ${showError ? '!border-[#E24646]/50' : 'border-0'}`}
      disabled={!position?.harvesting?.status}
      assetType={`${assetType}s`}
    />
  );
};
