// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { SectionContainer } from 'components';
import { PortfolioLoaded } from 'features/utils/interfaces/portfolio-loaded';
import { FC } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useUploaderExcel } from '../hooks';
import { Button } from '@mui/material';
import { IconButton } from 'features/ui/icon-button';

export interface UploaderExcelProps {
  typeAsset: string;
  setExcelData: React.Dispatch<React.SetStateAction<[] | PortfolioLoaded[]>>;
}

export const UploaderExcel: FC<UploaderExcelProps> = ({
  typeAsset,
  setExcelData,
}) => {
  const { handleFileChange, setExcelFileError, excelFileError } = useUploaderExcel({
    setExcelData,
  });

  return (
    <SectionContainer
      title='Upload Excel File'
      variant='h3'
      headerClass='justify-center'
      className='text-center sticky top-4'
    >
      <div
        className='border-dashed border-[#1072BA] border rounded-lg mt-4 grid'
        data-testid='btn-submit'
      >
        <FileUploader
          handleChange={handleFileChange}
          name='file'
          types={['XLSX']}
          onTypeError={setExcelFileError}
        >
          <div className='p-4 flex flex-col gap-2 justify-center'>
            <p className='font-semibold text-[#1072BA]'>
              Drag and drop a file <br />
            </p>
            <span className='font-normal text-[#79828D]'>Limit 200MB per file</span>
            <Button
              variant='tonal'
              color='secondary'
              data-testid='upInput'
              className='!px-4 !py-2 self-center'
            >
              Browse file
            </Button>
          </div>
        </FileUploader>
      </div>

      <a
        className='flex items-center justify-center mt-4 space-x-2 text-secondary hover:text-secondary/75'
        href={`/assets/${typeAsset || 'stock'}_template.xlsx`}
        data-testid='download-link'
        download
      >
        <p className='hover:underline hover:underline-offset-4'>Download template</p>
        <IconButton
          icon='Download'
          variant='fill'
          className='!bg-secondary hover:!bg-secondary/75'
        />
      </a>

      {!!excelFileError && (
        <p className='font-semibold text-[#FC848B]' data-testid='excel-file-error'>
          {excelFileError}
        </p>
      )}
    </SectionContainer>
  );
};
