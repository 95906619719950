// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { useEffect, useState } from 'react';
import axios from 'axios';
import { fetchAiApi } from 'api/ai-api';
import { fetchBrokerageApi } from 'api/brokerage-api';
import { useSafeUpdate, useHandleApiErrors } from 'hooks';

type UseFetchApiProps = {
  fetchFrom?: 'brokerApi' | 'aiApi';
  endpoint: string;
  method: 'POST' | 'GET' | 'DELETE';
  payload?: any;
  params?: any;
  startCallInmediatly?: boolean;
  makeIntervalCall?: boolean;
  dependency?: any;
};

export const useFetchApi = <T>({
  endpoint,
  payload,
  method,
  params,
  dependency,
  makeIntervalCall,
  startCallInmediatly = true,
  fetchFrom = 'brokerApi',
}: UseFetchApiProps) => {
  const handleApiErrors = useHandleApiErrors();
  const runSafeUpdate = useSafeUpdate();

  const apiConnectors = {
    aiApi: fetchAiApi,
    brokerApi: fetchBrokerageApi,
  };

  const [resp, setResp] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const apiConnectorParameters: any = {
    endpoint,
    method,
    params,
    payload,
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      try {
        const { data } = await apiConnectors[fetchFrom]<T>(apiConnectorParameters);

        runSafeUpdate(() => setResp(data));
      } catch (e: any) {
        setError(e.message);
        if (axios.isAxiosError(e)) handleApiErrors(e);
      }

      runSafeUpdate(() => setIsLoading(false));
    };

    if (makeIntervalCall) {
      getData();

      const interval = setInterval(() => {
        getData();
        // 15 min
      }, 900000);

      return () => {
        clearInterval(interval);
      };
    }

    if (startCallInmediatly) {
      runSafeUpdate(() => getData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startCallInmediatly, dependency]);

  return { resp, isLoading, error };
};
