// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { createAsyncThunk } from '@reduxjs/toolkit';
import { citecApi } from 'api/engine/citec-api';
import {
  SearchTickerTag,
  TickerTag,
  TickerTagFeature,
  TickerTagResp,
} from 'api/interfaces/engine/citec';
import { groupMap } from 'features/utils/groupby';
import { Tag } from 'features/utils/interfaces/redux/tag-manager-state';
import type { AppState } from 'store/store';
import { selectAllPositions } from '../broker';
import { selectWatchListPositions } from '../my-research';
import { BrokerPosition } from 'features/utils/interfaces/redux/broker';
import { selectAllTags, selectTag } from './tag-manager-selectors';
import { WatchListPosition } from 'features/utils/interfaces/redux/my-research-state';
import { selectFeature, toggleFeature } from 'features/utils/toggle-feature';

export const getAllTickerTags = createAsyncThunk(
  'tagManager/getAllTickerTags',
  async (params: Pick<SearchTickerTag, 'feature'>, { getState }): Promise<Tag[]> => {
    const response = await citecApi.searchTickerTag(params);

    const state = getState() as AppState;

    const positions = selectFeature(
      {
        'portfolio-positions': selectAllPositions(state),
        watchlist: selectWatchListPositions(state),
      },
      params.feature
    );

    const tags = groupMap(
      ({ tag }) => tag,
      (tag) => {
        switch (params.feature) {
          case TickerTagFeature.PortfolioPositions: {
            const position = (positions as BrokerPosition[]).find(
              (position) => position.ticker === tag.ticker
            );

            return {
              investment: position?.investment ?? 0,
              ticker: tag.ticker,
              unrealizedCapitalGains: position?.unrealized_capital_gains ?? 0,
              url_logo: position?.url_logo ?? null,
            };
          }
          default:
            return {
              investment: 0,
              ticker: tag.ticker,
              unrealizedCapitalGains: 0,
              url_logo:
                (positions as WatchListPosition[]).find(
                  (position) => position.ticker === tag.ticker
                )?.url_logo ?? null,
            };
        }
      },
      (tag, positions): Tag => ({
        name: tag,
        positions: positions,
        investment: positions.reduce(
          (current, next) => current + next.investment,
          0
        ),
        unrealizedCapitalGains: positions.reduce(
          (current, next) => current + next.unrealizedCapitalGains,
          0
        ),
      }),
      response
    );

    return tags;
  }
);

export const createTickerTag = createAsyncThunk(
  'tagManager/createTickerTag',
  async (
    {
      update,
      includeTickers,
      ...params
    }: TickerTagResp & { update?: boolean; includeTickers?: boolean },
    { getState }
  ) => {
    const response = await (update
      ? citecApi.updateTickerTag(
          {
            ...params,
            feature: toggleFeature(params.feature),
          },
          params
        )
      : citecApi.createTickerTag(params));

    const state = getState() as AppState;

    if (response.ticker) {
      const tag = selectTag(state, params.feature, params.tag)!;

      const positions =
        params.feature === TickerTagFeature.PortfolioPositions
          ? selectAllPositions(state)
          : selectWatchListPositions(state);

      switch (params.feature) {
        case TickerTagFeature.PortfolioPositions: {
          const position = (positions as BrokerPosition[]).find(
            (position) => position.ticker === response.ticker
          );

          const tagPositions = tag.positions.concat({
            investment: position?.investment ?? 0,
            ticker: response.ticker,
            unrealizedCapitalGains: position?.unrealized_capital_gains ?? 0,
            url_logo: position?.url_logo ?? null,
          });

          return {
            ...tag,
            positions: tagPositions,
            investment: tagPositions.reduce(
              (current, next) => current + next.investment,
              0
            ),
            unrealizedCapitalGains: tagPositions.reduce(
              (current, next) => current + next.unrealizedCapitalGains,
              0
            ),
          };
        }
        default:
          return {
            ...tag,
            positions: tag.positions.concat({
              investment: 0,
              ticker: response.ticker,
              unrealizedCapitalGains: 0,
              url_logo:
                (positions as WatchListPosition[]).find(
                  (position) => position.ticker === response.ticker
                )?.url_logo ?? null,
            }),
          };
      }
    }

    if (includeTickers) {
      const currentTag = selectTag(
        state,
        toggleFeature(params.feature),
        response.tag
      );

      return {
        name: response.tag,
        positions: currentTag?.positions ?? [],
        investment: currentTag?.investment ?? 0,
        unrealizedCapitalGains: currentTag?.unrealizedCapitalGains ?? 0,
      };
    }

    return {
      name: response.tag,
      positions: [],
      investment: 0,
      unrealizedCapitalGains: 0,
    };
  }
);

export const deleteTickerTag = createAsyncThunk(
  'tagManager/deleteTickerTag',
  async (params: TickerTag, { getState }) => {
    await citecApi.deleteTickerTag(params);

    if (params.ticker) {
      const state = getState() as AppState;

      const tags = selectAllTags(state, params.feature)!;

      return tags.map((tag) =>
        tag.name === params.tag
          ? {
              ...tag,
              positions: tag.positions.filter(
                (position) => position.ticker !== params.ticker
              ),
            }
          : tag
      );
    }

    return params.tag;
  }
);
