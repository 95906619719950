// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { Button } from '@mui/material';
import { AssetStock } from 'api/interfaces/brokerage/assets';
import { messageHighMape } from 'features/brokers/utils/message-high-mape';
import { alertUnavailableTrades } from 'features/trader/utils/alert-unavailable-trades';
import { MODAL_TEMPLATES_OPTIONS, ModalOptions, showModal } from 'features/ui/modal';
import { alertInsufficientCash } from 'features/utils/alert-insufficient-cash';
import { formatToMoney } from 'features/utils/format-to-money';
import { TradeDetails } from 'features/utils/interfaces/redux/trades';
import { validateOpenMarket } from 'features/utils/validate-open-market';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import { selectBrokerApiParams, setOpenLinkAccountModal } from 'store/slices/broker';
import { selectValidTrades } from 'store/slices/trader';
import Swal from 'sweetalert2';
import { useExecuteTrades } from '../hooks/use-execute-trades';
import { getAssetsExchange, getLatestPrices } from '../utils';
import { OrdersStatus } from './orders-status';

export type ButtonExecuteTradesProps = {
  data: TradeDetails[];
  mape: number;
  areSomeTradeUnavailable: boolean;
  container?: boolean;
  debt?: number;
};

export const ButtonExecuteTrades = ({
  mape,
  areSomeTradeUnavailable,
  container = true,
  debt = 0,
}: ButtonExecuteTradesProps) => {
  const dispatch = useDispatch();

  const broker = useAppSelector(selectBrokerApiParams);
  const validTrades = useAppSelector(selectValidTrades);

  const { open, closeModal, executeTrades, orders, loading } =
    useExecuteTrades(validTrades);

  const openModal = useCallback(
    () => dispatch(setOpenLinkAccountModal(true)),
    [dispatch]
  );

  const updateModalTable = useCallback(
    (obj: AssetStock[], prices: Record<string, number>) => {
      return showModal({
        ...MODAL_TEMPLATES_OPTIONS.EXECUTE_TRADES,
        content: (
          <div id='tradeExecutionTable' className='max-h-72 overflow-y-auto !mt-0'>
            <table className='table-modal table-auto mt-2 mx-auto'>
              <thead className='sticky top-0 bg-white'>
                <tr>
                  <th>TICKER</th>
                  <th>QTY</th>
                  <th>ESTIMATED PRICE</th>
                  <th>INVESTMENT</th>
                  <th>MARKET</th>
                  <th>TYPE</th>
                </tr>
              </thead>
              <tbody>
                {validTrades.map((trade, index) => {
                  const price = prices[trade.ticker];
                  const investment = price * trade.contracts_number;
                  return (
                    <tr key={index}>
                      <td className='flex'>
                        <div className='h-6 w-6 mr-2 rounded-full overflow-hidden'>
                          <img
                            src={trade.columnName?.url_logo}
                            className='w-full h-full object-scale-down'
                            alt={`${trade.ticker} logo`}
                          />
                        </div>
                        <div className='flex items-center'>{trade.ticker}</div>
                      </td>
                      <td>{trade.contracts_number}</td>
                      <td>{formatToMoney(price)}</td>
                      <td>{formatToMoney(investment)}</td>
                      <td>{obj[index]?.market}</td>
                      <td>At Market</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ),
        width: '580px',
        buttons: {
          cancel: {
            show: true,
          },
          confirm: {
            show: true,
            text: 'Execute',
          },
        },
      });
    },
    [validTrades]
  );

  const handleExecuteTrades = useCallback(async () => {
    if (!broker.brokerConnected) {
      openModal();
      return;
    }

    if (debt > 0) {
      const { isConfirmed } = await alertInsufficientCash(debt);

      if (!isConfirmed) return;
    }

    const isMarketOpen = validateOpenMarket();

    if (!isMarketOpen) {
      return showModal({
        ...MODAL_TEMPLATES_OPTIONS.WARN,
        title: 'The market is closed',
        content: 'Market hours are from 9:30am to 4:00pm EST',
        buttons: {
          confirm: {
            show: true,
            text: 'OK',
          },
        },
      });
    }

    if (validTrades.length > 0) {
      showModal({
        ...MODAL_TEMPLATES_OPTIONS.EXECUTE_TRADES,
        content: 'Getting market exchange from broker',
        width: '475px',
        didOpen: async () => {
          Swal.showLoading();
          const tickers = validTrades.map((trade) => trade.ticker);
          const brokerConf = {
            broker_key: broker.brokerConnected,
            type_account: broker.accountTypeSelect,
          };
          const [prices, assetsExchange] = await Promise.all([
            getLatestPrices(broker, tickers),
            getAssetsExchange(brokerConf, tickers),
          ]);

          const pricesObj = prices.reduce((acc, curr) => {
            acc[curr.ticker] = curr.price;
            return acc;
          }, {} as Record<string, number>);

          if (assetsExchange.success && assetsExchange.data) {
            const { isConfirmed } = await updateModalTable(
              assetsExchange.data,
              pricesObj
            );

            if (!isConfirmed) return;
            executeTrades(prices);
          } else {
            Swal.close();
            showModal({
              ...MODAL_TEMPLATES_OPTIONS.UNHANDLED_ERROR,
              content:
                'Error getting market exchange from the broker. Please try again.',
              width: '400px',
              buttons: {
                confirm: {
                  show: true,
                  text: 'OK',
                },
              },
            });
          }
        },
      } as ModalOptions);
    }
  }, [broker, debt, validTrades, openModal, updateModalTable, executeTrades]);

  useEffect(() => {
    if (mape > 5) {
      const timeout = setTimeout(() => {
        messageHighMape(mape);
      }, 1500);

      return () => clearTimeout(timeout);
    }
  }, [mape]);

  useEffect(() => {
    if (areSomeTradeUnavailable) {
      const timeout = setTimeout(() => {
        alertUnavailableTrades();
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [areSomeTradeUnavailable]);

  return (
    <>
      {container ? (
        <div className='inline'>
          <Button
            color='secondary'
            onClick={handleExecuteTrades}
            data-testid='test-execute-trades'
            disabled={areSomeTradeUnavailable}
          >
            Execute trades
          </Button>
        </div>
      ) : (
        <Button
          color='secondary'
          onClick={handleExecuteTrades}
          data-testid='test-execute-trades'
          disabled={areSomeTradeUnavailable}
        >
          Execute trades
        </Button>
      )}

      <OrdersStatus
        open={open}
        handleClose={closeModal}
        orders={orders}
        loading={loading}
      />
    </>
  );
};
