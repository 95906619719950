// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { AppIcon } from 'components/app-icon';
import { useState } from 'react';

type CopilotChatInputProps = {
  handleSendMessage: (message: string) => void;
};

export const CopilotChatInput = ({
  handleSendMessage,
}: CopilotChatInputProps): JSX.Element => {
  const [text, setText] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const handleClick = () => {
    if (text.trim()) {
      handleSendMessage(text);
      setText('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div className='input-ticker relative pr-2 ml-2' data-testid='copilot-input'>
      <input
        className='input-common h-[35px] pr-9 rounded-3xl !placeholder-[#1072BA] bg-[#E8EFF6] select-none border-none focus:!placeholder-[#79828D] focus:!outline-[#1072BA] focus:!outline-1'
        value={text}
        onChange={handleInputChange}
        name='search_text'
        placeholder='Message AI Copilot'
        required
        type='text'
        autoComplete='off'
        onKeyDown={handleKeyDown}
      />
      <div className='absolute right-5 top-2.5'>
        <button
          type='submit'
          data-testid='copilot-send-button'
          className='inline-flex h-7 w-7 items-center justify-center'
          disabled={!text}
          onClick={handleClick}
        >
          <AppIcon icon='CopilotChatSendButton' color='main' />
        </button>
      </div>
    </div>
  );
};
