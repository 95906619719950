// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Tooltip } from '@mui/material';
import { TickerTagFeature } from 'api/interfaces/engine/citec';
import { PopupSelector } from 'components/popup-selector';
import { IconButton } from 'features/ui/icon-button';
import { FC, useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  addWatchListPosition,
  removeWatchListPosition,
  selectWatchListPositions,
} from 'store/slices/my-research';
import { selectTagsNames } from 'store/slices/tag-manager';
import { createTickerTag } from 'store/slices/tag-manager/tag-manager-thunk';

export interface WatchlistToggleButtonProps {
  toolTipTitle?: string;
  ticker: string;
  assetType: 'stock' | 'etf' | 'mutual_fund';
}

export const WatchlistToggleButton: FC<WatchlistToggleButtonProps> = ({
  ticker,
  assetType,
}) => {
  const dispatch = useAppDispatch();
  const watchlist = useAppSelector(selectWatchListPositions);
  const tags = useAppSelector((state) =>
    selectTagsNames(state, TickerTagFeature.WatchList)
  );

  const isWatchlisted = useMemo(
    () => watchlist.some((item) => item.ticker === ticker),
    [watchlist, ticker]
  );

  const toolTipTitle = `${isWatchlisted ? 'Remove from' : 'Add to'} Watchlist`;

  const handleToggleWatchlist = useCallback(() => {
    if (isWatchlisted) {
      dispatch(removeWatchListPosition({ ticker }));
    } else {
      dispatch(addWatchListPosition({ ticker, assetType }));
    }
  }, [dispatch, isWatchlisted, ticker, assetType]);

  const onSave = useCallback(
    (tags: string[]) => {
      tags.forEach((tag) => {
        dispatch(
          createTickerTag({ ticker, feature: TickerTagFeature.WatchList, tag })
        );
      });
    },
    [dispatch, ticker]
  );

  return (
    <PopupSelector
      title='Select tags'
      options={tags}
      onSave={onSave}
      showPopup={!isWatchlisted}
      placeholder='Select Tags'
    >
      <Tooltip title={toolTipTitle} placement='top'>
        <IconButton
          variant='icon'
          icon='Star'
          color={isWatchlisted ? 'main' : 'disabled'}
          data-testid='watchlist-toggle-button'
          onClick={handleToggleWatchlist}
        />
      </Tooltip>
    </PopupSelector>
  );
};
