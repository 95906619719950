// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { Alert, Tooltip } from '@mui/material';
import { BenchmarkList, SectionContainer } from 'components';
import { IconButton } from 'features/ui/icon-button';
import { classNames } from 'features/utils/classnames';
import { PortfolioLoaded } from 'features/utils/interfaces/portfolio-loaded';
import { PortfolioPositionsCurrent } from 'features/utils/interfaces/redux/portfolio-state';
import {
  TypeWorkflow,
  WorkflowAsset,
} from 'features/utils/interfaces/redux/workflow-state';
import { Dispatch, FC, SetStateAction } from 'react';
import { SelectedAssetsSelector } from './selected-assets-selector';
import { TablePositions } from './table-positions';
import { UploaderExcel } from './uploader-excel';

export interface PortfolioPositionsProps {
  workflowType: TypeWorkflow;
  filteredDataTable: PortfolioLoaded[];
  positionsTableData: PortfolioLoaded[];
  invalidTickers: string[];
  isSomeInvalidTicker: boolean;
  handleDeleteAllPositions: VoidFunction;
  handleDeleteInvalidPositions?: VoidFunction;
  updateMyData: (rowIndex: number, columnId: string, value: any) => void;
  typeAsset: WorkflowAsset;
  portfolio: PortfolioPositionsCurrent[];
  setPositionTableData: Dispatch<SetStateAction<PortfolioLoaded[]>>;
  tableHeaderText: string;
  scroll?: boolean;
}

export const PortfolioPositions: FC<PortfolioPositionsProps> = ({
  workflowType,
  filteredDataTable,
  isSomeInvalidTicker,
  positionsTableData,
  invalidTickers,
  handleDeleteAllPositions,
  handleDeleteInvalidPositions,
  updateMyData,
  typeAsset,
  portfolio,
  setPositionTableData,
  tableHeaderText,
  scroll,
}) => {
  const invalidTickersCount = invalidTickers.length;
  const isSingular = invalidTickersCount === 1;
  const positionWord = isSingular ? 'position' : 'positions';

  return (
    <div>
      <div className='flex my-6 ml-6'>
        {workflowType !== 'tax-harvesting' && (
          <div>
            <BenchmarkList />
          </div>
        )}
      </div>
      <div className='grid grid-cols-[1fr_200px] gap-4 items-start relative'>
        <SectionContainer
          title={tableHeaderText}
          variant='h3'
          className='!overflow-visible'
          actions={
            <Tooltip title='Delete all positions'>
              <IconButton
                icon='Trashcan'
                onClick={handleDeleteAllPositions}
                disabled={!filteredDataTable.length}
              />
            </Tooltip>
          }
        >
          {isSomeInvalidTicker && !!positionsTableData.length && (
            <Alert severity='error' className='mt-3 !bg-red-200 relative'>
              There {isSingular ? 'is' : 'are'} {invalidTickersCount} incorrect{' '}
              {positionWord} over a total of {portfolio.length}
              <button
                className='absolute top-[10px] right-5 bg-red-300 px-3 py-1 rounded-md text-red-600 font-bold'
                onClick={handleDeleteInvalidPositions}
                data-testid='delete-invalid-positions-button'
              >
                Remove all
              </button>
            </Alert>
          )}

          <div
            className={classNames({
              'overflow-y-auto h-[420px] relative mt-2': scroll,
            })}
          >
            <TablePositions
              excelData={filteredDataTable}
              setExcelData={setPositionTableData}
              updateMyData={updateMyData}
              invalidPositions={invalidTickers}
            />
          </div>
        </SectionContainer>
        <div className='flex flex-col gap-y-4'>
          <SelectedAssetsSelector onSelectAssets={setPositionTableData} />
          <UploaderExcel typeAsset={typeAsset} setExcelData={setPositionTableData} />
        </div>
      </div>
    </div>
  );
};
