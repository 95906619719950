// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Modal } from '@mui/material';
import { AddCash } from './add-cash';
import { BasePortfolio } from 'features/utils/interfaces/base-portfolio';
import { Paths } from 'routes';
import { CloseButton } from 'components/dynamic-dialog';

interface AddCashModalProps {
  dataPortfolio: BasePortfolio;
  openModal: boolean;
  handleClose: () => void;
  to: Paths;
  portfolioName?: string;
}

export const AddCashModal = ({
  dataPortfolio,
  openModal,
  handleClose,
  portfolioName,
  to,
}: AddCashModalProps) => {
  return (
    <Modal open={openModal} sx={{ overflowY: 'scroll' }}>
      <div className='add-cash-modal relative'>
        <div className='add-cash-modal_header bg-white relative'>
          <AddCash
            dataPortfolio={dataPortfolio}
            portfolioName={portfolioName}
            to={to}
          />
          <CloseButton onClick={handleClose} />
        </div>
      </div>
    </Modal>
  );
};
