// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

export const DASHBOARD = {
  investments: (
    <p className='text-justify'>
      Measures the evolution of the account’s equity value.
      <br />
      Equity value = Amount invested + Cash - Debt
    </p>
  ),

  cashAndBuyPower: (
    <p className='text-justify'>
      Buying power is the maximum cash available to invest including the potential
      leverage from the broker.
    </p>
  ),

  portfolioPositions: (
    <p className='text-start'>Market value of money currently invested.</p>
  ),

  assetVehicles: (
    <p className='text-justify'>
      Access a visual representation of how assets are allocated across different
      investment vehicles such as stocks, bonds, Mutual Funds, ETFs, and more.
    </p>
  ),

  tagComposition: (
    <p className='text-justify'>
      Organize and categorize your assets by applying customizable tags, to better
      understand the composition of your holdings.
    </p>
  ),
};

export type DashboardPopupName = keyof typeof DASHBOARD;
