// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { Link } from 'react-router-dom';
import { TopbarUserMenu } from './topbar-user-menu';
import { SwitchAccountType } from './switch-account-type';
import { AppIcon } from 'components/app-icon';
import { AssetsSearch } from './assets-search';

interface TopbarProps {
  hidden?: boolean;
}

export const Topbar = ({ hidden }: TopbarProps) => {
  return (
    <div
      data-testid='topbar'
      className={`flex w-full py-10 px-16 sticky z-50 top-0 bg-[#f5f6fa] ${
        hidden ? 'hidden' : ''
      }`}
    >
      <div className='flex flex-1 space-x-4 items-center'>
        <AssetsSearch />
        <SwitchAccountType />
        <nav className='border-r pr-4'>
          <Link to='/'>
            <AppIcon
              icon='Home'
              className='text-[#C6D0DD]'
              size='xl'
              data-testid='app-icon'
            />
          </Link>
        </nav>

        <TopbarUserMenu />
      </div>
    </div>
  );
};
