// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { selectExpirationDate, setUserData } from 'store/slices/user';
import { useAppSelector } from 'store/store';
import { subscriptionsApi } from 'api/engine/subscriptions-api';

export const useAppSubscriptions = () => {
  const [canUserUseApp, setCanUserUseApp] = useState<boolean>(true);
  const expiration_date = useAppSelector(selectExpirationDate);
  const dispatch = useDispatch();

  const isActivatedSubscriptions =
    process.env.REACT_APP_ACTIVATE_SUBSCRIPTIONS === 'true';

  const addTrialPeriod = useCallback(async () => {
    try {
      // add trial time
      const data = await subscriptionsApi.trial();
      if (data.expiration_date) {
        dispatch(setUserData({ ...data }));
        send_welcome_email();
      }
    } catch (e: any) {
      console.log(e.message);
      return e.message;
    }
  }, [dispatch]);

  const checkExpirationDate = useCallback(async () => {
    if (expiration_date) return;
    try {
      const data = await subscriptionsApi.subscription();

      if (!data.expiration_date) addTrialPeriod();

      dispatch(setUserData({ ...data }));
    } catch (error) {
      console.log(error);
    }
  }, [addTrialPeriod, dispatch, expiration_date]);

  useEffect(() => {
    checkExpirationDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (expiration_date && isActivatedSubscriptions) {
      const currentTimestamp = Date.now();
      setCanUserUseApp(expiration_date > currentTimestamp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiration_date]);

  return { expiration_date, canUserUseApp };
};

const send_welcome_email = () => {
  const body = { key: process.env.REACT_APP_MAILCHIMP_API_KEY };
  axios({
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    url: 'https://mandrillapp.com/api/1.0/users/ping',
    data: body,
  })
    .then(function (response) {
      return {
        code: 'sucess',
        value: response.data,
      };
    })
    .catch(function (error) {
      return {
        code: 'error',
        value: error,
      };
    });
};
