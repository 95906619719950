// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Button, CircularProgress, Dialog, DialogContent } from '@mui/material';
import { FC, PropsWithChildren, useCallback, useState } from 'react';
import { Heading } from './heading';
import {
  AvailableOptions,
  OptionsSelector,
} from './options-selector/options-selector';

export interface PopupSelectorProps extends PropsWithChildren {
  readonly title: string;
  readonly options: AvailableOptions;
  readonly onSave: (options: string[]) => void | Promise<void>;
  readonly placeholder?: string;
  readonly showPopup?: boolean;
  readonly primaryButtonLabel?: string;
  readonly secondaryButtonLabel?: string;
  readonly testId?: string;
  readonly initialSelectedOptions?: string[];
  readonly description?: string;
}

export const PopupSelector: FC<PopupSelectorProps> = ({
  children,
  title,
  options,
  placeholder = 'Select Option',
  showPopup = true,
  primaryButtonLabel = 'Save',
  secondaryButtonLabel = 'Cancel',
  onSave,
  testId = 'popup-selector',
  initialSelectedOptions = [],
  description,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    initialSelectedOptions
  );

  const handleSaveOptions = useCallback(async () => {
    setLoading(true);
    await onSave(selectedOptions);
    setLoading(false);
    setIsOpen(false);
  }, [onSave, selectedOptions]);

  return (
    <>
      <div
        className='cursor-pointer inline-flex'
        onClick={(e) => {
          e.stopPropagation();
          showPopup && setIsOpen(true);
        }}
      >
        {children}
      </div>
      <Dialog
        open={isOpen}
        data-testid={testId}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogContent classes={{ root: '!p-10 w-[300px]' }}>
          <Heading variant='h2' title={title} className='!flex justify-center' />
          <div className='mt-4'>
            {description && (
              <p className='text-center mb-4 text-[#56606D]'>{description}</p>
            )}
            <OptionsSelector
              optionsAvailable={options}
              onChangeValueSelected={setSelectedOptions}
              optionSelected={selectedOptions}
              defaultText={placeholder}
              selectType='gray'
              checkbox
              multiple
            />

            <div className='grid grid-cols-2 gap-8 mt-8 items-center'>
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => setIsOpen(false)}
              >
                {secondaryButtonLabel}
              </Button>

              {loading ? (
                <CircularProgress
                  color='secondary'
                  size={24}
                  thickness={4}
                  className='justify-self-center'
                />
              ) : (
                <Button
                  color='secondary'
                  onClick={handleSaveOptions}
                  data-testid='save-button'
                >
                  {primaryButtonLabel}
                </Button>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
