// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

interface Message {
  method: string;
  endpoint: string;
  message: string;
}

const messageList: Message[] = [
  {
    method: 'post',
    endpoint: '/portfolio/stock/optimization/',
    message:
      'Please go back and verify the optimization parameters and screener selected previously',
  },
  {
    method: 'post',
    endpoint: '/portfolio/any/rebalance/withdrawals/',
    message: 'There is no withdrawal strategy for the provided parameters.',
  },
  {
    method: 'post',
    endpoint: '/asset_allocation/analysis/',
    message:
      'Please go back and verify the Asset Allocation strategy selected previously',
  },
];

export const messages = new Map(
  messageList.map(({ method, endpoint, message }) => [
    `${method}-${endpoint}`,
    message,
  ])
);
