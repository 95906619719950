// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { selectWorkflowAsset } from 'store/slices/workflow';
import { chatApi } from 'api/copilot/chat-api';
import { useSafeUpdate } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store/store';
import {
  resetCopilotInsightPayload,
  selectCopilotContext,
  selectCopilotInsightPayload,
  selectCopilotIntent,
  selectCopilotMessages,
  setCopilotComparisonInsightPayload,
  setCopilotContext,
  setCopilotIntent,
  setCopilotMessages,
} from 'store/slices/copilot-buffer';
import { selectUserData } from 'store/slices/user';
import { getCopilotContext, getCopilotIntent, removeBlankSpaces } from '../utils';
import { useLocation } from 'react-router-dom';
import {
  selectComparisonAssets,
  selectComparisonLoading,
} from 'store/slices/my-research';

type Message = {
  message: string;
  sender: 'CITEC' | 'HUMAN';
};

export const useCopilotChat = () => {
  const dispatch = useDispatch();
  const runSafeUpdate = useSafeUpdate();

  const location = useLocation();

  const user = useAppSelector(selectUserData);
  const copilotMessages = useAppSelector(selectCopilotMessages);

  const assetType = useAppSelector(selectWorkflowAsset);

  const assetsComparison = useAppSelector(selectComparisonAssets);
  const isLoadingComparisonAssets = useAppSelector(selectComparisonLoading);

  const bufferPayload = useAppSelector(selectCopilotInsightPayload);
  const bufferIntent = useAppSelector(selectCopilotIntent);
  const bufferContext = useAppSelector(selectCopilotContext);

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(false);

  const [messages, setMessages] = useState<Message[]>([
    {
      message: 'Welcome to CITEC AI Copilot! How can we assist you today?',
      sender: 'CITEC',
    },
  ]);

  const toggleChat = useCallback(() => {
    setIsChatOpen(!isChatOpen);
    setNotification(false);
  }, [isChatOpen]);

  const updateCopilotIntent = useCallback(() => {
    const intent = getCopilotIntent(location);

    if (intent !== bufferIntent) {
      runSafeUpdate(() => {
        dispatch(setCopilotIntent(intent));
      });
    }

    const { comparison, portfolio, simulation } = bufferPayload;

    if (intent === 'citec' && (comparison || portfolio || simulation)) {
      runSafeUpdate(() => {
        dispatch(resetCopilotInsightPayload());
      });
    }
  }, [bufferIntent, dispatch, location, runSafeUpdate, bufferPayload]);

  const updateCopilotContext = useCallback(async () => {
    const context = await getCopilotContext(
      bufferIntent,
      bufferPayload,
      assetType,
      (enhancement) => {
        const message = enhancement
          .map(({ insight }) =>
            insight
              .replace('**PORTFOLIO INSIGHTS:**\n', '')
              .replace('**PORTFOLIO INSIGHTS:**\n\n', '')
              .replace('**PORTFOLIO INSIGHTS:**', '')
              .replace('**PORTFOLIO INSIGHTS**\n\n---\n\n', '')
              .replace('\n\n---\n\n', '\n')
          )
          .join('\n\n');

        runSafeUpdate(() => {
          setMessages((prevMessages) =>
            prevMessages.concat({
              message: message,
              sender: 'CITEC',
            })
          );

          setNotification(true);
        });
      }
    );

    if (context !== bufferContext) {
      runSafeUpdate(() => {
        dispatch(setCopilotContext(context));
      });
    }
  }, [
    bufferIntent,
    bufferPayload,
    assetType,
    bufferContext,
    dispatch,
    runSafeUpdate,
  ]);

  const handleSendMessage = useCallback(
    async (message: string) => {
      runSafeUpdate(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { message: message, sender: 'HUMAN' },
        ]);
      });

      setLoading(true);
      try {
        const params = { user: user?.email as string };

        const payload_agent = {
          buffer_conversation: {
            messages: [...copilotMessages, { human: message }],
          },
          chat_context: { intent: bufferIntent, context: bufferContext },
        };

        const resp = await chatApi.postChatAgent(payload_agent, params);

        const formattedResp = removeBlankSpaces(resp.citec as string);

        runSafeUpdate(() => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { message: formattedResp, sender: 'CITEC' },
          ]);

          dispatch(setCopilotMessages([resp]));
        });
      } catch (error) {
        console.error('[useCopilotChat]', error);
        runSafeUpdate(() => {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              message: 'Something went wrong, please try again',
              sender: 'CITEC',
            },
          ]);
        });
      }
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, messages, runSafeUpdate, copilotMessages, bufferIntent, bufferContext]
  );

  useEffect(() => {
    updateCopilotIntent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    updateCopilotContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bufferPayload, bufferIntent]);

  useEffect(() => {
    if (assetsComparison !== bufferPayload.comparison) {
      runSafeUpdate(() => {
        dispatch(setCopilotComparisonInsightPayload(assetsComparison));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingComparisonAssets]);

  return {
    isChatOpen,
    loading,
    messages,
    toggleChat,
    handleSendMessage,
    notification,
  };
};
