// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import {
  ComponentType,
  forwardRef,
  PropsWithoutRef,
  SVGProps,
  useMemo,
} from 'react';
import { ReactComponent as Account } from 'assets/icons/account.svg';
import { ReactComponent as Add } from 'assets/icons/add.svg';
import { ReactComponent as AiBuilder } from 'assets/icons/ai-builder.svg';
import { ReactComponent as Analysis } from 'assets/icons/analysis.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { ReactComponent as Asset } from 'assets/icons/asset.svg';
import { ReactComponent as AssetAllocation } from 'assets/icons/asset-allocation.svg';
import { ReactComponent as Back } from 'assets/icons/back.svg';
import { ReactComponent as BrazilFlag } from 'assets/icons/flags/brazil.svg';
import { ReactComponent as Buy } from 'assets/icons/buy.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as CaretDown } from 'assets/icons/caret-down.svg';
import { ReactComponent as CaretRight } from 'assets/icons/caret-right.svg';
import { ReactComponent as CaretUp } from 'assets/icons/caret-up.svg';
import { ReactComponent as ChatCaretLeft } from 'assets/icons/chat-caret-left.svg';
import { ReactComponent as ChatCaretRight } from 'assets/icons/chat-caret-right.svg';
import { ReactComponent as Checkbox } from 'assets/icons/checkbox.svg';
import { ReactComponent as Checked } from 'assets/icons/checked.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as CloseOutline } from 'assets/icons/close-outline.svg';
import { ReactComponent as CopilotChatBackButton } from 'assets/icons/copilot-chat-back.svg';
import { ReactComponent as CopilotChatButton } from 'assets/icons/copilot-chat.svg';
import { ReactComponent as CopilotChatSendButton } from 'assets/icons/copilot-chat-send.svg';
import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { ReactComponent as Dashboard } from 'assets/icons/dashboard.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as Eliminate } from 'assets/icons/eliminate.svg';
import { ReactComponent as ETF } from 'assets/icons/etf.svg';
import { ReactComponent as EuropeanUnionFlag } from 'assets/icons/flags/european-union.svg';
import { ReactComponent as ExclamationMark } from 'assets/icons/exclamation-mark.svg';
import { ReactComponent as FranceFlag } from 'assets/icons/flags/france.svg';
import { ReactComponent as GermanyFlag } from 'assets/icons/flags/germany.svg';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as Insights } from 'assets/icons/insights.svg';
import { ReactComponent as JapanFlag } from 'assets/icons/flags/japan.svg';
import { ReactComponent as Lines } from 'assets/icons/lines.svg';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import { ReactComponent as OpenLock } from 'assets/icons/open-lock.svg';
import { ReactComponent as Menu } from 'assets/icons/menu.svg';
import { ReactComponent as MutualFunds } from 'assets/icons/mutual-fund.svg';
import { ReactComponent as Pen } from 'assets/icons/pen.svg';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { ReactComponent as Rebalance } from 'assets/icons/rebalance.svg';
import { ReactComponent as Reporting } from 'assets/icons/reporting.svg';
import { ReactComponent as Research } from 'assets/icons/research.svg';
import { ReactComponent as Save } from 'assets/icons/save.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { ReactComponent as Sell } from 'assets/icons/sell.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';
import { ReactComponent as Sort } from 'assets/icons/sort.svg';
import { ReactComponent as SortAsc } from 'assets/icons/sort-asc.svg';
import { ReactComponent as SortDesc } from 'assets/icons/sort-desc.svg';
import { ReactComponent as SouthKoreaFlag } from 'assets/icons/flags/south-korea.svg';
import { ReactComponent as SpainFlag } from 'assets/icons/flags/spain.svg';
import { ReactComponent as Star } from 'assets/icons/star.svg';
import { ReactComponent as TaxManager } from 'assets/icons/tax-manager.svg';
import { ReactComponent as Trader } from 'assets/icons/trader.svg';
import { ReactComponent as Trashcan } from 'assets/icons/trashcan.svg';
import { ReactComponent as UKFlag } from 'assets/icons/flags/uk.svg';
import { ReactComponent as Unlink } from 'assets/icons/unlink.svg';
import { ReactComponent as USAFlag } from 'assets/icons/flags/usa.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { classNames } from 'features/utils/classnames';

/**
 * Type representing an SVG component.
 */
type SVGComponent = ComponentType<JSX.IntrinsicElements['svg']>;

const SVG_ICONS = {
  Account,
  Add,
  AiBuilder,
  Analysis,
  ArrowDown,
  ArrowUp,
  Asset,
  AssetAllocation,
  Back,
  BrazilFlag,
  Buy,
  Calendar,
  CaretDown,
  CaretRight,
  CaretUp,
  ChatCaretLeft,
  ChatCaretRight,
  Checkbox,
  Checked,
  Close,
  CloseOutline,
  CopilotChatBackButton,
  CopilotChatButton,
  CopilotChatSendButton,
  Copy,
  Dashboard,
  Download,
  Eliminate,
  ETF,
  EuropeanUnionFlag,
  ExclamationMark,
  FranceFlag,
  GermanyFlag,
  Home,
  Info,
  Insights,
  JapanFlag,
  Lines,
  Lock,
  Menu,
  MutualFunds,
  OpenLock,
  Pen,
  Plus,
  Rebalance,
  Reporting,
  Research,
  Save,
  Search,
  Sell,
  Settings,
  Sort,
  SortAsc,
  SortDesc,
  SouthKoreaFlag,
  SpainFlag,
  Star,
  TaxManager,
  Trader,
  Trashcan,
  UKFlag,
  Unlink,
  USAFlag,
  Warning,
};

export type SvgIconName = keyof typeof SVG_ICONS;

/**
 * Registry for storing and retrieving SVG components.
 */
export const ICON_REGISTRY = new Map<string, SVGComponent>(
  Object.entries(SVG_ICONS)
);

/**
 * Properties for the Icon component.
 *
 * @property title - The title of the icon.
 * @property icon - The name of the icon.
 * @property color - The color of the icon. It can be one of the following:
 *  - main: #1072BA
 *  - secondary: #9C2B85
 *  - success: #2DCC89
 *  - danger: #E24646
 *  - disabled: #9FADBE
 *  - light: #FFFFFF
 *  - dark: #323A58
 *  - none: unset
 * @property size - The size of the icon. It can be one of the following:
 *  - sm: 9px
 *  - md: 10.5px
 *  - base: 12px
 *  - lg: 15px
 *  - xl: 18px
 *  - 2xl: 30px
 *  - number: value in pixels
 */
export interface IconProps extends PropsWithoutRef<SVGProps<SVGSVGElement>> {
  title?: string;
  icon: SvgIconName;
  /**
   * The color of the icon. It can be one of the following:
   *  - main: #1072BA
   *  - secondary: #3959E5
   *  - tertiary: #9C2B85
   *  - success: #2DCC89
   *  - danger: #E24646
   *  - disabled: #9FADBE
   *  - light: #FFFFFF
   *  - dark: #323A58
   *  - none: unset
   */
  color?:
    | 'main'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'danger'
    | 'disabled'
    | 'light'
    | 'dark'
    | 'inherit';
  /**
   * The size of the icon. It can be one of the following:
   *  - sm: 9px
   *  - md: 10.5px
   *  - base: 12px
   *  - lg: 15px
   *  - xl: 18px
   *  - 2xl: 30px
   *  - number: value in pixels
   */
  size?: 'sm' | 'md' | 'base' | 'lg' | 'xl' | '2xl' | number;
}

export const classes = {
  color: {
    main: '!text-primary',
    secondary: '!text-secondary',
    tertiary: '!text-[#9C2B85]',
    success: '!text-positive',
    danger: '!text-negative',
    disabled: '!text-[#9FADBE]',
    light: '!text-[#FFFFFF]',
    dark: '!text-[#323A58]',
    inherit: '!text-inherit',
  },
  size: {
    sm: 'size-[0.75rem]',
    md: 'size-[0.875rem]',
    base: 'size-[1rem]',
    lg: 'size-[1.25rem]',
    xl: 'size-[1.5rem]',
    '2xl': 'size-[2.5rem]',
  },
};

/**
 * AppIcon is a forwardRef component that renders an icon from the ICON_REGISTRY.
 * It takes an icon name and SVG properties as props and returns an SVG element.
 *
 * @param props - The properties for the icon.
 * @param ref - The ref for the icon.
 * @returns An SVG element.
 * @throws Will throw an error if the icon is not found in the ICON_REGISTRY.
 */
export const AppIcon = forwardRef<SVGElement, IconProps>(
  ({ icon, size, color, className, ...props }, ref) => {
    const Icon = useMemo(() => {
      if (!ICON_REGISTRY.has(icon)) {
        throw new Error(
          `The icon "${icon}" is not valid or not found in the registry.`
        );
      }

      return ICON_REGISTRY.get(icon)!;
    }, [icon]);

    const iconClasses = useMemo(
      () =>
        classNames(
          classes.color?.[color!],
          typeof size !== 'number' && classes.size?.[size!],
          'transition-colors',
          className
        ),
      [className, color, size]
    );

    const iconSize = useMemo(
      () =>
        typeof size === 'number'
          ? { width: `${size / 12}rem`, height: `${size / 12}rem` }
          : {},
      [size]
    );

    return (
      <Icon ref={ref as any} className={iconClasses} {...iconSize} {...props} />
    );
  }
);

AppIcon.displayName = 'AppIcon';
