// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Intent } from 'api/interfaces/copilot/chat';
import type { Location } from 'react-router-dom';

interface Conditions {
  condition: string;
  intent: Intent;
}

const containsSubstring = (str: string, substr: string) => str.includes(substr);

export const getCopilotIntent = (location: Location): Intent => {
  const conditions: Conditions[] = [
    { condition: 'simulation', intent: 'montecarlo' },
    { condition: 'comparison', intent: 'asset_comparison' },
    { condition: 'positions', intent: 'portfolio' },
  ];

  const foundCondition = conditions.find(
    ({ condition }) =>
      containsSubstring(location.search, condition) ||
      containsSubstring(location.pathname, condition)
  );

  return foundCondition ? foundCondition.intent : 'citec';
};
