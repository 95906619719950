// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

export const KPIS = {
  valueAtRisk: (
    <p className='text-justify'>
      Quantifies the portfolio’s possible financial losses over one month at a 95%
      confidence level.
    </p>
  ),

  annualizedVolatility: (
    <p className='text-justify'>
      Measure the variability (Standard Deviation) of a portfolio&apos;s price over
      one year.
      <br />
      Standard Deviation = σ = ∑ ( x i − μ ) 2 N
    </p>
  ),

  sharpeRatio: (
    <p className='text-justify'>
      Measure used to evaluate the risk-adjusted return of an investment portfolio.
      <br />
      Sharpe Ratio = (Rx – Rf) / Standard Deviation Rx
    </p>
  ),

  beta: (
    <p className='text-justify'>
      Measures a stock&apos;s volatility compared to the overall market. A stock that
      swings more than the market over time has a beta above 1.0. If it swings less
      than the market, it has a beta below one.
    </p>
  ),

  maxDrawdown: (
    <p className='text-justify'>
      Measures the largest percentage decline in the value of an investment portfolio
      from its highest point to its lowest point before a new peak is reached. It
      helps investors understand the worst-case loss scenario associated with an
      investment.
    </p>
  ),

  avgMarketCap: (
    <p className='text-justify'>
      The total market value of a company’s outstanding shares of a stock. It’s
      calculated by multiplying the total number of a company’s outstanding shares by
      the current market price of one share.
    </p>
  ),

  pricePerEarnings: (
    <p className='text-justify'>
      Measures how expensive a stock is relative to its earnings.
      <br />
      PE = (Stock price / Earning per share)
    </p>
  ),

  dividendYield: (
    <p className='text-justify'>
      Money paid out to a company’s shareholders.
      <br />
      Dividend Yield = Dividend per share / Stock price
    </p>
  ),

  unrealizedGains: (
    <p className='text-justify'>
      A portfolio’s currently outstanding capital gains/losses that haven’t been
      sold/realized yet. These gains are not taxed until the position is sold.
    </p>
  ),

  harvestOpportunity: (
    <p className='text-justify'>
      The sum of your portfolio’s currently outstanding unrealized losses.
    </p>
  ),

  cashAvailable: (
    <p className='text-justify'>
      Cash available to reinvest coming from the additional cash added or from
      selling existing positions.
    </p>
  ),

  lossesAvailable: (
    <p className='text-justify'>
      Tax losses available to harvest in the existing portfolio that haven’t been
      selected.
    </p>
  ),

  lossesHarvested: (
    <p className='text-justify'>
      Outstanding capital gains on the positions selected.
    </p>
  ),

  realizedGains: (
    <p className='text-justify'>
      Refers to the profits or positive returns obtained from the sale or disposal of
      an investment.
    </p>
  ),

  rsquare: (
    <p className='text-justify'>
      Measures the relationship between a portfolio and its benchmark index.
      It&apos;s expressed as a percentage from one to 100. R-square is not a measure
      of the portfolio’s performance. Rather, it measures the correlation between the
      portfolio&apos;s returns and the benchmark&apos;s returns.
    </p>
  ),
  cagr: (
    <p className='text-justify'>
      The compound annual growth rate (CAGR) is the rate of return (RoR) that would
      be required for an investment to grow from its beginning balance to its ending
      balance, assuming the profits were reinvested at the end of each period of the
      investment&#x2019;s life span.
    </p>
  ),

  pricePerBook: (
    <p className='text-justify'>
      <b>Price-to-book value (P/B)</b> is the ratio of the company&#x27;s value
      (market capitalization) over its equity book value. The book value is defined
      as the difference between the book value of assets and the book value of
      liabilities as expressed in the company&#x2019;s balance sheet in the 10K.
    </p>
  ),

  alpha: (
    <p className='text-justify'>
      <b>Alpha</b> measures the portfolio&#x27;s ability to beat the market. An
      investment&#x2019;s excess return relative to a benchmark index&#x2019;s return
      is called the investment&#x2019;s alpha. Alpha can be positive or negative and
      is the result of active investing.
    </p>
  ),
  realizedSt: (
    <p className='text-justify'>
      Gains are profits or positive returns earned from the sale of an investment
      held for one year or less.
    </p>
  ),

  realizedLt: (
    <p className='text-justify'>
      Gains are profits or positive returns earned from the sale of an investment
      held for more than one year.
    </p>
  ),
  deviation: (
    <p className='text-justify'>
      The sum of the absolute value of the differences of each position in percentage
      vs. the target portfolio.
    </p>
  ),
};

export type KPIPopupName = keyof typeof KPIS;
