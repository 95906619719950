// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { appStore as store } from 'store/store';
import { EditPortfolioConfig } from '../hooks';
import { updateSavedPortfolio } from 'store/slices/my-research';
import { MODAL_TEMPLATES_OPTIONS, showModal } from 'features/ui/modal';
import { PortfolioLoaded } from 'features/utils/interfaces/portfolio-loaded';
import { SavedPortfolio } from 'features/utils/interfaces/redux/my-research-state';

export interface EditSavedPortfolioProps {
  editPortfolioConfig: EditPortfolioConfig;
  updatedPortfolioName: string;
  positions: PortfolioLoaded[];
  benchmark: string;
  onClose: () => void;
}

export const editSavedPortfolio = ({
  editPortfolioConfig,
  updatedPortfolioName,
  positions,
  benchmark,
  onClose,
}: EditSavedPortfolioProps) => {
  const { portfolioBenchmark, portfolioDate, portfolioId, portfolioName } =
    editPortfolioConfig;

  showModal({
    ...MODAL_TEMPLATES_OPTIONS.EDIT_SAVED_PORTFOLIO,
    preConfirm: () => {
      const params = { portfolio_id: portfolioId };
      const payload = {
        portfolio_id: portfolioId,
        portfolio: updatedPortfolioName || portfolioName,
        benchmark: benchmark || portfolioBenchmark,
        creation_date: portfolioDate,
        holdings: positions,
      } as SavedPortfolio;

      return store.dispatch(updateSavedPortfolio({ params, payload }));
    },
  }).then((result) => {
    if (result.isConfirmed) {
      showModal({
        content: 'The portfolio was updated correctly',
        buttons: { confirm: { show: true, text: 'Ok' } },
      }).then(() => onClose?.());
    }
  });
};
