// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { classNames } from 'features/utils/classnames';

interface SelectItemProps {
  label: string;
  labelId?: string;
  checkedValidator?: string;
  groupName: string;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  disabled?: boolean;
  boxTestId?: string;
  labelHtmlFor?: string;
  handleSelectItem?: () => void;
  rightIconFunction?: () => void;
}

export const SelectItem = ({
  label,
  labelId,
  checkedValidator,
  groupName,
  rightIcon,
  leftIcon,
  disabled,
  boxTestId,
  labelHtmlFor,
  handleSelectItem,
  rightIconFunction,
}: SelectItemProps) => {
  return (
    <div
      className={classNames('relative', {
        'select-item-box': !disabled,
        'select-item-box-disabled': disabled,
      })}
      onClick={(e) => {
        e.preventDefault();
        handleSelectItem && handleSelectItem();
      }}
      data-testid={boxTestId}
    >
      <input
        checked={checkedValidator == labelId}
        type='radio'
        name={groupName}
        id={labelId}
        readOnly
      />
      <label htmlFor={labelHtmlFor}>
        {leftIcon && <div className='mr-2.5'>{leftIcon}</div>}
        {label}
        {rightIcon && (
          <div
            className={classNames('absolute right-5', {
              '[&_svg]:!text-white': checkedValidator == labelId,
            })}
            data-testid={`popup_${labelId}`}
            onClick={rightIconFunction}
          >
            {rightIcon}
          </div>
        )}
      </label>
    </div>
  );
};
