// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { useDispatch } from 'react-redux';
import { Paths } from 'routes/routes';
import { useAppSelector } from 'store/store';
import {
  selectCashPosition,
  selectCurrentBalanceWithoutCash,
  selectPortfolioTrades,
  selectTaxHarvestingCash,
  updateCashPortfolio,
  updateCashTrades,
} from 'store/slices/portfolio-positions';

export const useValidateSoldPosition = () => {
  const dispatch = useDispatch();

  const totalsPositionsSelected = useAppSelector(selectTaxHarvestingCash);
  const trades = useAppSelector(selectPortfolioTrades);

  const isCashOnPortfolio = !!useAppSelector(selectCashPosition);

  const totalPortfolio = useAppSelector(selectCurrentBalanceWithoutCash);
  const minimumValueSold = Math.round(totalPortfolio * 0.1);
  const is10PercentSold = totalsPositionsSelected >= minimumValueSold;

  const isSomeSellPosition = trades.some((item) => item.operation === 'SELL');

  const pagesThatNeedSoldPosition: Paths[] = ['/ai-builder'];

  const soldCashOnTaxHarvesting = (amount: number) => {
    if (amount < 0) return;
    dispatch(updateCashPortfolio(amount));
    dispatch(updateCashTrades(amount));
  };

  return {
    is10PercentSold,
    isCashOnPortfolio,
    isSomeSellPosition,
    pagesThatNeedSoldPosition,
    soldCashOnTaxHarvesting,
  };
};
