// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { assetsApi } from 'store/api/ai/assets-api';
import { groupPositionsByAssetType } from '../brokers/utils/group-positions-by-asset-type';
import { AssetsInfoResp } from 'api/interfaces/ai/assets';
import { appStore } from 'store/store';

export const getAssetsInfo = async (
  tickers: string[]
): Promise<AssetsInfoResp[]> => {
  const action = assetsApi.endpoints.classifier.initiate({ payload: tickers });
  const response = await appStore.dispatch(action).unwrap();
  const { other: _, ...assetTypes } = groupPositionsByAssetType(response);

  const requests = Object.keys(assetTypes).map((assetType) => {
    const tickers = assetTypes[assetType as keyof typeof assetTypes].map(
      ({ ticker }) => ticker
    );
    const action = assetsApi.endpoints.assetsInfo.initiate({
      params: { detail: true },
      payload: assetTypes[assetType as keyof typeof assetTypes].map(
        ({ ticker }) => ticker
      ),
      assetType,
    });
    return tickers.length ? appStore.dispatch(action).unwrap() : [];
  });

  const assetsInfo = await Promise.all(requests);

  return assetsInfo.flat();
};
