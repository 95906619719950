// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { forwardRef, HTMLAttributes } from 'react';
import { TabsProvider } from './tabs-context';
import { TabsController, useTabsController } from './use-tabs-controller';

export interface TabsProps extends HTMLAttributes<HTMLDivElement> {
  readonly defaultValue?: string;
  readonly baseId?: string;
  readonly controller?: TabsController;
}

export const Tabs = forwardRef<HTMLDivElement, TabsProps>(
  ({ children, defaultValue, baseId, controller, ...props }, ref) => {
    const { id, setTab, tab } = useTabsController(defaultValue);

    return (
      <TabsProvider
        baseId={baseId ?? controller?.id ?? id}
        onValueChange={controller?.setTab ?? setTab}
        value={controller?.tab ?? tab}
      >
        <div {...props} ref={ref}>
          {children}
        </div>
      </TabsProvider>
    );
  }
);

Tabs.displayName = 'Tabs';
