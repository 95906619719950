// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Button } from '@mui/material';
import { TickerPopupSelector } from 'features/trader/components/ticker-popup-selector';
import { useForm } from 'hooks';
import { useSendToTrader } from 'hooks/use-sent-to-trader';
import { FC, useState } from 'react';

export interface BuySellButtonProps {
  ticker: string;
  operation: 'buy' | 'sell';
  testId?: string;
}

export const BuySellButton: FC<BuySellButtonProps> = ({
  operation,
  testId = '',
  ticker,
}) => {
  const { sendTradeTicker } = useSendToTrader();
  const {
    values: { volume },
    handleResetForm,
    handleInputNumberChange,
  } = useForm({ ticker: '', volume: 0 });
  const [selectedOperation, setSelectedOperation] = useState<'BUY' | 'SELL'>('BUY');
  const [openModalCash, setOpenModalCash] = useState(false);
  const handleCloseModal = () => setOpenModalCash(false);

  const selectOperation = (operation: 'BUY' | 'SELL') => {
    setSelectedOperation(operation);
  };
  const handleAddTicker = () => {
    sendTradeTicker({
      operation: selectedOperation,
      ticker: ticker,
      volume: Number(volume),
    });
    handleCloseModal();
    handleResetForm();
  };

  return (
    <>
      <Button
        data-testid={`${operation}-button-${testId}`}
        variant='outlined'
        color='secondary'
        size='small'
        onClick={() => setOpenModalCash(true)}
      >
        {operation === 'buy' ? 'Buy' : 'Sell'}
      </Button>
      <TickerPopupSelector
        onChangeVolume={handleInputNumberChange}
        valueVolume={volume}
        onAddTicker={handleAddTicker}
        ticker={ticker}
        closePopup={handleCloseModal}
        selectOperation={selectOperation}
        selectedOperation={selectedOperation}
        showPopupSelector={openModalCash}
      />
    </>
  );
};
