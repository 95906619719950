// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import {
  ReactElement,
  ReactNode,
  TableHTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  flexRender,
  ExpandedState,
  VisibilityState,
  PaginationState,
  PaginationOptions,
  SortingState,
  TableOptions,
  RowSelectionState,
} from '@tanstack/react-table';
import { classNames } from 'features/utils/classnames';
import { SortHeader } from './sort-header';
import { ColumnVisibilitySelector } from './column-visibility-selector';
import styles from './grouped-table.module.css';
import { Pagination } from './pagination';
import { InfoTooltip, PopupsName } from 'features/popups';

export interface GroupedTableProps<
  T extends { subRows?: any[] } | Record<string, any>
> extends TableHTMLAttributes<HTMLTableElement> {
  readonly data: T[];
  readonly columns: ColumnDef<T, any>[];
  readonly initialExpanded?: ExpandedState;
  readonly manualPagination?: boolean;
  readonly onPaginationChange?: PaginationOptions['onPaginationChange'];
  readonly enablePagination?: boolean;
  readonly enableExpanding?: boolean;
  readonly pageCount?: number;
  readonly pageIndex?: number;
  readonly pageSize?: number;
  readonly emptyState?: ReactNode;
  readonly initialSorting?: SortingState;
  readonly initialRowSelection?: RowSelectionState;
  readonly enableRowSelection?: TableOptions<T>['enableRowSelection'];
  readonly enableMultiRowSelection?: TableOptions<T>['enableMultiRowSelection'];
  readonly onRowSelectionChange?: (value: T[]) => void;
  readonly getRowId?: TableOptions<T>['getRowId'];
  readonly disableDeselect?: boolean;
}

export interface GroupedTableComponent {
  <T extends { subRows?: any[] } | Record<string, any>>(
    props: GroupedTableProps<T>,
    context?: any
  ): ReactElement<any, any> | null;
}

export const GroupedTable: GroupedTableComponent = ({
  data,
  columns,
  initialExpanded,
  manualPagination = false,
  enablePagination,
  enableExpanding = true,
  pageCount,
  pageIndex,
  pageSize,
  onPaginationChange,
  onRowSelectionChange: onRowChange,
  emptyState,
  initialSorting,
  enableRowSelection = false,
  enableMultiRowSelection = false,
  initialRowSelection,
  disableDeselect = false,
  getRowId,
  ...props
}) => {
  const isMounted = useRef(false);

  const [expanded, setExpanded] = useState<ExpandedState>(
    () => initialExpanded ?? {}
  );

  const initialColumnVisibility: VisibilityState = useMemo(
    () =>
      columns.reduce(
        (state, column: any) =>
          column.meta?.initiallyHidden
            ? { ...state, [column.accessorKey ?? column?.id]: false }
            : state,
        {} as VisibilityState
      ),
    [columns]
  );

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    initialColumnVisibility
  );
  const [pagination, setPagination] = useState<PaginationState>(() => ({
    pageIndex: pageIndex ?? 0,
    pageSize: enablePagination ? pageSize ?? 50 : data.length,
  }));

  const [sorting, setSorting] = useState<SortingState>(() => initialSorting ?? []);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    () => initialRowSelection ?? {}
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSubRows: (row) => (Array.isArray(row?.subRows) ? row?.subRows : []),
    defaultColumn: {
      minSize: 36,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    state: {
      expanded,
      columnVisibility,
      pagination,
      sorting,
      rowSelection,
    },
    enableExpanding: enableExpanding,
    onExpandedChange: setExpanded,
    onPaginationChange: manualPagination ? onPaginationChange : setPagination,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    paginateExpandedRows: manualPagination,
    manualPagination: manualPagination,
    pageCount: manualPagination ? pageCount : undefined,
    enableRowSelection,
    enableMultiRowSelection,
    getRowId,
  });

  useEffect(() => {
    if (enableRowSelection) setRowSelection(() => initialRowSelection ?? {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  useEffect(() => {
    if (!manualPagination && !enablePagination) {
      setPagination((prev) => ({
        ...prev,
        pageSize: data.length,
      }));
    }
  }, [data.length, enablePagination, manualPagination]);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;

      return;
    }

    if (enableRowSelection && onRowChange && rowSelection !== initialRowSelection) {
      const value = table.getSelectedRowModel().rows.map((row) => row.original);

      onRowChange?.(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSelection, enableRowSelection]);

  return (
    <table {...props} className={classNames(styles.table, props.className)}>
      <thead className={styles.thead}>
        {data.length > 0 ? (
          table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className={styles.tr}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={classNames(
                    styles.th,
                    { 'cursor-pointer': header.column.getCanSort() },
                    header.column.columnDef.meta?.className
                  )}
                  style={{
                    minWidth:
                      header.column.columnDef.minSize === Number.MAX_SAFE_INTEGER
                        ? undefined
                        : (header.column.columnDef.minSize &&
                            `${header.column.columnDef.minSize / 16}rem`) ||
                          undefined,
                    width:
                      header.getSize() === Number.MAX_SAFE_INTEGER
                        ? undefined
                        : `${header.getSize() / 16}rem`,
                    maxWidth:
                      header.column.columnDef.maxSize === Number.MAX_SAFE_INTEGER
                        ? undefined
                        : (header.column.columnDef.maxSize &&
                            `${header.column.columnDef.maxSize / 16}rem`) ||
                          undefined,
                  }}
                  onClick={header.column.getToggleSortingHandler()}
                  data-testid={`header-${header.id}`}
                >
                  <div
                    className={classNames({
                      'justify-end':
                        header.column.columnDef.meta?.showColumnVisibilitySelector,
                    })}
                  >
                    {header.isPlaceholder ? null : header.column.columnDef.meta
                        ?.tooltip ? (
                      <InfoTooltip
                        title={header.column.columnDef.meta.tooltip as PopupsName}
                      >
                        <span>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </span>
                      </InfoTooltip>
                    ) : (
                      flexRender(header.column.columnDef.header, header.getContext())
                    )}
                    <SortHeader sort={header.column} />

                    {header.column.columnDef.meta?.showColumnVisibilitySelector &&
                      !header.column.getCanSort() && (
                        <ColumnVisibilitySelector
                          columns={table.getAllLeafColumns()}
                          onColumnVisibilityChange={setColumnVisibility}
                        />
                      )}
                  </div>
                </th>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <th
              colSpan={columns.length}
              className='h-6'
              aria-roledescription='empty state header'
            ></th>
          </tr>
        )}
      </thead>
      <tbody className={styles.tbody}>
        {data.length === 0 ? (
          <tr>
            <td colSpan={columns.length} aria-roledescription='empty state body'>
              <div className={styles['empty-state']}>
                {emptyState ?? <span>There is no data</span>}
              </div>
            </td>
          </tr>
        ) : (
          table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              aria-expanded={row.getCanExpand() ? row.getIsExpanded() : undefined}
              className={classNames(styles.tr, {
                [styles.animate]: !row.getCanExpand(),
              })}
              onClick={
                row.getCanExpand()
                  ? row.getToggleExpandedHandler()
                  : row.getCanSelect()
                  ? disableDeselect
                    ? row.getIsSelected()
                      ? () =>
                          setRowSelection((prev) => ({ ...prev, [row.id]: true }))
                      : row.getToggleSelectedHandler()
                    : row.getToggleSelectedHandler()
                  : undefined
              }
              aria-selected={row.getCanSelect() ? row.getIsSelected() : undefined}
              data-testid={`row-${row.id}`}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={classNames(cell.column.columnDef.meta?.className)}
                  style={{
                    minWidth:
                      cell.column.columnDef.minSize === Number.MAX_SAFE_INTEGER
                        ? undefined
                        : (cell.column.columnDef.minSize &&
                            `${cell.column.columnDef.minSize / 16}rem`) ||
                          undefined,
                    width:
                      cell.column.getSize() === Number.MAX_SAFE_INTEGER
                        ? undefined
                        : `${cell.column.getSize() / 16}rem`,
                    maxWidth:
                      cell.column.columnDef.maxSize === Number.MAX_SAFE_INTEGER
                        ? undefined
                        : (cell.column.columnDef.maxSize &&
                            `${cell.column.columnDef.maxSize / 16}rem`) ||
                          undefined,
                  }}
                  data-testid={`row-${row.id}-cell-${cell.id}`}
                >
                  <div>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
      <tfoot className={styles.tfoot}>
        {table.getFooterGroups().map((footerGroup) => (
          <tr
            key={footerGroup.id}
            className={styles.tr}
            data-testid={`footer-${footerGroup.id}`}
          >
            {footerGroup.headers.map((cell) => (
              <th
                key={cell.id}
                colSpan={cell.colSpan}
                className={cell.column.columnDef.meta?.className}
                style={{
                  minWidth:
                    cell.column.columnDef.minSize === Number.MAX_SAFE_INTEGER
                      ? undefined
                      : (cell.column.columnDef.minSize &&
                          `${cell.column.columnDef.minSize / 16}rem`) ||
                        undefined,
                  width:
                    cell.column.getSize() === Number.MAX_SAFE_INTEGER
                      ? undefined
                      : `${cell.column.getSize() / 16}rem`,
                  maxWidth:
                    cell.column.columnDef.maxSize === Number.MAX_SAFE_INTEGER
                      ? undefined
                      : (cell.column.columnDef.maxSize &&
                          `${cell.column.columnDef.maxSize / 16}rem`) ||
                        undefined,
                }}
                data-testid={`footer-${footerGroup.id}-cell-${cell.id}`}
              >
                <div>
                  {cell.isPlaceholder
                    ? null
                    : flexRender(cell.column.columnDef.footer, cell.getContext())}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </tfoot>
      {enablePagination && data.length > 0 ? (
        <tfoot>
          <tr>
            <td
              colSpan={table.getAllColumns().length}
              aria-roledescription='pagination'
            >
              <Pagination
                pageIndex={table.getState().pagination.pageIndex}
                pageSize={table.getState().pagination.pageSize}
                pageCount={table.getPageCount()}
                canPreviousPage={table.getCanPreviousPage()}
                canNextPage={table.getCanNextPage()}
                onNextPage={table.nextPage}
                onPreviousPage={table.previousPage}
              />
            </td>
          </tr>
        </tfoot>
      ) : null}
    </table>
  );
};
