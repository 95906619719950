// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  BrokerPosition,
  BrokerPositionsAssetType,
  BrokerPositionsState,
  BrokerState,
  BrokerSummaryState,
} from 'features/utils/interfaces/redux/broker';
import { MyInvestments } from 'features/utils/interfaces/my-investment';
import { AccountInfo } from 'api/interfaces/brokerage/account';

export const positionsEntityAdapter = createEntityAdapter<BrokerPositionsState>({
  selectId: ({ asset }) => asset,
  sortComparer: (current, next) => current.asset.localeCompare(next.asset),
});

export const accountsEntityAdapter = createEntityAdapter<AccountInfo>({
  selectId: ({ account_id }) => account_id,
  sortComparer: (current, next) => current.account_id.localeCompare(next.account_id),
});

export const initialState: BrokerState = {
  broker: '',
  account: { accountId: '', name: '', currency: '' },
  openLinkAccountModal: false,
  accountType: 'PAPER',
  positions: positionsEntityAdapter.getInitialState(),
  summary: {
    cash: 0,
    etf: 0,
    mutual_fund: 0,
    other: 0,
    aum: 0,
    stock: 0,
    equity: 0,
    buyingPower: 0,
  },
  investmentsTableData: [],
  accounts: accountsEntityAdapter.getInitialState({ loading: false }),
};

export const brokerSlice = createSlice({
  name: 'broker',
  initialState: () => initialState,
  reducers: {
    setBroker: (state, { payload }: PayloadAction<string>) => {
      state.broker = payload;
    },
    setBrokerAccount: (
      state,
      {
        payload,
      }: PayloadAction<Pick<BrokerState, 'account' | 'accountType' | 'broker'>>
    ) => {
      state.broker = payload.broker;
      state.accountType = payload.accountType;
      state.account = payload.account;
    },
    setAccount: (state, { payload }: PayloadAction<BrokerState['account']>) => {
      state.account = payload;
    },
    setAccountId: (state, { payload }: PayloadAction<string>) => {
      state.account.accountId = payload;
    },
    setAccountName: (state, { payload }: PayloadAction<string>) => {
      state.account.name = payload;
    },
    setAccountType: (
      state,
      { payload }: PayloadAction<BrokerState['accountType']>
    ) => {
      state.accountType = payload;
    },
    setOpenLinkAccountModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openLinkAccountModal = payload;
    },
    updateSummary: (state, action: PayloadAction<Partial<BrokerSummaryState>>) => {
      state.summary = { ...state.summary, ...action.payload };
    },
    setAllPositions: (
      state,
      {
        payload,
      }: PayloadAction<Partial<Record<BrokerPositionsAssetType, BrokerPosition[]>>>
    ) => {
      const entities = Object.fromEntries(
        Object.entries(payload).map(([type, value]) => [
          type,
          { asset: type as BrokerPositionsAssetType, positions: value },
        ])
      );

      positionsEntityAdapter.setAll(state.positions, entities);
    },
    setPositionsById: (
      state,
      {
        payload: [id, values],
      }: PayloadAction<[id: BrokerPositionsAssetType, values: BrokerPosition[]]>
    ) => {
      positionsEntityAdapter.setOne(state.positions, {
        asset: id,
        positions: values,
      });
    },
    setInvestmentsTableData: (state, action: PayloadAction<MyInvestments[]>) => {
      state.investmentsTableData = action.payload;
    },
    setAccounts: (state, { payload }: PayloadAction<AccountInfo[]>) => {
      accountsEntityAdapter.setAll(state.accounts, payload);
    },
    setAccountsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.accounts.loading = payload;
    },
    resetBroker: () => initialState,
  },
  /**
   * TODO: Add extraReducers
   * @example
   * extraReducers: (builder) => builder.addMatcher(accountApi.endpoints.accountAll.matchFulfilled, (state, { payload }) => {
    accountsEntityAdapter.setAll(state.accounts, payload);
  })
   */
});

export const {
  actions: {
    resetBroker,
    setAccount,
    setAccountId,
    setAccountName,
    setAccountType,
    setAllPositions,
    setBroker,
    setBrokerAccount,
    setOpenLinkAccountModal,
    setPositionsById,
    setInvestmentsTableData,
    updateSummary,
    setAccounts,
    setAccountsLoading,
  },
  reducer,
} = brokerSlice;
