// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { createTheme } from '@mui/material';

const themeSlider = {
  horizontal: {
    rail: {
      backgroundColor: '#CACED8',
      height: '6px',
    },
    track: {
      height: '6px',
    },
    valueLabel: {
      backgroundColor: 'transparent!important',
      color: '#79828d',
      height: '15px',
      padding: '10px',
    },
    thumb: {
      backgroundColor: 'white',
      border: '3px solid',
      width: '15px',
      height: '15px',
      '&.Mui-disabled': {
        color: '#bdbdbd',
      },
    },
    valueLabelLabel: {
      fontSize: '1em',
    },
  },
  vertical: {
    rail: {
      backgroundColor: '#CACED8',
      height: '168px',
      width: '6px',
    },
    track: {
      width: '6px',
      color: '#1072BA',
      border: 'none',
    },
    valueLabel: {
      backgroundColor: 'transparent!important',
      height: '15px',
      padding: '10px',
    },
    valueLabelLabel: {
      fontSize: '1em',
    },
    thumb: {
      backgroundColor: 'white',
      border: '3px solid',
      width: '15px',
      height: '15px',
    },
  },
};

export const slider = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        rail: ({ ownerState }) => ({
          ...themeSlider[ownerState.orientation as keyof typeof themeSlider].rail,
        }),
        track: ({ ownerState }) => ({
          ...themeSlider[ownerState.orientation as keyof typeof themeSlider].track,
        }),
        valueLabelLabel: ({ ownerState }) => ({
          ...themeSlider[ownerState.orientation as keyof typeof themeSlider]
            .valueLabelLabel,
        }),
        valueLabel: ({ ownerState }) => ({
          ...themeSlider[ownerState.orientation as keyof typeof themeSlider]
            .valueLabel,
        }),
        thumb: ({ ownerState }) => ({
          ...themeSlider[ownerState.orientation as keyof typeof themeSlider].thumb,
          color: '#1072BA',
        }),
      },
    },
  },
});
