// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

export const COMMON = {
  oneYReturn: (
    <p className='text-justify'>
      Percentage change in the value of an investment or asset over one year.
    </p>
  ),

  threeYReturn: (
    <p className='text-justify'>
      Measures the average annualized rate of return of an investment over three
      years.
    </p>
  ),

  fiveYReturn: (
    <p className='text-justify'>
      Measures the average annualized rate of return of an investment over five
      years.
    </p>
  ),

  tenYReturn: (
    <p className='text-justify'>
      Measures the average annualized rate of return of an investment over ten years.
    </p>
  ),

  ytdReturn: (
    <p className='text-justify'>
      Percentage change in the value of an investment or asset from the beginning of
      the current calendar year to the present date.
    </p>
  ),

  threeYVol: (
    <p className='text-justify'>
      3-year annualized volatility | Measures the degree of variation or fluctuation
      in the returns of an investment over three years, expressed as an annualized
      percentage.
    </p>
  ),

  ter: (
    <p className='text-justify'>
      TER (Total Expense Ratio) | Represents the total annual costs associated with
      managing and operating an investment portfolio or asset.
    </p>
  ),
};

export type CommonPopupName = keyof typeof COMMON;
