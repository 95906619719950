// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { fetchBrokerageApi } from 'api/brokerage-api';
import { EquityApi } from 'api/interfaces/brokerage/equity';
import { LatestPricesResp } from 'api/interfaces/commons';

export const equityApi: EquityApi = {
  postPrices: async (params, tickers) => {
    if (tickers.length === 0) return [];

    const { data } = await fetchBrokerageApi<LatestPricesResp[]>({
      endpoint: '/v2/equity/prices/',
      method: 'POST',
      params,
      payload: tickers,
    });
    return data;
  },

  citecPrice: async (tickers): Promise<LatestPricesResp[]> => {
    const { data } = await fetchBrokerageApi<LatestPricesResp[]>({
      endpoint: '/v2/equity/citec/price/',
      method: 'POST',
      payload: tickers,
    });

    return data;
  },
};
