// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { Button, CircularProgress } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { AssetBrand, Table, UsePaginationResult } from 'components';
import { ChartContainer } from 'components/chart-container';
import { TableCellText } from 'features/ui/table/components';
import { capitalizeString } from 'features/utils/capitalize-string';
import { FC, useCallback, useMemo, useState } from 'react';
import { FundHoldingDetail } from '../utils/fetch-fund-holdings';
import { IconButton } from 'features/ui/icon-button';

interface AssetProfileTopHoldingsProps {
  holdings?: FundHoldingDetail[];
  pagination: UsePaginationResult;
  loading: boolean;
  onAnalyzeHoldings: () => void;
  onDownloadHoldings: () => void;
}

const columnHelper = createColumnHelper<FundHoldingDetail>();

export const AssetProfileTopHoldings: FC<AssetProfileTopHoldingsProps> = ({
  pagination,
  loading,
  holdings = [],
  onAnalyzeHoldings,
  onDownloadHoldings,
}) => {
  const [showMore, setShowMore] = useState(() => pagination.pageSize === 50);

  const hasNames = useMemo(
    () => holdings.some(({ name }) => !!name && name !== 'missing'),
    [holdings]
  );

  const handleShowMore = useCallback(() => {
    setShowMore((prev) => !prev);
    pagination.onPaginationChange({
      pageIndex: 0,
      pageSize: 50,
    });
  }, [pagination]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('ticker', {
        header: 'TICKER',
        minSize: 150,
        cell: ({ row }) => {
          const { url_logo, url_morningstar } = row.original;
          return (
            <AssetBrand
              ticker={row.original.ticker}
              assetType='stock'
              logoUrl={url_logo}
              url={url_morningstar}
            />
          );
        },
      }),
      columnHelper.accessor('name', {
        header: 'NAME',
        meta: { initiallyHidden: !hasNames },
        cell: ({ row }) => {
          const { name, ticker } = row.original;
          const value = name == 'missing' ? ticker : capitalizeString(name!);
          return <TableCellText value={value} oneLine />;
        },
      }),
      columnHelper.accessor('isin', {
        header: 'ISIN',
        cell: ({ getValue }) => getValue(),
      }),
      columnHelper.accessor('percentage', {
        header: 'WEIGHT',
        cell: ({ getValue }) => (
          <Table.Data format='percentage' value={getValue() / 100} />
        ),
      }),
    ],
    [hasNames]
  );

  const actions = useMemo(() => {
    return (
      !!holdings.length && (
        <div className='flex items-center gap-4'>
          <IconButton
            data-testid='analysis-button'
            icon='Analysis'
            variant='button'
            onClick={onAnalyzeHoldings}
          />
          <IconButton
            data-testid='download-button'
            icon='Download'
            variant='button'
            onClick={onDownloadHoldings}
          />
        </div>
      )
    );
  }, [holdings.length, onAnalyzeHoldings, onDownloadHoldings]);

  return (
    <ChartContainer
      header='Top Holdings'
      className='w-[678px] h-[540px]'
      headerRight={actions}
    >
      {loading ? (
        <CircularProgress size={50} className='m-auto' color='primary' />
      ) : (
        <div className='overflow-auto flex flex-col gap-4'>
          <Table.Grouped
            columns={columns}
            data={holdings}
            enablePagination={showMore}
            manualPagination
            pageCount={pagination.pageCount}
            pageIndex={pagination.pageIndex}
            pageSize={pagination.pageSize}
            onPaginationChange={pagination.onPaginationChange}
          />
          {!showMore && (
            <Button
              className='self-center'
              color='secondary'
              onClick={handleShowMore}
            >
              Show More
            </Button>
          )}
        </div>
      )}
    </ChartContainer>
  );
};
