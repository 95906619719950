// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { useEffect } from 'react';
import { useConst } from './use-const';

/**
 * A React hook that creates an AbortController instance and returns its signal.
 * The signal can be used to check if a component is still mounted before performing a state update.
 * This is useful to prevent the "Can't perform a React state update on an unmounted component" error.
 *
 * @returns {AbortSignal} The signal of the AbortController instance.
 *
 * @example
 * function MyComponent() {
 *   const unmountSignal = useUnmountSignal();
 *
 *   useEffect(() => {
 *     fetch().then(data => {
 *       if (!unmountSignal.aborted) {
 *         // Update state here
 *       }
 *     });
 *   }, []);
 *
 *   return (...)
 * }
 */
export const useUnmountSignal = (): AbortSignal => {
  const abortController = useConst(() => new AbortController());

  useEffect(() => () => abortController.abort(), [abortController]);

  return abortController.signal;
};
