// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.

import { detectIncognito } from 'detectincognitojs';
import { MODAL_TEMPLATES_OPTIONS, showModal } from 'features/ui/modal';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
  selectIsAuthenticatedUser,
  selectIsLoadingAuthentication,
  selectUserData,
} from 'store/slices/user';
import { authorizeUser, getUser, loginUser, logoutUser } from 'store/slices/user';

export const useAuthentication = () => {
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(selectIsAuthenticatedUser);

  const isLoading = useAppSelector(selectIsLoadingAuthentication);

  const user = useAppSelector(selectUserData);

  const login = useCallback(() => void dispatch(loginUser()), [dispatch]);

  const logout = useCallback(
    () => void dispatch(logoutUser(window.location.origin)),
    [dispatch]
  );

  const getUserData = useCallback(() => void dispatch(getUser()), [dispatch]);

  const authorize = useCallback(() => {
    dispatch(authorizeUser());
  }, [dispatch]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      detectIncognito().then((result) => {
        /* istanbul ignore next */ if (result.isPrivate) {
          // istanbul ignore next
          return showModal({
            ...MODAL_TEMPLATES_OPTIONS.WARN,
            title: `Error in your credentials`,
            content: `For security reasons this application does not support browsers in incognito mode, if
                     this is the case please open the application in a browser in public browsing mode
                     \n- You will be redirected back to the Login page`,
          }).then(() => logout());
        }
      });
    }, 2000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isAuthenticated, login, logout, getUserData, isLoading, user, authorize };
};
