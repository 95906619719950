// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { createAsyncThunk } from '@reduxjs/toolkit';
import { assetsApi } from 'api/ai/assets-api';
import { citecApi } from 'api/engine/citec-api';
import { AssetsComparison, AssetsInfoResp } from 'api/interfaces/ai/assets';
import { PortfolioId } from 'api/interfaces/engine/citec';
import { buildDataIdeas } from 'features/my-research/utils/build-data-ideas';
import { getComparisonAnalysis } from 'features/utils/comparison-request';
import { getAssetsInfo } from 'features/utils/get-assets-info';
import {
  SavedPortfolio,
  WatchListPosition,
} from 'features/utils/interfaces/redux/my-research-state';
import { groupBy } from 'lodash';
import { AppState } from 'store/store';
import { selectComparisonState, selectIdeas } from './my-research-selectors';

export const getSavedPortfolios = createAsyncThunk(
  'myResearch/getSavedPortfolios',
  async (_, { signal }) => {
    try {
      const response = await citecApi.portfolioAll({ detail: 'true' }, signal);

      /* istanbul ignore next */
      const portfolios = response
        .map((idea) =>
          'holdings' in idea
            ? idea
            : {
                benchmark: idea.benchmark,
                creation_date: idea.creation_date,
                holdings: idea.ticker.map((ticker, index) => ({
                  ticker,
                  outstanding_balance: idea.outstanding_balance[index],
                  unrealized_capital_gains: idea.unrealized_capital_gains[index],
                })),
                portfolio: idea.portfolio,
                optimization_status: idea.optimization_status,
                portfolio_id: idea.portfolio_id,
                organizations: idea.organizations,
                owner: idea.owner,
              }
        )
        .map((idea) => ({
          ...idea,
          holdings: idea.holdings.filter((holding) => holding.ticker !== '$CASH'),
        }));

      const tickers_of_ideas = portfolios.flatMap((x) =>
        x.holdings.map((holding) => holding.ticker)
      );

      const unique_tickers_of_ideas = [...new Set(tickers_of_ideas.flat(1))];

      const data = await getAssetsInfo(unique_tickers_of_ideas);

      const ideas = buildDataIdeas(portfolios, data);

      return {
        ideas,
        savedPortfolios: portfolios,
      };
    } catch (error) {
      console.log(error);
      /* istanbul ignore next */ return { ideas: [], savedPortfolios: [] };
    }
  }
);

export const updateSavedPortfolio = createAsyncThunk(
  'myResearch/updateSavedPortfolios',
  async (
    { params, payload }: { params: PortfolioId; payload: SavedPortfolio },
    { getState }
  ) => {
    await citecApi.updatePortfolio(params, payload as SavedPortfolio);

    const portfolios = selectIdeas(getState() as AppState);

    const portfolio = payload as SavedPortfolio;

    const tickers = Array.from(
      new Set(portfolio.holdings.map((holding) => holding.ticker))
    );

    const data = await getAssetsInfo(tickers);

    const ideas = buildDataIdeas(
      portfolios.map((idea) =>
        idea.portfolio_id === portfolio.portfolio_id
          ? { ...idea, ...portfolio }
          : { ...idea }
      ),
      data
    );

    return {
      idea: ideas.find((idea) => idea.portfolio_id === portfolio.portfolio_id)!,
      portfolio: portfolio,
    };
  }
);

export const getWatchListPositions = createAsyncThunk(
  'myResearch/getWatchListPositions',
  async () => {
    const response = await citecApi.getWatchlist();

    const assetTypes = groupBy(response, 'asset_type');

    const requests = Object.keys(assetTypes).map((assetType) =>
      assetsApi.assetsInfo({
        params: { detail: true },
        payload: assetTypes[assetType].map(({ ticker }) => ticker),
        assetType,
      })
    );

    const assetsInfo = await Promise.all(requests);

    const assetsList = assetsInfo.flat();

    const assetsDiff = response.filter(
      (e) => !assetsList.find(({ ticker }) => ticker === e.ticker)
    ) as AssetsInfoResp[];

    assetsList.push(...assetsDiff);

    const watchlist = assetsList.map((item: any) => {
      const watchListItem = response.find(({ ticker }) => ticker === item.ticker);
      return {
        pb: item?.pb,
        pe: item?.pe,
        market_cap: item?.market_cap,
        yield: item?.dividend_yield,
        url_logo: item?.url_logo,
        url_morningstar: item?.url_morningstar,
        ytd_return: item.ytd_return,
        y1_return: item.y1_return,
        y3_return: item.y3_return,
        y5_return: item.y5_return,
        y10_return: item.y10_return,
        y1_vol: item.y1_vol,
        y3_vol: item.y3_vol,
        y5_vol: item.y5_vol,
        y10_vol: item.y10_vol,
        max_draw_down: item.max_draw_down,
        currency: item?.currency,
        last_update: item?.last_update,
        asset_class:
          watchListItem?.asset_type === 'stock' ? 'Equity' : item.asset_class,
        ...watchListItem,
      };
    });

    return watchlist as WatchListPosition[];
  }
);

export const addWatchListPosition = createAsyncThunk(
  'myResearch/addWatchListPosition',
  async (params: { ticker: string; assetType: string }) => {
    const assetInfoResp = await assetsApi.assetsInfo({
      params: { detail: true },
      payload: [params.ticker],
      assetType: params.assetType,
    });

    const assetInfo = assetInfoResp[0] as any;

    /* istanbul ignore next */
    const item = {
      ticker: assetInfo.ticker,
      name: assetInfo.name,
      asset_type: params.assetType,
      isin: assetInfo.isin || '-',
    };

    const [addedItem] = await citecApi.addToWatchlist([item]);

    return {
      ...addedItem,
      pb: assetInfo.pb,
      pe: assetInfo.pe,
      market_cap: assetInfo?.market_cap,
      yield: assetInfo?.dividend_yield,
      url_logo: assetInfo?.url_logo,
      ytd_return: assetInfo.ytd_return,
      y1_return: assetInfo.y1_return,
      y3_return: assetInfo.y3_return,
      y5_return: assetInfo.y5_return,
      y10_return: assetInfo.y10_return,
      y3_vol: assetInfo.y3_vol,
      y5_vol: assetInfo.y5_vol,
      y10_vol: assetInfo.y10_vol,
      max_draw_down: assetInfo.max_draw_down,
      last_update: assetInfo.last_update,
      asset_class:
        addedItem?.asset_type === 'stock' ? 'Equity' : assetInfo.asset_class,
      currency: assetInfo.currency,
    } as WatchListPosition;
  }
);

export const removeWatchListPosition = createAsyncThunk(
  'myResearch/removeWatchListPosition',
  async (params: { ticker: string }) => {
    await citecApi.deleteFromWatchlist({ ticker: params.ticker });

    return params.ticker;
  }
);

export const getScreeners = createAsyncThunk('myResearch/getScreeners', () => {
  const response = citecApi.universeAll();

  return response;
});

export const getComparison = createAsyncThunk(
  'myResearch/getComparison',
  async (
    tickers: string[],
    { getState }
  ): Promise<[AssetsComparison, AssetsInfoResp[]]> => {
    const { benchmark, w_hist: hist_w } = selectComparisonState(getState() as any);
    const payload = Array.from(
      new Set([benchmark, ...tickers].sort((x, y) => x.localeCompare(y)))
    );

    const comparisonAnalysis = await getComparisonAnalysis(hist_w, payload);

    if (tickers.length === 0) return [comparisonAnalysis, []];

    const assetsInfoResponse = await getAssetsInfo(payload);

    return [comparisonAnalysis, assetsInfoResponse];
  }
);
