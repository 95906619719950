// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { Broker } from 'api/interfaces/brokerage/account';
import { BrokerCredentialsResp } from 'api/interfaces/brokerage/commons';
import { brokerAuthorizationPopUp } from 'features/brokers/utils/broker-authorization-popup';
import { appStore as store } from 'store/store';
import { accountApi } from 'store/api/brokerage/accounts-api';

export interface AuthorizationToken {
  code: string;
  state: string;
}

export interface IBKRAuthorizationToken {
  oauth_token: string;
  oauth_verifier: string;
}

export interface BrokerAuthPopupResponse {
  token: string;
  oauth_verifier?: string;
}

interface BrokerAuth {
  tradier: (state: string) => Promise<BrokerAuthPopupResponse>;
  alpaca: (state: string) => Promise<BrokerAuthPopupResponse>;
  interactive_brokers: () => Promise<BrokerAuthPopupResponse>;
}

export const brokerAuthenticationApi: BrokerAuth = {
  tradier: async (state) => {
    const params = {
      state,
      client_id: process.env.REACT_APP_TRADIER_CLIENT_ID as string,
      scope: 'write,trade,market',
    };

    const queryParams = new URLSearchParams(params);
    const codeUri = `https://api.tradier.com/v1/oauth/authorize?${queryParams}`;

    const response = <AuthorizationToken>await brokerAuthorizationPopUp(codeUri);

    return { token: response.code || '' };
  },

  alpaca: async (state) => {
    const params = {
      state,
      client_id: process.env.REACT_APP_ALPACA_CLIENT_ID as string,
      scope: 'account:write trading',
      redirect_uri: process.env.REACT_APP_ALPACA_REDIRECT_URI as string,
      response_type: 'code',
    };

    const queryParams = new URLSearchParams(params);
    const codeUri = `https://app.alpaca.markets/oauth/authorize?${queryParams}`;
    const response = <AuthorizationToken>await brokerAuthorizationPopUp(codeUri);

    return { token: response.code || '' };
  },

  interactive_brokers: async () => {
    const resp = (await store.dispatch(
      accountApi.endpoints.postBrokerCredentials.initiate({
        brokerKey: Broker.IBKR,
        brokerCredentials: { token: '' },
      })
    )) as unknown as { data: BrokerCredentialsResp };

    const brokerUrl = 'https://www.interactivebrokers.com/authorize';
    const params = { oauth_token: resp.data.token };
    const codeUri = `${brokerUrl}?${new URLSearchParams(params)}`;

    const response = <IBKRAuthorizationToken>await brokerAuthorizationPopUp(codeUri);

    return {
      token: response.oauth_token,
      oauth_verifier: response.oauth_verifier,
    };
  },
};
