// Copyright (C) 2021-Present CITEC Inc. <https://citecsolutions.com/>
// All rights reserved
//
// This file is part of CITEC Inc. source code.
// This software framework contains the confidential and proprietary information
// of CITEC Inc., its affiliates, and its licensors. Your use of these
// materials is governed by the terms of the Agreement between your organisation
// and CITEC Inc., and any unauthorised use is forbidden. Except as otherwise
// stated in the Agreement, this software framework is for your internal use
// only and may only be shared outside your organisation with the prior written
// permission of CITEC Inc.
// CITEC Inc. source code can not be copied and/or distributed without the express
// permission of CITEC Inc.
import { classNames } from 'features/utils/classnames';
import {
  FC,
  Fragment,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { AppLink, Paths } from 'routes';
import { ReactComponent as LogoCitec } from 'assets/img/logo-citec.svg';
import { AppIcon, SvgIconName } from './app-icon';
import styles from './navigation-sidebar.module.css';
import { AccountLogo } from './account-logo';
import { getTextBeforeSymbol } from 'features/utils/get-text-before-symbol';
import { useAppSelector } from 'store/store';
import {
  selectAccount,
  selectAccountId,
  selectBrokerConnected,
} from 'store/slices/broker';
import { NavLink, useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from '@mui/material';
import { resetTrades, selectTraderTrades } from 'store/slices/trader';
import { deleteConfirmationPopup } from 'features/utils/delete-confirmation-popup';
import { useDispatch } from 'react-redux';
import { DeleteButton } from 'features/ui/table/components';

export type NavigationLink = {
  title: string;
  to: Paths;
  children?: Array<Pick<NavigationLink, 'title' | 'to'>>;
  icon: SvgIconName;
  separator?: boolean;
  disabled?: boolean;
  description?: string;
  adornment?: ReactNode;
  tooltip?: string;
};

export const NavigationSidebar: FC = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);

  const onDrawerChange = useCallback(() => setOpen(!open), [open]);

  const isBrokerConnected = useAppSelector(selectBrokerConnected);

  const accountId = useAppSelector(selectAccountId);

  const account = useAppSelector(selectAccount);

  const accountName = useMemo(
    () => account.name?.split(' ').slice(0, 2).join(' ') || '',
    [account.name]
  );

  const trades = useAppSelector(selectTraderTrades);

  const location = useLocation();

  const onDeleteTrades: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();

      deleteConfirmationPopup(
        {
          title: 'Delete all trades',
          text: 'Are you sure you want to delete all trades?',
          confirmMessage: 'All trades were deleted',
        },
        (isDeleted) => {
          if (!isDeleted) return;
          dispatch(resetTrades());
        }
      );
    },
    [dispatch]
  );

  const navigationLinks = useMemo<NavigationLink[]>(
    () => [
      {
        title: 'Accounts',
        to: '/',
        children: [],
        icon: 'Account',
      },
      {
        title: 'Dashboard',
        to: '/dashboard',
        children: [],
        icon: 'Dashboard',
        separator: true,
        disabled: !isBrokerConnected || !accountId,
      },
      {
        title: 'Research',
        to: '/research',
        children: [],
        icon: 'Research',
      },
      {
        title: 'AI Builder',
        to: '/ai-builder',
        children: [
          {
            title: 'Stocks',
            to: '/ai-builder/stock',
          },
          {
            title: 'ETFs',
            to: '/ai-builder/etf',
          },
          {
            title: 'Mutual Funds',
            to: '/ai-builder/mutual-fund',
          },
        ],
        icon: 'AiBuilder',
      },
      {
        title: 'Analysis',
        to: '/analysis',
        children: [
          {
            title: 'Stocks',
            to: '/analysis/stock',
          },
          {
            title: 'ETFs',
            to: '/analysis/etf',
          },
          {
            title: 'Mutual Funds',
            to: '/analysis/mutual-fund',
          },
        ],
        icon: 'Analysis',
      },
      {
        title: 'Rebalance',
        to: '/rebalance',
        icon: 'Rebalance',
      },
      {
        title: 'Tax Manager',
        to: '/tax-manager',
        children: [
          {
            title: 'Stocks',
            to: '/tax-manager/stock',
          },
          {
            title: 'ETFs',
            to: '/tax-manager/etf',
          },
        ],
        icon: 'TaxManager',
      },
      {
        title: 'Asset Allocation',
        to: '/asset-allocation',
        icon: 'AssetAllocation',
      },
      {
        title: 'Trader',
        to: '/trader',
        icon: 'Trader',
        description: trades.length > 0 ? `(${trades.length})` : undefined,
        tooltip:
          trades.length > 0
            ? `Trader ${trades.length} order${trades.length > 1 ? 's' : ''} pending`
            : undefined,
        adornment:
          trades.length > 0 ? (
            <DeleteButton
              onClick={onDeleteTrades}
              toolTipTitle='Delete all trades!!!!'
              data-testid='delete-all-trades'
            />
          ) : null,
      },
    ],
    [accountId, isBrokerConnected, onDeleteTrades, trades.length]
  );

  return (
    <aside
      aria-expanded={open}
      className={classNames(styles.container, 'group')}
      data-scrollable
      data-testid='navigation-sidebar'
    >
      <div className={styles.header}>
        <AppLink to='/' className='group-aria-expanded:block hidden'>
          <LogoCitec />
        </AppLink>
        <button
          onClick={onDrawerChange}
          data-testid='navigation-sidebar-drawer-button'
        >
          <AppIcon icon='Menu' color='light' />
        </button>
      </div>
      <hr className={styles.divider} />
      <div>
        {accountName !== '' && (
          <>
            <figure
              className='mb-[18px] mt-7 flex items-center gap-x-3 px-3 aria-expanded:justify-start justify-center'
              aria-expanded={open}
            >
              <AccountLogo className={classNames({ 'ml-3': open })} />
              <figcaption
                className='whitespace-nowrap font-poppins text-lg capitalize leading-6 text-white aria-expanded:block hidden'
                aria-expanded={open}
              >
                {getTextBeforeSymbol(accountName)}
              </figcaption>
            </figure>
            <hr className={styles['list-divider']} />
          </>
        )}
        <nav className={styles.navigation} aria-expanded={open}>
          {Array.from(navigationLinks).map(
            (
              {
                title,
                to,
                icon,
                separator,
                disabled,
                children,
                adornment,
                description,
                tooltip,
              },
              index
            ) => (
              <Fragment key={index}>
                {children?.length && open ? (
                  <Accordion
                    classes={{
                      root: styles.accordion,
                      expanded: styles['accordion-expanded'],
                    }}
                    disableGutters
                  >
                    <AccordionSummary
                      id={to}
                      classes={{
                        root: classNames(styles['accordion-summary'], {
                          [styles['accordion-summary-active']]:
                            location.pathname.includes(to),
                        }),
                        content: classNames(styles['accordion-summary-content']),
                      }}
                    >
                      <span className='flex flex-row gap-x-4 items-center'>
                        <AppIcon icon={icon} size={20} />
                        <span className={styles['link-title']}>{title}</span>
                      </span>
                    </AccordionSummary>
                    <AccordionDetails
                      classes={{ root: classNames(styles['accordion-details']) }}
                    >
                      {children.map(({ title, to }) => (
                        <NavLink
                          to={to}
                          key={to}
                          className={({ isActive }) =>
                            classNames({ 'text-white': isActive })
                          }
                        >
                          {title}
                        </NavLink>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <NavLink
                    to={to}
                    className={({ isActive }) =>
                      classNames(styles.link, { [styles['link-active']]: isActive })
                    }
                    key={to}
                    aria-expanded={open}
                    aria-disabled={disabled}
                    end={to === '/'}
                  >
                    {open ? (
                      <AppIcon icon={icon} size={20} />
                    ) : (
                      <Tooltip title={tooltip ?? title} placement='right'>
                        <AppIcon icon={icon} size={20} />
                      </Tooltip>
                    )}
                    <span className={styles['link-title']} aria-hidden={!open}>
                      {title}&nbsp;
                      {open && description}
                    </span>
                    {open && adornment}
                  </NavLink>
                )}
                {separator && <hr className={styles['list-divider']} />}
              </Fragment>
            )
          )}
        </nav>
      </div>
    </aside>
  );
};
